<template>
  <div class="container">
    <div class="container-body">欢迎使用达芬奇后台</div>
  </div>
</template>

<script>
export default {
  name: 'Index',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: 500;
}

.container-body {
  margin: 0 12px;
  padding: 12px 32px 24px;
  flex-grow: 1;
  background-color: #fff;
}
</style>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">使用记录(使用 e_base_task 埋点生成,数据 T-1)</div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column prop="type" label="类型"></el-table-column>

          <el-table-column label="时间">
            <template slot-scope="scope">
              <span>开始：{{ $moment(scope.row.started_at).format('YYYY-MM-DD HH:mm:ss') }}<br /></span>
              <span>结束：{{ $moment(scope.row.ended_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column label="消费情况">
            <template slot-scope="scope">
              <span>时长：{{ humanReadableSeconds(scope.row.use_duration) }}<br /></span>
              <span>完成率：{{ scope.row.progress }}%</span>
            </template>
          </el-table-column>

          <el-table-column label="结束原因">
            <template slot-scope="scope">
              <span>{{ scope.row.end_reason }}</span>
            </template>
          </el-table-column>

          <el-table-column label="消费资源">
            <template slot-scope="scope">
              <span v-if="scope.row.meditation_album_id">专辑：{{ scope.row.meditation_album_id }}<br /></span>
              <span v-if="scope.row.meditation_section_id">章节：{{ scope.row.meditation_section_id }}<br /></span>
              <span v-if="scope.row.sleepstory_id">睡眠故事：{{ scope.row.sleepstory_id }}<br /></span>
              <span v-if="scope.row.scene_id">声音场景：{{ scope.row.scene_id }}<br /></span>
              <br />
              <span v-if="scope.row.speaker_id">朗诵者：{{ scope.row.speaker_id }}<br /></span>
              <span v-if="scope.row.resource_guid">guid：{{ scope.row.resource_guid }}<br /></span>
              <span v-if="scope.row.resource_hash">hash：{{ scope.row.resource_hash }}<br /></span>
            </template>
          </el-table-column>
          <el-table-column label="资源信息"> - </el-table-column>

          <el-table-column width="240" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'EditEquip',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      busy: false,
      finish: false,
      JSONVisible: false,
      list: [],
      detail: '',
      affects: [],
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        console.log(val);
        this.init();
      },
    },
  },
  methods: {
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    getInfo(data) {
      switch (data.type) {
        case 'meditation':
          return `冥想: ${data.start_info.tags.album_name} - ${data.start_info.tags.section_name} - ${data.start_info.tags.narrator} - ${data.start_info.tags.duration}`;
        case 'scene':
          return `声音场景: ${data.start_info.tags.scene_name || data.start_info.tags.scene_id}`;
        case 'sleep_story':
          return `睡眠故事: ${data.start_info.tags.sleepstory_name || data.start_info.tags.sleepstory_id}`;
        default:
          return '功能使用记录';
      }
    },
    humanReadableSeconds(sec) {
      const hours = Math.floor(sec / 3600);
      const minutes = Math.floor((sec % 3600) / 60);
      const seconds = sec % 60;

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/usages_task`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    init: async function () {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.note {
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

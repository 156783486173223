<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SplashesManager',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

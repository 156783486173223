<template>
  <edit-form :form="form" :list="list" @save="save"></edit-form>
</template>

<script>
import Form from './form';

export default {
  name: 'EditEquip',
  components: {
    EditForm: Form,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      baseRikeUrl,
      list: [],
      form: {
        email: '',
        nickname: '',
        sign: '',
        sex: 0,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        wechat_mp_first_login_time: 0,
        is_pwd_set: false,
        comment_limit: false,
        who_see_my_favourite: 0,
        push: {},
        received_likes: 0,
        created_at: 0,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    save: async function () {
      const form = this.form;
      const data = {
        nickname: form.nickname,
        birthday: parseInt(form.birthday),
        sex: form.sex,
      };
      try {
        const res = await this.$request({
          method: 'PATCH',
          url: `/v1/admin/users/${this.$route.params.user_id}`,
          data,
        });
        this.form = res.data;
        this.$message({
          message: '保存成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(`保存失败, ${e.response.data.msg}`);
      }
    },
    init: async function () {
      const userRes = await this.$request({
        url: `${this.baseRikeUrl}/v1/admin/users/${this.$route.params.user_id}`,
      });
      const commentsRes = await this.$request({
        url: `${this.baseRikeUrl}/v1/admin/users/${this.$route.params.user_id}/comments`,
      });
      this.form = Object.assign({}, this.form, userRes.data);
      this.list = commentsRes.data;
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

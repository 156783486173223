<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">混音记录</div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column label="UserSceneID">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>

          <el-table-column label="封面" width="180">
            <template slot-scope="scope">
              <img class="avatar" :src="scope.row.image" />
            </template>
          </el-table-column>
          <el-table-column label="场景名">
            <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
          </el-table-column>
          <el-table-column width="120" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showMixedScene(scope.row)">详情</el-button>
              <el-button :disabled="!$store.getters.hasEditRole" type="text" @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="详情" :visible.sync="mixedSceneVisible">
        <el-row>
          <el-col :span="4" class="list-item">
            <p>场景名</p>
          </el-col>
          <el-col :span="20" class="list-item">
            <p>{{ getTR(currentMixedScene.name) }}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="list-item">
            <p>封面图</p>
          </el-col>
          <el-col :span="20" class="list-item">
            <img class="avatar" :src="currentMixedScene.image" />
          </el-col>
        </el-row>
        <div v-for="(track_info, index) in currentMixedScene.track_infos">
          <el-row>
            <el-col :span="4" class="list-item">
              <p>{{ `声音场景${index + 1}` }}</p>
            </el-col>
            <el-col :span="4" class="list-item">
              <p>{{ getTR(getScene(track_info.scene_id).name) }}</p>
            </el-col>
            <el-col :span="2" class="list-item">
              <p>音量:</p>
            </el-col>
            <el-col :span="4" class="list-item">
              <p>{{ track_info.volume }}%</p>
            </el-col>
            <el-col :span="2" class="list-item">
              <p>定价:</p>
            </el-col>
            <el-col :span="4" class="list-item">
              <div v-if="getScene(track_info.scene_id).access.includes('free')">免费</div>
              <div v-else-if="getScene(track_info.scene_id).access.includes('subscribe')">订阅</div>
              <template v-else-if="getScene(track_info.scene_id).access.includes('purchase')">
                <div>¥{{ getScene(track_info.scene_id).purchase_info.price }}</div>
              </template>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="copyMixedScene(currentMixedScene)">复制到混音管理</el-button>
        </div>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'UserMixedScenes',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      busy: false,
      finish: false,
      sceneMap: {},
      mixedSceneVisible: false,
      currentMixedScene: {
        name: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
      },
      list: [],
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    deleteItem(item) {
      const that = this;
      this.$confirm('此操作将永久删除此用户的该混音场景, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async function () {
        const itemId = item.id;
        await that.$request({
          method: 'DELETE',
          url: `/v1/admin/users/${that.$route.params.user_id}/mixed_scenes/${item.local_id}`,
        });
        that.list = that.list.filter((item) => {
          return item.id !== itemId;
        });
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    async copyMixedScene(data) {
      // fill creator
      data.creator = {
        en: this.form.nickname,
        'zh-Hans': this.form.nickname,
        'zh-Hant': this.form.nickname,
        ja: this.form.nickname,
        es: this.form.nickname,
        ru: this.form.nickname,
        ko: this.form.nickname,
      };
      data.creator_avatar = this.form.avatar;
      data.status = 'reviewing';
      data.category = 'mixed';
      // clear user-filled fields
      delete data.id;
      delete data.user_id;
      delete data.local_id;
      const sceneRes = await this.$request({
        url: `/v1/admin/market/scenes`,
        method: 'POST',
        data,
      });
      const scene = sceneRes.data;
      this.$router.replace({
        name: 'EditMixedScene',
        params: {
          scene_id: scene.id,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
    showMixedScene(data) {
      this.currentMixedScene = data;
      this.mixedSceneVisible = true;
    },
    fetchSceneList: async function () {
      const scenesRes = await this.$request({
        url: '/v1/admin/scenes',
        params: {
          offset: 0,
          limit: 512,
          category: 'normal',
        },
      });
      for (let v of scenesRes.data) {
        this.sceneMap[v.id] = v;
      }
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/mixed_scenes`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    getScene(id) {
      return this.sceneMap[id];
    },
    init: async function () {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
    },
  },
  created() {
    this.fetchSceneList();
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">OAuth 2.0 Client配置</div>
      </div>
      <el-form class="form container-body">
        <el-form-item label="名字">
          <el-input v-model="form.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" placeholder="请输入内容" v-model="formStr" :autosize="{ minRows: 50 }"> </el-input>
        </el-form-item>
      </el-form>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';

export default {
  name: 'Form',
  components: {
    Container,
  },
  data() {
    return {
      formStr: JSON.stringify({ secret: '', name: '', scopes: [] }, undefined, 4),
    };
  },
  props: {
    form: {
      type: Object,
    },
  },
  watch: {
    // 如果 `form` 发生改变，这个函数就会运行
    form: function(newObj, oldObj) {
      console.log(newObj, oldObj);
      this.formStr = JSON.stringify(newObj, undefined, 4);
    },
  },
  methods: {
    async save() {
      try {
        var newForm = JSON.parse(this.formStr);
        this.$emit('save', newForm);
      } catch (e) {
        this.$message.error('解析JSON失败');
        return;
      }
    },
  },
};
</script>
<style lang="less" scoped></style>

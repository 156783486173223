<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">参与者配置</div>
      </div>
      <el-form class="form container-body" label-position="top" v-model="form">
        <el-form-item>
          <el-tabs type="border-card" v-model="activeLang">
            <el-tab-pane v-for="lang in langs" :key="lang" :label="lang" :name="lang">
              <el-col :span="23">
                <el-form-item label="参与者名称">
                  <el-input v-model="form.name_i18n[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="参与者头衔">
                  <el-input v-model="form.title[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="参与者介绍">
                  <el-input type="textarea" :rows="3" v-model="form.description[lang]"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="23" v-if="lang === 'zh-Hant'">
                <el-form-item>
                  <el-button type="text" @click="s2t4album">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="嘉宾">
          <el-radio-group v-model="form.special">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="角色">
          <el-radio-group v-model="form.role">
            <el-radio label="author">作者</el-radio>
            <el-radio label="speaker">朗读者</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="语言">
          <el-radio-group v-model="form.language">
            <el-radio label="zh">中文</el-radio>
            <el-radio label="en">英文</el-radio>
            <el-radio label="zh_yue">粤语</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="头像">
          <image-uploader v-model="form.avatar" resourceFolder="scenes"></image-uploader>
        </el-form-item>
      </el-form>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import ImageUploader from '@/components/image-uploader';
import OpenCC from '@/plugins/opencc';

export default {
  name: 'ParticipantForm',
  components: {
    Container,
    ImageUploader,
  },
  model: {
    prop: 'form',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      langs: require('@/langs.json'),
      activeLang: 'zh-Hans',

      busy: false,
      finish: false,
    };
  },
  methods: {
    save() {
      this.$emit('save', this.form);
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    async s2t4album() {
      try {
        this.form.name_i18n['zh-Hant'] = this.opencc_twp(this.form.name_i18n['zh-Hans']);
        this.form.title['zh-Hant'] = this.opencc_twp(this.form.title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_twp(this.form.description['zh-Hans']);
      } catch (e) {
        console.log(e);
        this.$message.error('转换失败');
      }
    },
  },
  created: async function () {
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.layers {
  display: inline-flex;
}

.uploader-container {
  display: inline-flex;
  vertical-align: top;
}

.layer-container {
  position: relative;
  width: 480px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.layer {
  position: absolute;
  max-width: 480px;
  max-height: 160px;
  object-fit: cover;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  line-height: 28px;
}
.preview:hover .layer-actions {
  opacity: 1;
}
.layer-actions {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);

  i {
    font-size: 16px;
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.48);
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 0.72);
    }
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
</style>

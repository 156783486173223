<template>
  <router-view v-if="$route.name !== 'LessonsSearch'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">资源列表</div>
          <div class="container-body-header-extra">
            <el-form :model="form" @submit.native.prevent="submit">
              <el-input v-model="form.keyword" class="input-with-select" name="keyword" size="small" placeholder="请输入要搜索的关键字">
                <el-select v-model="searchType" slot="prepend" placeholder="请选择" class="input-select">
                  <el-option label="内容" value="content"></el-option>
                </el-select>
                <el-button slot="append" native-type="submit" icon="el-icon-search"></el-button>
              </el-input>
            </el-form>
          </div>
        </div>
        <div class="container-body">
          <el-menu mode="horizontal" router :default-active="defaultActive">
            <el-menu-item :index="`/rike/lessons/manager/${year}/search`" style="float: right">搜索</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year - 1}-01`">&lt;&lt;</el-menu-item>
            <li class="el-menu-item">{{ year }}</li>
            <el-menu-item :index="`/rike/lessons/manager/${year}-01`">01</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-02`">02</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-03`">03</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-04`">04</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-05`">05</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-06`">06</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-07`">07</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-08`">08</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-09`">09</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-10`">10</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-11`">11</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-12`">12</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year + 1}-01`">&gt;&gt;</el-menu-item>
          </el-menu>
        </div>
        <div class="container-body">
          <div v-if="!list.length">
            <div class="list-item" style="justify-content: center">暂无结果</div>
          </div>
          <div v-else>
            <div class="list-item" v-for="item in list" :key="item.date_by_day">
              <div class="list-main-item">
                <div class="list-item-name">{{ item.title }}</div>
                <div>{{ item.provenance }}</div>
              </div>
              <div class="list-item-attr">
                <div>Author</div>
                <div>{{ item.author.name }}</div>
              </div>
              <div class="list-item-attr">
                <div>Date</div>
                <div>{{ item.date_by_day }}</div>
              </div>
              <div class="list-item-actions">
                <el-button type="text" @click="gotoComments(item)">查看评论</el-button>
                <el-button type="text" @click="showJSON(item)">JSON</el-button>
                <el-button type="text" @click="editItem(item)">编辑</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'rike/lessonsManager',
  components: {
    Container,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      baseRikeUrl,
      searchType: 'content',
      form: {
        keyword: '',
      },
      busy: false,
      finish: false,
      lang: 'zh-Hans',
      list: [],
      popoverAdvancedVisible: false,
      JSONVisible: false,
      detail: '',
      year: this.$route.params.year * 1,
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'rike/lessonsManager') {
        this.refetch();
      }
    },
    searchType(type) {
      localStorage.setItem('lesson-search-type', type);
    },
  },
  methods: {
    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.JSONVisible = true;
    },
    editItem(item) {
      const year = `${item.date_by_day}`.slice(0, 4);
      const month = `${item.date_by_day}`.slice(4, 6);
      const day = `${item.date_by_day}`.slice(6, 8);
      this.$router.push({
        name: 'LessonEditor',
        params: { year, month, day },
      });
    },
    submit() {
      this.popoverAdvancedVisible = false;
      this.refetch();
    },
    refetch() {
      this.fetchList();
    },
    fetchList: async function () {
      const { keyword } = this.form;
      console.log(keyword);
      const params = {};
      params.article = keyword;
      const res = await this.$request({
        url: `${this.baseRikeUrl}/v1/admin/lessons/search`,
        params,
      });
      const list = res.data;
      this.list = list;
    },
  },
  created() {
    const searchType = localStorage.getItem('lesson-search-type');
    if (searchType) {
      this.searchType = searchType;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.list-item-attr {
  flex-basis: 60px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.input-select {
  width: 80px;
}
</style>

<template>
  <div class="previews">
    <div class="table">
      <div v-if="imageInfo" class="devices">
        <div v-for="device in devices" :key="device.name" class="device">
          <div>
            <device-preview
              @click="device.showDetail = true"
              origin
              :key="device.name"
              :device="device.name"
              :image-info="imageInfo"
              :crop="crop"
              :lang="activeLang"
              :scale="device.scale"
              :data="{
                date: form.date,
                content: form.content,
                pic_url: oUrl,
              }"
            ></device-preview>
          </div>
        </div>
        <div class="device">
          <div>
            <tide-v2-preview
              @click="dialogShow.tidev2 = true"
              origin
              :image-info="imageInfo"
              :crop="crop"
              :lang="activeLang"
              :scale="0.15"
              :data="{
                date: form.date,
                content: form.content,
                pic_url: oUrl,
              }"
            ></tide-v2-preview>
          </div>
        </div>
        <div class="device">
          <div>
            <dailypic-preview
              @click="dialogShow.dailypic = true"
              origin
              :image-info="imageInfo"
              :crop="crop"
              :lang="activeLang"
              :scale="0.1"
              :data="{
                date: form.date,
                content: form.content,
                pic_url: oUrl,
              }"
            ></dailypic-preview>
          </div>
        </div>
        <div class="device">
          <div>
            <focus-share-pic-preview
              @click="dialogShow.focusSharePic = true"
              origin
              :image-info="imageInfo"
              :crop="crop"
              :lang="activeLang"
              :scale="0.1"
              :data="{
                date: form.date,
                content: form.content,
                pic_url: oUrl,
              }"
            ></focus-share-pic-preview>
          </div>
        </div>
        <div class="device">
          <div>
            <sleep-share-pic-preview
              @click="dialogShow.sleepSharePic = true"
              origin
              :image-info="imageInfo"
              :crop="crop"
              :lang="activeLang"
              :scale="0.1"
              :data="{
                date: form.date,
                content: form.content,
                pic_url: oUrl,
              }"
            ></sleep-share-pic-preview>
          </div>
        </div>
        <div class="device">
          <div>
            <wx-calendar-preview
              @click="dialogShow.wxCalendar = true"
              origin
              :image-info="imageInfo"
              :crop="crop"
              :lang="activeLang"
              :scale="0.1"
              :data="{
                date: form.date,
                content: form.content,
                pic_url: oUrl,
              }"
            ></wx-calendar-preview>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-for="device in devices" :key="device.name" :title="device.name" :visible.sync="device.showDetail">
      <device-preview
        v-if="imageInfo"
        origin
        :key="device.name"
        :device="device.name"
        :image-info="imageInfo"
        :crop="crop"
        :lang="activeLang"
        :scale="device.scale * 2.5"
        :data="{
          date: form.date,
          content: form.content,
          pic_url: oUrl,
        }"
      ></device-preview>
    </el-dialog>

    <el-dialog title="潮汐2.0首页" :visible.sync="dialogShow.tidev2">
      <tide-v2-preview
        origin
        :image-info="imageInfo"
        :crop="crop"
        :lang="activeLang"
        :scale="0.5"
        :data="{
          date: form.date,
          content: form.content,
          pic_url: oUrl,
        }"
      ></tide-v2-preview>
    </el-dialog>

    <el-dialog title="日帖分享卡片" :visible.sync="dialogShow.dailypic">
      <dailypic-preview
        origin
        :image-info="imageInfo"
        :crop="crop"
        :lang="activeLang"
        :scale="0.5"
        :data="{
          date: form.date,
          content: form.content,
          pic_url: oUrl,
        }"
      ></dailypic-preview>
    </el-dialog>

    <el-dialog title="专注分享卡片" :visible.sync="dialogShow.focusSharePic">
      <focus-share-pic-preview
        origin
        :image-info="imageInfo"
        :crop="crop"
        :lang="activeLang"
        :scale="0.5"
        :data="{
          date: form.date,
          content: form.content,
          pic_url: oUrl,
        }"
      ></focus-share-pic-preview>
    </el-dialog>

    <el-dialog title="睡眠分享卡片" :visible.sync="dialogShow.sleepSharePic">
      <sleep-share-pic-preview
        origin
        :image-info="imageInfo"
        :crop="crop"
        :lang="activeLang"
        :scale="0.5"
        :data="{
          date: form.date,
          content: form.content,
          pic_url: oUrl,
        }"
      ></sleep-share-pic-preview>
    </el-dialog>

    <el-dialog title="小程序分享卡片" :visible.sync="dialogShow.wxCalendar">
      <wx-calendar-preview
        origin
        :image-info="imageInfo"
        :crop="crop"
        :lang="activeLang"
        :scale="0.5"
        :data="{
          date: form.date,
          content: form.content,
          pic_url: oUrl,
        }"
      ></wx-calendar-preview>
    </el-dialog>
  </div>
</template>

<script>
import DevicePreview from './previews/device-preview';
import TideV2Preview from './previews/tide-v2-preview';
import DailypicPreview from './previews/dailypic-preview';
import FocusSharePicPreview from './previews/focus-share-pic-preview';
import SleepSharePicPreview from './previews/sleep-share-pic-preview';
import wxCalendarPreview from './previews/wx-calendar-preview';
export default {
  name: 'Darkroom',
  components: {
    DevicePreview,
    TideV2Preview,
    DailypicPreview,
    FocusSharePicPreview,
    SleepSharePicPreview,
    wxCalendarPreview,
  },
  props: {
    form: {
      type: Object,
    },
    activeLang: {
      type: String,
      default: 'zh-Hans',
    },
    imageInfo: {
      type: Object,
    },
    crop: {
      type: Object,
    },
  },
  data() {
    return {
      devices: [
        { name: 'iPad', scale: 0.2, showDetail: false },
        { name: 'iPad-Landscape', scale: 0.3, showDetail: false },
        { name: 'iPhone-X', scale: 0.4, showDetail: false },
        { name: 'iPhone-8', scale: 0.4, showDetail: false },
      ],
      activeCropper: false,
      dialogShow: {
        tidev2: false,
        dailypic: false,
        focusSharePic: false,
        sleepSharePic: false,
        wxCalendar: false,
      },
    };
  },
  computed: {
    oUrl: {
      get() {
        let url = this.form.pic_url;
        if (this.form.raw_pic) {
          url = this.form.raw_pic.split('?')[0];
        }
        return url || '';
      },
      set(url) {
        this.form.raw_pic = url;
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.devices {
  display: flex;
  flex-wrap: wrap;
}
.device {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 25%;
  height: 320px;
}
.cropper-wrapper {
  display: flex;
  margin: 0 auto;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
.cropper-wrapper-2 {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.cropper-padding {
  display: block;
  visibility: hidden;
}
.vue-cropper.cropper {
  position: absolute;
  left: 0;
  top: 0;
}
.preview-wrapper {
  flex-grow: 1;
}
.upload-wrapper {
  display: inline-block;
  margin: 16px auto;
}
</style>

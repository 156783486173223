<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddScene',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        sort_key: 0,
        name: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        description: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        benefits: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        image: '',
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      try {
        const res = await this.$request({
          url: '/v1/admin/meditation/skills',
          method: 'POST',
          data,
        });
        const skill = res.data;
        this.$router.replace({
          name: 'EditMedSkill',
          params: {
            skill_id: skill.id,
          },
        });
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<script>
import allStatus from '@/login_status.json';

export default {
  name: 'LoginStatusSelecter',
  props: {
    value: Array,
    selected: Array,
  },
  data() {
    return {
      allStatus: allStatus,
    };
  },
  watch: {
    value(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<template>
  <el-select v-model="value" multiple clearable filterable default-first-option placeholder="请选择登录状态">
    <el-option v-for="item in allStatus" :key="item.key" :label="item.name" :value="item.key"></el-option>
  </el-select>
</template>

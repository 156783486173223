<template>
  <div>
    <div class="preview voice" v-if="uploading" v-loading="uploading">
      <i class="material-icons">queue_music</i>
      <span>正在上传</span>
    </div>

    <div class="preview voice" v-else-if="hashKey">
      <i class="material-icons">queue_music</i>
      <span style="color: green">已经上传[密]</span>
      <div class="preview-cover">
        <el-button type="text" @click="startPlay" icon="el-icon-video-play">播放</el-button>
        <el-button type="text" @click="clearVideo" icon="el-icon-delete" v-if="!onlyShow">清除</el-button>
      </div>
    </div>

    <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadProgress" :on-error="uploadError" :on-success="uploadSuccess" :before-upload="beforeUpload" :data="{ token: uploadToken }" :show-file-list="false">
      <div class="preview">
        <i class="el-icon-upload"></i>
        <span style="color: red">未上传[密]</span>
      </div>
    </el-upload>
    <el-input v-model="hashKey" size="mini" disabled />

    <el-dialog title="预览视频" :visible.sync="dialogDisplay.videoPreview">
      <el-input v-model="hashKey" disabled />
      <el-input v-model="videoUrl" disabled />

      <br />
      <br />
      <video :src="videoUrl" width="40%" autoplay controls></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'EncryptVideoUploader',
  props: {
    hashKey: String,
    resourceFolder: {
      type: String,
      required: true,
    },
    persistentOps: {
      type: String,
      default: '',
    },
    onlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogDisplay: {
        videoPreview: false,
      },
      videoUrl: '',
      uploading: false,
      uploadToken: '',
    };
  },
  methods: {
    async startPlay() {
      const res = await this.$request({
        method: 'GET',
        url: '/v1/admin/exchange_resource_download_url',
        params: { hash_key: this.hashKey },
      });
      this.videoUrl = res.data.url;
      this.dialogDisplay.videoPreview = true;
    },
    clearVideo() {
      this.hashKey = '';
      this.$emit('change', { hash: '', key: '' });
    },
    beforeUpload: async function () {
      if (!this.resourceFolder) {
        this.$message.error('请设置资源文件夹');
        return false;
      }
      if (this.onlyShow) {
        this.$message.error('只能查看，不能上传');
        return false;
      }

      const getTokenResp = await this.$request({
        url: '/v1/admin/get_encrypt_resource_upload_token',
        method: 'POST',
        data: {
          folder: this.resourceFolder,
          persistent_ops: this.persistentOps,
        },
      });
      this.uploadToken = getTokenResp.data.token;
    },

    uploadSuccess(response) {
      const finishUpload = (result) => {
        this.uploading = false;
        if (!result) {
          this.$message.error('上传失败，请重试');
          return;
        }
        this.$emit('change', response);
        return;
      };

      const checkPersistentOperation = (() => {
        const MAX_RETRIES = 100;
        let retries = 0;

        return () => {
          this.$request({
            method: 'POST',
            url: '/v1/admin/qiniu_api_proxy',
            data: {
              url: `https://api.qiniu.com/status/get/prefop?id=${response.persistentId}`,
            },
          })
            .then((res) => {
              if (res.data.code === 0) {
                finishUpload(true);
              } else if (res.data.code === 3) {
                finishUpload(false);
              } else if (retries > MAX_RETRIES) {
                finishUpload(false);
              } else {
                retries++;
                setTimeout(checkPersistentOperation, 1000);
              }
            })
            .catch((error) => {
              console.error('Persistent operation error:', error);
              finishUpload(false);
            });
        };
      })();

      response.persistentId ? checkPersistentOperation() : finishUpload(true);
    },

    uploadProgress() {
      this.uploading = true;
    },
    uploadError() {
      this.uploading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.preview {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-cover {
  opacity: 1;
}
.preview-cover {
  /* opacity: 0; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  /deep/ i {
    color: #fff;
  }
  /deep/ span {
    color: #fff;
  }
}
.preview-dialog-img {
  width: 100%;
}

.preview {
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

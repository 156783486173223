<template>
  <router-view v-if="$route.name !== 'OAuth2Scopes'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">OAuth 2.0</div>
          <div class="container-body-header-extra">
            <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">添加</el-button>
          </div>
        </div>
        <div class="container-body">
          <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
            <el-table-column prop="id" label="ID" width="210px"></el-table-column>
            <el-table-column prop="name" label="Name" width="210px"></el-table-column>
            <el-table-column label="SceneIDs">
              <template slot-scope="scope">
                <span>{{ JSON.stringify(scope.row.scene_ids || []) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="MeditationIDs">
              <template slot-scope="scope">
                <span>{{ JSON.stringify(scope.row.meditation_ids || []) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="SleepStoryIDs">
              <template slot-scope="scope">
                <span>{{ JSON.stringify(scope.row.sleep_story_ids || []) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="config">
              <template slot-scope="scope">
                <span>return_all: {{ JSON.stringify(scope.row.return_all) }}<br /></span>
                <span>filter_charge: {{ JSON.stringify(scope.row.filter_charge) }}<br /></span>
                <span>filter_meditation_multi_chapter: {{ JSON.stringify(scope.row.filter_meditation_multi_chapter) }}<br /></span>
                <span>废弃hide_resource: {{ JSON.stringify(scope.row.hide_resource) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Created At">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.created_at * 1000) }}</span
                ><br />
                <span>{{ $moment(scope.row.updated_at * 1000) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="right" width="50px">
              <template slot-scope="scope">
                <router-link class="el-button el-button--text" :to="{ name: 'EditOAuth2Scope', params: { scope_id: scope.row.id } }">编辑</router-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'OAuth2Scopes',
  components: {
    Container,
  },
  data() {
    return {
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'OAuth2Scopes') {
        this.refetch();
      }
    },
  },
  methods: {
    addItem() {
      this.$router.push({
        name: 'AddOAuth2Scope',
      });
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: '/v1/admin/oauth2/scopes',
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
</style>

<template>
  <router-view v-if="$route.name !== 'MedReportPageRecommends'"></router-view>
  <container v-else>
    <div class="container" slot="container">
      <div class="container-body-header">
        <div class="container-body-header-title">报告页推荐管理</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">添加</el-button>
        </div>
      </div>

      <div class="container-body">
        <div class="container-flex">
          <div class="container-body-left">
            <el-form :inline="true">
              <el-form-item label="推荐展示位置：">
                <el-select v-model="location" default-first-option>
                  <el-option label="全部" value></el-option>
                  <el-option label="专注" value="focus"></el-option>
                  <el-option label="睡眠" value="sleep"></el-option>
                  <el-option label="小憩" value="nap"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="container-body-right"></div>
        </div>
        <div v-if="ready">
          <div class="list-item" :class="recommend.status" v-for="recommend in recommends" :key="recommend.id">
            <img class="avatar" :src="`${getAlbumPic(recommend)}?imageMogr2/thumbnail/!120x120r`" />

            <div style="margin-left: 16px; flex-basis: 60px">
              <div>专辑名称</div>
              <div>{{ getAlbumName(recommend) }}</div>
            </div>

            <div style="margin-left: 16px; flex-basis: 80px">
              <div>推荐页位置</div>
              <div>{{ getRecommendLocation(recommend.location) }}</div>
            </div>

            <div style="margin-left: 16px; flex-basis: 180px">
              <div>描述语</div>
              <div>{{ getTR(recommend.description) }}</div>
            </div>

            <div style="margin-left: 16px; flex-basis: 180px">
              <p>{{ getRecommendCondition(recommend.condition) }}</p>
            </div>

            <div class="list-item-actions" style="flex-basis: 180px">
              <template v-if="!recommend.preset">
                <router-link class="el-button el-button--text" :to="{ name: 'EditMedReportPageRecommend', params: { recommend_id: recommend.id } }">编辑</router-link>
                <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(recommend)">删除</el-button>
              </template>
              <template v-else>内置场景</template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';

export default {
  name: 'MedReportPageRecommends',
  components: {
    Container,
  },
  data() {
    return {
      recommends: [],
      albumDict: {},
      ready: false,
      location: '',
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'MedReportPageRecommends') {
        this.init();
      }
    },
    location() {
      this.init();
    },
  },
  methods: {
    addItem() {
      this.$router.push({
        name: 'AddMedReportPageRecommend',
      });
    },
    async removeItem(item) {
      const { value } = await this.$prompt('此操作将永久删除该推荐位，请输入 “确定删除” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (value !== '确定删除') {
        this.$message.error('输入错误');
      } else {
        try {
          await this.$request({
            url: `/v1/admin/report_page_recommendations/${item.id}`,
            method: 'DELETE',
          });
          this.recommends = this.recommends.filter(recommend => recommend.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('操作失败');
        }
      }
    },
    async init() {
      this.fetchList();
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    fetchList: async function() {
      const recommendsRes = await this.$request({
        url: '/v1/admin/report_page_recommendations',
        params: { location: this.location },
      });
      this.recommends = recommendsRes.data;
    },
    getAlbumPic(recommend) {
      if (!recommend.meditation_album_id) {
        return '';
      }
      console.log(this.albumDict);
      return this.albumDict[recommend.meditation_album_id].image;
    },
    getRecommendLocation(location) {
      switch (location) {
        case 'sleep':
          return '睡眠报告页';
        case 'nap':
          return '小憩报告页';
        case 'focus':
          return '专注报告页';
      }
    },
    getRecommendCondition(condition) {
      const conditionText = [];
      if (condition.current_day_focus_duration_gte) {
        conditionText.push(`当日专注时长大于等于${condition.current_day_focus_duration_gte}分钟`);
      }
      if (condition.current_day_focus_times_gte) {
        conditionText.push(`当日专注次数大于等于${condition.current_day_focus_times_gte}次`);
      }
      if (condition.current_focus_duration_gte) {
        conditionText.push(`单次专注时长大于等于${condition.current_focus_duration_gte}分钟`);
      }
      return conditionText.join(', ');
    },
    getAlbumName(recommend) {
      if (!recommend.meditation_album_id) {
        return '';
      }
      return this.getTR(this.albumDict[recommend.meditation_album_id].name);
    },
    async fetchAlbums() {
      this.albumDict = {};
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: { offset: 0, limit: 999 },
      });
      for (let album of res.data) {
        this.albumDict[album.id] = album;
      }
    },
  },
  async created() {
    await this.init();
    await this.fetchAlbums();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  .select-btn {
    display: inline-block;
  }
  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;
  .select-btn {
    display: none;
  }
  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.reviewing {
  background-color: rgba(255, 126, 126, 0.12);
}

.sort-list-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

import Layout from '@/components/layout';

import LessonsManager from '@/pages/rike/lessons/index';
import LessonsSearch from '@/pages/rike/lessons/search';
import LessonEditor from '@/pages/rike/lessons/edit';
import LessonComments from '@/pages/rike/lessons/comments';
import RikeUsersQuery from '@/pages/rike/users/query';
import RikeUserComments from '@/pages/rike/users/comments';
import RikeReportsManager from '@/pages/rike/reports/index';

import RikeContribute from '@/pages/rike/contribute/index';

export default [
  {
    path: '/rike/lessons',
    name: 'LessonsIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '岛读',
    },
    children: [
      {
        path: 'manager/:year/search',
        name: 'LessonsSearch',
        components: {
          default: LessonsSearch,
        },
        meta: {
          requireAuth: true,
          label: '资源搜索',
          newDesign: true,
        },
      },
      {
        path: 'manager/:year-:month',
        name: 'LessonsManager',
        components: {
          default: LessonsManager,
        },
        meta: {
          requireAuth: true,
          label: '鲁迅语录',
          newDesign: true,
        },
        children: [
          {
            path: ':day/edit',
            name: 'LessonEditor',
            components: {
              default: LessonEditor,
            },
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
          {
            path: ':day/comments',
            name: 'LessonComments',
            components: {
              default: LessonComments,
            },
            meta: {
              requireAuth: true,
              label: '查看评论',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/rike/contribute',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '资源管理',
      newDesign: true,
    },
    children: [
      {
        path: '/',
        name: 'RikeContribute',
        components: {
          default: RikeContribute,
        },
        meta: {
          requireAuth: true,
          label: '投稿箱',
          newDesign: true,
        },
      },
    ],
  },

  {
    path: '/rike/users',
    name: 'RikeUsersIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '资源管理',
      newDesign: true,
    },
    children: [
      {
        path: 'query',
        name: 'RikeUsersQuery',
        components: {
          default: RikeUsersQuery,
        },
        meta: {
          requireAuth: true,
          label: '用户查询',
          newDesign: true,
        },
      },
      {
        path: ':user_id/comments',
        name: 'RikeUserComments',
        components: {
          default: RikeUserComments,
        },
        meta: {
          requireAuth: true,
          label: '用户查询',
          newDesign: true,
        },
      },
    ],
  },

  {
    path: '/rike/reports',
    name: 'RikeReportsIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '资源管理',
      newDesign: true,
    },
    children: [
      {
        path: 'manager',
        name: 'RikeReportsManager',
        components: {
          default: RikeReportsManager,
        },
        meta: {
          requireAuth: true,
          label: '用户评论',
          newDesign: true,
        },
      },
    ],
  },
];

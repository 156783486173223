<template>
  <container>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">book</i>
        <h3 slot="title">
          {{ $route.params.recommendation_location === 'explorer' ? '用户推荐管理' : '首页推荐管理' }}
        </h3>
        <p slot="desc">仅支持3.5.0之后版本</p>
      </page-header>
    </div>
    <div class="container" slot="container">
      <div class="container-head">
        <div class="container-head-title">
          {{ $route.params.recommendation_location === 'explorer' ? '用户推荐管理' : '首页推荐管理' }}
        </div>
        <div class="container-head-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="gotoAdder" type="text">新增推荐</el-button>
        </div>
      </div>
      <div class="container-body">
        <el-table :data="list" stripe style="width: 100%">
          <el-table-column label="名称">
            <template slot-scope="scope">
              {{
                scope.row.type === 'scene'
                  ? getTR((scope.row.scene || {}).name)
                  : scope.row.type === 'mixed_scene'
                  ? getTR((scope.row.mixed_scene || {}).name)
                  : scope.row.type === 'meditation'
                  ? getTR((scope.row.meditation || {}).name)
                  : scope.row.type === 'article'
                  ? getTR((scope.row.article || {}).title)
                  : scope.row.type === 'sleep_story'
                  ? getTR((scope.row.sleep_story || {}).name)
                  : '未知'
              }}
            </template>
          </el-table-column>

          <el-table-column label="推荐用户" v-if="$route.params.recommendation_location === 'explorer'">
            <template slot-scope="scope">{{ getTR(scope.row.author_nickname) }}</template>
          </el-table-column>

          <el-table-column label="类型">
            <template slot-scope="scope">
              {{ scope.row.type === 'scene' ? '声音场景' : scope.row.type === 'mixed_scene' ? '混音场景' : scope.row.type === 'meditation' ? '冥想练习' : scope.row.type === 'article' ? '图文' : scope.row.type === 'sleep_story' ? '睡前故事' : '未知' }}
            </template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
            </template>
          </el-table-column>

          <el-table-column label="生效时间" v-if="$route.params.recommendation_location === 'home'">
            <template slot-scope="scope">{{ $moment(scope.row.start_local_time).format('YYYY-MM-DD HH:mm:ss') }}</template>
          </el-table-column>
          <el-table-column label="失效时间" v-if="$route.params.recommendation_location === 'home'">
            <template slot-scope="scope">{{ $moment(scope.row.end_local_time).format('YYYY-MM-DD HH:mm:ss') }}</template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="gotoEditor(scope.row)">编辑</el-button>
              <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from '@/components/page-header';

export default {
  name: 'Recommendations',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      ready: false,
      busy: false,
      finish: false,
      list: [],
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'Recommendations') {
        this.refetch();
      }
    },
    '$route.params.recommendation_location'() {
      this.refetch();
    },
  },
  methods: {
    gotoAdder() {
      this.$router.push({
        name: 'RecommendationsAdder',
      });
    },
    gotoEditor(recommendInfo) {
      this.$router.push({
        name: 'RecommendationsEditor',
        params: {
          recommend_id: recommendInfo.id,
        },
      });
    },
    async removeItem(item) {
      const { value } = await this.$prompt('此操作将永久删除该用户推荐，请输入 “确定删除” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (value !== '确定删除') {
        this.$message.error('输入错误');
      } else {
        try {
          await this.$request({
            url: `/v2/admin/explorer/recommendations/${item.id}`,
            method: 'DELETE',
          });
          this.list = this.list.filter(recommend => recommend.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('操作失败');
        }
      }
    },
    cancelSort() {
      this.init();
      this.sortChanged = false;
    },
    async saveSort() {
      await this.$request({
        url: '/v1/admin/scenes/sort_keys',
        method: 'POST',
        data: this.list.map(({ id, sort_key: sortKey }) => {
          return {
            id,
            sort_key: sortKey,
          };
        }),
      });
      this.sortChanged = false;
      this.$message({
        message: '操作成功',
        type: 'success',
      });
    },
    async init() {
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetchList();
    },
    fetchList: async function() {
      const { recommendation_location: location } = this.$route.params;
      const res = await this.$request({
        url: '/v2/admin/explorer/recommendations',
        params: {
          location: location,
        },
      });
      this.list = res.data;
    },
    getTR(langDict) {
      if (!langDict) {
        return '';
      }
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  async created() {
    await this.init();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.image {
  width: 48px;
  height: 48px;
}
</style>

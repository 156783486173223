<template>
  <div class="main">
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-01' } }">1月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-02' } }">2月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-03' } }">3月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-04' } }">4月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-05' } }">5月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-06' } }">6月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-07' } }">7月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-08' } }">8月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-09' } }">9月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-10' } }">10月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-11' } }">11月</router-link>
    <router-link :to="{ name: 'TidetimeManager', params: { month: '2018-12' } }">12月</router-link>
  </div>
</template>

<script>
export default {
  name: 'Users',
  components: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <router-view v-if="$route.name !== 'ClientMetrics'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">客户端上报</div>
        </div>
        <div class="container-body">
          <div class="container-body-left">
            <el-form :inline="true">
              <el-form-item>
                <el-date-picker v-model="dateRange" type="daterange" value-format="yyyy-MM-dd" :clearable="false" :disabled="busy" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="userID" name="userID" placeholder="UserID" :disabled="busy"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="deviceID" name="deviceID" placeholder="DeviceID" :disabled="busy"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="event" name="event" placeholder="事件名" :disabled="busy"></el-input>
              </el-form-item>
              <el-button @click="onClick">搜索</el-button>
            </el-form>
          </div>
          <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
            <el-table-column label="时间">
              <template slot-scope="scope">
                {{ scope.row.event }}<br />
                客户端：{{ $moment(scope.row.ts).format('YYYY-MM-DD HH:mm:ss Z') }}
                <br />
                服务端：{{ $moment(scope.row.sts).format('YYYY-MM-DD HH:mm:ss Z') }}
              </template>
            </el-table-column>
            <el-table-column label="用户信息">
              <template slot-scope="scope">
                <span>
                  uid:
                  <router-link v-if="scope.row.uid" target="_blank" :to="{ name: 'UserEditor', params: { user_id: scope.row.uid } }">{{ scope.row.uid }}</router-link>
                  <span v-else>-</span>
                  <br />
                </span>
                <span>device_id: {{ scope.row.device_id }}<br /></span>
                <span>
                  ip: <a target="_blank" :href="`https://realip.cc/?ip=${scope.row.ip}`">{{ scope.row.ip }}</a>
                </span>
              </template>
            </el-table-column>

            <el-table-column label="设备信息">
              <template slot-scope="scope">
                <span>OS: {{ scope.row.os }}<br /></span>
                <span>OS Version: {{ scope.row.osv }}<br /></span>
                <span>model: {{ scope.row.model }}<br /></span>
                <span>brand: {{ scope.row.brand }}<br /></span>
              </template>
            </el-table-column>

            <el-table-column label="版本信息">
              <template slot-scope="scope">
                <span>channel: {{ scope.row.channel }}<br /></span>
                <span>App Version: {{ scope.row.apv }}<br /></span>
                <span>local: {{ scope.row.local }}<br /></span>
                <span>language: {{ scope.row.language }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Tags">
              <template slot-scope="scope">
                <span>{{ JSON.stringify(scope.row.tags) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'ClientMetrics',
  components: {
    Container,
  },
  data() {
    return {
      dateRange: [this.defaultStartTime(), this.defaultEndTime()],
      userID: '',
      deviceID: '',
      event: '',
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'ClientMetrics') {
        this.refetch();
      }
    },
  },
  methods: {
    onClick() {
      this.refetch();
    },
    defaultStartTime() {
      return this.$moment()
        .startOf('day')
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
    },
    defaultEndTime() {
      return this.$moment()
        .startOf('day')
        .format('YYYY-MM-DD');
    },
    addOneDay(date) {
      return this.$moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function() {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: '/v1/admin/client_metrics',
        params: {
          limit,
          offset: this.list.length,
          startTime: this.dateRange[0],
          endTime: this.addOneDay(this.dateRange[1]),
          event: this.event,
          userID: this.userID,
          deviceID: this.deviceID,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
</style>

<template>
  <equip-form v-if="ready" :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'RecommendationsEditor',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      ready: false,
      form: {
        scene: { name: {} },
        mixed_scene: { name: {} },
        article: { title: {} },
        meditation: { name: {} },
        type: 'meditation',
        start_local_time: '',
        end_local_time: '',
        regions: ['ALL'],
        languages: [],
        vip_status: [],
        login_status_v2: [],
        location: '',
        style: {
          display_on_top: false,
        },
        name: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        author_nickname: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        avatar: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },

        recommend_content: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        status: 'draft',
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      switch (data.type) {
        case 'meditation':
          delete data.article;
          delete data.scene;
          delete data.mixed_scene;
          delete data.sleep_story;
          break;
        case 'scene':
          delete data.article;
          delete data.mixed_scene;
          delete data.meditation;
          delete data.sleep_story;
          break;
        case 'article':
          delete data.scene;
          delete data.mixed_scene;
          delete data.meditation;
          delete data.sleep_story;
          break;
        case 'sleep_story':
          delete data.scene;
          delete data.mixed_scene;
          delete data.meditation;
          delete data.article;
          break;
        case 'mixed_scene':
          delete data.scene;
          delete data.sleep_story;
          delete data.meditation;
          delete data.article;
          break;
      }
      const { recommend_id: recommendId } = this.$route.params;
      try {
        await this.$request({
          url: `/v2/admin/explorer/recommendations/${recommendId}`,
          method: 'PUT',
          data,
        });
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
        await this.init();
      } catch (e) {
        this.$message.error('操作失败');
      }
    },

    init: async function () {
      const { recommend_id: recommendId } = this.$route.params;
      try {
        const res = await this.$request({
          url: `/v2/admin/explorer/recommendations/${recommendId}`,
        });
        this.form = Object.assign({}, this.form, res.data);
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
      this.ready = true;
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

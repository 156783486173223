<template>
  <edit-form :form="form" @save="save"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'NewsAdd',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        content: {
          'zh-Hans': {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          en: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          'zh-Hant': {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          ja: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          es: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          ru: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          ko: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
        },
        status: 'draft',
        sort_key: 2,
        valid_from: parseInt(+new Date() / 1000),
        valid_to: parseInt(+new Date() / 1000 + 86400),

        show_dialog: true,
        dialog_show_page: 'home',
        ios_versions: [],
        android_versions: [],
        regions: [],
        provinces: [],
        channels: [],

        vip_status: [],
        login_status_v2: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    save: async function() {
      const res = await this.$request({
        url: '/v1/admin/news',
        method: 'POST',
        data: this.form,
      });
      const news = res.data;
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.$router.replace({
        name: 'NewsEditor',
        params: { news_id: news.id },
      });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

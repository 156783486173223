<script>
import allLanguages from '@/languages.json';

const allLanguageOptions = Object.keys(allLanguages).map((value) => {
  const label = allLanguages[value];
  return { value, label, disabled: false };
});

export default {
  name: 'LanguagesSelecter',
  props: {
    value: Array,
    selected: Array,
  },
  data() {
    return {
      allLanguages: allLanguageOptions,
    };
  },
  watch: {
    value(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<template>
  <el-select v-model="value" multiple clearable filterable default-first-option placeholder="请选择语言">
    <el-option v-for="item in allLanguages" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<template>
  <router-view v-if="$route.name !== 'DailypicsManager'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-head">
          <div class="container-head-title">
            {{ $route.params.year }}-{{ $route.params.month }}
            <el-popover placement="right" width="400" trigger="click" v-model="monthPickerVisible" @show="monthPickerInit">
              <div class="month-picker">
                <div class="month-picker-year-switcher">
                  <el-button type="text" icon="el-icon-d-arrow-left" @click="prevYear"></el-button>
                  <span>{{ selectedYear }}</span>
                  <el-button type="text" icon="el-icon-d-arrow-right" @click="nextYear"></el-button>
                </div>
                <div class="month-picker-items">
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '01' }" type="text" @click="goto({ year: '' + selectedYear, month: '01' })">一月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '02' }" type="text" @click="goto({ year: '' + selectedYear, month: '02' })">二月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '03' }" type="text" @click="goto({ year: '' + selectedYear, month: '03' })">三月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '04' }" type="text" @click="goto({ year: '' + selectedYear, month: '04' })">四月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '05' }" type="text" @click="goto({ year: '' + selectedYear, month: '05' })">五月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '06' }" type="text" @click="goto({ year: '' + selectedYear, month: '06' })">六月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '07' }" type="text" @click="goto({ year: '' + selectedYear, month: '07' })">七月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '08' }" type="text" @click="goto({ year: '' + selectedYear, month: '08' })">八月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '09' }" type="text" @click="goto({ year: '' + selectedYear, month: '09' })">九月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '10' }" type="text" @click="goto({ year: '' + selectedYear, month: '10' })">十月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '11' }" type="text" @click="goto({ year: '' + selectedYear, month: '11' })">十一月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '12' }" type="text" @click="goto({ year: '' + selectedYear, month: '12' })">十二月</el-button>
                </div>
              </div>
              <a slot="reference">切换月份</a>
            </el-popover>
            | <a @click="prevMonth">上个月</a> | <a @click="nextMonth">下个月</a>
          </div>
          <div class="container-head-extra">
            <template v-if="isDev">
              <a @click="batchSync">批量同步</a>
              <a @click="batchFillQuotes">批量填充句子</a>
              <router-link :to="`/dailypics/manager/upload`">批量上传图片</router-link>
            </template>
            <router-link style="margin-left: 16px" :to="`/dailypics/manager/search`">搜索</router-link>
          </div>
        </div>

        <div class="container-body">
          <div>
            <div class="list-item" v-for="(item, index) in list" :key="item.date">
              <template v-if="item.pic_url && item.pic_url !== 'https://pics.tide.moreless.io/dailypics/default.jpg'">
                <img class="avatar" :alt="index + 1" :src="`${item.pic_url}?imageMogr2/thumbnail/!120x120r`" />
              </template>
              <template v-else>
                <img class="avatar" :alt="index + 1" src="~@/assets/logo.png" />
              </template>
              <div class="list-main-item">
                <div class="list-item-name">{{ item.content && item.content['zh-Hans'] ? item.content['zh-Hans'].quote.text || '-' : '-' }}</div>
                <div>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="white-space: pre">{{ getLangContent(item.content, 'zh-Hans') }}</div>
                    <span slot="reference" :class="checkLangContent(item.content, 'zh-Hans')">简</span>
                  </el-popover>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="white-space: pre">{{ getLangContent(item.content, 'zh-Hant') }}</div>
                    <span slot="reference" :class="checkLangContent(item.content, 'zh-Hant')">繁</span>
                  </el-popover>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="white-space: pre">{{ getLangContent(item.content, 'en') }}</div>
                    <span slot="reference" :class="checkLangContent(item.content, 'en')">英</span>
                  </el-popover>
                </div>
              </div>
              <div>
                <div>Author</div>
                <div>{{ item.content && item.content['zh-Hans'] ? item.content['zh-Hans'].author.text || '-' : '-' }}</div>
              </div>
              <div>
                <div>Date</div>
                <div>{{ item.date }}</div>
                <template v-if="item.lunar.festival.length">
                  <span class="festival" v-for="(festival, idnex) in item.lunar.festival" :key="festival.desc">{{ idnex > 0 ? '\n' : '' }}{{ festival.desc }}</span>
                </template>
                <span class="festival" v-if="item.lunar.term">{{ item.lunar.festival.length ? '\n' : '' }}{{ item.lunar.term }}</span>
              </div>
              <div class="list-item-actions">
                <el-button type="text" @click="showJSON(item)">JSON</el-button>
                <el-button type="text" @click="editItem(item)">编辑</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import lunar from '@/plugins/lunar';
import OpenCC from '@/plugins/opencc';

const DefaultData = (options) => {
  const data = {
    content: {
      'zh-Hans': {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
      'zh-Hant': {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
      en: {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
    },
    watermark: {
      url: '',
      height: 640,
      width: 165,
    },
    date: '',
    layout_template: '',
    pic_url: '',
    raw_pic: '',
    unsplash_id: '',
  };
  return Object.assign({}, data, options);
};

export default {
  name: 'DailypicsManager',
  components: {
    Container,
  },
  data() {
    return {
      isDev: this.$env === 'dev',
      busy: false,
      finish: false,
      lang: 'zh-Hans',
      list: [],
      JSONVisible: false,
      monthPickerVisible: false,
      detail: '',
      year: this.$route.params.year * 1,
      month: this.$route.params.month * 1,
      selectedYear: this.$route.params.year * 1,
      getLangContent(content, lang) {
        if (content && content[lang]) {
          return `内容: ${content[lang].quote.text || '内容未填写'}\n作者: ${content[lang].author.text || '作者未填写'}\n作者头衔: ${content[lang].author_title.text || '作者头衔未填写'}\n节日: ${content[lang].festival.text || '节日未填写'}`;
        }
        return '';
      },
      checkLangContent(content, lang) {
        if (content) {
          const currentContent = content[lang];
          const isReady = currentContent && currentContent.quote.text && currentContent.author.text && currentContent.author_title.text;
          const isPerfect = isReady && currentContent.festival.text && currentContent.festival.text;
          return isPerfect ? 'perfect' : isReady ? 'ready' : 'unready';
        }
        return 'unready';
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    defaultActive() {
      return `/dailypics/manager/2018-${this.$route.params.month}`;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'DailypicsManager') {
        this.refetch();
      }
    },
    '$route.params.month'(month) {
      this.month = month * 1;
      this.refetch();
    },
    '$route.params.year'(year) {
      this.year = year * 1;
    },
  },
  methods: {
    async syncToProduction(dailypic) {
      const date = dailypic.date;
      try {
        await this.$request({
          baseURL: 'https://davinci.moreless.space/api',
          url: 'v1/admin/dailypics',
          method: 'POST',
          data: dailypic,
        });
        this.$message({
          message: '同步成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error('同步数据至 正式环境 失败');
      }
    },
    batchSync() {
      this.$prompt('此操作将使测试环境的数据同步到正式环境，请输入 “同步到正式环境” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        if (value === '同步到正式环境') {
          for (let i = 0; i < this.list.length; ++i) {
            const dailypic = this.list[i];
            if (dailypic.updated_at) {
              await this.syncToProduction(dailypic);
            }
          }
          this.$message('同步完成');
        }
      });
    },
    batchFillQuotes() {
      this.$prompt('粘帖需要填充日期的句子', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value: multiQuoteInfos }) => {
        multiQuoteInfos = multiQuoteInfos.replace(/\s(\d{4}-\d{2}-\d{2})/g, (date) => {
          return `\n${date.trim()}`;
        });
        multiQuoteInfos = multiQuoteInfos.split('\n');

        for (let i = 0; i < multiQuoteInfos.length; ++i) {
          const quoteInfos = multiQuoteInfos[i].split('	');
          if (quoteInfos.length !== 9) {
            this.$message.error(`第 ${i + 1} 行解析失败`);
          } else {
            const [date, en_quote, en_author_title, en_author, en_festival, zh_hans_quote, zh_hans_author_title, zh_hans_author, zh_hans_festival] = quoteInfos;
            const converted = this.opencc_tw(`${zh_hans_quote}	${zh_hans_author_title}	${zh_hans_author}	${zh_hans_festival}`);
            const [zh_hant_quote, zh_hant_author_title, zh_hant_author, zh_hant_festival] = converted.split('	');
            const dailypic = this.list.find((dailypic) => dailypic.date === date);

            dailypic.content.en.quote.text = en_quote || dailypic.content.en.quote.text;
            dailypic.content.en.author_title.text = en_author_title || dailypic.content.en.author_title.text;
            dailypic.content.en.author.text = en_author || dailypic.content.en.author.text;
            dailypic.content.en.festival.text = en_festival || dailypic.content.en.festival.text;
            dailypic.content['zh-Hans'].quote.text = zh_hans_quote || dailypic.content['zh-Hans'].quote.text;
            dailypic.content['zh-Hans'].author_title.text = zh_hans_author_title || dailypic.content['zh-Hans'].author_title.text;
            dailypic.content['zh-Hans'].author.text = zh_hans_author || dailypic.content['zh-Hans'].author.text;
            dailypic.content['zh-Hans'].festival.text = zh_hans_festival || dailypic.content['zh-Hans'].festival.text;
            dailypic.content['zh-Hant'].quote.text = zh_hant_quote || dailypic.content['zh-Hant'].quote.text;
            dailypic.content['zh-Hant'].author_title.text = zh_hant_author_title || dailypic.content['zh-Hant'].author_title.text;
            dailypic.content['zh-Hant'].author.text = zh_hant_author || dailypic.content['zh-Hant'].author.text;
            dailypic.content['zh-Hant'].festival.text = zh_hant_festival || dailypic.content['zh-Hant'].festival.text;

            if (!dailypic.raw_pic && !dailypic.pic_url) {
              dailypic.raw_pic = 'https://pics.tide.moreless.io/dailypics/default.jpg';
            }
            try {
              const res = await this.$request({
                url: 'v1/admin/dailypics',
                method: 'POST',
                data: dailypic,
              });
              this.$message({
                message: `${date} 保存成功`,
                type: 'success',
              });
            } catch (e) {
              this.$message.error(`${date} 保存失败, ${e.response.data.msg}`);
            }
          }
        }
        this.$message('填充结束');
      });
    },
    monthPickerInit() {
      this.selectedYear = this.$route.params.year * 1;
    },
    prevYear() {
      this.selectedYear = this.selectedYear - 1;
    },
    nextYear() {
      this.selectedYear = this.selectedYear + 1;
    },
    prevMonth() {
      const $date = this.$moment({
        years: this.year,
        months: this.month - 1,
      }).subtract(1, 'month');

      this.goto({
        year: $date.format('YYYY'),
        month: $date.format('MM'),
      });
    },
    nextMonth() {
      const $date = this.$moment({
        years: this.year,
        months: this.month - 1,
      }).add(1, 'month');

      this.goto({
        year: $date.format('YYYY'),
        month: $date.format('MM'),
      });
    },
    goto(link) {
      console.log(link);
      this.monthPickerVisible = false;
      this.$router.replace({
        name: 'DailypicsManager',
        params: {
          year: link.year,
          month: link.month,
        },
      });
    },
    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.JSONVisible = true;
    },
    editItem(item) {
      const $date = this.$moment(item.date);
      this.$router.push({
        name: 'DailypicEditor',
        params: {
          day: $date.format('DD'),
        },
      });
    },
    refetch() {
      this.fetchList();
    },
    fetchList: async function () {
      if (this.infiniteScrollDisabled) {
        return;
      }
      const { year, month } = this.$route.params;
      const $date = this.$moment(`${year}-${month}`);
      const from = $date.format('YYYY-MM-DD');
      const to = $date.endOf('month').format('YYYY-MM-DD');
      const res = await this.$request({
        url: '/v1/dailypics',
        params: { from, to },
      });
      const range = this.$moment.range(from, to);
      const acc = Array.from(range.by('day', { exclusive: false }));
      const list = acc.map(($item) => {
        const date = $item.format('YYYY-MM-DD');
        const dailypic = res.data.find((item) => item.date === date) || DefaultData({ date });
        dailypic.lunar = lunar(date);
        return dailypic;
      });
      this.list = list;
    },
  },
  created: async function () {
    this.fetchList();
    this.opencc_tw = await OpenCC.Converter('cn', 'tw');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.festival {
  white-space: pre;
  font-size: 11px;
  color: #67c23a;
}

.unready {
  color: #f56c6c;
}

.ready {
  color: #409eff;
}

.perfect {
  color: #67c23a;
}
.month-picker {
  button {
    color: rgba(0, 0, 0, 0.8);
    &:hover,
    &.actived {
      color: #1890ff;
    }
  }
}

.month-picker-year-switcher {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.month-picker-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.month-picker-item {
  flex-basis: 25%;
  font-size: 12px;
  height: 64px;
}
.month-picker-item + .month-picker-item {
  margin: 0;
}
.container-head-extra /deep/ a {
  margin-left: 16px;
}
</style>

<template>
  <router-view v-if="$route.name !== 'DailypicsUpload'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-head-title">
            批上传图片
            <el-popover placement="right" width="400" trigger="click" v-model="monthPickerVisible" @show="monthPickerInit">
              <div class="month-picker">
                <div class="month-picker-year-switcher">
                  <el-button type="text" icon="el-icon-d-arrow-left" @click="prevYear"></el-button>
                  <span>{{ selectedYear }}</span>
                  <el-button type="text" icon="el-icon-d-arrow-right" @click="nextYear"></el-button>
                </div>
                <div class="month-picker-items">
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '01' }" type="text" @click="goto({ year: '' + selectedYear, month: '01' })">一月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '02' }" type="text" @click="goto({ year: '' + selectedYear, month: '02' })">二月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '03' }" type="text" @click="goto({ year: '' + selectedYear, month: '03' })">三月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '04' }" type="text" @click="goto({ year: '' + selectedYear, month: '04' })">四月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '05' }" type="text" @click="goto({ year: '' + selectedYear, month: '05' })">五月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '06' }" type="text" @click="goto({ year: '' + selectedYear, month: '06' })">六月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '07' }" type="text" @click="goto({ year: '' + selectedYear, month: '07' })">七月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '08' }" type="text" @click="goto({ year: '' + selectedYear, month: '08' })">八月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '09' }" type="text" @click="goto({ year: '' + selectedYear, month: '09' })">九月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '10' }" type="text" @click="goto({ year: '' + selectedYear, month: '10' })">十月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '11' }" type="text" @click="goto({ year: '' + selectedYear, month: '11' })">十一月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '12' }" type="text" @click="goto({ year: '' + selectedYear, month: '12' })">十二月</el-button>
                </div>
              </div>
              <span slot="reference" style="cursor: pointer">
                <span style="font-size: 11px; color: rgba(0, 0, 0, 0.24)">
                  切换
                  <i class="material-icons" style="font-size: 11px; line-height: 18px; vertical-align: bottom">swap_horiz</i>
                </span>
              </span>
            </el-popover>
          </div>
          <div class="container-body-header-extra"></div>
        </div>
        <div class="container-body">
          <el-upload
            ref="upload"
            action="https://upload.qiniup.com"
            multiple
            drag
            list-type="picture"
            :file-list="fileList"
            :auto-upload="false"
            :on-progress="uploadCoverProgress"
            :on-error="uploadCoverError"
            :on-success="uploadCoverSuccess"
            :before-upload="beforeUploadCover"
            :data="{ token: picToken }"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或
              <em>点击选择</em>
            </div>
            <div>文件名必须为 1 ~ 31，对应当月的日期</div>
          </el-upload>

          <div class="fail-file" v-for="file in errorFileList" :key="file.uid">
            <img :src="file.url" />
            <div>
              <div>{{ file.name }}</div>
              <div>{{ file.msg.error }}</div>
            </div>
          </div>

          <el-form inline style="margin-top: 24px">
            <el-form-item label="上传到">
              <el-input v-model="uploadYear">
                <span slot="suffix">年</span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="uploadMonth">
                <span slot="suffix">月</span>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="force">强制上传</el-checkbox>
            </el-form-item>

            <el-form-item>
              <el-button @click="submitUpload">开始上传</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';

const DefaultData = (options) => {
  const data = {
    content: {
      'zh-Hans': {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
      'zh-Hant': {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
      en: {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
    },
    watermark: {
      url: '',
      height: 640,
      width: 165,
    },
    date: '',
    layout_template: '',
    pic_url: '',
    raw_pic: '',
  };
  return Object.assign({}, data, options);
};

export default {
  name: 'DailypicsUpload',
  components: {
    Container,
  },
  data() {
    const now = this.$moment();
    return {
      monthPickerVisible: false,
      popoverAdvancedVisible: false,
      selectedYear: parseInt(now.format('YYYY')),
      picToken: '',
      picCDN: '',
      errorFileList: [],
      uploadYear: now.format('YYYY'),
      uploadMonth: now.add({ month: 1 }).format('MM'),
      force: false,
    };
  },
  watch: {},
  methods: {
    monthPickerInit() {
      this.selectedYear = 2019;
    },
    prevYear() {
      this.selectedYear = this.selectedYear - 1;
    },
    nextYear() {
      this.selectedYear = this.selectedYear + 1;
    },
    goto(link) {
      this.monthPickerVisible = false;
      this.$router.replace({
        name: 'DailypicsManager',
        params: {
          year: link.year,
          month: link.month,
        },
      });
    },
    submitUpload() {
      this.errorFileList = [];
      this.$refs.upload.submit();
    },
    beforeUploadCover: async function () {
      if (!this.picToken || !this.picCDN) {
        const getTokenResp = await this.$request({
          url: '/v1/admin/market/scenes/pic_upload_token',
        });
        this.picToken = getTokenResp.data.token;
        this.picCDN = getTokenResp.data.cdn;
      }
    },
    async uploadCoverSuccess(response, file) {
      const picUrl = `${this.picCDN}/${response.key}`;
      try {
        if (!this.force) {
          await this.checkImageUsageRecord(picUrl);
        }
        const { data: imageInfo } = await this.$request({
          url: `${picUrl}?imageInfo`,
          auth: undefined,
          withCredentials: false,
        });
        const size = imageInfo.width > imageInfo.height ? imageInfo.height : imageInfo.width;
        const rawPic = `${picUrl}?imageMogr2/crop/!${size}x${size}a0a0/quality/100`;
        let date = file.name.split('.')[0];
        if (!isNaN(date)) {
          const $date = this.$moment({
            years: this.uploadYear,
            months: parseInt(this.uploadMonth) - 1,
            days: parseInt(date),
          });

          date = $date.format('YYYY-MM-DD');
          let dailypic;
          try {
            const res = await this.$request({
              url: `/v1/dailypics/${date}`,
            });
            dailypic = res.data;
          } catch (e) {
            if (e.response.data.code === 99999) {
              dailypic = new DefaultData({ date });
            } else {
              throw new Error(e.response.data.msg);
            }
          }
          // console.log(dailypic)
          if (dailypic) {
            dailypic.raw_pic = rawPic;
            await this.$request({
              url: '/v1/admin/dailypics',
              method: 'POST',
              data: dailypic,
            });
          }
        }
      } catch (e) {
        file.msg = { error: e.message };
        this.errorFileList.push(file);
      }
    },
    uploadCoverProgress() {},
    uploadCoverError(e, file) {
      file.msg = JSON.parse(e.message);
      this.errorFileList.push(file);
    },
    async checkImageUsageRecord(url) {
      const res = await this.$request({
        url: '/v1/admin/check_image_usage_record',
        method: 'POST',
        data: { url, folder: 'dailypics' },
      });
      const usageRecords = res.data.dailypics;
      if (usageRecords.length) {
        const errorRocords = usageRecords.map((record) => record.date).join(', ');
        throw new Error(`图片已经在 ${errorRocords} 使用过`);
      }
      return res.data;
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.month-picker {
  button {
    color: rgba(0, 0, 0, 0.8);
    &:hover,
    &.actived {
      color: #1890ff;
    }
  }
}

.month-picker-year-switcher {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.month-picker-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.month-picker-item {
  flex-basis: 25%;
  font-size: 12px;
  height: 64px;
}
.month-picker-item + .month-picker-item {
  margin: 0;
}

.fail-file {
  padding: 16px;
  border: 1px solid red;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.3);
  img {
    width: 72px;
    height: 72px;
    margin-right: 16px;
  }
}

.fail-file {
  margin-top: 16px;
}
</style>

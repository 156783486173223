<template>
  <div class="container">
    <div class="container-head">
      <div class="container-head-title">推荐位列表</div>
      <div class="container-head-extra">
        <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="gotoAdder">新建推荐位</el-button>
      </div>
    </div>
    <div class="container-body">
      <el-table :data="list" stripe style="width: 100%">
        <el-table-column label="名称">
          <template slot-scope="scope">{{ scope.row.activity_name }}<br />{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <div class="layer-container">
              <img v-for="(layer, index) in scope.row.content.en.layers" :class="`layer ${layer.align}`" :style="{ zIndex: index + 1 }" :key="layer.image" :src="layer.image" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="展示位置">
          <template slot-scope="scope">第 {{ scope.row.row_position }} 行</template>
        </el-table-column>
        <el-table-column label="地区">
          <template slot-scope="scope">
            <span class="region" v-for="region in scope.row.regions" :key="region">{{ regionsDict[region] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">复制JSON</el-button>
            <el-button type="text" @click="gotoEditor(scope.row)">编辑</el-button>
            <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeAdBanner(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import allRegions from '@/regions.json';

export default {
  name: 'AdBanners',
  props: {
    type: String,
    regions: {
      type: Array,
      default: () => ['ALL'],
    },
  },
  data() {
    return {
      list: [],
      busy: false,
      finish: false,
      regionsDict: allRegions,
    };
  },

  // -----------------------------------------------------------
  // MARK: - Computer Properties
  // -----------------------------------------------------------
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },

  methods: {
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },

    gotoAdder() {
      let routerName = 'ExploreAdBannersAdder';
      switch (this.type) {
        case 'meditation':
          routerName = 'MedAdBannersAdder';
          break;
        case 'scene':
          routerName = 'ScenesAdBannersAdder';
          break;
        case 'explorer':
          routerName = 'ExploreAdBannersAdder';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryAdBannersAdder';
          break;
        case 'article':
          routerName = 'ArticleAdBannersAdder';
          break;
        case 'sleep_topic':
          routerName = 'SleepTopicPageAdBannersAdder';
          break;
        case 'nap_topic':
          routerName = 'NapTopicPageAdBannersAdder';
          break;
        case 'theme':
          routerName = 'ThemePageAdBannersAdder';
          break;
      }

      this.$router.push({
        name: routerName,
      });
    },

    gotoEditor(adBannerInfo) {
      let routerName = 'ExploreAdBannersEditor';
      switch (this.type) {
        case 'meditation':
          routerName = 'MedAdBannersEditor';
          break;
        case 'scene':
          routerName = 'ScenesAdBannersEditor';
          break;
        case 'explorer':
          routerName = 'ExploreAdBannersEditor';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryAdBannersEditor';
          break;
        case 'article':
          routerName = 'ArticleAdBannersEditor';
          break;
        case 'sleep_topic':
          routerName = 'SleepTopicPageAdBannersEditor';
          break;
        case 'nap_topic':
          routerName = 'NapTopicPageAdBannersEditor';
          break;
        case 'theme':
          routerName = 'ThemePageAdBannersEditor';
          break;
      }

      this.$router.push({
        name: routerName,
        params: {
          ad_banner_id: adBannerInfo.id,
        },
      });
    },

    async removeAdBanner(adBannerInfo) {
      try {
        await this.$confirm('确定要删除该推荐位吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.$request({
          url: `/v2/admin/explorer/embedded_banners/${adBannerInfo.id}`,
          method: 'DELETE',
        });
        this.list = this.list.filter(item => item.id !== adBannerInfo.id);
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        if (e !== 'cancel') {
          this.$message.error(e.message);
        }
      }
    },

    init() {
      this.refreshList();
    },

    async refreshList() {
      this.list = [];
      await this.fetchList();
    },

    async fetchList() {
      const res = await this.$request({
        url: '/v2/admin/explorer/embedded_banners',
        params: {
          type: this.type,
        },
      });
      if (this.regions.includes('ALL')) {
        this.list = res.data;
      } else {
        const filterList = res.data.filter(item => {
          let returnFlag = false;
          item.regions.forEach(region => {
            if (region === 'ALL') {
              returnFlag = true;
              return;
            } else if (this.regions.includes(region)) {
              returnFlag = true;
              return;
            }
          });
          return returnFlag;
        });
        this.list = filterList;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.region + .region {
  &::before {
    content: ', ';
  }
}

.layer-container {
  position: relative;
  width: 120px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer {
  position: absolute;
  max-width: 120px;
  max-height: 48px;
  object-fit: cover;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}
</style>

<style>
.el-table__row:hover .action-btns {
  display: block;
}
</style>

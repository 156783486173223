<template>
  <router-view v-if="$route.name !== 'DailypicsSearch'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-head-title">
            搜索
            <el-popover placement="right" width="400" trigger="click" v-model="monthPickerVisible" @show="monthPickerInit">
              <div class="month-picker">
                <div class="month-picker-year-switcher">
                  <el-button type="text" icon="el-icon-d-arrow-left" @click="prevYear"></el-button>
                  <span>{{ selectedYear }}</span>
                  <el-button type="text" icon="el-icon-d-arrow-right" @click="nextYear"></el-button>
                </div>
                <div class="month-picker-items">
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '01' }" type="text" @click="goto({ year: '' + selectedYear, month: '01' })">一月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '02' }" type="text" @click="goto({ year: '' + selectedYear, month: '02' })">二月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '03' }" type="text" @click="goto({ year: '' + selectedYear, month: '03' })">三月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '04' }" type="text" @click="goto({ year: '' + selectedYear, month: '04' })">四月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '05' }" type="text" @click="goto({ year: '' + selectedYear, month: '05' })">五月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '06' }" type="text" @click="goto({ year: '' + selectedYear, month: '06' })">六月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '07' }" type="text" @click="goto({ year: '' + selectedYear, month: '07' })">七月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '08' }" type="text" @click="goto({ year: '' + selectedYear, month: '08' })">八月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '09' }" type="text" @click="goto({ year: '' + selectedYear, month: '09' })">九月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '10' }" type="text" @click="goto({ year: '' + selectedYear, month: '10' })">十月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '11' }" type="text" @click="goto({ year: '' + selectedYear, month: '11' })">十一月</el-button>
                  <el-button class="month-picker-item" :class="{ actived: $route.params.month === '12' }" type="text" @click="goto({ year: '' + selectedYear, month: '12' })">十二月</el-button>
                </div>
              </div>
              <span slot="reference" style="cursor: pointer">
                <span style="font-size: 11px; color: rgba(0, 0, 0, 0.24)">
                  切换
                  <i class="material-icons" style="font-size: 11px; line-height: 18px; vertical-align: bottom">swap_horiz</i>
                </span>
              </span>
            </el-popover>
          </div>
          <div class="container-body-header-extra">
            <el-form :model="form" @submit.native.prevent="submit">
              <el-input v-model="form.keyword" class="input-with-select" name="keyword" size="small" placeholder="请输入要搜索的关键字">
                <el-select v-model="searchType" slot="prepend" placeholder="请选择" class="input-select">
                  <el-option label="作者" value="author"></el-option>
                  <el-option label="内容" value="content"></el-option>
                </el-select>
                <el-button slot="append" native-type="submit" icon="el-icon-search"></el-button>
              </el-input>
            </el-form>
          </div>
        </div>
        <div class="container-body">
          <div>
            <div class="list-item" v-for="(item, index) in list" :key="item.date">
              <template v-if="item.pic_url && item.pic_url !== 'https://pics.tide.moreless.io/dailypics/default.jpg'">
                <img class="avatar" :alt="index + 1" :src="`${item.pic_url}?imageMogr2/thumbnail/!120x120r`" />
              </template>
              <template v-else>
                <img class="avatar" :alt="index + 1" src="~@/assets/logo.png" />
              </template>
              <div class="list-main-item">
                <div class="list-item-name">{{ item.content && item.content['zh-Hans'] ? item.content['zh-Hans'].quote.text || '-' : '-' }}</div>
                <div>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="white-space: pre">{{ getLangContent(item.content, 'zh-Hans') }}</div>
                    <span slot="reference" :class="checkLangContent(item.content, 'zh-Hans')">简</span>
                  </el-popover>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="white-space: pre">{{ getLangContent(item.content, 'zh-Hant') }}</div>
                    <span slot="reference" :class="checkLangContent(item.content, 'zh-Hant')">繁</span>
                  </el-popover>
                  <el-popover placement="top-start" trigger="hover">
                    <div style="white-space: pre">{{ getLangContent(item.content, 'en') }}</div>
                    <span slot="reference" :class="checkLangContent(item.content, 'en')">英</span>
                  </el-popover>
                </div>
              </div>
              <div>
                <div>Author</div>
                <div>{{ item.content && item.content['zh-Hans'] ? item.content['zh-Hans'].author.text || '-' : '-' }}</div>
              </div>
              <div>
                <div>Date</div>
                <div>{{ item.date }}</div>
                <template v-if="item.lunar.festival.length">
                  <span class="festival" v-for="(festival, idnex) in item.lunar.festival" :key="festival.desc">{{ idnex > 0 ? '\n' : '' }}{{ festival.desc }}</span>
                </template>
                <span class="festival" v-if="item.lunar.term">{{ item.lunar.festival.length ? '\n' : '' }}{{ item.lunar.term }}</span>
              </div>
              <div class="list-item-actions">
                <el-button type="text" @click="showJSON(item)">JSON</el-button>
                <el-button type="text" @click="editItem(item)">编辑</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
import lunar from '@/plugins/lunar';
export default {
  name: 'DailypicsManager',
  components: {
    Container,
  },
  data() {
    const now = new Date();
    return {
      searchType: 'author',
      form: {
        keyword: '',
      },
      busy: false,
      finish: false,
      lang: 'zh-Hans',
      list: [],
      monthPickerVisible: false,
      popoverAdvancedVisible: false,
      JSONVisible: false,
      detail: '',
      selectedYear: now.getFullYear(),
      getLangContent(content, lang) {
        if (content && content[lang]) {
          return `内容: ${content[lang].quote.text || '内容未填写'}\n作者: ${content[lang].author.text || '作者未填写'}\n作者头衔: ${content[lang].author_title.text || '作者头衔未填写'}\n节日: ${content[lang].festival.text || '节日未填写'}`;
        }
        return '';
      },
      checkLangContent(content, lang) {
        if (content) {
          const currentContent = content[lang];
          const isReady = currentContent && currentContent.quote.text && currentContent.author.text && currentContent.author_title.text;
          const isPerfect = isReady && currentContent.festival.text && currentContent.festival.text;
          return isPerfect ? 'perfect' : isReady ? 'ready' : 'unready';
        }
        return 'unready';
      },
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'DailypicsManager') {
        this.refetch();
      }
    },
    searchType(type) {
      localStorage.setItem('dailypic-search-type', type);
    },
  },
  methods: {
    monthPickerInit() {
      this.selectedYear = 2019;
    },
    prevYear() {
      this.selectedYear = this.selectedYear - 1;
    },
    nextYear() {
      this.selectedYear = this.selectedYear + 1;
    },
    goto(link) {
      console.log(link);
      this.monthPickerVisible = false;
      this.$router.replace({
        name: 'DailypicsManager',
        params: {
          year: link.year,
          month: link.month,
        },
      });
    },
    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.JSONVisible = true;
    },
    editItem(item) {
      const $date = this.$moment(item.date);
      this.$router.push({
        name: 'DailypicEditor',
        params: {
          year: $date.format('YYYY'),
          month: $date.format('MM'),
          day: $date.format('DD'),
        },
      });
    },
    submit() {
      this.popoverAdvancedVisible = false;
      this.refetch();
    },
    refetch() {
      this.fetchList();
    },
    fetchList: async function () {
      const { keyword } = this.form;
      const params = {};
      params[this.searchType] = keyword;
      const res = await this.$request({
        url: '/v1/admin/dailypics/search',
        params,
      });
      const list = res.data.map(($item) => {
        $item.lunar = lunar($item.date);
        return $item;
      });
      this.list = list;
    },
  },
  created() {
    const searchType = localStorage.getItem('dailypic-search-type');
    if (searchType) {
      this.searchType = searchType;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.input-select {
  width: 80px;
}
.festival {
  white-space: pre;
  font-size: 11px;
  color: #67c23a;
}

.unready {
  color: #f56c6c;
}

.ready {
  color: #409eff;
}

.perfect {
  color: #67c23a;
}
.month-picker {
  button {
    color: rgba(0, 0, 0, 0.8);
    &:hover,
    &.actived {
      color: #1890ff;
    }
  }
}

.month-picker-year-switcher {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.month-picker-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.month-picker-item {
  flex-basis: 25%;
  font-size: 12px;
  height: 64px;
}
.month-picker-item + .month-picker-item {
  margin: 0;
}
</style>

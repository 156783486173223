<template>
  <router-view v-if="$route.name !== 'LessonsManager'"></router-view>
  <div v-else>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="material-icons">format_quote</i>
          <h3 slot="title">鲁迅语录</h3>
          <p slot="desc">我实在没有说过这样一句话。 — 鲁迅致台静农 （1932年12月13日）</p>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-body">
          <el-menu mode="horizontal" router :default-active="defaultActive">
            <el-menu-item :index="`/rike/lessons/manager/${year}/search`" style="float: right">搜索</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year - 1}-01`">&lt;&lt;</el-menu-item>
            <li class="el-menu-item">{{ year }}</li>
            <el-menu-item :index="`/rike/lessons/manager/${year}-01`">01</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-02`">02</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-03`">03</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-04`">04</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-05`">05</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-06`">06</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-07`">07</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-08`">08</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-09`">09</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-10`">10</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-11`">11</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year}-12`">12</el-menu-item>
            <el-menu-item :index="`/rike/lessons/manager/${year + 1}-01`">&gt;&gt;</el-menu-item>
          </el-menu>
        </div>

        <div class="container-body">
          <div>
            <div class="list-item" v-for="item in list" :key="item.date_by_day">
              <div class="list-main-item">
                <div class="list-item-name">{{ item.title }}</div>
                <div>{{ item.provenance }}</div>
              </div>
              <div class="list-item-attr">
                <div>Author</div>
                <div>{{ item.author.name }}</div>
              </div>
              <div class="list-item-attr">
                <div>Date</div>
                <div>{{ item.date_by_day }}</div>
              </div>
              <div class="list-item-actions">
                <el-button :disabled="!$store.getters.hasEditRole" type="text" @click="showRecommendComments(item)">精选评论{{ item.recommendCommentsCount ? ` (${item.recommendCommentsCount})` : '' }}</el-button>
                <el-button type="text" @click="gotoComments(item)">查看评论</el-button>
                <el-button type="text" @click="showJSON(item)">JSON</el-button>
                <el-button type="text" @click="editItem(item)">编辑</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
        <el-dialog :title="`精选评论 (${recommendComments.length})`" :visible.sync="recommendCommentsVisible">
          <div class="recommend-comments" v-loading="wait4recommendComments">
            <div class="comment-container" v-for="comment in recommendComments" :key="comment.id">
              <div class="comment">
                <div class="userinfo">
                  <img class="avatar" :src="comment.user.avatar" />
                  <span class="nickname">{{ comment.user.nickname }}</span>
                </div>
                <div class="content">{{ comment.content }}</div>
                <div class="conmment-date">{{ $moment(comment.created_at * 1000).format('YYYY-MM-DD hh:mm:ss') }}</div>
              </div>
              <div class="btns-container">
                <el-button type="text" @click="removeComment(comment)">移除</el-button>
                <div v-if="comment.like_count" class="mark">{{ comment.like_count }} 赞</div>
                <div v-if="comment.sub_comment_count" class="mark">{{ comment.sub_comment_count }} 回复</div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="showRandomPickCommentDialog">挑选</el-button>
            <el-button @click="recommendCommentsVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveRecommendComments">保 存</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="`挑选精选评论 (${recommendComments.length})`" :visible.sync="pickCommentVisible" width="75%">
          <div class="comment-pond" v-loading="wait4commentPond">
            <div class="comment-container" :class="recommendComments.find((item) => item.id === comment.id) ? 'selected' : ''" v-for="comment in commentPond" :key="comment.id">
              <div class="comment">
                <div class="userinfo">
                  <img class="avatar" :src="comment.user.avatar" />
                  <span class="nickname">{{ comment.user.nickname }}</span>
                </div>
                <div class="content">{{ comment.content }}</div>
                <div class="conmment-date">{{ $moment(comment.created_at * 1000).format('YYYY-MM-DD hh:mm:ss') }}</div>
                <el-button class="provenance" type="text" @click="editItem(comment.lesson)">{{ comment.lesson.title }} ({{ comment.lesson.date_by_day }}) -《{{ comment.lesson.provenance }}》，{{ comment.lesson.author.name }}</el-button>
              </div>
              <div class="btns-container">
                <el-button class="select-btn" type="text" @click="selectComment(comment)">选中</el-button>
                <el-button class="remove-btn" type="text" @click="removeComment(comment)">移除</el-button>
                <div v-if="comment.like_count" class="mark">{{ comment.like_count }} 赞</div>
                <div v-if="comment.sub_comment_count" class="mark">{{ comment.sub_comment_count }} 回复</div>
                <div v-if="comment.used_dates.length" class="mark">{{ comment.used_dates.length }} 次精选</div>
              </div>
            </div>
          </div>

          <el-form v-if="!nextPage" slot="footer" class="dialog-footer">
            <el-row>
              <el-col :span="3">
                <el-form-item>
                  <el-input size="small" placeholder="日期" v-model="date"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2" :offset="1">
                <el-form-item>
                  <el-input size="small" placeholder="点赞数" v-model="like_count"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2" :offset="1">
                <el-form-item>
                  <el-input size="small" placeholder="回复数" v-model="comment_count"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2" :offset="1">
                <el-form-item>
                  <el-input size="small" placeholder="评论长度" v-model="comment_length"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2" :offset="1">
                <el-form-item>
                  <el-checkbox v-model="in_pool">从池里挑</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="5" :offset="4">
                <el-button @click="refreshRandomCommentPond">换一批</el-button>
                <el-button @click="pickCommentVisible = false">返 回</el-button>
              </el-col>
            </el-row>
          </el-form>
          <span v-else slot="footer" class="dialog-footer">
            <el-button v-if="nextPage > 1" @click="turnPrevPage">上一页</el-button>
            <el-button v-if="commentPond.length === 5" @click="turnNextPage">下一页</el-button>
            <el-button @click="pickCommentVisible = false">返 回</el-button>
          </span>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
import PageHeader from '@/components/page-header';

export default {
  name: 'Weibo',
  components: {
    Container,
    PageHeader,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      baseRikeUrl,
      busy: false,
      finish: false,
      editDate: 0,
      wait4recommendComments: false,
      wait4commentPond: false,
      list: [],
      recommendComments: [],
      commentPond: [],
      JSONVisible: false,
      recommendCommentsVisible: false,
      somedayCommentCommentVisible: false,
      pickCommentVisible: false,
      nextPage: 0,
      detail: '',
      date: '',
      like_count: '',
      comment_count: '',
      comment_length: '',
      in_pool: false,
      year: this.$route.params.year * 1,
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    defaultActive() {
      return `/rike/lessons/manager/2018-${this.$route.params.month}`;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'DailypicsManager') {
        this.refetch();
      }
    },
    '$route.params.month'() {
      this.refetch();
    },
    '$route.params.year'(year) {
      this.year = year * 1;
    },
  },
  methods: {
    gotoComments(item) {
      const year = `${item.date_by_day}`.slice(0, 4);
      const month = `${item.date_by_day}`.slice(4, 6);
      const day = `${item.date_by_day}`.slice(6, 8);
      this.$router.push({
        name: 'LessonComments',
        params: { year, month, day },
      });
    },
    async saveRecommendComments() {
      console.log('saveRecommendComments');
      const ids = this.recommendComments.map((comment) => comment.id);
      console.log(ids);
      try {
        await this.$request({
          method: 'PUT',
          url: `${this.baseRikeUrl}/v1/admin/recommend_comments/${this.editDate}`,
          data: { ids },
        });
        this.$message({
          message: '保存成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(`保存失败, ${e.response.data.msg}`);
      }
    },
    turnPrevPage() {
      this.nextPage -= 2;
      this.refreshSomedayCommentPond();
    },
    turnNextPage() {
      this.refreshSomedayCommentPond();
    },
    async refreshSomedayCommentPond() {
      const page = this.nextPage;
      const year = `${this.someday}`.slice(0, 4);
      const month = `${this.someday}`.slice(4, 6);
      const day = `${this.someday}`.slice(6, 8);
      const date = `${year}-${month}-${day}`;
      this.wait4commentPond = true;
      try {
        const res = await this.$request({
          url: `${this.baseRikeUrl}/v1/admin/lessons/${date}/comments`,
          params: {
            offset: 5 * page,
            limit: 5,
          },
        });
        this.commentPond = res.data;
        this.nextPage = page + 1;
      } catch (e) {
        this.commentPond = [];
      }
      this.wait4commentPond = false;
    },
    async refreshRandomCommentPond() {
      this.nextPage = 0;
      this.wait4commentPond = true;
      try {
        const params = { count: 5 };
        if (this.date) {
          params.lesson_date = this.date;
        }
        if (this.like_count && !isNaN(this.like_count)) {
          params.like_count = this.like_count;
        }
        if (this.comment_count && !isNaN(this.comment_count)) {
          params.comment_count = this.comment_count;
        }
        if (this.comment_length && !isNaN(this.comment_length)) {
          params.length = this.comment_length;
        }
        if (this.in_pool) {
          params.in_pool = this.in_pool;
        }
        const res = await this.$request({
          url: `${this.baseRikeUrl}/v1/admin/comments/random_recommends`,
          params,
        });
        this.commentPond = res.data;
      } catch (e) {
        this.commentPond = [];
      }
      this.wait4commentPond = false;
    },
    selectComment(item) {
      this.recommendComments.push(item);
    },
    removeComment(item) {
      this.recommendComments = this.recommendComments.filter((comment) => {
        return item.id !== comment.id;
      });
    },
    showDateInput() {
      this.$prompt('请输入日期', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.editDate,
      }).then(({ value }) => {
        this.showSomedayCommentDialog(value);
      });
    },
    showSomedayCommentDialog(date) {
      this.pickCommentVisible = true;
      this.someday = date;
      this.nextPage = 0;
      this.refreshSomedayCommentPond(date);
    },
    showRandomPickCommentDialog() {
      this.pickCommentVisible = true;
      this.refreshRandomCommentPond();
    },

    async showRecommendComments(item) {
      const date = item.date_by_day;
      this.recommendCommentsVisible = true;
      this.wait4recommendComments = true;
      this.editDate = date;
      try {
        const res = await this.$request({
          url: `${this.baseRikeUrl}/v1/admin/recommend_comments/${date}/comments`,
        });
        this.recommendComments = res.data;
      } catch (e) {
        this.recommendComments = [];
      }
      this.wait4recommendComments = false;
    },
    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.JSONVisible = true;
    },
    editItem(item) {
      const year = `${item.date_by_day}`.slice(0, 4);
      const month = `${item.date_by_day}`.slice(4, 6);
      const day = `${item.date_by_day}`.slice(6, 8);
      this.$router.push({
        name: 'LessonEditor',
        params: { year, month, day },
      });
    },
    refetch() {
      this.fetchList();
    },
    fetchList: async function () {
      if (this.infiniteScrollDisabled) {
        return;
      }
      const { year, month } = this.$route.params;
      const $date = this.$moment(`${year}-${month}`);
      const from = $date.format('YYYY-MM-DD');
      const to = $date.endOf('month').format('YYYY-MM-DD');
      const lessonsRes = await this.$request({
        url: `${this.baseRikeUrl}/v1/lessons`,
        params: { from, to },
      });
      let commentList = [];
      try {
        const commentsRes = await this.$request({
          url: `${this.baseRikeUrl}/v1/admin/recommend_comments`,
          params: { from, to },
        });
        commentList = commentsRes.data;
      } catch (e) {
        console.log(e);
      }

      const range = this.$moment.range(from, to);
      const acc = Array.from(range.by('day', { exclusive: false }));
      const list = acc.map(($item) => {
        const dateByDay = parseInt($item.format('YYYYMMDD'));
        const comments = commentList.find((item) => item.date === dateByDay);
        const lesson = lessonsRes.data.find((item) => item.date_by_day === dateByDay) || {
          article: '',
          provenance: '',
          title: '',
          author: {},
          date_by_day: dateByDay,
        };
        lesson.recommendCommentsCount = 0;
        if (comments) {
          lesson.recommendCommentsCount = comments.comment_ids.length;
        }
        return lesson;
      });
      this.list = list;
    },
  },
  created() {
    this.fetchList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.list-item-attr {
  flex-basis: 60px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  flex-basis: 280px;
  display: block;
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  .select-btn {
    display: inline-block;
  }
  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;
  .select-btn {
    display: none;
  }
  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.avatar {
  display: block;
  width: 32px;
  height: 32px;
  flex-basis: 32px;
  border-radius: 16px;
  flex-shrink: 0;
  flex-grow: 0;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.conmment-date,
.provenance {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.32);
  margin-top: 4px;
}
</style>

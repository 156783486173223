<template>
  <container>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">fiber_new</i>
        <h3 slot="title">广播电台</h3>
        <p slot="desc">潮汐广播电台</p>
      </page-header>
    </div>
    <div class="container" style="margin-bottom: 56px" slot="container">
      <el-form class="form container-body" label-position="top" v-model="form">
        <el-tabs v-model="activeLang">
          <template v-for="lang in langs">
            <el-tab-pane :key="lang.key" :label="lang.label" :name="lang.key" v-if="form.content[lang.key]">
              <el-row>
                <el-col :span="7">
                  <el-form-item label="标题">
                    <el-input v-model="form.content[lang.key].title"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7" :offset="1">
                  <el-form-item label="按钮文本">
                    <el-input v-model="form.content[lang.key].button_title"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                  <el-form-item label="副标题">
                    <el-input type="textarea" v-model="form.content[lang.key].sub_title"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                  <el-form-item label="iOS link">
                    <el-input v-model="form.content[lang.key].ios_link"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                  <el-form-item label="Android Link">
                    <el-input v-model="form.content[lang.key].android_link"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="7">
                  <el-form-item label="图片">
                    <div class="preview" v-if="form.content[lang.key].pic_url" @click="previewPic">
                      <img class="preview-img" :src="form.content[lang.key].pic_url" />
                      <div class="preview-img-cover">
                        <i class="material-icons">edit</i>
                        <span>编辑</span>
                      </div>
                    </div>
                    <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadPicProgress" :on-error="uploadPicError" :on-success="uploadPicSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                      <div class="uploadPic">
                        <i class="el-icon-upload2"></i>
                        <span>上传图片</span>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>

                <el-col :span="7" :offset="1">
                  <el-form-item label="横幅">
                    <div class="preview" v-if="form.content[lang.key].banner_url" @click="previewBanner">
                      <img class="preview-img" :src="form.content[lang.key].banner_url" />
                      <div class="preview-img-cover">
                        <i class="material-icons">edit</i>
                        <span>编辑</span>
                      </div>
                    </div>
                    <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadBannerProgress" :on-error="uploadBannerError" :on-success="uploadBannerSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                      <div class="uploadPic">
                        <i class="el-icon-upload2"></i>
                        <span>上传横幅</span>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
          </template>
        </el-tabs>
        <el-row>
          <el-col :span="7">
            <el-form-item label="开始日期">
              <el-col :span="24">
                <el-date-picker type="datetime" v-model="startTime"></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-form-item label="结束日期">
              <el-col :span="24">
                <el-date-picker type="datetime" v-model="endTime"></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="会员状态：">
              <vip-status-selecter v-model="form.vip_status"></vip-status-selecter>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="登录状态：">
              <login-status-selecter v-model="form.login_status_v2"></login-status-selecter>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="是否弹窗展示">
              <el-radio-group v-model="form.show_dialog">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="7" v-if="form.show_dialog">
            <el-form-item label="弹窗位置">
              <el-radio-group v-model="form.dialog_show_page">
                <el-radio label="all">首页||探索页(ios>=3.36.7, android>=3.36.6)</el-radio>
                <el-radio label="home">首页</el-radio>
                <el-radio label="explore">探索页</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="上架状态：">
              <el-radio-group v-model="form.status">
                <el-radio label="published">发布</el-radio>
                <el-radio label="reviewing">审阅</el-radio>
                <el-radio label="draft">草稿</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="优先级(0最大)(默认就2)：">
              <el-select v-model="form.sort_key" placeholder="请选择">
                <el-option :label="0" :value="0"> </el-option>
                <el-option :label="1" :value="1"> </el-option>
                <el-option :label="2" :value="2"> </el-option>
                <el-option :label="3" :value="3"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="展示地区">
            <regions-selecter v-model="form.regions"></regions-selecter>
          </el-form-item>
          <el-form-item label="展示省份" v-if="form.regions.length === 1 && form.regions[0] === 'CN'">
            <provinces-selecter v-model="form.provinces"></provinces-selecter>
          </el-form-item>
        </el-row>
        <el-form-item label="展示语言：">
          <languages-selecter v-model="form.languages"></languages-selecter>
        </el-form-item>
        <el-form-item label="展示渠道">
          <channels-selector v-model="form.channels"></channels-selector>
        </el-form-item>

        <el-row>
          <el-col :span="7">
            <el-form-item label="iOS展示版本">
              <el-input-tag v-model="form.ios_versions"></el-input-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="Android展示版本">
              <el-input-tag v-model="form.android_versions"></el-input-tag>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog title="横幅预览" :visible.sync="dialogDisplay.banner">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form.content[activeLang].banner_url">
              <i v-if="bannerUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadBannerProgress" :on-error="uploadBannerError" :on-success="uploadBannerSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
        <img class="preview-dialog-img" :src="form.content[activeLang].banner_url" />
      </el-dialog>
      <el-dialog title="图片预览" :visible.sync="dialogDisplay.pic">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form.content[activeLang].pic_url">
              <i v-if="picUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadPicProgress" :on-error="uploadPicError" :on-success="uploadPicSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
        <img class="preview-dialog-img" :src="form.content[activeLang].pic_url" />
      </el-dialog>
    </div>
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="act-btn" @click="save">保存</el-button>
        <el-button v-if="$env === 'production'" size="small" class="act-btn" @click="sync">复制到开发环境</el-button>
        <el-button v-else :disabled="!$store.getters.hasEditRole" size="small" class="act-btn" @click="sync">复制到正式环境</el-button>
      </div>
    </div>
  </container>
</template>

<script>
function getRGBAInfo(txt) {
  const p = /rgba\(([\s\S*?]+)\)/gi;
  const m = p.exec(txt);
  return m[1];
}
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import ElInputTag from '@/components/el-input-tag';
import RegionsSelecter from '@/components/regions-selecter';
import ProvincesSelecter from '@/components/provinces-selecter';
import LanguagesSelecter from '@/components/languages-selecter';
import ChannelsSelector from '@/components/channels-selector';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';

import allRegions from '@/regions.json';

const allRegionsData = Object.keys(allRegions).map(value => {
  const label = allRegions[value];
  return { value, label, disabled: false };
});

export default {
  name: 'NewsForm',
  components: {
    Container,
    PageHeader,
    ElInputTag,
    RegionsSelecter,
    ProvincesSelecter,
    LanguagesSelecter,
    ChannelsSelector,
    VipStatusSelecter,
    LoginStatusSelecter,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      env: 'production',
      activeLang: 'zh-Hans',
      allRegions: allRegionsData,

      langs: [
        {
          key: 'zh-Hans',
          label: 'zh-Hans',
        },
        {
          key: 'zh-Hant',
          label: 'zh-Hant',
        },
        {
          key: 'en',
          label: 'en',
        },
        {
          key: 'ja',
          label: 'ja',
        },
        {
          key: 'es',
          label: 'es',
        },
        {
          key: 'ru',
          label: 'ru',
        },
        {
          key: 'ko',
          label: 'ko',
        },
      ],
      loginStatus: [
        { key: 0, name: '未指定' },
        { key: 1, name: '登录' },
        { key: 2, name: '未登录' },
      ],
      dialogDisplay: {
        pic: false,
        banner: false,
      },
      picUploading: false,
      bannerUploading: false,
      picToken: '',
      picCDN: '',
    };
  },
  computed: {
    startTime: {
      get() {
        return new Date(this.form.valid_from * 1000);
      },
      set(startTime) {
        this.form.valid_from = parseInt(startTime / 1000);
      },
    },
    endTime: {
      get() {
        return new Date(this.form.valid_to * 1000);
      },
      set(endTime) {
        this.form.valid_to = parseInt(endTime / 1000);
      },
    },
    backgroundColor: {
      get: function() {
        if (!this.form.resource.image.background_color) {
          return 'rgba(255, 255, 255, 1)';
        }
        return `rgba(${this.form.resource.image.background_color})`;
      },
      set: function(val) {
        this.form.resource.image.background_color = getRGBAInfo(val);
      },
    },
  },
  watch: {},
  methods: {
    sync() {
      this.$emit('sync', this.form);
    },
    save() {
      this.$emit('save', this.form);
    },
    loginStatusChange(value) {
      console.log(value);
    },
    previewPic() {
      this.dialogDisplay.pic = true;
    },
    previewBanner() {
      this.dialogDisplay.banner = true;
    },
    beforeUploadImage: async function() {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadPicSuccess(response) {
      console.log('Success');
      this.form.content[this.activeLang].pic_url = `${this.picCDN}/${response.key}`;
      this.picUploading = false;
    },
    uploadPicError() {
      this.picUploading = false;
    },
    uploadPicProgress() {
      this.picUploading = true;
    },
    uploadBannerSuccess(response) {
      console.log('BannerSuccess');
      this.form.content[this.activeLang].banner_url = `${this.picCDN}/${response.key}`;
      this.bannerUploading = false;
    },
    uploadBannerError() {
      this.bannerUploading = false;
    },
    uploadBannerProgress() {
      this.bannerUploading = true;
    },
  },
  created: function() {
    this.env = this.$env;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .act-btn + .act-btn {
    margin-left: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}

.layers {
  display: flex;
  flex-direction: row;
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}
.form-label {
  position: relative;
  z-index: 2;
}
.el-form-item__label > .form-label i {
  opacity: 0;
}
.el-form-item:hover > .el-form-item__label > .form-label i {
  opacity: 1;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.32);
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
</style>

<template>
  <router-view v-if="$route.name !== 'TransactionsHistory'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">交易记录管理</div>
        </div>
        <div class="container-body">
          <div class="container-body-left">
            <el-form :inline="true">
              <el-row>
                <el-form-item>
                  <el-date-picker v-model="dateRange" type="daterange" value-format="yyyy-MM-dd" clearable :disabled="busy" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="type" placeholder="选择类型" clearable>
                    <el-option v-for="item in types" :key="item.key" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="channel" placeholder="选择渠道" clearable>
                    <el-option v-for="item in channels" :key="item.key" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="status" placeholder="选择状态" clearable>
                    <el-option v-for="item in statuslist" :key="item.key" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item>
                  <el-input v-model="user_id" placeholder="用户ID"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="txn_id" placeholder="内部交易ID"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="external_txn_id" placeholder="外部交易ID"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="external_base_txn_id" placeholder="订阅ID"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-button @click="onClick">搜索</el-button>
              </el-row>
            </el-form>
          </div>
          <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50" style="margin-top: 30px">
            <el-table-column label="UserID">
              <template slot-scope="scope">
                <router-link :to="{ name: 'UserEditor', params: { user_id: scope.row.user_id } }">
                  {{ scope.row.user_id }}
                </router-link>
              </template>
            </el-table-column>

            <el-table-column label="交易信息" width="400">
              <template slot-scope="scope">
                <span>内部交易ID：{{ scope.row.txn_id }}<br /></span>
                <span>{{ scope.row.product_id }}<br /></span>
                <span>创建：{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}<br /></span>
                <span>更新：{{ $moment(scope.row.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}<br /></span>
                <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
              </template>
            </el-table-column>

            <el-table-column prop="type" label="交易类型"></el-table-column>
            <el-table-column prop="status" label="交易状态"></el-table-column>
            <el-table-column label="外部交易信息" align="center">
              <el-table-column prop="channel" label="交易渠道"></el-table-column>
              <el-table-column prop="external_txn_id" label="外部交易ID"></el-table-column>
              <el-table-column prop="external_base_txn_id" label="订阅ID"></el-table-column>
              <el-table-column prop="fail_reason" label="失败原因"></el-table-column>
            </el-table-column>
          </el-table>
          <el-dialog title="JSON" :visible.sync="JSONVisible">
            <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
          </el-dialog>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'ClientMetrics',
  components: {
    Container,
  },
  data() {
    return {
      types: [
        { key: 'payment', value: '支付' },
        { key: 'refund', value: '退款' },
        { key: 'restore', value: '恢复购买' },
        { key: 'restore_refund', value: '恢复购买退款' },
        { key: 'subscribe', value: '订阅签约' },
      ],
      channels: [
        { key: 'huawei_iap', value: 'HuaweiIAP' },
        { key: 'appstore', value: 'AppStore' },
        { key: 'googleplay', value: 'GooglePlay' },
        { key: 'alipay', value: 'Alipay' },
        { key: 'xiaomi', value: 'Xiaomi' },
        { key: 'wechat', value: 'Wechat' },
        { key: 'oppo', value: 'OPPO' },
        { key: 'redeem_code', value: 'Redeem' },
        { key: 'manual', value: 'Manual' },
        { key: 'promotion', value: 'Promotion' },
        { key: 'oauth2', value: 'OAuth 2.0' },
      ],
      statuslist: [
        { key: 'NEW', value: '新创建' },
        { key: 'WAITING', value: '等待回调' },
        { key: 'PROCESSED', value: '处理成功' },
        { key: 'FAILED', value: '处理失败' },
        { key: 'CANCELED', value: '已取消（订阅）' },
      ],

      JSONVisible: false,
      detail: '',

      dateRange: [this.defaultStartTime(), this.defaultEndTime()],
      type: '',
      channel: '',
      status: '',
      user_id: '',
      txn_id: '',
      external_txn_id: '',
      external_base_txn_id: '',
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'ClientMetrics') {
        this.refetch();
      }
    },
  },
  methods: {
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    onClick() {
      this.refetch();
    },
    defaultStartTime() {
      return this.$moment().startOf('day').subtract(3, 'years').format('YYYY-MM-DD');
    },
    defaultEndTime() {
      return this.$moment().startOf('day').format('YYYY-MM-DD');
    },
    addOneDay(date) {
      return this.$moment(date).add(1, 'days').format('YYYY-MM-DD');
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: '/v1/admin/transactions',
        params: {
          limit,
          offset: this.list.length,
          type: this.type,
          channel: this.channel,
          status: this.status,
          start_from: (this.dateRange && this.dateRange[0]) || '',
          start_to: (this.dateRange && this.addOneDay(this.dateRange[1])) || '',
          user_id: this.user_id,
          txn_id: this.txn_id,
          external_txn_id: this.external_txn_id,
          external_base_txn_id: this.external_base_txn_id,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
</style>

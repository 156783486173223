<template>
  <div>
    <audio ref="audio" :src="audioUrl" @play="onAudioPlay" @pause="onAudioPause" @ended="onAudioEnded">
      <source :src="audioUrl" type="audio/mpeg" />
      <source :src="audioUrl" type="audio/ogg" />
    </audio>
    <div class="preview voice" v-if="uploading" v-loading="uploading">
      <i class="material-icons">queue_music</i>
    </div>
    <div class="preview voice" v-else-if="hashKey.length">
      <i class="material-icons">queue_music</i>
      <span style="color: green">已经上传[密]</span>
      <div class="preview-cover">
        <el-button v-if="playing" type="text" @click="stopAudio" icon="el-icon-video-pause"><font color="red">停止</font></el-button>
        <el-button v-else type="text" @click="playAudio" icon="el-icon-video-play">播放</el-button>
        <el-button type="text" @click="clearAudio" icon="el-icon-delete">清除</el-button>
      </div>
    </div>
    <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadProgress" :on-error="uploadError" :on-success="uploadSuccess" :before-upload="beforeUpload" :data="{ token: resourceToken }" :show-file-list="false">
      <div class="preview">
        <i class="el-icon-upload"></i>
        <span style="color: red">未上传[密]</span>
      </div>
    </el-upload>
    <el-input v-model="hashKey" size="mini" disabled />
    <el-input v-if="audioUrl && playing" v-model="audioUrl" size="mini" disabled />
  </div>
</template>

<script>
export default {
  name: 'AudioUploader',
  props: {
    hashKey: String,
    resourceFolder: String,
  },
  data() {
    return {
      dialogDisplay: false,
      playing: false,
      uploading: false,
      resourceToken: '',
      audioUrl: '',
    };
  },
  computed: {
    audio() {
      return this.$refs.audio;
    },
  },
  methods: {
    async playAudio() {
      const res = await this.$request({
        method: 'GET',
        url: '/v1/admin/exchange_resource_download_url',
        params: { hash_key: this.hashKey },
      });
      this.audioUrl = res.data.url;
      this.$nextTick(() => {
        this.audio.play();
      });
    },
    stopAudio() {
      this.audio.pause();
    },
    onAudioPlay() {
      this.playing = true;
      this.$root.$emit('playAudio', this);
    },
    onAudioPause() {
      this.audio.currentTime = 0;
      this.playing = false;
    },
    onAudioEnded() {
      this.playing = false;
    },
    clearAudio() {
      this.audioUrl = '';
      this.$emit('change', { hash: '', key: '' });
    },
    beforeUpload: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/get_encrypt_resource_upload_token',
        method: 'POST',
        data: { folder: this.resourceFolder },
      });
      this.resourceToken = getTokenResp.data.token;
      this.stopAudio();
    },
    uploadSuccess(response) {
      this.uploading = false;
      this.$emit('change', response);
    },
    uploadProgress() {
      this.uploading = true;
    },
    uploadError() {
      this.uploading = false;
    },
  },
  mounted() {
    this.$root.$on('playAudio', (e) => {
      if (e !== this) {
        this.stopAudio();
      }
    });
  },
};
</script>
<style lang="less" scoped>
.preview {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-cover {
  opacity: 1;
}
.preview-cover {
  /* opacity: 0; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  /deep/ i {
    color: #fff;
  }
  /deep/ span {
    color: #fff;
  }
}
.preview-dialog-img {
  width: 100%;
}

.preview {
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

import axios from 'axios';
import qs from 'qs';
let baseURL = '/api';
let baseRikeUrl = 'https://rike-dapi.moreless.io';
const env = location.hostname === 'davinci.moreless.space' ? 'production' : 'dev';
if (env === 'production') {
  baseURL = '/api';
  baseRikeUrl = 'https://rike-mapi.moreless.io';
}

const options = {
  baseURL: baseURL,
  baseRikeUrl: baseRikeUrl,
  withCredentials: true,
  Origin: 'http://tidetime.moreless.io',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  auth: {
    username: 'W8TxC2uDKWy6P8G7u4mjV',
    password: 'khsvJs9pjhoP943o7VK9A',
  },
  paramsSerializer(params) {
    return qs.stringify(params, {
      indices: false,
    });
  },
};
const $request = axios.create(options);

export default {
  install(Vue) {
    Vue.prototype.$request = $request;
    Vue.request = $request;
  },
  $request: $request,
};

export { $request };

<template>
  <edit-form :form="form" @save="save"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'RedeemCodeAdd',
  components: {
    EditForm: Form,
  },
  data() {
    const today = this.$moment().set({
      hour: 0,
      minute: 0,
      second: 1,
      millisecond: 0,
    });
    return {
      form: {
        content: {
          tide_plus_period: 86400 * 30,
        },
        count: 100,
        valid_from: parseInt(today.add(1, 'days') / 1000),
        valid_to: parseInt(today.add(3, 'months') / 1000),
        name: '',
        aborted: false,
        only_non_vip: '0',
        maximum_usage_count: 1,
      },
    };
  },
  methods: {
    save: function() {
      this.$confirm('兑换活动一旦创建，将无法删除及修改，请确定所有字段皆已填写正确！是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const res = await this.$request({
          url: '/v1/admin/redeem_code_groups',
          method: 'POST',
          data: this.form,
        });
        const redeemCodeGroups = res.data;
        this.$message({
          message: '保存成功',
          type: 'success',
        });
        this.$router.replace({
          name: 'RedeemCodeEditor',
          params: { redeem_code_group_id: redeemCodeGroups.id },
        });
      });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

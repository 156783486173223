<template>
  <container>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">attach_money</i>
        <h3 slot="title">活动管理</h3>
        <p slot="desc">管理运营活动的地方</p>
      </page-header>
    </div>
    <div class="container" slot="container">
      <div class="container-body-header">
        <div class="container-body-header-title">闪屏活动</div>
        <div class="container-head-extra">
          <el-button :disabled="!$store.getters.hasEditRole" type="text" @click="addItem">添加</el-button>
          <el-button :disabled="!$store.getters.hasEditRole" type="text" @click="dialogDisplay.pasteBannerJSON = true">添加JSON</el-button>
        </div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="500" stripe>
          <el-table-column label="图片" width="150px">
            <template slot-scope="scope">
              <img class="avatar" :src="`${scope.row.resource.image.background.url}?imageMogr2/thumbnail/!120x120r`" />
            </template>
          </el-table-column>
          <el-table-column label="跳转链接" width="240px">
            <template slot-scope="scope">
              <p style="word-wrap: break-word">{{ scope.row.link }}</p>
            </template>
          </el-table-column>
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.start_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.end_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="copyToBoard(scope.row.id)">复制ID</el-button>
              <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">复制JSON</el-button>
              <el-button type="text" @click="editItem(scope.row)">编辑</el-button>
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="新增JSON" :visible.sync="dialogDisplay.pasteBannerJSON">
        <el-form>
          <el-form-item label="JSON">
            <el-input v-model="toPasteJSON" type="textarea" :autosize="{ minRows: 8 }"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.pasteBannerJSON = false">取 消</el-button>
          <el-button type="primary" @click="addItemByJSON">添 加</el-button>
        </div>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import _ from 'lodash';
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
export default {
  name: 'SplashesManager',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      searchQuery: {
        nickname: '',
        email: '',
        phone_number: '',
        nation_code: '',
      },
      form: {
        nickname: '',
        email: '',
        phone_number: '',
        nation_code: '',
      },
      toPasteJSON: '',
      dialogDisplay: {
        pasteBannerJSON: false,
      },
      popoverAdvancedVisible: false,
      expand: false,
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'SplashesManager') {
        this.refetch();
      }
    },
  },
  methods: {
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
    addItem() {
      this.$router.push({
        name: 'SplashAdd',
      });
    },
    addItemByJSON: async function () {
      try {
        const data = JSON.parse(this.toPasteJSON);
        data.id = '';
        data.status = 'draft';
        const res = await this.$request({
          url: '/v1/admin/splashes',
          method: 'POST',
          data: data,
        });
        const splashes = res.data;
        this.$message({
          message: '保存成功',
          type: 'success',
        });
        this.$router.replace({
          name: 'SplashEditor',
          params: { splashes_id: splashes.id },
        });
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    editItem(item) {
      this.$router.push({
        name: 'SplashEditor',
        params: {
          splashes_id: item.id,
        },
      });
    },
    deleteItem(item) {
      const that = this;
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async function () {
        const itemId = item.id;
        await that.$request({
          method: 'DELETE',
          url: `/v1/admin/splashes/${itemId}`,
        });
        that.list = that.list.filter((item) => {
          return item.id !== itemId;
        });
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    submit() {
      console.log(_.cloneDeep(this.form));
      this.searchQuery = Object.assign({}, _.cloneDeep(this.form));
      this.popoverAdvancedVisible = false;
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: '/v1/admin/splashes',
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const users = res.data;
      if (users.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...users];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  width: 360px;
}

.avatar {
  width: 120px;
  height: 120px;
  align-self: center;
  object-fit: cover;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>

<template>
  <edit-form :form="form" @save="save"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddMember',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        nickname: '',
        phone_number: '',
        old_role: 1,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    save: async function (data) {
      const resp = await this.$request({
        url: '/v1/admin/admins',
        method: 'POST',
        data,
      });
      const user = resp.data;
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.$router.replace({
        name: 'EditMemberRole',
        params: { user_id: user.id },
      });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

import Layout from '@/components/layout';

import Feedback from '@/pages/service/feedback/index';
import FeedbackReview from '@/pages/service/feedback/review';

export default [
  {
    path: '/service',
    name: 'ServiceIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '传达室',
    },
    redirect: {
      name: 'Feedback',
    },
    children: [
      {
        path: 'feedback',
        name: 'Feedback',
        components: {
          default: Feedback,
        },
        meta: {
          requireAuth: true,
          label: 'Feedback',
          newDesign: true,
        },
      },
      {
        path: 'feedback/:feedback_id',
        name: 'FeedbackReview',
        components: {
          default: FeedbackReview,
        },
        meta: {
          requireAuth: true,
          label: 'FeedbackReview',
          newDesign: true,
        },
      },
    ],
  },
];

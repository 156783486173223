<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">
          Tide Plus :
          <span v-if="form.vip.is_lifetime_member">终身会员</span>
          <span v-else-if="form.vip.expired_at == 0" :style="{ color: 'darkgray' }">从未开通过</span>
          <span v-else>
            <span :style="$moment(form.vip.expired_day_at * 1000).isBefore() ? { color: 'darkgray' } : { color: 'green' }"> [自然日]{{ $moment(form.vip.expired_day_at * 1000).format('YYYY-MM-DD HH:mm:ss') }} </span>
            <span :style="$moment(form.vip.expired_at * 1000).isBefore() ? { color: 'darkgray' } : { color: 'green' }"> [实际]{{ $moment(form.vip.expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }} </span>
          </span>
        </div>
      </div>

      <div class="container-body">
        <div class="info-blocks">
          <div>
            <h4>会员信息</h4>
            <div>
              <label>会员类型：</label>
              <span v-if="form.vip.premium_type === 1">从未开通过</span>
              <span v-else-if="form.vip.premium_type === 2">订阅中</span>
              <span v-else-if="form.vip.premium_type === 3">买断中</span>
              <span v-else-if="form.vip.premium_type === 4">试用期</span>
              <span v-else-if="form.vip.premium_type === 5">曾经是会员</span>
              <span v-else>未知</span>
            </div>
            <div>
              <label>终身会员：</label>
              <span>{{ form.vip.is_lifetime_member ? '是' : '否' }}</span>
            </div>
            <div>
              <label>到期时间：</label>
              <span>{{ $moment(form.vip.expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
            <div>
              <label>变动时间：</label>
              <span>{{ $moment(form.vip.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
            <div>
              <label>首次开通：</label>
              <span>{{ $moment(form.vip.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
          </div>

          <div>
            <h4>AppStore 自动续订</h4>
            <template v-if="form.app_store_info.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.app_store_info.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.app_store_info.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.app_store_info.subscription_type === 1">包月</span>
                <span v-else-if="form.app_store_info.subscription_type === 2">包季</span>
                <span v-else-if="form.app_store_info.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span>{{ $moment(form.app_store_info.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.app_store_info.cancel_at">{{ $moment(form.app_store_info.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.app_store_info.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>
            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>
          <div>
            <h4>GooglePlay 自动续订</h4>
            <template v-if="form.google_play_info.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.google_play_info.google_play_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.google_play_info.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.google_play_info.subscription_type === 1">包月</span>
                <span v-else-if="form.google_play_info.subscription_type === 2">包季</span>
                <span v-else-if="form.google_play_info.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span>{{ $moment(form.google_play_info.google_play_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.google_play_info.cancel_at">{{ $moment(form.google_play_info.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.google_play_info.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>

          <div>
            <h4>华为自动续订</h4>
            <template v-if="form.huawei_info.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.huawei_info.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.huawei_info.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.huawei_info.subscription_type === 1">包月</span>
                <span v-else-if="form.huawei_info.subscription_type === 2">包季</span>
                <span v-else-if="form.huawei_info.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span :class="{ expired: $moment(form.huawei_info.auto_subscription_expired_at * 1000).isBefore() }">{{ $moment(form.huawei_info.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.huawei_info.cancel_at">{{ $moment(form.huawei_info.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.huawei_info.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>

          <div>
            <h4>支付宝自动续订</h4>
            <template v-if="form.alipay_info.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.alipay_info.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.alipay_info.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.alipay_info.subscription_type === 1">包月</span>
                <span v-else-if="form.alipay_info.subscription_type === 2">包季</span>
                <span v-else-if="form.alipay_info.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span :class="{ expired: $moment(form.alipay_info.auto_subscription_expired_at * 1000).isBefore() }">{{ $moment(form.alipay_info.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.alipay_info.cancel_at">{{ $moment(form.alipay_info.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.alipay_info.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>

          <div>
            <h4>微信自动续订</h4>
            <template v-if="form.wechat_info.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.wechat_info.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.wechat_info.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.wechat_info.subscription_type === 1">包月</span>
                <span v-else-if="form.wechat_info.subscription_type === 2">包季</span>
                <span v-else-if="form.wechat_info.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span :class="{ expired: $moment(form.wechat_info.auto_subscription_expired_at * 1000).isBefore() }">{{ $moment(form.wechat_info.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.wechat_info.cancel_at">{{ $moment(form.wechat_info.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.wechat_info.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>

          <div>
            <h4>小米自动续订</h4>
            <template v-if="form.xiaomi_info.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.xiaomi_info.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.xiaomi_info.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.xiaomi_info.subscription_type === 1">包月</span>
                <span v-else-if="form.xiaomi_info.subscription_type === 2">包季</span>
                <span v-else-if="form.xiaomi_info.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span :class="{ expired: $moment(form.xiaomi_info.auto_subscription_expired_at * 1000).isBefore() }">{{ $moment(form.xiaomi_info.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.xiaomi_info.cancel_at">{{ $moment(form.xiaomi_info.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.xiaomi_info.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>
        </div>

        <p class="tips">开通自动续订时，每个周期会自动更新订阅到期时间</p>

        <el-button
          :disabled="!$store.getters.hasEditRole"
          type="primary"
          size="medium"
          class="apply save-btn"
          @click="
            paymentType = 'tide-plus';
            dialog.addPayment = true;
          "
          >增加潮汐PLUS时长</el-button
        >

        <el-button
          :disabled="!$store.getters.hasEditRole"
          size="medium"
          class="apply save-btn"
          @click="
            paymentType = 'tide-plus';
            dialog.reducePayment = true;
          "
          >减少潮汐PLUS时长</el-button
        >

        <el-button v-if="!form.vip.is_lifetime_member" :disabled="!$store.getters.hasEditRole" size="medium" class="apply save-btn" @click="dialog.setLifeTimeVip = true">设为终身会员</el-button>
        <el-button v-else :disabled="$env !== 'dev'" size="medium" class="apply save-btn" @click="dialog.dismissLifeTimeVip = true">罢黜终身会员</el-button>
      </div>

      <div class="container-head">
        <div class="container-head-title">
          声音卡 :
          <span v-if="form.scene_pass.is_lifetime_member">终身会员</span>
          <span v-else-if="form.scene_pass.expired_at == 0" :style="{ color: 'darkgray' }">从未开通过</span>
          <span v-else>
            <span :style="$moment(form.scene_pass.expired_day_at * 1000).isBefore() ? { color: 'darkgray' } : { color: 'green' }"> [自然日]{{ $moment(form.scene_pass.expired_day_at * 1000).format('YYYY-MM-DD HH:mm:ss') }} </span>
            <span :style="$moment(form.scene_pass.expired_at * 1000).isBefore() ? { color: 'darkgray' } : { color: 'green' }"> [实际]{{ $moment(form.scene_pass.expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }} </span>
          </span>
        </div>
      </div>

      <div class="container-body">
        <div class="info-blocks">
          <div>
            <h4>会员信息</h4>
            <div>
              <label>会员类型：</label>
              <span v-if="form.scene_pass.premium_type === 1">从未开通过</span>
              <span v-else-if="form.scene_pass.premium_type === 2">订阅中</span>
              <span v-else-if="form.scene_pass.premium_type === 3">买断中</span>
              <span v-else-if="form.scene_pass.premium_type === 4">试用期</span>
              <span v-else-if="form.scene_pass.premium_type === 5">曾经是会员</span>
              <span v-else>未知</span>
            </div>
            <div>
              <label>终身会员：</label>
              <span>{{ form.scene_pass.is_lifetime_member ? '是' : '否' }}</span>
            </div>
            <div>
              <label>到期时间：</label>
              <span>{{ $moment(form.scene_pass.expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
            <div>
              <label>变动时间：</label>
              <span>{{ $moment(form.scene_pass.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
            <div>
              <label>首次开通：</label>
              <span>{{ $moment(form.scene_pass.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
          </div>

          <div>
            <h4>AppStore 自动续订</h4>
            <template v-if="form.app_store_scene_pass_subscription.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.app_store_scene_pass_subscription.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.app_store_scene_pass_subscription.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.app_store_scene_pass_subscription.subscription_type === 1">包月</span>
                <span v-else-if="form.app_store_scene_pass_subscription.subscription_type === 2">包季</span>
                <span v-else-if="form.app_store_scene_pass_subscription.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span>{{ $moment(form.app_store_scene_pass_subscription.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.app_store_scene_pass_subscription.cancel_at">{{ $moment(form.app_store_scene_pass_subscription.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.app_store_scene_pass_subscription.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>
            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>
          <div>
            <h4>GooglePlay 自动续订</h4>
            <template v-if="form.google_play_scene_pass_subscription.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.google_play_scene_pass_subscription.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.google_play_scene_pass_subscription.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.google_play_scene_pass_subscription.subscription_type === 1">包月</span>
                <span v-else-if="form.google_play_scene_pass_subscription.subscription_type === 2">包季</span>
                <span v-else-if="form.google_play_scene_pass_subscription.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span>{{ $moment(form.google_play_scene_pass_subscription.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.google_play_scene_pass_subscription.cancel_at">{{ $moment(form.google_play_scene_pass_subscription.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.google_play_scene_pass_subscription.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>

          <div>
            <h4>华为自动续订</h4>
            <template v-if="form.huawei_scene_pass_subscription.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.huawei_scene_pass_subscription.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.huawei_scene_pass_subscription.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.huawei_scene_pass_subscription.subscription_type === 1">包月</span>
                <span v-else-if="form.huawei_scene_pass_subscription.subscription_type === 2">包季</span>
                <span v-else-if="form.huawei_scene_pass_subscription.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span>{{ $moment(form.huawei_scene_pass_subscription.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.huawei_scene_pass_subscription.cancel_at">{{ $moment(form.huawei_scene_pass_subscription.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.huawei_scene_pass_subscription.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>

          <div>
            <h4>支付宝自动续订</h4>
            <template v-if="form.alipay_scene_pass_subscription.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.alipay_scene_pass_subscription.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.alipay_scene_pass_subscription.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.alipay_scene_pass_subscription.subscription_type === 1">包月</span>
                <span v-else-if="form.alipay_scene_pass_subscription.subscription_type === 2">包季</span>
                <span v-else-if="form.alipay_scene_pass_subscription.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span>{{ $moment(form.alipay_scene_pass_subscription.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.alipay_scene_pass_subscription.cancel_at">{{ $moment(form.alipay_scene_pass_subscription.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.alipay_scene_pass_subscription.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>

          <div>
            <h4>微信自动续订</h4>
            <template v-if="form.wechat_scene_pass_subscription.subscription_type">
              <div>
                <label>状态：</label>
                <span v-if="$moment(form.wechat_scene_pass_subscription.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                <span v-else>正常</span>
              </div>
              <div>
                <label>商品：</label>
                <span>{{ form.wechat_scene_pass_subscription.product_id }}</span>
              </div>
              <div>
                <label>续订类型：</label>
                <span v-if="form.wechat_scene_pass_subscription.subscription_type === 1">包月</span>
                <span v-else-if="form.wechat_scene_pass_subscription.subscription_type === 2">包季</span>
                <span v-else-if="form.wechat_scene_pass_subscription.subscription_type === 3">包年</span>
              </div>
              <div>
                <label>到期时间：</label>
                <span>{{ $moment(form.wechat_scene_pass_subscription.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
              <div>
                <label>取消时间：</label>
                <span v-if="form.wechat_scene_pass_subscription.cancel_at">{{ $moment(form.wechat_scene_pass_subscription.cancel_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                <span v-else>无</span>
              </div>
              <div>
                <label>更新时间：</label>
                <span>{{ $moment(form.wechat_scene_pass_subscription.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>

            <div v-else>
              <label>状态：</label>
              <span>未曾开通</span>
            </div>
          </div>
        </div>

        <p class="tips">开通自动续订时，每个周期会自动更新订阅到期时间</p>

        <el-button
          :disabled="!$store.getters.hasEditRole"
          type="primary"
          size="medium"
          class="apply save-btn"
          @click="
            paymentType = 'scene-pass';
            dialog.addPayment = true;
          "
          >增加声音卡时长</el-button
        >

        <el-button
          :disabled="!$store.getters.hasEditRole"
          size="medium"
          class="apply save-btn"
          @click="
            paymentType = 'scene-pass';
            dialog.reducePayment = true;
          "
          >减少声音卡时长</el-button
        >
      </div>

      <el-dialog title="设为终身会员" width="700px" :visible.sync="dialog.setLifeTimeVip">
        <el-form>
          <div style="margin-bottom: 24px">将用户设为终身会员</div>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.setLifeTimeVip = false">取 消</el-button>
          <el-button type="primary" @click="setLifeTimeVip">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="罢黜终身会员" width="700px" :visible.sync="dialog.dismissLifeTimeVip">
        <el-form>
          <div style="margin-bottom: 24px">去除用户的终身会员</div>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.dismissLifeTimeVip = false">取 消</el-button>
          <el-button type="primary" @click="dismissLifeTimeVip">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="增加潮汐PLUS时长" width="700px" :visible.sync="dialog.addPayment">
        <el-form>
          <div style="margin-bottom: 24px">为用户增加会员时间，增加的时间即刻开始计算（例：当天中午 12:00 增加了 7 天时间，那么将在7天后的 12:00 过期），如果该用户本身仍剩余有会员时间，则是对剩余时间进行延长</div>
          <el-form-item label="时间">
            <el-col>
              <el-col :span="8">
                <el-input-number :min="1" v-model="newPaymentData.times"></el-input-number>
              </el-col>
              <el-col :span="8" :offset="1">
                <el-radio v-model="newPaymentData.multiple" :label="60">分钟</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="3600">小时</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="86400">天</el-radio>
              </el-col>
            </el-col>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.addPayment = false">取 消</el-button>
          <el-button type="primary" @click="manualAddUserPayment">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="减少订阅时长" width="700px" :visible.sync="dialog.reducePayment">
        <el-form>
          <div style="margin-bottom: 24px">减少用户的会员时间，减少的时间即刻开始计算</div>
          <el-form-item label="时间">
            <el-col>
              <el-col :span="8">
                <el-input-number :min="1" v-model="newPaymentData.times"></el-input-number>
              </el-col>
              <el-col :span="8" :offset="1">
                <el-radio v-model="newPaymentData.multiple" :label="60">分钟</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="3600">小时</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="86400">天</el-radio>
              </el-col>
            </el-col>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.reducePayment = false">取 消</el-button>
          <el-button type="primary" @click="manualReduceUserPayment">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'UserVipInfo',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      dialog: {
        setLifeTimeVip: false,
        dismissLifeTimeVip: false,
        addPayment: false,
        reducePayment: false,
      },
      newPaymentData: {
        note: '',
        times: 1,
        multiple: 60,
      },
      paymentType: 'tide-plus',
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        app_store_info: {},
        app_store_scene_pass_subscription: {},
        google_play_info: {},
        google_play_scene_pass_subscription: {},
        huawei_info: {},
        huawei_scene_pass_subscription: {},
        alipay_info: {},
        alipay_scene_pass_subscription: {},
        wechat_info: {},
        wechat_scene_pass_subscription: {},
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        console.log(val);
        this.init();
      },
    },
  },
  methods: {
    manualAddUserPayment: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
      };

      if (this.paymentType === 'scene-pass') {
        data.scene_pass_seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      } else if (this.paymentType === 'tide-plus') {
        data.seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      }

      try {
        await this.$request({
          method: 'POST',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.addPayment = false;
        this.$message({
          message: '补单成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
          times: 30,
          multiple: 86400,
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    manualReduceUserPayment: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
      };
      if (this.paymentType === 'scene-pass') {
        data.scene_pass_seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      } else if (this.paymentType === 'tide-plus') {
        data.seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      }
      try {
        await this.$request({
          method: 'DELETE',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.reducePayment = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
          times: 30,
          multiple: 86400,
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    setLifeTimeVip: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
        life_time_user: true,
      };
      try {
        await this.$request({
          method: 'POST',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.addPayment = false;
        this.$message({
          message: '设为终身会员成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    dismissLifeTimeVip: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
        unset_life_time: true,
      };
      try {
        await this.$request({
          method: 'DELETE',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.addPayment = false;
        this.$message({
          message: '罢黜终身会员成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
      this.dialog.dismissLifeTimeVip = false;
    },
    async clearActivities() {
      try {
        await this.$request({
          method: 'DELETE',
          url: `/debug/users/${this.$route.params.user_id}/activities`,
        });
        this.$message({
          message: '清理成功',
          type: 'success',
        });
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    dismissVip: async function() {
      try {
        await this.$request({
          method: 'DELETE',
          url: `/debug/users/${this.$route.params.user_id}/vip`,
        });
        this.$message({
          message: '罢黜成功',
          type: 'success',
        });
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    init: async function() {
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, res.data);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.info-blocks {
  display: flex;
  flex-direction: rows;
  padding-bottom: 8px;
  font-size: 14px;
}
.info-blocks > div {
  padding-right: 12px;
}
.info-blocks > div + div {
  padding-left: 12px;
}
.tips {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.24);
  margin-bottom: 16px;
}
</style>

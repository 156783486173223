import Layout from '@/components/layout';

import Tidesleep from '@/pages/tidesleep/index';
import TidesleepManager from '@/pages/tidesleep/manager/index';
import TidesleepAdd from '@/pages/tidesleep/manager/add';
import TidesleepEditor from '@/pages/tidesleep/manager/edit';

export default [
  {
    path: '/tidesleep',
    name: 'TidesleepIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '梦工厂',
    },
    redirect: {
      name: 'TidesleepManager',
    },
    children: [
      {
        path: 'index',
        name: 'Tidesleep',
        components: {
          default: Tidesleep,
        },
        meta: {
          requireAuth: true,
          label: 'Tidesleep',
          newDesign: true,
        },
      },

      {
        path: 'manager',
        name: 'TidesleepManager',
        components: {
          default: TidesleepManager,
        },
        meta: {
          requireAuth: true,
          label: '资源管理',
          newDesign: true,
        },
        children: [
          {
            path: 'add',
            name: 'TidesleepAdd',
            components: {
              default: TidesleepAdd,
            },
            meta: {
              requireAuth: true,
              label: '增加',
              newDesign: true,
            },
          },
          {
            path: ':id/edit',
            name: 'TidesleepEditor',
            components: {
              default: TidesleepEditor,
            },
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },
];

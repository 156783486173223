<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:02
 * @LastEditors  : Ruilong Li
 * @LastEditTime : 2020-02-14 14:41:47
 * @Description:  颜色picker
 * @FilePath: /davinci/src/components/color-picker.vue
 -->
<template>
  <el-col>
    <el-col :span="10">
      <el-input placeholder="Hex" v-model="hex"></el-input>
    </el-col>
    <el-col :span="5" :offset="1">
      <el-input placeholder="A" v-model="alpha"></el-input>
    </el-col>
    <br />
    <p>HEX: {{ this.value }}</p>
  </el-col>
</template>

<script>
// 将rgb颜色转成hex
function rgb2Hex(color) {
  var rgb = color.split(',');
  var r = parseInt(rgb[0]);
  var g = parseInt(rgb[1]);
  var b = parseInt(rgb[2]);

  var hex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return hex;
}
// 将hex颜色转成rgb
function hex2Rgb(hex, alpha) {
  var RGBA = parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + parseInt(alpha) / 100;
  return RGBA;
}
export default {
  name: 'Color-picker',
  components: {},
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return { hex: '#000000', alpha: 0 };
  },
  watch: {
    value() {
      this.syncColorChange();
    },
    hex(hex) {
      const { alpha } = this;
      if (hex.length !== 7) {
        return;
      }
      const color = `${hex2Rgb(hex, alpha)}`;
      if (this.value === color) {
        return;
      }
      this.$emit('input', color);
    },
    alpha(alpha) {
      if (!alpha.length) {
        return;
      }
      const [r, g, b] = this.value.split(',');
      const color = `${r},${g},${b},${parseInt(alpha) / 100}`;
      if (this.value === color) {
        return;
      }
      this.$emit('input', color);
    },
  },
  methods: {
    syncColorChange() {
      let colorValue = this.value.replace(/\s+/g, '');
      if (!colorValue) {
        this.hex = '#000000';
        this.alpha = 0;
        return;
      }

      const [r, g, b, a] = colorValue.split(',');
      this.hex = rgb2Hex([r, g, b].join(','));
      this.alpha = parseInt(parseFloat(a) * 100);
    },
  },
  created() {
    if (this.value) {
      this.syncColorChange();
    }
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <router-view v-if="$route.name !== 'ScenesManage'"></router-view>
  <div v-else>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="material-icons">music_note</i>
          <h3 slot="title">闹钟及唤醒</h3>
          <p slot="desc">太阳晒屁股了，该起床了</p>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-body">
          <el-menu class="nav" mode="horizontal" router :default-active="defaultActive">
            <template v-for="category in categories">
              <el-menu-item :key="category.id" :index="`/scenes/${category.type}`">{{ category.name }}</el-menu-item>
            </template>
            <el-button :disabled="!$store.getters.hasEditRole" @click="showListSortPanel" type="text">重新排序</el-button>
            <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">添加</el-button>
          </el-menu>
        </div>
        <div class="container-body">
          <el-table :data="scenes" stripe min-width="70px" @row-dblclick="rowDblClick">
            <el-table-column label="封面图片">
              <template slot-scope="scope">
                <button @click="copyToBoard(scope.row.image)">
                  <cover :width="48" :height="48" :src="scope.row.image" :mask-colors="[scope.row.primary_color]"></cover>
                </button>
              </template>
            </el-table-column>

            <el-table-column label="名称" min-width="120px">
              <template slot-scope="scope">
                <p>{{ getTR(scope.row.name) }}</p>
                <el-button type="text" @click="copyToBoard(scope.row.id)">{{ scope.row.id }} </el-button>
                <p style="font-size: 10px">{{ scope.row.resource.guid }}</p>
              </template>
            </el-table-column>

            <el-table-column label="状态">
              <template slot-scope="scope">{{ getStatus(scope.row.status) }} </template>
            </el-table-column>

            <el-table-column label="价格">
              <template slot-scope="scope">
                <div v-if="scope.row.access.includes('free')">免费</div>
                <template v-else-if="scope.row.access.includes('purchase')">
                  <div>¥{{ scope.row.purchase_info.price }}</div>
                </template>
                <div v-else-if="scope.row.access.includes('subscribe')">仅会员</div>
                <div v-else>无</div>

                <div v-if="scope.row.trial_ended_local_time && scope.row.trial_started_local_time" style="font-size: 8px">
                  <div>开始：{{ scope.row.trial_started_local_time }}</div>
                  <div>结束：{{ scope.row.trial_ended_local_time }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right">
              <template slot-scope="scope">
                <el-button type="text" @click="editItem(scope.row)">编辑</el-button>
                <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row.id)">删除</el-button>
                <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">复制JSON</el-button>
                <el-button type="text" v-if="$env === 'dev'" @click="copyToProduction(scope.row)">复制到正式环境</el-button>
                <el-button type="text" v-else @click="copyToDevelopment(scope.row)">复制到测试环境</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-dialog title="重新排序" width="40%" :visible.sync="dialogDisplay.listSortPanel">
          <draggable class="sort-list-container" v-model="sortList">
            <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
              <div class="sort-item-key">{{ index + 1 }}</div>
              <cover :width="48" :height="48" :src="item.image"></cover>
              <div class="sort-item-name">{{ item.name[lang] }}</div>
            </div>
          </draggable>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveSort">保 存</el-button>
            <el-button @click="dialogDisplay.listSortPanel = false">返 回</el-button>
          </span>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import Cover from '@/components/cover';
import Draggable from 'vuedraggable';
import allStatus from '@/status.json';

export default {
  name: 'ScenesManage',
  components: {
    Container,
    PageHeader,
    Cover,
    Draggable,
  },
  data() {
    return {
      lang: 'zh-Hans',
      langs: [
        {
          value: 'en',
          label: 'en',
        },
        {
          value: 'zh-Hans',
          label: 'zh-Hans',
        },
        {
          value: 'zh-Hant',
          label: 'zh-Hant',
        },
      ],
      sortChanged: false,
      pertain: {},
      ready: false,
      busy: false,
      finish: false,
      categories: [
        {
          type: 'alarm',
          name: '闹铃',
        },
        {
          type: 'wakeup',
          name: '轻唤醒',
        },
        {
          type: 'normal',
          name: '普通',
        },
        {
          type: 'mixed',
          name: '混音',
        },
        {
          type: 'meditation',
          name: '冥想专用',
        },
      ],
      scenes: [],
      sortList: [],
      dialogDisplay: {
        JSONVisible: false,
        listSortPanel: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    defaultActive() {
      return `/scenes/${this.$route.params.category_type}`;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'ScenesManage') {
        this.init();
      }
    },
    '$route.params.category_type'() {
      this.init();
    },
  },
  methods: {
    async copyToProduction(scene) {
      const data = { ...{}, ...scene };
      delete data.id;
      delete data.created_at;
      delete data.updated_at;
      data.status = 'draft';

      this.$prompt('此操作将使测试环境的数据复制到正式环境，请输入 “没有问题” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        if (value === '没有问题') {
          try {
            await this.$request({
              url: 'https://davinci.moreless.space/api/v1/admin/market/scenes',
              method: 'POST',
              data,
            });
            this.$message({
              message: '复制成功',
              type: 'success',
            });
          } catch (e) {
            console.log(e);
            this.$message.error('复制失败');
          }
        } else {
          this.$message.error('输入错误');
        }
      });
    },

    async copyToDevelopment(scene) {
      const data = { ...{}, ...scene };
      delete data.id;
      delete data.created_at;
      delete data.updated_at;
      // 复制到测试环境，sku清掉
      delete data.purchase_info;
      data.status = 'draft';

      this.$prompt('此操作将使正式环境的数据复制到测试环境，请输入 “没有问题” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        if (value === '没有问题') {
          try {
            await this.$request({
              url: 'https://leonardo.moreless.space/api/v1/admin/market/scenes',
              method: 'POST',
              data,
            });
            this.$message({
              message: '复制成功',
              type: 'success',
            });
          } catch (e) {
            this.$message.error('复制失败');
          }
        } else {
          this.$message.error('输入错误');
        }
      });
    },

    showListSortPanel() {
      this.sortList = this.scenes;
      this.dialogDisplay.listSortPanel = true;
    },

    async saveSort() {
      const data = this.sortList.map((item, idx) => {
        return { id: item.id, sort_key: idx };
      });
      try {
        await this.$request({
          url: '/v1/admin/scenes/sort_keys',
          method: 'POST',
          data,
        });
        this.scenes = this.sortList;
        this.dialogDisplay.listSortPanel = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(e.message);
      }
    },

    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.dialogDisplay.JSONVisible = true;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
    async fetchGroups() {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: {
          type: 'scene',
        },
      });
      const pertain = {};
      for (let group of res.data) {
        if (group.scene_ids) {
          for (let albumId of group.scene_ids) {
            let album = pertain[albumId];
            if (!album) {
              pertain[albumId] = [{ group_id: group.id, key: group.key }];
            } else {
              album.push({ group_id: group.id, key: group.key });
            }
          }
        }
      }
      this.pertain = pertain;
    },
    addItem() {
      if (this.$route.params.category_type == 'mixed') {
        this.$router.push({
          name: 'AddMixedScene',
        });
      } else {
        this.$router.push({
          name: 'AddScene',
          category_type: this.$route.params.category_type,
        });
      }
    },
    async removeItem(id) {
      try {
        const { value } = await this.$prompt('此操作将永久删除该场景，请输入 “确定删除” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        if (value !== '确定删除') {
          this.$message.error('输入错误');
        } else {
          await this.$request({
            url: `/v1/admin/market/scenes/${id}`,
            method: 'DELETE',
          });
          this.scenes = this.scenes.filter((scene) => scene.id !== id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      await this.fetchList();
      await this.fetchGroups();
    },
    fetchList: async function () {
      const { category_type: categoryType } = this.$route.params;
      const scenesRes = await this.$request({
        url: '/v1/admin/scenes',
        params: {
          offset: 0,
          limit: 512,
          category: categoryType,
        },
      });
      this.scenes = scenesRes.data;
    },
    editItem(item) {
      if (item.category === 'mixed') {
        this.$router.push({
          name: 'EditMixedScene',
          params: {
            scene_id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: 'EditScene',
          params: {
            scene_id: item.id,
            category_type: this.$route.params.category_type,
          },
        });
      }
    },
    rowDblClick(row) {
      this.editItem(row);
    },
  },
  async created() {
    await this.init();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  margin-left: auto;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  .select-btn {
    display: inline-block;
  }
  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;
  .select-btn {
    display: none;
  }
  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.reviewing {
  background-color: rgba(255, 126, 126, 0.12);
}

.sort-list-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

export default {
  state: {
    user: {},
  },
  mutations: {
    LOGIN(state, user) {
      state.user = user;
    },
  },
  actions: {
    login({ commit }, data) {
      window.localStorage.setItem('loginInfo', JSON.stringify(data));
      commit('LOGIN', data);
    },
  },
  getters: {
    user: (state) => {
      let user = state.user;
      if (!user) {
        user = JSON.parse(window.localStorage.getItem('loginInfo') || null);
      }
      return user;
    },
  },
};

<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">已购场景列表</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" size="small" @click="paymentAddVisible = true">增加场景</el-button>
          <el-button :disabled="!$store.getters.hasEditRole" size="small" type="danger" @click="clearPurchasedScenes">清空已购场景</el-button>
        </div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column label="封面" width="180">
            <template slot-scope="scope">
              <img class="avatar" :src="scope.row.image" />
            </template>
          </el-table-column>
          <el-table-column label="场景名">
            <template slot-scope="scope">{{ scope.row.name['zh-Hans'] }}</template>
          </el-table-column>
          <el-table-column label="定价">
            <template slot-scope="scope">
              <div v-if="scope.row.access.includes('free')">免费</div>
              <template v-else-if="scope.row.access.includes('purchase')">
                <div>¥{{ scope.row.purchase_info.price }}</div>
              </template>
              <div v-else>无</div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="操作">
            <template slot-scope="scope">
              <el-button :disabled="!$store.getters.hasEditRole" type="text" @click="deleteItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="增加场景" :visible.sync="paymentAddVisible">
        <el-form label-position="top">
          <el-form-item label="选择要增加的场景">
            <el-cascader :options="allScenes" :show-all-levels="false" v-model="selectedScenes"></el-cascader>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="paymentAddVisible = false">取 消</el-button>
          <el-button type="primary" @click="manualAddUserPayment">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'EditEquip',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      busy: false,
      finish: false,
      paymentAddVisible: false,
      list: [],
      selectedScenes: [],
      allScenes: [],
      newPaymentData: {
        note: '',
      },
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    loadSceneData: async function () {
      let ret = [];
      for (let category of [
        ['alarm', '闹铃'],
        ['wakeup', '轻唤醒'],
        ['normal', '普通'],
      ]) {
        const scenesResp = await this.$request({
          url: '/v1/admin/scenes',
          params: {
            offset: 0,
            limit: 999,
            category: category[0],
          },
        });
        ret.push({
          value: category[0],
          label: category[1],
          children: scenesResp.data.map((scene) => {
            return {
              value: scene.id,
              label: scene.name['zh-Hans'],
            };
          }),
        });
      }
      console.log(ret);
      this.allScenes = ret;
    },
    clearPurchasedScenes: async function () {
      this.$confirm('此操作将清空该用户所有已购场景, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$request({
          method: 'DELETE',
          url: `/debug/users/${this.$route.params.user_id}/purchased_scenes`,
        });
        this.list = [];
        this.finish = true;
        this.$message({
          message: '清除成功',
          type: 'success',
        });
      });
    },
    deleteItem(item) {
      const that = this;
      this.$confirm('此操作将永久删除此用户的该场景, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async function () {
        const itemId = item.id;
        await that.$request({
          method: 'DELETE',
          url: `/debug/users/${that.$route.params.user_id}/purchased_scenes/${itemId}`,
        });
        that.list = that.list.filter((item) => {
          return item.id !== itemId;
        });
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    manualAddUserPayment: async function () {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
        scene_id: this.selectedScenes[this.selectedScenes.length - 1],
      };

      await this.$request({
        method: 'POST',
        url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
        data,
      });
      this.paymentAddVisible = false;
      this.$message({
        message: '补单成功',
        type: 'success',
      });
      this.newPaymentData = {
        note: '',
        admin: '',
        times: 30,
        multiple: 86400,
      };
      this.selectedScenes = [];
      this.init();
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/purchased_scenes`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    init: async function () {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
    },
  },
  created() {
    this.init();
    this.loadSceneData();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>

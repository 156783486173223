<template>
  <router-view v-if="$route.name !== 'MedSkillManager'"></router-view>
  <container v-else>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">spa</i>
        <h3 slot="title">隐市小居</h3>
        <p slot="desc">结庐在人境，而无车马喧，问君何能尔？心远地自偏</p>
      </page-header>
    </div>
    <div class="container" slot="container">
      <div class="container-head">
        <div class="container-head-title">技巧管理</div>
        <div class="container-head-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="showListSortPanel" type="text">重新排序</el-button>
          <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">新建技巧</el-button>
        </div>
      </div>
      <div class="container-body">
        <el-table :data="list" stripe style="width: 100%">
          <el-table-column label="图片">
            <template slot-scope="scope">
              <cover :width="48" :height="48" :src="scope.row.image" :mask-colors="scope.row.mask_colors"></cover>
            </template>
          </el-table-column>
          <el-table-column label="名称" min-width="120px">
            <template slot-scope="scope"
              ><p>{{ getTR(scope.row.name) }}</p>
              <el-button type="text" @click="copyToBoard(scope.row.id)">{{ scope.row.id }}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="介绍" min-width="120px">
            <template slot-scope="scope"
              ><p>{{ getTR(scope.row.description) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Benefits" min-width="120px">
            <template slot-scope="scope"
              ><p>{{ getTR(scope.row.benefits) }}</p>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <router-link class="el-button el-button--text" :to="{ name: 'EditMedSkill', params: { skill_id: scope.row.id } }">编辑 </router-link>
              <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除 </el-button>
              <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">copy</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="重新排序" width="40%" :visible.sync="dialogDisplay.listSortPanel">
        <draggable class="sort-list-container" v-model="sortList">
          <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <cover :width="48" :height="48" :src="item.image"></cover>
            <div class="sort-item-name">{{ getTR(item.name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSort">保 存</el-button>
          <el-button @click="dialogDisplay.listSortPanel = false">返 回</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
// import _ from 'lodash'
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import Cover from '@/components/cover';
import Draggable from 'vuedraggable';
import allRegions from '@/regions.json';

const regionOptions = Object.keys(allRegions).map(value => {
  const label = allRegions[value];
  return { value, label };
});

export default {
  name: 'MedSkillManager',
  components: {
    Container,
    PageHeader,
    Draggable,
    Cover,
  },
  data() {
    return {
      lang: 'zh-Hans',
      allRegions,
      regionOptions: regionOptions,
      langs: [
        {
          value: 'en',
          label: 'en',
        },
        {
          value: 'zh-Hans',
          label: 'zh-Hans',
        },
        {
          value: 'zh-Hant',
          label: 'zh-Hant',
        },
      ],
      keyword: '',
      filterRegions: [],
      filterStatus: '',
      sortList: [],
      list: [],
      pertain: {},
      pertainTags: {},
      ready: false,
      busy: false,
      finish: false,

      dialogDisplay: {
        listSortPanel: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'MedSkillManager') {
        this.init();
      }
    },
    filterRegions() {
      this.refetch();
    },
    filterStatus() {
      this.refetch();
    },
  },
  methods: {
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
    addItem() {
      this.$router.push({
        name: 'AddMedSkill',
      });
    },
    async removeItem(item) {
      const { value } = await this.$prompt('此操作将永久删除该技巧，请输入 “确定删除” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (value !== '确定删除') {
        this.$message.error('输入错误');
      } else {
        try {
          await this.$request({
            url: `/v1/admin/meditation/skills/${item.id}`,
            method: 'DELETE',
          });
          this.list = this.list.filter(skill => skill.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('操作失败');
        }
      }
    },
    async init() {
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetchList();
    },
    async fetchList() {
      const res = await this.$request({
        url: '/v1/admin/meditation/skills',
      });
      const list = res.data;
      this.list = [...this.list, ...list];
    },
    showListSortPanel() {
      this.sortList = this.list;
      this.dialogDisplay.listSortPanel = true;
    },
    async saveSort() {
      const data = this.sortList.map((item, idx) => {
        return { id: item.id, sort_key: idx };
      });

      try {
        await this.$request({
          url: '/v1/admin/meditation/skills/sort_keys',
          method: 'PUT',
          data,
        });

        this.list = this.sortList;
        this.dialogDisplay.listSortPanel = false;

        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        if (e !== 'cancel') {
          this.$message.error(e.message);
        }
      }
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  async created() {
    await this.init();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}

.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;

  a {
    color: #1890ff;
    cursor: pointer;

    &:hover {
      color: #40a9ff;
    }
  }

  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;

  .select-btn {
    display: inline-block;
  }

  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;

  .select-btn {
    display: none;
  }

  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.reviewing {
  background-color: rgba(255, 126, 126, 0.12);
}

.region + .region::before {
  content: ', ';
}

.group + .group::before {
  content: ', ';
}

.sort-list-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}

.sort-item:hover {
  background-color: #f5f7fa;
}

.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:00
 * @LastEditTime : 2020-02-15 11:03:34
 * @LastEditors  : Ruilong Li
 * @Description: 探索页基础
 * @FilePath: /davinci/src/past-pages/explore/base/index.vue
 -->

<script>
import adBanners from '@/components/ad-banners';
import Container from '@/components/container';
import PageHeader from '@/components/page-header';

// -----------------------------------------------------------
// MARK: - Class: ExplorePageAdBanners
// -----------------------------------------------------------
export default {
  name: 'ExplorePageAdBanners',

  components: {
    adBanners,
    Container,
    PageHeader,
  },

  // -----------------------------------------------------------
  // MARK: - Props Data
  // -----------------------------------------------------------
  model: {
    prop: 'pageData',
    event: 'change',
  },
  props: {
    pageData: Object,
  },
  // -----------------------------------------------------------
  // MARK: - Views Data
  // -----------------------------------------------------------
  data() {
    return {};
  },

  // -----------------------------------------------------------
  // MARK: - Life Cycle
  // -----------------------------------------------------------
  created() {},

  methods: {},
};
</script>

<template>
  <div>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="material-icons">explore</i>
          <h3 slot="title">
            {{ $route.meta.label }}
          </h3>
          <p slot="desc">{{ $route.meta.description }}</p>
        </page-header>
      </div>

      <div slot="container">
        <ad-banners type="theme" :regions="['ALL']"></ad-banners>
      </div>
    </container>
  </div>
</template>

<style lang="less" scoped></style>

import Layout from '@/components/layout';

import News from '@/pages/notice/news/index';
import NewsManager from '@/pages/notice/news/manager/index';
import NewsAdd from '@/pages/notice/news/manager/add';
import NewsEditor from '@/pages/notice/news/manager/edit';

export default [
  {
    path: '/notice',
    name: 'NoticeIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '广播电台',
    },
    redirect: {
      name: 'NewsManager',
    },
    children: [
      {
        path: 'news',
        meta: {
          requireAuth: true,
          label: '公告',
          newDesign: true,
        },
        component: News,
        children: [
          {
            path: '/',
            name: 'NewsManager',
            component: NewsManager,
            meta: {
              requireAuth: true,
              label: '资源管理',
              newDesign: true,
            },
          },
          {
            path: 'add',
            name: 'NewsAdd',
            component: NewsAdd,
            meta: {
              requireAuth: true,
              label: '添加',
              newDesign: true,
            },
          },
          {
            path: ':news_id/edit',
            name: 'NewsEditor',
            component: NewsEditor,
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },
];

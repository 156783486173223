<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:01
 * @LastEditors: Ruilong Li
 * @LastEditTime: 2019-12-13 21:02:41
 * @Description:  冥想分类管理新增页面
 * @FilePath: /davinci/src/pages/meditation/group/add.vue
 -->
<template>
  <tag-form :form="form" @change="change" @save="save"></tag-form>
</template>

<script>
import TagForm from '@/components/tags/form';
export default {
  name: 'MeditationTagAdder',
  components: {
    TagForm,
  },
  props: {
    type: String,
  },
  data() {
    return {
      form: {
        id: '',
        key: '',
        name: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        sort_key: 0,
        type: 'article',
        status: 'draft',
      },
    };
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      try {
        const res = await this.$request({
          url: '/v2/admin/object_tags',
          method: 'POST',
          data,
        });
        this.$router.replace({
          name: 'ArticleTagsEditor',
          params: {
            tag_id: res.data.id,
          },
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

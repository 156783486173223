<template>
  <div>
    <div class="user-panel">
      <img class="avatar" :src="form.avatar || 'https://pics.tide.moreless.io/avatar/Fvut7zZlzpasbn9Uw-FZGzDpcM53'" />
      <div class="userinfo">
        <h3>{{ form.nickname }} ({{ form.id }})</h3>
        <p>
          <span v-if="!form.is_pwd_set">未设置密码</span>
          <span v-if="form.phone_number">手机号: {{ form.nation_code }} - {{ phone_number }}</span>
          <span v-if="form.sex">性别: {{ form.sex === 1 ? '男' : '女' }}</span>
          <span v-if="form.birthday">生日: {{ form.birthday }}</span>
          <span v-if="form.email">邮箱: {{ email }}</span>
          <span v-if="form.wechat.access_token">微信用户</span>
          <span v-if="form.qq.access_token">QQ用户</span>
          <span v-if="form.weibo.access_token">微博用户</span>

          <span>注册: {{ $moment(form.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
          <span>更新: {{ $moment(form.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </p>
      </div>
    </div>
    <el-menu class="nav-tab" mode="horizontal" router :default-active="defaultActive">
      <el-menu-item :index="`/rike/users/${$route.params.user_id}/edit`">用户评论</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'UsersPageHeader',
  props: {
    form: {
      type: Object,
    },
  },
  computed: {
    defaultActive() {
      return this.$route.fullPath;
    },
  },
  created() {
    console.log(this.form);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav-tab {
  margin-bottom: -18px;
}
.nav-tab .el-menu-item {
  line-height: 45px;
  height: 45px;
}
.user-panel {
  display: flex;
  margin-bottom: 16px;
}
.userinfo {
  position: relative;
  top: 4px;
  margin-left: 24px;
  h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 12px;
  }
  p {
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    font-size: 14px;
  }
  span + span:before {
    content: ' | ';
  }
}
.avatar {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
</style>

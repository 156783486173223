<template>
  <container>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">attach_money</i>
        <h3 slot="title">活动管理</h3>
        <p slot="desc">管理运营活动的地方</p>
      </page-header>
    </div>
    <div class="container" slot="container">
      <el-form class="form container-body" v-model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="链接地址">
              <el-input v-model="form.link"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="显示广告标记">
              <el-radio-group v-model="form.show_as_ads">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="可跳过">
              <el-radio-group v-model="form.can_skip">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="开始时间">
              <el-col :span="24">
                <el-date-picker type="datetime" v-model="startTime"></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-form-item label="结束时间">
              <el-col :span="24">
                <el-date-picker type="datetime" v-model="endTime"></el-date-picker>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="上架状态：">
            <el-radio-group v-model="form.status">
              <el-radio label="published">发布</el-radio>
              <el-radio label="reviewing">审阅</el-radio>
              <el-radio label="draft">草稿</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="展示地区">
            <regions-selecter v-model="form.regions"></regions-selecter>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item label="展示渠道">
            <channels-selector v-model="form.channels"></channels-selector>
          </el-form-item>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="会员状态">
              <vip-status-selecter v-model="form.vip_status"></vip-status-selecter>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="登录状态">
              <login-status-selecter v-model="form.login_status_v2"></login-status-selecter>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="iOS展示版本">
              <el-input-tag v-model="form.ios_versions"></el-input-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="Android展示版本">
              <el-input-tag v-model="form.android_versions"></el-input-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="显示时长（秒）">
              <el-input-number v-model="form.show_duration"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-form-item label="最多显示次数">
              <el-input-number v-model="form.max_show_count"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-form-item label="最小间隔时间（秒）">
              <el-input-number v-model="form.min_interval"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="背景颜色">
              <color-picker v-model="form.resource.image.background_color"></color-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <div slot="label">
            <span>背景图片</span>
          </div>
          <el-col>
            <div class="preview" v-if="form.resource.image.background.url" @click="previewBG">
              <img class="preview-img" :src="form.resource.image.background.url" />
              <div class="preview-img-alignment">
                <span>对齐: {{ form.resource.image.background.alignment }}</span>
              </div>
              <div class="preview-img-cover">
                <i class="material-icons">edit</i>
                <span>编辑</span>
              </div>
            </div>
            <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadBGProgress" :on-error="uploadBGError" :on-success="uploadBGSuccess" :before-upload="beforeUploadBG" :data="{ token: picToken }" :show-file-list="false">
              <div class="uploadPic">
                <i class="el-icon-upload2"></i>
                <span>上传封面</span>
              </div>
            </el-upload>
            <el-dialog title="背景设置" :visible.sync="dialogDisplay.BG">
              <el-form label-position="right">
                <el-form-item>
                  <div slot="label">
                    <span>图片URL：</span>
                  </div>
                  <el-input v-model="form.resource.image.background.url"></el-input>
                </el-form-item>
                <el-form-item>
                  <div slot="label">
                    <span>对齐方式：</span>
                  </div>
                  <el-radio-group v-model="form.resource.image.background.alignment">
                    <el-radio label="top">上</el-radio>
                    <el-radio label="right">右</el-radio>
                    <el-radio label="bottom">下</el-radio>
                    <el-radio label="left">左</el-radio>
                    <el-radio label="center">中</el-radio>
                    <el-radio label>无</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
              <img class="preview-dialog-img" :src="form.resource.image.background.url" />
            </el-dialog>
          </el-col>
        </el-form-item>

        <el-form-item>
          <div slot="label">
            <span>图层</span>
          </div>

          <el-col>
            <div class="layers">
              <div class="preview layer-preview" v-for="(layer, index) in form.resource.image.layers" :key="layer.url" @click="previewLayer(index)">
                <img class="preview-img" :src="layer.url" />
                <div class="preview-img-alignment">
                  <span>对齐: {{ layer.alignment }}</span>
                </div>
                <div class="preview-img-cover">
                  <i class="material-icons">edit</i>
                  <span>编辑</span>
                </div>
              </div>
              <el-upload action="https://upload.qiniup.com" :on-progress="uploadLayerProgress" :on-error="uploadLayerError" :on-success="uploadLayerSuccess" :before-upload="beforeUploadLayer" :data="{ token: picToken }" :show-file-list="false">
                <div class="uploadPic">
                  <i class="el-icon-upload2"></i>
                  <span>上传封面</span>
                </div>
              </el-upload>
            </div>
            <el-dialog v-if="layerIndex || layerIndex === 0" title="图层设置" :visible.sync="dialogDisplay.layer">
              <el-form label-position="right">
                <el-form-item>
                  <div slot="label">
                    <span>对齐方式：</span>
                  </div>
                  <el-radio-group v-model="form.resource.image.layers[layerIndex].alignment">
                    <el-radio label="top">上</el-radio>
                    <el-radio label="right">右</el-radio>
                    <el-radio label="bottom">下</el-radio>
                    <el-radio label="left">左</el-radio>
                    <el-radio label="center">中</el-radio>
                    <el-radio label>无</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
              <el-form-item>
                <div slot="label">
                  <span>操作：</span>
                </div>
                <el-button type="danger" @click="removeLayer(layerIndex)">删除图层</el-button>
              </el-form-item>
              <img class="preview-dialog-img" :src="form.resource.image.layers[layerIndex].url" />
            </el-dialog>
          </el-col>
        </el-form-item>
      </el-form>
    </div>
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="act-btn" @click="save">保存</el-button>
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="act-btn" @click="sync">{{ $env === 'production' ? '复制到开发环境' : '复制到正式环境' }}</el-button>
      </div>
    </div>
  </container>
</template>

<script>
function getRGBAInfo(txt) {
  const p = /rgba\(([\s\S*?]+)\)/gi;
  const m = p.exec(txt);
  return m[1];
}
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import ColorPicker from '@/components/color-picker';
import ElInputTag from '@/components/el-input-tag';
import ChannelsSelector from '@/components/channels-selector';
import RegionsSelecter from '@/components/regions-selecter';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';

export default {
  name: 'SplashForm',
  components: {
    Container,
    PageHeader,
    ColorPicker,
    ElInputTag,
    RegionsSelecter,
    ChannelsSelector,
    VipStatusSelecter,
    LoginStatusSelecter,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      dialogDisplay: {
        BG: false,
        layer: false,
      },

      layerIndex: null,
      BGUploading: false,
      layerUploading: false,
      picToken: '',
      picCDN: '',
    };
  },
  computed: {
    startTime: {
      get() {
        return new Date(this.form.start_time * 1000);
      },
      set(startTime) {
        this.form.start_time = startTime / 1000;
      },
    },
    endTime: {
      get() {
        return new Date(this.form.end_time * 1000);
      },
      set(endTime) {
        this.form.end_time = endTime / 1000;
      },
    },
    backgroundColor: {
      get: function () {
        if (!this.form.resource.image.background_color) {
          return 'rgba(255, 255, 255, 1)';
        }
        return `rgba(${this.form.resource.image.background_color})`;
      },
      set: function (val) {
        this.form.resource.image.background_color = getRGBAInfo(val);
      },
    },
  },
  watch: {},
  methods: {
    sync() {
      this.$emit('sync', this.form);
    },
    save() {
      this.$emit('save', this.form);
    },
    removeLayer(layerIndex) {
      this.layerIndex = null;
      this.dialogDisplay.layer = false;
      const layers = this.form.resource.image.layers.filter((item, index) => {
        return index !== layerIndex;
      });
      this.form.resource.image.layers = layers;
    },
    previewBG() {
      this.dialogDisplay.BG = true;
    },
    previewLayer(layerIndex) {
      this.layerIndex = layerIndex;
      this.dialogDisplay.layer = true;
    },
    beforeUploadBG: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadBGSuccess(response) {
      this.form.resource.image.background.url = `${this.picCDN}/${response.key}`;
      this.BGUploading = false;
    },
    uploadBGError() {
      this.BGUploading = false;
    },
    uploadBGProgress() {
      this.BGUploading = true;
    },
    beforeUploadLayer: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadLayerSuccess(response) {
      this.form.resource.image.layers.push({
        url: `${this.picCDN}/${response.key}`,
        alignment: 'center',
      });
      this.layerUploading = false;
    },
    uploadLayerError() {
      this.layerUploading = false;
    },
    uploadLayerProgress() {
      this.layerUploading = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .act-btn + .act-btn {
    margin-left: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}

.layers {
  display: flex;
  flex-direction: row;
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}
.form-label {
  position: relative;
  z-index: 2;
}
.el-form-item__label > .form-label i {
  opacity: 0;
}
.el-form-item:hover > .el-form-item__label > .form-label i {
  opacity: 1;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.32);
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
</style>

<template>
  <div id="app">
    <main-nav v-if="isNewDesign || invalidRoute"></main-nav>
    <loading v-if="loading">Loading</loading>
    <forbid v-else-if="forbid">forbid</forbid>
    <not-found v-else-if="invalidRoute">404</not-found>
    <router-view v-else id="main-container" class="container" :class="{ 'new-design': isNewDesign }"></router-view>
  </div>
</template>

<script>
import MainNav from '@/components/nav/main';
import Loading from '@/components/nav/loading';
import Forbid from '@/components/nav/forbid';
import NotFound from '@/components/nav/notfound';

const authentication = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return (roleCode & role) === role;
};

export default {
  components: {
    MainNav,
    Loading,
    Forbid,
    NotFound,
  },
  data() {
    return {
      loading: true,
      forbid: false,
      isNewDesign: Boolean(this.$route.meta.newDesign),
    };
  },
  computed: {
    invalidRoute() {
      return !this.$route.matched || this.$route.matched.length === 0;
    },
  },
  beforeUpdate() {
    this.isNewDesign = Boolean(this.$route.meta.newDesign);
  },
  async created() {
    if (location.protocol !== 'https:' && (location.hostname == 'davinci.moreless.space' || location.hostname == 'leonardo.moreless.space')) {
      location.replace(`https:${location.href.substring(location.protocol.length)}`);
    }
    this.initRoles();
  },
  methods: {
    async initRoles() {
      if (!this.$store.getters.role) {
        this.loading = true;
        console.log('getRole from App');
        try {
          await this.$store.dispatch('getRole');
        } catch (e) {
          const path = this.$route.path;
          if (path !== '/login') {
            this.$message.error('获取用户信息失败');
            location.href = '/login';
          }
        }
        this.loading = false;
      }
      const role = this.$store.getters.role;
      if (role) {
        const routes = this.genRoutes(role);
        this.$router.addRoutes(routes);
      }
    },
    genRoutes(roleCode) {
      let routes = [];
      if (authentication(roleCode, 1)) {
        if (authentication(roleCode, 10)) {
          routes = routes.concat(require('./router/AdminManager').default);
        }
        if (authentication(roleCode, 1000)) {
          routes = routes.concat(require('./router/UserReviews').default);
        }
        if (authentication(roleCode, 10000)) {
          routes = routes.concat(require('./router/DailypicsManager').default);
        }
        if (authentication(roleCode, 100000)) {
          routes = routes.concat(require('./router/ScenesManager').default);
        }
        if (authentication(roleCode, 1000000)) {
          routes = routes.concat(require('./router/TidesleepManager').default);
        }
        // if (authentication(roleCode, 10000000)) {
        // }
        if (authentication(roleCode, 100000000)) {
          routes = routes.concat(require('./router/NoticeManager').default);
          routes = routes.concat(require('./router/OperationManager').default);
        }
        if (authentication(roleCode, 1000000000)) {
          routes = routes.concat(require('./router/UsersManager').default);
          routes = routes.concat(require('./router/TransactionsManager').default);
        }
        if (authentication(roleCode, 10000000000)) {
          routes = routes.concat(require('./router/DevTools').default);
        }
        if (authentication(roleCode, 100000000000)) {
          routes = routes.concat(require('./router/RikeManager').default);
        }
      } else {
        this.forbid = true;
      }
      return routes;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  flex-grow: 1;
  height: 100vh !important;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<style lang="less">
body {
  margin: 0;
}
#app {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  // background-color: #f0f2f5;
}
.container {
  flex-grow: 1;
}
.new-design {
  height: auto !important;
  width: 0;
  background-color: #f0f2f5 !important;
}
:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
.el-breadcrumb__item .el-breadcrumb__inner {
  color: rgba(0, 0, 0, 0.45);
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: rgba(0, 0, 0, 0.65);
}
.el-upload-dragger {
  width: 315px;
}
.el-upload-list__item-thumbnail {
  object-fit: contain;
}

.nav-tab.el-menu--horizontal > .el-submenu .el-submenu__title {
  line-height: 45px;
  height: 45px;
}

.list-item:hover {
  background-color: #f5f7fa;
}
</style>

/*
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:00
 * @LastEditors: Ruilong Li
 * @LastEditTime: 2020-03-07 03:58:54
 * @Description:
 * @FilePath: /davinci/src/router/index.js
 */
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/components/layout';

import Login from '@/pages/login';
import Index from '@/pages/index';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },

    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/',
          name: 'Index',
          components: {
            default: Index,
          },
          meta: {
            requireAuth: true,
            label: '首页',
            newDesign: true,
          },
        },
      ],
    },
  ],
});

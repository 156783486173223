<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:01
 * @LastEditors: Ruilong Li
 * @LastEditTime: 2019-12-13 21:02:41
 * @Description:  冥想分类管理新增页面
 * @FilePath: /davinci/src/pages/scene/group/add.vue
 -->
<template>
  <group-form :form="form" @change="change" @save="save">
    <template slot="content-container">
      <div class="container-head">
        <div class="container-head-title">内容列表</div>
        <div class="container-head-extra">
          <el-button
            :disabled="!$store.getters.hasEditRole"
            type="text"
            size="mini"
            @click="
              sortList = form.contents;
              dialogDisplay.sortContents = true;
            "
            >排序</el-button
          >
          <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContentsScene = true">编辑声音列表</el-button>
          <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContentsMeditation = true">编辑冥想列表</el-button>
          <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContentsSleepstory = true">编辑睡眠故事列表</el-button>
        </div>
      </div>
      <div class="form container-body">
        <el-table class="data-table" :data="form.contents">
          <el-table-column label="排序" width="55px" align="left">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column label="图片">
            <template slot-scope="scope">
              <cover :width="48" :height="48" :src="getInnerContent(scope.row).image"></cover>
            </template>
          </el-table-column>

          <el-table-column label="名称">
            <template slot-scope="scope">{{ getTR(getInnerContent(scope.row).name) }}</template>
          </el-table-column>

          <el-table-column label="类型">
            <template slot-scope="scope">{{ getTypeName(scope.row.type) }}</template>
          </el-table-column>

          <el-table-column label="定价">
            <template slot-scope="scope">
              <div v-if="(getInnerContent(scope.row).access || '').includes('free')">免费</div>
              <template v-else-if="(getInnerContent(scope.row).access || '').includes('purchase')">
                <div>¥{{ getInnerContent(scope.row).purchase_info.price }}</div>
              </template>
              <div v-else>无</div>
            </template>
          </el-table-column>

          <el-table-column label="可订阅">
            <template slot-scope="scope">
              <div v-if="(getInnerContent(scope.row).access || '').includes('subscribe')" style="color: #67c23a">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!getInnerContent(scope.row).regions || !getInnerContent(scope.row).regions.length">所有</div>
              <template v-else>
                <div v-for="region in getInnerContent(scope.row).regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ getInnerContent(scope.row).status === 'published' ? '已上架' : getInnerContent(scope.row).status === 'draft' ? '草稿' : getInnerContent(scope.row).status === 'reviewing' ? '审阅中' : '' }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="选择声音场景" :visible.sync="dialogDisplay.editContentsScene">
        <el-tabs v-model="activeSceneTag">
          <el-tab-pane v-for="tag in sceneTags" :key="tag.value" :label="tag.label" :name="tag.value">
            <el-table v-if="tag.value === activeSceneTag" ref="listTableScene" :data="filtedSceneList" style="width: 100%" @select="onSelect" @select-all="onSelectAllScene">
              <el-table-column type="selection" width="55"></el-table-column>

              <el-table-column label="图片">
                <template slot-scope="scope">
                  <cover :width="48" :height="48" :src="scope.row.image"></cover>
                </template>
              </el-table-column>

              <el-table-column label="场景名">
                <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
              </el-table-column>

              <el-table-column label="定价">
                <template slot-scope="scope">
                  <div v-if="scope.row.access.includes('free')">免费</div>
                  <template v-else-if="scope.row.access.includes('purchase')">
                    <div>¥{{ scope.row.purchase_info.price }}</div>
                  </template>
                  <div v-else>无</div>
                </template>
              </el-table-column>

              <el-table-column label="可订阅">
                <template slot-scope="scope">
                  <div v-if="scope.row.access.includes('subscribe')" style="color: #67c23a">是</div>
                  <div v-else>否</div>
                </template>
              </el-table-column>

              <el-table-column label="展示地区">
                <template slot-scope="scope">
                  <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
                  <template v-else>
                    <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="状态">
                <template slot="header">
                  <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeyword"></el-input>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>

      <el-dialog title="选择冥想专辑" :visible.sync="dialogDisplay.editContentsMeditation">
        <el-tabs v-model="activeMedTag">
          <el-tab-pane v-for="tag in meditationTags" :key="tag.value" :label="tag.label" :name="tag.value">
            <el-table v-if="tag.value === activeMedTag" ref="listTableMeditation" :data="filtedMeditationList" style="width: 100%" @select="onSelect" @select-all="onSelectAllMeditation">
              <el-table-column type="selection" width="55"></el-table-column>

              <el-table-column label="图片">
                <template slot-scope="scope">
                  <cover :width="48" :height="48" :src="scope.row.image"></cover>
                </template>
              </el-table-column>

              <el-table-column label="场景名">
                <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
              </el-table-column>

              <el-table-column label="定价">
                <template slot-scope="scope">
                  <div v-if="scope.row.access.includes('free')">免费</div>
                  <template v-else-if="scope.row.access.includes('purchase')">
                    <div>¥{{ scope.row.purchase_info.price }}</div>
                  </template>
                  <div v-else>无</div>
                </template>
              </el-table-column>

              <el-table-column label="可订阅">
                <template slot-scope="scope">
                  <div v-if="scope.row.access.includes('subscribe')" style="color: #67c23a">是</div>
                  <div v-else>否</div>
                </template>
              </el-table-column>

              <el-table-column label="展示地区">
                <template slot-scope="scope">
                  <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
                  <template v-else>
                    <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="状态">
                <template slot="header">
                  <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeyword"></el-input>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>

      <el-dialog title="选择睡眠故事" :visible.sync="dialogDisplay.editContentsSleepstory">
        <el-tabs v-model="activeSSTag">
          <el-tab-pane v-for="tag in sleepstoryTags" :key="tag.value" :label="tag.label" :name="tag.value">
            <el-table v-if="tag.value === activeSSTag" ref="listTableSleepstory" :data="filtedSleepstoryList" style="width: 100%" @select="onSelect" @select-all="onSelectAllSleepstory">
              <el-table-column type="selection" width="55"></el-table-column>

              <el-table-column label="图片">
                <template slot-scope="scope">
                  <cover :width="48" :height="48" :src="scope.row.image"></cover>
                </template>
              </el-table-column>

              <el-table-column label="场景名">
                <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
              </el-table-column>

              <el-table-column label="定价">
                <template slot-scope="scope">
                  <div v-if="scope.row.access.includes('free')">免费</div>
                  <template v-else-if="scope.row.access.includes('purchase')">
                    <div>¥{{ scope.row.purchase_info.price }}</div>
                  </template>
                  <div v-else>无</div>
                </template>
              </el-table-column>

              <el-table-column label="可订阅">
                <template slot-scope="scope">
                  <div v-if="scope.row.access.includes('subscribe')" style="color: #67c23a">是</div>
                  <div v-else>否</div>
                </template>
              </el-table-column>

              <el-table-column label="展示地区">
                <template slot-scope="scope">
                  <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
                  <template v-else>
                    <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="状态">
                <template slot="header">
                  <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeyword"></el-input>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>

      <el-dialog title="内容排序" width="40%" :visible.sync="dialogDisplay.sortContents">
        <draggable class="sort-list-container" v-model="sortList">
          <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="sort-item-name">{{ getTypeName(item.type) }}</div>
            <div class="sort-item-name">{{ getTR(getInnerContent(item).name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSort">保 存</el-button>
          <el-button @click="dialogDisplay.sortContents = false">返 回</el-button>
        </span>
      </el-dialog>
    </template>
  </group-form>
</template>

<script>
import GroupForm from '@/components/groups/form';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
export default {
  name: 'ContentsGroupsEditor',
  components: {
    GroupForm,
    Draggable,
    Cover,
  },
  props: {
    type: String,
  },
  data() {
    return {
      sceneList: [],
      sceneTags: [],
      meditationList: [],
      meditationTags: [],
      sleepstoryList: [],
      sleepstoryTags: [],

      sortList: [],
      activeSceneTag: 'all',
      activeMedTag: 'all',
      activeSSTag: 'all',
      filterKeyword: '',

      dialogDisplay: {
        sortContents: false,
        editContentsScene: false,
        editContentsMeditation: false,
        editContentsSleepstory: false,
      },
      form: {
        id: '',
        mixed_scenes: [],
        scenes: [],
        meditations: [],
        articles: [],
        contents: [],
        type: 'content',
        is_mini_meditation: false,
        hide_albums_in_meditation_pages: false,
        is_can_share: true,
        status: 'draft',
        style: { preview_count: 0 },

        description: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },

        name: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },

        sub_title: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
      },
    };
  },
  computed: {
    filtedSceneList() {
      let filtedList = this.sceneList;
      const activeTag = this.activeSceneTag;
      const filterKeyword = this.filterKeyword;
      if (activeTag !== 'all') {
        const tag = this.sceneTags.filter((v) => v.id === activeTag)[0];
        console.log(this.sceneTags, activeTag);
        filtedList = filtedList.filter((item) => {
          return tag.scene_ids.includes(item.id);
        });
      }

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return (item.name['en'] || '').includes(filterKeyword) || (item.name['zh-Hans'] || '').includes(filterKeyword) || (item.name['zh-Hant'] || '').includes(filterKeyword);
        });
      }

      return filtedList;
    },
    filtedMeditationList() {
      let filtedList = this.meditationList;
      const activeTag = this.activeMedTag;
      const filterKeyword = this.filterKeyword;
      if (activeTag !== 'all') {
        const tag = this.meditationTags.filter((v) => v.id === activeTag)[0];
        filtedList = filtedList.filter((item) => {
          return tag.meditation_ids.includes(item.id);
        });
      }

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return (item.name['en'] || '').includes(filterKeyword) || (item.name['zh-Hans'] || '').includes(filterKeyword) || (item.name['zh-Hant'] || '').includes(filterKeyword);
        });
      }

      return filtedList;
    },
    filtedSleepstoryList() {
      let filtedList = this.sleepstoryList;
      const activeTag = this.activeSSTag;
      const filterKeyword = this.filterKeyword;
      if (activeTag !== 'all') {
        const tag = this.sleepstoryTags.filter((v) => v.id === activeTag)[0];
        filtedList = filtedList.filter((item) => {
          return tag.sleep_story_ids.includes(item.id);
        });
      }

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return (item.name['en'] || '').includes(filterKeyword) || (item.name['zh-Hans'] || '').includes(filterKeyword) || (item.name['zh-Hant'] || '').includes(filterKeyword);
        });
      }

      return filtedList;
    },
  },
  watch: {
    'form.contents'() {
      this.$emit('input', this.form);
    },
    filtedSceneList() {
      this.freshSelectedScene();
    },
    filtedMeditationList() {
      this.freshSelectedMeditation();
    },
    filtedSleepstoryList() {
      this.freshSelectedSleepstory();
    },
    'dialogDisplay.editContentsScene'() {
      this.freshSelectedScene();
    },
    'dialogDisplay.editContentsMeditation'() {
      this.freshSelectedMeditation();
    },
    'dialogDisplay.editContentsSleepstory'() {
      this.freshSelectedSleepstory();
    },
  },
  methods: {
    getInnerContent(content) {
      switch (content.type) {
        case 'sleep_story':
          return this.sleepstoryList.find((v) => v.id === content.content_id) || {};
        case 'meditation':
          return this.meditationList.find((v) => v.id === content.content_id) || {};
        case 'scene':
          return this.sceneList.find((v) => v.id === content.content_id) || {};
      }
      return {};
    },
    freshSelectedScene() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTableScene;
        if ($listTables && $listTables.length) {
          const $ref = $listTables[0];
          $ref.clearSelection();
          this.filtedSceneList.forEach((row) => {
            if (this.form.contents.some((item) => item.content_id === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    freshSelectedMeditation() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTableMeditation;
        if ($listTables && $listTables.length) {
          const $ref = $listTables[0];
          $ref.clearSelection();
          this.filtedMeditationList.forEach((row) => {
            if (this.form.contents.some((item) => item.content_id === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    freshSelectedSleepstory() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTableSleepstory;
        if ($listTables && $listTables.length) {
          const $ref = $listTables[0];
          $ref.clearSelection();
          this.filtedSleepstoryList.forEach((row) => {
            if (this.form.contents.some((item) => item.content_id === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    onSelect(selection, row) {
      if (this.form.contents.some((item) => item.content_id === row.id)) {
        this.form.contents = this.form.contents.filter((item) => item.content_id !== row.id);
      } else {
        this.form.contents.unshift(this.getActiveContentType(row));
      }
    },
    onSelectAllScene(selection) {
      if (!selection.length) {
        const list = this.form.contents.filter((content) => !this.filtedSceneList.some((item) => item.id === content.content_id));
        this.form.contents = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.form.contents.some((item) => item.content_id === album.id)) {
            list.push(album);
          }
        });
        this.form.contents = [...this.form.contents, ...list.map((v) => this.getActiveContentType(v))];
      }
    },
    onSelectAllMeditation(selection) {
      if (!selection.length) {
        const list = this.form.contents.filter((content) => !this.filtedMeditationList.some((item) => item.id === content.content_id));
        this.form.contents = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.form.contents.some((item) => item.content_id === album.id)) {
            list.push(album);
          }
        });
        this.form.contents = [...this.form.contents, ...list.map((v) => this.getActiveContentType(v))];
      }
    },
    onSelectAllSleepstory(selection) {
      if (!selection.length) {
        const list = this.form.contents.filter((content) => !this.filtedSleepstoryList.some((item) => item.id === content.content_id));
        this.form.contents = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.form.contents.some((item) => item.content_id === album.id)) {
            list.push(album);
          }
        });
        this.form.contents = [...this.form.contents, ...list.map((v) => this.getActiveContentType(v))];
      }
    },
    removeItem(row) {
      this.form.contents = this.form.contents.filter((item) => item.content_id !== row.content_id);
    },
    getActiveContentType(content) {
      if (this.dialogDisplay.editContentsScene) {
        return {
          type: 'scene',
          content_id: content.id,
          scene: content,
        };
      }
      if (this.dialogDisplay.editContentsMeditation) {
        return {
          type: 'meditation',
          content_id: content.id,
          meditation: content,
        };
      }
      if (this.dialogDisplay.editContentsSleepstory) {
        return {
          type: 'sleep_story',
          content_id: content.id,
          sleep_story: content,
        };
      }
      return 'unknown';
    },
    getTypeName(typ) {
      return typ === 'group' ? '分类' : typ === 'article' ? '图文' : typ === 'scene' ? '声音场景' : typ === 'dailypic' ? '日帖' : typ === 'meditation' ? '冥想' : typ === 'sleep_story' ? '睡眠故事' : typ === 'ad_banner' ? '推荐位' : '未知';
    },

    change(data) {
      this.form = Object({}, this.form, data);
    },
    async saveSort() {
      this.form.contents = this.sortList;
      this.save();
    },
    async save() {
      try {
        let data = Object.assign({}, this.form);
        await this.$request({
          url: `/v2/admin/explorer/groups/${this.$route.params.group_id}`,
          method: 'PUT',
          data,
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    async init() {
      const groupInfo = await this.getGroupInfo('scene');
      this.form = Object.assign({}, this.form, groupInfo);
      this.refreshTagAndList(this.form.type);
    },
    async refreshTagAndList(type) {
      const [sceneTags, sceneList, meditationTags, meditationList, sleepstoryTags, sleepstoryList] = await Promise.all([this.getSceneTags(), this.getSceneList(type), this.getMeditationTags(), this.getMeditationList(), this.getSleepStoryTags(), this.getSleepStoryList()]);
      this.sceneTags = sceneTags;
      this.sceneList = sceneList;
      this.meditationTags = meditationTags;
      this.meditationList = meditationList;
      this.sleepstoryTags = sleepstoryTags;
      this.sleepstoryList = sleepstoryList;
      this.form.contents = this.form.contents.filter((v) => this.getInnerContent(v).name);
    },
    async getGroupInfo() {
      const res = await this.$request({
        url: `/v2/admin/explorer/groups/${this.$route.params.group_id}`,
      });
      return res.data;
    },
    async getSceneTags() {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: 'scene' },
      });
      const tags = res.data.map((tag) => {
        return Object.assign(tag, {
          value: tag.id,
          label: this.getTR(tag.name),
        });
      });
      return [{ value: 'all', label: '全部' }, ...tags];
    },
    async getMeditationTags() {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: 'meditation' },
      });
      const tags = res.data.map((tag) => {
        return Object.assign(tag, {
          value: tag.id,
          label: this.getTR(tag.name),
        });
      });
      return [{ value: 'all', label: '全部' }, ...tags];
    },
    async getSleepStoryTags() {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: 'sleep_story' },
      });
      const tags = res.data.map((tag) => {
        return Object.assign(tag, {
          value: tag.id,
          label: this.getTR(tag.name),
        });
      });
      return [{ value: 'all', label: '全部' }, ...tags];
    },
    async getSceneList() {
      const res = await this.$request({
        url: '/v1/admin/scenes',
        params: { offset: 0, limit: 1000 },
      });
      return res.data;
    },
    async getMeditationList() {
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: { offset: 0, limit: 1000 },
      });
      return res.data;
    },
    async getSleepStoryList() {
      const res = await this.$request({
        url: '/v1/admin/sleep_stories',
        params: { offset: 0, limit: 1000 },
      });
      return res.data;
    },

    getTR(langDict) {
      return langDict ? langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en : '';
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

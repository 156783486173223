<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddAnnotation',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        name: '',
        en: [],
        'zh-Hans': [],
        'zh-Hant': [],
        ja: [],
        es: [],
        ru: [],
        ko: [],
      },
    };
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      const annotationRes = await this.$request({
        url: `/v2/admin/search_annotations`,
        method: 'POST',
        data,
      });
      const annotation = annotationRes.data;
      this.$router.replace({
        name: 'EditAnnotation',
        params: {
          annotation_id: annotation.id,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <edit-form :form="form" @save="save" @sync="sync"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'EditSplash',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        resource: {
          image: {
            background: {
              url: '',
              alignment: '',
            },
            background_color: '255,255,255,1.00',
            layers: [],
          },
        },
        status: 'published',
        show_as_ads: false,
        start_time: 0,
        end_time: 0,
        show_duration: 3,
        max_show_count: 3,
        min_interval: 3600,
        can_skip: true,
        link: '',
        vip_visible: false,
        regions: [],
        channels: [],
        updated_at: 0,
        created_at: 0,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    sync: async function() {
      const form = { ...{}, ...this.form };
      delete form.id;
      delete form.created_at;
      delete form.updated_at;

      if (this.$env === 'dev') {
        this.$prompt('此操作将使测试环境的数据复制到正式环境，请输入 “没有问题” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async ({ value }) => {
          if (value === '没有问题') {
            try {
              await this.$request({
                url: 'https://davinci.moreless.space/api/v1/admin/splashes',
                method: 'POST',
                data: form,
              });
              this.$message({
                message: '复制成功',
                type: 'success',
              });
            } catch (e) {
              this.$message.error('复制失败');
            }
          } else {
            this.$message.error('输入错误');
          }
        });
      } else if (this.$env === 'production') {
        try {
          await this.$request({
            url: 'https://leonardo.moreless.space/api/v1/admin/splashes',
            method: 'POST',
            data: form,
          });
          this.$message({
            message: '复制成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('复制失败');
        }
      }
    },
    save: async function() {
      const form = this.form;
      try {
        await this.$request({
          method: 'PUT',
          url: `/v1/admin/splashes/${this.$route.params.splashes_id}`,
          data: form,
        });
        this.$message({
          message: '保存成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(`保存失败, ${e.response.data.msg}`);
      }
    },
    init: async function() {
      const res = await this.$request({
        url: `/v1/admin/splashes/${this.$route.params.splashes_id}`,
      });
      this.form = Object.assign({}, this.form, res.data);

      console.log(JSON.stringify(res.data, undefined, 2));
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

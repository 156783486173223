<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">基础信息</div>
        <div class="container-head-extra">
          <el-button :disabled="!$store.getters.hasEditRole" type="danger" size="mini" @click="delUser">删除用户</el-button>
        </div>
      </div>
      <el-form class="form container-body" v-model="form" label-position="right" label-width="100px" style="padding-top: 36px">
        <el-form-item label="昵称：">
          <el-input class="nickname-input" v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <el-col>
            <el-radio v-model="form.sex" :label="1">男</el-radio>
            <el-radio v-model="form.sex" :label="2">女</el-radio>
          </el-col>
        </el-form-item>
        <el-form-item label="生日：">
          <el-input-number v-model="form.birthday"></el-input-number>
        </el-form-item>
        <el-form-item label=" ">
          <el-button :disabled="!$store.getters.hasEditRole" size="medium" class="apply save-btn" @click="save">应用</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">身份绑定</div>
      </div>
      <el-form class="form container-body" v-model="form">
        <el-row>
          <el-col :span="11">
            <el-form-item v-if="phoneLock">
              <div slot="label" class="contact-label">
                <span>手机</span>
                <el-button type="text" :disabled="!$store.getters.hasEditRole" class="material-icons icon-lock" @click="unlockPhone">lock</el-button>
              </div>
              <el-col>
                <el-col :span="3">
                  <el-input v-model="form.nation_code" disabled></el-input>
                </el-col>
                <el-col :span="1" align="center">-</el-col>
                <el-col :span="20">
                  <el-input v-model="form.phone_number" disabled></el-input>
                </el-col>
              </el-col>
            </el-form-item>
            <el-form-item v-else>
              <div slot="label" class="contact-label">
                <span>手机</span>
                <span class="material-icons icon-lock" @click="lockPhone">lock_open</span>
                <span class="lock-action-btn" :class="{ disabled: !phoneChanged }" @click="saveNewPhone">保存</span>
                <span class="lock-action-btn" @click="lockPhone">放弃</span>
              </div>
              <el-col>
                <el-col :span="3">
                  <el-input v-model="newNationCode"></el-input>
                </el-col>
                <el-col :span="1" align="center">-</el-col>
                <el-col :span="20">
                  <el-input v-model="newPhone"></el-input>
                </el-col>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item v-if="emailLock">
              <div slot="label" class="contact-label">
                <span>email ({{ form.email_verified ? '已验证' : '未验证' }})</span>
                <el-button type="text" :disabled="!$store.getters.hasEditRole" class="material-icons icon-lock" @click="unlockEmail">lock</el-button>
              </div>
              <el-input v-model="form.email" disabled></el-input>
            </el-form-item>
            <el-form-item v-else>
              <div slot="label" class="contact-label">
                <span>email</span>
                <span class="material-icons icon-lock" @click="lockEmail">lock_open</span>
                <span class="lock-action-btn" :class="{ disabled: !emailChanged }" @click="saveNewEmail">保存</span>
                <span class="lock-action-btn" @click="lockEmail">放弃</span>
              </div>
              <el-input v-model="newEmail"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table :data="accountList">
            <el-table-column prop="type" label="渠道"></el-table-column>
            <el-table-column prop="third_id" label="ID"></el-table-column>
            <el-table-column label="头像">
              <template slot-scope="scope">
                <img v-if="scope.row.avatar" class="avatar" :src="scope.row.avatar" />
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称"></el-table-column>
            <el-table-column prop="mobile" label="手机号"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column prop="email_verified" label="邮箱已验证"></el-table-column>
            <el-table-column label="绑定时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="更新时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right">
              <template slot-scope="scope">
                <el-button type="text" @click="unbind(scope.row.type)">解绑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">关联用户</div>
      </div>
      <el-form class="form container-body" v-model="form">
        <el-row>
          <el-table :data="relatedUserInfoList">
            <el-table-column label="UserID">
              <template slot-scope="scope">
                <router-link v-if="form.id != scope.row.user_id" target="_blank" :to="{ name: 'UserEditor', params: { user_id: scope.row.user_id } }">{{ scope.row.user_id }}</router-link>
                <span v-else>当前用户</span>
              </template>
            </el-table-column>
            <el-table-column label="DeviceID">
              <template slot-scope="scope">
                {{ scope.row.device_id }}
              </template>
            </el-table-column>
            <el-table-column label="UpdatedAt">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.updated_at * 1000) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'UserForm',
  components: {
    Container,
    PageHeader,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      phoneLock: true,
      emailLock: true,
      newNationCode: '',
      newPhone: '',
      newEmail: '',
      accountList: [],
      relatedUserInfoList: [],
    };
  },
  computed: {
    phoneChanged() {
      return `${this.newNationCode} - ${this.newPhone}` !== `${this.form.nation_code} - ${this.form.phone_number}`;
    },
    emailChanged() {
      return this.newEmail !== this.form.email;
    },
  },
  watch: {},
  methods: {
    save() {
      this.$emit('save', this.form);
    },
    async fetchUserIDs() {
      const resp = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/related_user_ids`,
      });
      this.relatedUserInfoList = resp.data;
    },
    delUser() {
      this.$prompt('此操作将删除该用户所有数据，并将其踢下线，请输入 “删除用户” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        if (value === '删除用户') {
          try {
            await this.$request({
              method: 'POST',
              url: `/v1/admin/users/${this.$route.params.user_id}/flush`,
            });

            if (window.history.length > 2) {
              this.$router.back(-1);
            } else {
              this.$router.replace({
                name: 'UsersManager',
              });
            }

            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 2000,
            });
          } catch (e) {
            this.$message.error(e.message);
          }
        } else {
          this.$message.error('输入错误');
        }
      });
    },
    lockPhone() {
      this.phoneLock = true;
    },
    unlockPhone() {
      this.$confirm('该字段为用户提供的联系方式，如无特殊情况，不建议修改，仍然继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.phoneLock = false;
        this.newPhone = this.form.phone_number;
        this.newNationCode = this.form.nation_code;
      });
    },
    saveNewPhone() {
      if (!this.phoneChanged) {
        return;
      }

      if (!this.newPhone && !this.newNationCode) {
        this.$confirm('确定要清除该信息吗？该改动将立即生效，是否继续', '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$request({
            method: 'DELETE',
            url: `/v1/admin/users/${this.$route.params.user_id}/phone`,
          }).then(() => {
            this.form.phone_number = this.newPhone;
            this.form.nation_code = this.newNationCode;
            this.phoneLock = true;
          });
        });
        return;
      }

      if (!this.newPhone || !this.newNationCode) {
        this.$alert('信息需要填写完整才能保存', '错误', {
          type: 'error',
        });
        return;
      }

      this.$confirm('该改动将立即生效，确定真要进行修改吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$request({
          method: 'POST',
          url: `/v1/admin/users/${this.$route.params.user_id}/phone`,
          data: {
            phone_number: this.newPhone,
            nation_code: this.newNationCode,
          },
        }).then(() => {
          this.form.phone_number = this.newPhone;
          this.form.nation_code = this.newNationCode;
          this.phoneLock = true;
        });
      });
    },
    lockEmail() {
      this.emailLock = true;
    },
    unlockEmail() {
      this.$confirm('该字段为用户提供的联系方式，如无特殊情况，不建议修改，仍然继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.emailLock = false;
        this.newEmail = this.form.email;
      });
    },
    saveNewEmail() {
      if (!this.emailChanged) {
        return;
      }
      if (!this.newEmail) {
        this.$confirm('确定要清除该信息吗？该改动将立即生效，是否继续', '提示', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.$request({
            method: 'DELETE',
            url: `/v1/admin/users/${this.$route.params.user_id}/email`,
          }).then(() => {
            this.form.email = this.newEmail;
            this.emailLock = true;
          });
        });
        return;
      }
      this.$confirm('该改动将立即生效，确定真要进行修改吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$request({
          method: 'POST',
          url: `/v1/admin/users/${this.$route.params.user_id}/email`,
          data: {
            email: this.newEmail,
          },
        }).then(() => {
          this.form.email = this.newEmail;
          this.emailLock = true;
        });
      });
    },
    loadAccounts: async function() {
      let resp = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/third_party_accounts`,
      });
      this.accountList = resp.data;
    },
    unbind: async function(channel) {
      this.$confirm('是否确认解绑此第三方账号？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$request({
          method: 'DELETE',
          url: `v1/admin/users/${this.$route.params.user_id}/connect_accounts/${channel}`,
        });
        await this.loadAccounts();
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
  },
  created: async function() {
    this.loadAccounts();
    this.fetchUserIDs();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-head-extra {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dash {
  display: block;
  margin: 24px 0;
  height: 1px;
  background-color: #e8e8e8;
}
.apply {
  display: block;
}
.nickname-input {
  width: 180px;
}
.base-col {
  min-width: 280px;
  flex-shrink: 0;
}
.el-form-container {
  clear: both;
}

.contact-label {
  position: relative;
  z-index: 1;
}

.icon-lock {
  font-size: 16px;
  vertical-align: sub;
  cursor: pointer;
}

.lock-action-btn {
  color: #08f;
  cursor: pointer;
  &.disabled {
    color: #dfe4ed;
    cursor: default;
  }
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}
</style>

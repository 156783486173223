<template>
  <participant-form :form="form" @change="change" @save="save"></participant-form>
</template>

<script>
import ParticipantForm from './form';
export default {
  name: 'ParticipantEditor',
  components: {
    ParticipantForm,
  },
  props: {
    type: String,
  },
  data() {
    return {
      form: {
        avatar: '',
        avatar_unselected: '',
        name_i18n: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        title: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        description: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        role: 'speaker',
        language: 'zh',
        special: false,
        sort_key: 0,
      },
    };
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      try {
        await this.$request({
          url: `/v1/admin/participants/${this.$route.params.participant_id}`,
          method: 'PUT',
          data,
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    async init() {
      const res = await this.$request({
        url: `/v1/admin/participants/${this.$route.params.participant_id}`,
      });
      this.form = Object.assign({}, this.form, res.data);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <container>
    <div class="container" slot="container">
      <el-form class="form container-body" v-model="form" label-width="120px">
        <el-form-item label="场景名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="分享标题">
          <el-input v-model="form.share_text"></el-input>
        </el-form-item>
        <el-form-item label="解锁所需分享数">
          <el-input-number v-model="form.musical_note_unlock.count"></el-input-number>
        </el-form-item>
        <el-form-item label="转圈方式">
          <el-radio v-model="form.like" label="disc">唱片</el-radio>
          <el-radio v-model="form.like" label="clock">时钟</el-radio>
        </el-form-item>
        <el-row>
          <el-col :span="7">
            <el-form-item label="背景颜色">
              <el-col :span="24">
                <color-picker v-model="form.color"></color-picker>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form class="form container-body" v-model="form">
        <el-row>
          <el-col :span="4">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>封面图</span>
                <i class="el-icon-edit" @click="dialogDisplay.cover = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="coverUploading">
                  <i class="el-icon-loading"></i>
                </div>
                <div class="preview" v-if="form.img_url" @click="dialogDisplay.coverPreview = true">
                  <img class="preview-img" :src="form.img_url" />
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadCoverProgress" :on-error="uploadCoverError" :on-success="uploadCoverSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传封面</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>

          <el-col :span="4" :offset="1">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>图标</span>
                <i class="el-icon-edit" @click="dialogDisplay.icon = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="iconUploading">
                  <i class="el-icon-loading"></i>
                </div>
                <div class="preview" v-if="form.icon_url" @click="dialogDisplay.iconPreview = true">
                  <img class="preview-img" :src="form.icon_url" />
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadIconProgress" :on-error="uploadIconError" :on-success="uploadIconSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传图标</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>GIF</span>
                <i class="el-icon-edit" @click="dialogDisplay.gif = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="gifUploading">
                  <i class="el-icon-loading"></i>
                </div>
                <div class="preview" v-if="form.gif_url" @click="dialogDisplay.gifPreview = true">
                  <img class="preview-img" :src="form.gif_url" />
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadGifProgress" :on-error="uploadGifError" :on-success="uploadGifSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传GIF</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>分享图</span>
                <i class="el-icon-edit" @click="dialogDisplay.sharePic = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="sharePicUploading">
                  <i class="el-icon-loading"></i>
                </div>
                <div class="preview" v-if="form.share_img_url" @click="dialogDisplay.sharePreview = true">
                  <img class="preview-img" :src="form.share_img_url" />
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadSharePicProgress" :on-error="uploadSharePicError" :on-success="uploadSharePicSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传分享图</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>声音地址</span>
                <i class="el-icon-edit" @click="dialogDisplay.sound = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="soundUploading">
                  <i class="el-icon-loading"></i>
                </div>
                <div class="preview voice" v-else-if="form['audio_url']" @click="dialogDisplay.soundPreview = true">
                  <i class="material-icons">queue_music</i>
                  <div class="preview-img-cover">
                    <i class="material-icons">headset</i>
                    <span>试听</span>
                    <audio ref="sound" :src="form['audio_url']" @play="onAudioPlay" @pause="onAudioPause" @ended="onAudioEnded">
                      <source :src="form['audio_url']" type="audio/mpeg" />
                      <source :src="form['audio_url']" type="audio/ogg" />
                    </audio>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadSoundProgress" :on-error="uploadSoundError" :on-success="uploadSoundSuccess" :before-upload="beforeUploadSound" :data="{ token: soundToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传场景声音</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>试听地址</span>
                <i class="el-icon-edit" @click="dialogDisplay.demo = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="demoUploading">
                  <i class="el-icon-loading"></i>
                </div>
                <div class="preview voice" v-else-if="form['demo_url']" @click="dialogDisplay.demoPreview = true">
                  <i class="material-icons">queue_music</i>
                  <div class="preview-img-cover">
                    <i class="material-icons">headset</i>
                    <span>试听</span>
                    <audio ref="demo" :src="form['demo_url']" @play="onDemoPlay" @pause="onDemoPause" @ended="onDemoEnded">
                      <source :src="form['demo_url']" type="audio/mpeg" />
                      <source :src="form['demo_url']" type="audio/ogg" />
                    </audio>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadDemoProgress" :on-error="uploadDemoError" :on-success="uploadDemoSuccess" :before-upload="beforeUploadDemo" :data="{ token: soundToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传试听声音</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="act-btn" @click="save">保存</el-button>
        <el-button size="small" class="act-btn" @click="dialogDisplay.JSON = true">JSON</el-button>
      </div>
    </div>

    <div class="dialogs" slot="container">
      <el-dialog title="JSON" :visible.sync="dialogDisplay.JSON">
        <el-input v-model="json" type="textarea" :autosize="{ minRows: 8 }"></el-input>
      </el-dialog>

      <el-dialog title="封面地址" :visible.sync="dialogDisplay.cover">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['img_url']">
              <i v-if="coverUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadCoverProgress" :on-error="uploadCoverError" :on-success="uploadCoverSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="封面预览" :visible.sync="dialogDisplay.coverPreview">
        <img class="preview-dialog-img" :src="form['img_url']" />
      </el-dialog>

      <el-dialog title="图标地址" :visible.sync="dialogDisplay.icon">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['icon_url']">
              <i v-if="coverUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadIconProgress" :on-error="uploadIconError" :on-success="uploadIconSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="图标预览" :visible.sync="dialogDisplay.iconPreview">
        <img class="preview-dialog-img" :src="form['icon_url']" />
      </el-dialog>

      <el-dialog title="GIF地址" :visible.sync="dialogDisplay.gif">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['gif_url']">
              <i v-if="gifUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadGifProgress" :on-error="uploadGifError" :on-success="uploadGifSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="GIF预览" :visible.sync="dialogDisplay.gifPreview">
        <img class="preview-dialog-img" :src="form['gif_url']" />
      </el-dialog>

      <el-dialog title="分享图地址" :visible.sync="dialogDisplay.sharePic">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['share_img_url']">
              <i v-if="sharePicUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadSharePicProgress" :on-error="uploadSharePicError" :on-success="uploadSharePicSuccess" :before-upload="beforeUploadImage" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="分享图预览" :visible.sync="dialogDisplay.sharePreview">
        <img class="preview-dialog-img" :src="form['share_img_url']" />
      </el-dialog>

      <el-dialog title="声音" :visible.sync="dialogDisplay.sound">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['audio_url']">
              <i v-if="soundUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadSoundProgress" :on-error="uploadSoundError" :on-success="uploadSoundSuccess" :before-upload="beforeUploadSound" :data="{ token: soundToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="试听" :visible.sync="dialogDisplay.demo">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['demo_url']">
              <i v-if="demoUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadDemoProgress" :on-error="uploadDemoError" :on-success="uploadDemoSuccess" :before-upload="beforeUploadDemo" :data="{ token: soundToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="声音预览" width="200px" :visible.sync="dialogDisplay.soundPreview">
        <div style="text-align: center">
          <div v-if="soundPlaying" class="sound-btn" @click="stopSound">
            <i class="material-icons">stop</i>
          </div>
          <div v-else class="sound-btn" @click="playSound">
            <i class="material-icons">headset</i>
          </div>
          <span>{{ soundPlaying ? '试听中' : '播放' }}</span>
        </div>
      </el-dialog>

      <el-dialog title="试听预览" width="200px" :visible.sync="dialogDisplay.demoPreview">
        <div style="text-align: center">
          <div v-if="demoPlaying" class="sound-btn" @click="stopDemo">
            <i class="material-icons">stop</i>
          </div>
          <div v-else class="sound-btn" @click="playDemo">
            <i class="material-icons">headset</i>
          </div>
          <span>{{ demoPlaying ? '试听中' : '播放' }}</span>
        </div>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import ColorPicker from '@/components/color-picker';
export default {
  name: 'TidesleepForm',
  components: {
    Container,
    ColorPicker,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      dialogDisplay: {
        JSON: false,
        cover: false,
        coverPreview: false,
        icon: false,
        iconPreview: false,
        gif: false,
        gifPreview: false,
        sharePic: false,
        sharePreview: false,
        sound: false,
        soundPreview: false,
        demo: false,
        demoPreview: false,
      },
      soundPlaying: false,
      demoPlaying: false,
      coverUploading: false,
      iconUploading: false,
      gifUploading: false,
      sharePicUploading: false,
      soundUploading: false,
      demoUploading: false,
      picToken: '',
      soundToken: '',
      picCDN: '',
      soundCDN: '',
    };
  },
  computed: {
    json: {
      get: function () {
        return JSON.stringify(this.form, undefined, 2);
      },
      set: function (val) {
        Object.assign(this.form, JSON.parse(val));
      },
    },
  },
  watch: {},
  methods: {
    save() {
      this.$emit('save', this.form);
    },

    playSound() {
      const audio = this.$refs.sound;
      audio.play();
    },
    stopSound() {
      const audio = this.$refs.sound;
      if (!audio.paused) {
        audio.pause();
      }
    },
    playDemo() {
      const demo = this.$refs.demo;
      demo.play();
    },
    stopDemo() {
      const demo = this.$refs.demo;
      if (!demo.paused) {
        demo.pause();
      }
    },
    onAudioPlay() {
      this.soundPlaying = true;
    },
    onAudioPause(e) {
      const audio = e.target;
      audio.currentTime = 0;
      this.soundPlaying = false;
    },
    onAudioEnded() {
      this.soundPlaying = false;
    },

    onDemoPlay() {
      this.demoPlaying = true;
    },
    onDemoPause(e) {
      const audio = e.target;
      audio.currentTime = 0;
      this.demoPlaying = false;
    },
    onDemoEnded() {
      this.demoPlaying = false;
    },

    previewCover() {
      this.dialogDisplay.coverPreview = true;
    },
    previewIcon() {
      this.dialogDisplay.iconPreview = true;
    },
    previewGif() {
      this.dialogDisplay.gif = true;
    },
    previewShare() {
      this.dialogDisplay.sharePic = true;
    },
    previewAudio() {
      this.dialogDisplay.audio = true;
    },
    beforeUploadImage: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },

    uploadCoverSuccess(response) {
      this.form.img_url = `${this.picCDN}/${response.key}`;
      this.coverUploading = false;
    },
    uploadCoverError() {
      this.coverUploading = false;
    },
    uploadCoverProgress() {
      this.coverUploading = true;
    },

    uploadIconSuccess(response) {
      this.form.icon_url = `${this.picCDN}/${response.key}`;
      this.iconUploading = false;
    },
    uploadIconError() {
      this.iconUploading = false;
    },
    uploadIconProgress() {
      this.iconUploading = true;
    },

    uploadGifSuccess(response) {
      this.form.gif_url = `${this.picCDN}/${response.key}`;
      this.gifUploading = false;
    },
    uploadGifError() {
      this.gifUploading = false;
    },
    uploadGifProgress() {
      this.gifUploading = true;
    },

    uploadSharePicSuccess(response) {
      this.form.share_img_url = `${this.picCDN}/${response.key}`;
      this.sharePicUploading = false;
    },
    uploadSharePicError() {
      this.sharePicUploading = false;
    },
    uploadSharePicProgress() {
      this.sharePicUploading = true;
    },

    beforeUploadSound: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/get_open_resource_upload_token',
        method: 'GET',
        params: { folder: 'demo_sounds' },
      });
      this.uploadToken = getTokenResp.data.token;
      this.uploadCDN = getTokenResp.data.cdn;
    },

    uploadSoundSuccess(response) {
      this.form.audio_url = `${this.soundCDN}/${response.key}`;
      this.soundUploading = false;
    },
    uploadSoundError() {
      this.soundUploading = false;
    },
    uploadSoundProgress() {
      this.soundUploading = true;
    },

    beforeUploadDemo: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/get_open_resource_upload_token',
        method: 'GET',
        params: { folder: 'demo_sounds' },
      });
      this.uploadToken = getTokenResp.data.token;
      this.uploadCDN = getTokenResp.data.cdn;
    },

    uploadDemoSuccess(response) {
      this.form.demo_url = `${this.soundCDN}/${response.key}`;
      this.demoUploading = false;
    },
    uploadDemoError() {
      this.demoUploading = false;
    },
    uploadDemoProgress() {
      this.demoUploading = true;
    },
  },
  created: async function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .act-btn + .act-btn {
    margin-left: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}

.layers {
  display: flex;
  flex-direction: row;
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}
.form-label {
  position: relative;
  z-index: 2;
}
.el-form-item__label > .form-label i {
  opacity: 0;
}
.el-form-item:hover > .el-form-item__label > .form-label i {
  opacity: 1;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.16);
}
.preview-img-alignment {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.32);
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.38);
}
</style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddMixedScene',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        type: 'meditation',
        location: '',
        condition: {},
        description: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        meditation_album_id: '',
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      const recommendRes = await this.$request({
        url: `/v1/admin/report_page_recommendations`,
        method: 'POST',
        data,
      });
      const recommend = recommendRes.data;
      this.$router.replace({
        name: 'EditMedReportPageRecommend',
        params: {
          recommend_id: recommend.id,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:01
 * @LastEditors: Ruilong Li
 * @LastEditTime: 2019-12-13 21:02:41
 * @Description:  冥想分类管理新增页面
 * @FilePath: /davinci/src/pages/meditation/group/add.vue
 -->
<template>
  <group-form :form="form" @change="change" @save="save">
    <template slot="content-container">
      <div class="container-head">
        <div class="container-head-title">专辑列表</div>
        <div class="container-head-extra">
          <el-button
            :disabled="!$store.getters.hasEditRole"
            type="text"
            size="mini"
            @click="
              sortList = form.meditations;
              dialogDisplay.sortContents = true;
            "
            >排序</el-button
          >
          <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContents = true">编辑专辑列表</el-button>
        </div>
      </div>
      <div class="form container-body">
        <el-table class="data-table" :data="form.meditations">
          <el-table-column label="排序" width="55px" align="left">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column label="图片">
            <template slot-scope="scope">
              <cover :width="48" :height="48" :src="scope.row.image"></cover>
            </template>
          </el-table-column>

          <el-table-column label="专辑名">
            <template slot-scope="scope">
              {{ getTR(scope.row.name) }} <br />
              {{ scope.row.id }}
            </template>
          </el-table-column>

          <el-table-column label="章节数">
            <template slot-scope="scope">{{ scope.row.sections.length }}</template>
          </el-table-column>

          <el-table-column label="免费章节数">
            <template slot-scope="scope">
              {{ scope.row.sections.filter((v) => v.access == 'free').length }}
            </template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
              <template v-else>
                <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ getStatus(scope.row.status) }}
            </template>
          </el-table-column>

          <el-table-column label="限时体验">
            <template slot-scope="scope">
              <template v-if="scope.row.trial_started_local_time && scope.row.trial_ended_local_time">
                <div style="font-size: 8px">开始：{{ scope.row.trial_started_local_time }}</div>
                <div style="font-size: 8px">结束：{{ scope.row.trial_ended_local_time }}</div>
              </template>
              <template v-else>无</template>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="选择专辑" :visible.sync="dialogDisplay.editContents">
        <el-tabs v-model="activeTag">
          <el-tab-pane v-for="tag in tags" :key="tag.value" :label="tag.label" :name="tag.value">
            <el-table v-if="tag.value === activeTag" ref="listTable" :data="filtedList" style="width: 100%" @select="onSelect" @select-all="onSelectAll">
              <el-table-column type="selection" width="55"></el-table-column>

              <el-table-column label="图片">
                <template slot-scope="scope">
                  <cover :width="48" :height="48" :src="scope.row.image"></cover>
                </template>
              </el-table-column>

              <el-table-column label="专辑名">
                <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
              </el-table-column>

              <el-table-column label="章节数">
                <template slot-scope="scope">{{ scope.row.sections.length }}</template>
              </el-table-column>

              <el-table-column label="展示地区">
                <template slot-scope="scope">
                  <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
                  <template v-else>
                    <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="限时体验">
                <template slot-scope="scope">
                  <template v-if="scope.row.trial_started_local_time && scope.row.trial_ended_local_time">
                    <div style="font-size: 8px">开始：{{ scope.row.trial_started_local_time }}</div>
                    <div style="font-size: 8px">结束：{{ scope.row.trial_ended_local_time }}</div>
                  </template>
                  <template v-else>-</template>
                </template>
              </el-table-column>

              <el-table-column label="状态">
                <template slot="header">
                  <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeyword"></el-input>
                </template>
                <template slot-scope="scope">
                  {{ getStatus(scope.row.status) }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>

      <el-dialog title="专辑排序" width="40%" :visible.sync="dialogDisplay.sortContents">
        <draggable class="sort-list-container" v-model="sortList">
          <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="sort-item-name">{{ getTR(item.name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSort">保 存</el-button>
          <el-button @click="dialogDisplay.sortContents = false">返 回</el-button>
        </span>
      </el-dialog>
    </template>
  </group-form>
</template>

<script>
import GroupForm from '@/components/groups/form';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
import allStatus from '@/status.json';

export default {
  name: 'MedGroupsEditor',
  components: {
    GroupForm,
    Draggable,
    Cover,
  },
  props: {
    type: String,
  },
  data() {
    return {
      list: [],
      tags: [],
      sortList: [],
      filterKeyword: '',
      activeTag: 'all',
      object_tags: [],
      dialogDisplay: {
        sortContents: false,
        editContents: false,
      },
      form: {
        id: '',
        scenes: [],
        articles: [],
        meditations: [],
        type: 'meditation',
        is_mini_meditation: false,
        hide_albums_in_meditation_pages: false,
        is_can_share: true,
        status: 'draft',
        style: { preview_count: 0 },

        description: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },

        name: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },

        sub_title: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
      },
    };
  },
  computed: {
    filtedList() {
      let filtedList = this.list;
      const activeTag = this.activeTag;
      const filterKeyword = this.filterKeyword;
      if (activeTag !== 'all') {
        const activeOT = this.object_tags.find((v) => v.id === activeTag);
        if (activeOT) {
          filtedList = filtedList.filter((item) => {
            return activeOT.meditation_ids.find((v) => v === item.id);
          });
        }
      }

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return (item.name['en'] || '').includes(filterKeyword) || (item.name['zh-Hans'] || '').includes(filterKeyword) || (item.name['zh-Hant'] || '').includes(filterKeyword);
        });
      }

      return filtedList;
    },
  },
  watch: {
    'form.meditations'() {
      this.$emit('input', this.form);
    },
    filtedList() {
      this.freshSelected();
    },
    'dialogDisplay.editContents'() {
      this.freshSelected();
    },
  },
  methods: {
    freshSelected() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTable;
        if ($listTables && $listTables.length) {
          const $ref = $listTables[0];
          $ref.clearSelection();
          this.filtedList.forEach((row) => {
            if (this.form.meditations.some((item) => item.id === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    onSelect(selection, row) {
      if (this.form.meditations.some((item) => item.id === row.id)) {
        this.form.meditations = this.form.meditations.filter((item) => item.id !== row.id);
      } else {
        this.form.meditations.unshift(row);
      }
    },
    onSelectAll(selection) {
      if (!selection.length) {
        const list = this.form.meditations.filter((album) => !this.filtedList.some((item) => item.id === album.id));
        this.form.meditations = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.form.meditations.some((item) => item.id === album.id)) {
            list.push(album);
          }
        });
        this.form.meditations = [...this.form.meditations, ...list];
      }
    },
    removeItem(row) {
      this.form.meditations = this.form.meditations.filter((item) => item.id !== row.id);
    },
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async saveSort() {
      this.form.meditations = this.sortList;
      this.save();
    },
    async save() {
      try {
        await this.$request({
          url: `/v2/admin/explorer/groups/${this.$route.params.group_id}`,
          method: 'PUT',
          data: this.form,
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    async init() {
      const [groupInfo, tags, list] = await Promise.all([this.getGroupInfo('meditation'), this.getTags('meditation'), this.getList()]);

      this.form = Object.assign({}, this.form, groupInfo);
      this.form.meditations = this.form.meditations.filter((v) => !!v);
      this.tags = tags;
      this.list = list;
    },
    async getGroupInfo() {
      const res = await this.$request({
        url: `/v2/admin/explorer/groups/${this.$route.params.group_id}`,
      });
      return res.data;
    },
    async getTags(type) {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type },
      });
      this.object_tags = res.data;
      const tags = res.data.map((tag) => {
        return {
          value: tag.id,
          label: this.getTR(tag.name),
        };
      });
      return [{ value: 'all', label: '全部' }, ...tags];
    },
    async getList() {
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: { offset: 0, limit: 255 },
      });
      return res.data;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'StoriesAdder',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        sort_key: 0,
        name: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        description: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        cooperation: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        primary_color: '0,0,0,1',
        image: '',
        video_cover_demo_url: '',
        video_cover_key: '',
        regions: ['ALL'],
        style: { size: 'small' },
        status: 'draft',
        blocked_channels: [],
        access: 'subscribe',
        fresh_until: 0,
        trial_ended_local_time: '',
        trial_started_local_time: '',
        trial_login_status: [],
        resources_v2: [],
        simple_tag_ids: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },

    async save(data) {
      try {
        const res = await this.$request({
          url: '/v1/admin/sleep_stories',
          method: 'POST',
          data,
        });
        this.$router.replace({
          name: 'StoriesEditor',
          params: {
            story_id: res.data.id,
          },
        });
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">用户设置</div>
      </div>
      <div class="form container-body" label-width="120px" style="padding-top: 36px">
        <div class="item" v-if="preference" :key="preference.local_id">
          <el-card>
            <div slot="header" class="clearfix">
              <h3>新手引导设置</h3>
              <div>创建时间: {{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>更新时间: {{ $moment(item.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="setting-blocks">
              <div style="padding: 0 16px; white-space: pre">{{ JSON.stringify(preference, undefined, 2) }}</div>
            </div>
          </el-card>
        </div>
        <div v-if="!list.length">无</div>
        <div class="item" v-else v-for="item in list" :key="item.type">
          <el-card v-if="item.type === 'focus'">
            <div slot="header" class="clearfix">
              <h3>专注设置</h3>
              <div>创建时间: {{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>更新时间: {{ $moment(item.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="setting-blocks">
              <div style="padding: 0 16px; white-space: pre">{{ JSON.stringify(item.data, undefined, 2) }}</div>
            </div>
          </el-card>
          <el-card v-else-if="item.type === 'sleep'">
            <div slot="header" class="clearfix">
              <h3>睡眠设置</h3>
              <div>创建时间: {{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>更新时间: {{ $moment(item.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="setting-blocks">
              <div style="padding: 0 16px; white-space: pre">{{ JSON.stringify(item.data, undefined, 2) }}</div>
            </div>
          </el-card>
          <el-card v-else-if="item.type === 'nap'">
            <div slot="header" class="clearfix">
              <h3>小憩设置</h3>
              <div>创建时间: {{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>更新时间: {{ $moment(item.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="setting-blocks">
              <div style="padding: 0 16px; white-space: pre">{{ JSON.stringify(item.data, undefined, 2) }}</div>
            </div>
          </el-card>
          <el-card v-else-if="item.type === 'breath'">
            <div slot="header" class="clearfix">
              <h3>呼吸设置</h3>
              <div>创建时间: {{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>更新时间: {{ $moment(item.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="setting-blocks">
              <div style="padding: 0 16px; white-space: pre">{{ JSON.stringify(item.data, undefined, 2) }}</div>
            </div>
          </el-card>
          <el-card v-else-if="item.type === 'relax'">
            <div slot="header" class="clearfix">
              <h3>冥想设置</h3>
              <div>创建时间: {{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>更新时间: {{ $moment(item.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="setting-blocks">
              <div style="padding: 0 16px; white-space: pre">{{ JSON.stringify(item.data, undefined, 2) }}</div>
            </div>
          </el-card>
          <el-card v-else-if="item.type === 'general'">
            <div slot="header" class="clearfix">
              <h3>通用设置</h3>
              <div>创建时间: {{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>更新时间: {{ $moment(item.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div class="setting-blocks">
              <div style="padding: 0 16px; white-space: pre">{{ JSON.stringify(item.data, undefined, 2) }}</div>
            </div>
          </el-card>
          <el-card v-else-if="item.type === 'general'">
            <p style="white-space: pre-wrap">{{ JSON.stringify(item, undefined, 2) }}</p>
          </el-card>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'UserSubscribe',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      list: [],
      preference: null,
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    init: async function () {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/hostcontents`,
      });
      this.list = res.data;
      const preferenceRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/preference`,
      });
      this.preference = preferenceRes.data;
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.item + .item {
  margin-top: 16px;
}
.setting-blocks {
  display: flex;
  flex-direction: row;
}

.setting-blocks > div {
  padding: 0 12px;
}
.setting-blocks > div + div {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}
.setting-blocks > div > div + div {
  margin-top: 8px;
}

.setting-blocks {
  label {
    display: inline-block;
    min-width: 64px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>

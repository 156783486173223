<template>
  <div>
    <container custom-style="paddingBottom: 80px">
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="el-icon-service"></i>
          <h3 slot="title">传达室</h3>
          <p slot="desc">潮汐听取领导们批评的地方</p>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-body">
          <div>
            <h3 class="container-head-title">意见详情</h3>
          </div>
          <div class="feedback-preferences">
            提交用户:
            <a target="_blank" :href="`/users/manager/${form.user_id}/edit`">{{ form.user_id }}</a>
          </div>
          <div class="feedback-preferences">
            联系邮箱:
            <mailto :to="form.email" :nickname="form.user_nickname" :user-id="form.user_id" :platform="form.platform" :quote="form.content" :app-version="form.app_version" :system="form.system" :device="form.device" :created-at="form.created_at">{{ form.email }}</mailto>
          </div>

          <div class="feedback-preferences">提交时间: {{ $moment(form.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
          <div class="feedback-preferences">状态: {{ form.status === 1 ? '未处理' : '已处理' }}</div>
          <div class="feedback-content">
            <div>{{ form.content }}</div>
            <template v-if="trText.length">
              <el-divider content-position="left">翻译</el-divider>
              {{ trText }}
            </template>
          </div>
          <div class="feedback-preferences">
            <template v-for="(image, index) in form.attached_images">
              <video v-if="maybeVideoIndexs.includes(index)" class="attached-image" :key="`attached-video-${image}-${index}`" :src="image" @click="preview(index)"></video>
              <img v-else class="attached-image" :key="`attached-image-${image}-${index}`" :src="image" @click="preview(index)" @error="imageOnloadFail(index)" />
            </template>
          </div>

          <el-divider></el-divider>

          <div class="feedback-blocks">
            <div class="feedback-preferences">
              <h3 class="container-head-title">相关信息</h3>
              <div class="setting-blocks">
                <div>
                  <div>
                    <label>Device:</label>
                    <span>{{ form.device }}</span>
                  </div>
                  <div>
                    <label>System:</label>
                    <span>{{ form.system }}</span>
                  </div>
                  <div>
                    <label>Platform:</label>
                    <span>{{ form.platform }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <label>App ID:</label>
                    <span>{{ form.app_id }}</span>
                  </div>
                  <div>
                    <label>App Version:</label>
                    <span>{{ form.app_version }}</span>
                  </div>
                  <div>
                    <label>App Version Number:</label>
                    <span>{{ form.real_app_version }}</span>
                  </div>
                  <div>
                    <label>DeviceID:</label>
                    <span>{{ form.device_id }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="feedback-blocks" v-if="userInfos">
            <div class="feedback-preferences">
              <h3 class="container-head-title">TidePlus</h3>
              <div class="setting-blocks">
                <div>
                  <div>
                    <label>终身会员：</label>
                    <span>{{ userInfos.vip.is_lifetime_member ? '是' : '否' }}</span>
                  </div>
                  <div>
                    <label>到期时间：</label>
                    <span>{{ $moment(userInfos.vip.expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                  </div>
                  <div>
                    <label>变动时间：</label>
                    <span>{{ $moment(userInfos.vip.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                  </div>
                  <div>
                    <label>首次开通：</label>
                    <span>{{ $moment(userInfos.vip.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <label>订阅平台：</label>
                    <span>AppStore</span>
                  </div>
                  <template v-if="userInfos.app_store_info.subscription_type">
                    <div>
                      <label>订阅状态：</label>
                      <span v-if="$moment(userInfos.app_store_info.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                      <span v-else>正常</span>
                    </div>
                    <div>
                      <label>订阅类型：</label>
                      <span v-if="userInfos.app_store_info.subscription_type === 1">包月</span>
                      <span v-else-if="userInfos.app_store_info.subscription_type === 2">包季</span>
                      <span v-else-if="userInfos.app_store_info.subscription_type === 3">包年</span>
                    </div>
                    <div>
                      <label>到期时间：</label>
                      <span>{{ $moment(userInfos.app_store_info.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </div>
                  </template>
                  <div v-else>
                    <label>订阅状态：</label>
                    <span>未曾开通</span>
                  </div>
                </div>

                <div>
                  <div>
                    <label>订阅平台：</label>
                    <span>GooglePlay</span>
                  </div>
                  <template v-if="userInfos.google_play_info.subscription_type">
                    <div>
                      <label>订阅状态：</label>
                      <span v-if="$moment(userInfos.google_play_info.google_play_subscription_expired_at * 1000).isBefore()">过期</span>
                      <span v-else>正常</span>
                    </div>
                    <div>
                      <label>订阅类型：</label>
                      <span v-if="userInfos.google_play_info.subscription_type === 1">包月</span>
                      <span v-else-if="userInfos.google_play_info.subscription_type === 2">包季</span>
                      <span v-else-if="userInfos.google_play_info.subscription_type === 3">包年</span>
                    </div>
                    <div>
                      <label>到期时间：</label>
                      <span>{{ $moment(userInfos.google_play_info.google_play_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </div>
                  </template>

                  <div v-else>
                    <label>订阅状态：</label>
                    <span>未曾开通</span>
                  </div>
                </div>
                <div>
                  <div>
                    <label>订阅平台：</label>
                    <span>华为</span>
                  </div>

                  <div>
                    <div>
                      <label>订阅平台：</label>
                      <span>AppStore</span>
                    </div>
                    <template v-if="userInfos.huawei_info.subscription_type">
                      <div>
                        <label>订阅状态：</label>
                        <span v-if="$moment(userInfos.huawei_info.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                        <span v-else>正常</span>
                      </div>
                      <div>
                        <label>订阅类型：</label>
                        <span v-if="userInfos.huawei_info.subscription_type === 1">包月</span>
                        <span v-else-if="userInfos.huawei_info.subscription_type === 2">包季</span>
                        <span v-else-if="userInfos.huawei_info.subscription_type === 3">包年</span>
                      </div>
                      <div>
                        <label>到期时间：</label>
                        <span>{{ $moment(userInfos.huawei_info.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                      </div>
                    </template>

                    <div v-else>
                      <label>订阅状态：</label>
                      <span>未曾开通</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="feedback-blocks" v-if="userInfos">
            <div class="feedback-preferences">
              <h3 class="container-head-title">声音卡</h3>
              <div class="setting-blocks">
                <div>
                  <div>
                    <label>终身会员：</label>
                    <span>{{ userInfos.scene_pass.is_lifetime_member ? '是' : '否' }}</span>
                  </div>
                  <div>
                    <label>到期时间：</label>
                    <span>{{ $moment(userInfos.scene_pass.expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                  </div>
                  <div>
                    <label>变动时间：</label>
                    <span>{{ $moment(userInfos.scene_pass.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                  </div>
                  <div>
                    <label>首次开通：</label>
                    <span>{{ $moment(userInfos.scene_pass.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <label>订阅平台：</label>
                    <span>AppStore</span>
                  </div>
                  <template v-if="userInfos.app_store_scene_pass_subscription.subscription_type">
                    <div>
                      <label>订阅状态：</label>
                      <span v-if="$moment(userInfos.app_store_scene_pass_subscription.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                      <span v-else>正常</span>
                    </div>
                    <div>
                      <label>订阅类型：</label>
                      <span v-if="userInfos.app_store_scene_pass_subscription.subscription_type === 1">包月</span>
                      <span v-else-if="userInfos.app_store_scene_pass_subscription.subscription_type === 2">包季</span>
                      <span v-else-if="userInfos.app_store_scene_pass_subscription.subscription_type === 3">包年</span>
                    </div>
                    <div>
                      <label>到期时间：</label>
                      <span>{{ $moment(userInfos.app_store_scene_pass_subscription.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </div>
                  </template>
                  <div v-else>
                    <label>订阅状态：</label>
                    <span>未曾开通</span>
                  </div>
                </div>
                <div>
                  <div>
                    <label>订阅平台：</label>
                    <span>GooglePlay</span>
                  </div>
                  <template v-if="userInfos.google_play_scene_pass_subscription.subscription_type">
                    <div>
                      <label>订阅状态：</label>
                      <span v-if="$moment(userInfos.google_play_scene_pass_subscription.google_play_subscription_expired_at * 1000).isBefore()">过期</span>
                      <span v-else>正常</span>
                    </div>
                    <div>
                      <label>订阅类型：</label>
                      <span v-if="userInfos.google_play_scene_pass_subscription.subscription_type === 1">包月</span>
                      <span v-else-if="userInfos.google_play_scene_pass_subscription.subscription_type === 2">包季</span>
                      <span v-else-if="userInfos.google_play_scene_pass_subscription.subscription_type === 3">包年</span>
                    </div>
                    <div>
                      <label>到期时间：</label>
                      <span>{{ $moment(userInfos.google_play_scene_pass_subscription.google_play_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </div>
                  </template>

                  <div v-else>
                    <label>订阅状态：</label>
                    <span>未曾开通</span>
                  </div>
                </div>
                <div>
                  <div>
                    <label>订阅平台：</label>
                    <span>华为</span>
                  </div>
                  <template v-if="userInfos.huawei_scene_pass_subscription.subscription_type">
                    <div>
                      <label>订阅状态：</label>
                      <span v-if="$moment(userInfos.huawei_scene_pass_subscription.auto_subscription_expired_at * 1000).isBefore()">过期</span>
                      <span v-else>正常</span>
                    </div>
                    <div>
                      <label>订阅类型：</label>
                      <span v-if="userInfos.huawei_scene_pass_subscription.subscription_type === 1">包月</span>
                      <span v-else-if="userInfos.huawei_scene_pass_subscription.subscription_type === 2">包季</span>
                      <span v-else-if="userInfos.huawei_scene_pass_subscription.subscription_type === 3">包年</span>
                    </div>
                    <div>
                      <label>到期时间：</label>
                      <span>{{ $moment(userInfos.huawei_scene_pass_subscription.auto_subscription_expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                    </div>
                  </template>

                  <div v-else>
                    <label>订阅状态：</label>
                    <span>未曾开通</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="preferences">
            <div class="feedback-preferences">
              <h3 class="container-head-title">偏好设置</h3>
              <div class="setting-blocks" style="white-space: pre">{{ JSON.stringify(preferences, undefined, 2) }}</div>
            </div>
          </template>

          <template v-if="extraData">
            <div class="feedback-preferences">
              <h3 class="container-head-title">其他信息</h3>
              <div class="setting-blocks" style="white-space: pre">{{ JSON.stringify(extraData, undefined, 2) }}</div>
            </div>
          </template>
        </div>

        <div class="container-footer">
          <el-button size="small" class="act-btn" @click="translation">翻译</el-button>
          <a v-if="form.log_file" class="el-button act-btn el-button--default el-button--small" download="log.txt" :href="form.log_file">下载日志</a>
        </div>
      </div>
    </container>

    <!-- <el-dialog title="谷歌翻译" :visible.sync="dialogsVisible.translation">
      <div style="padding: 0 16px; white-space: pre-line">{{trText}}</div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogsVisible.translation = false">关闭</el-button>
      </div>
    </el-dialog>-->

    <attacheds-previewer :show.sync="dialogsVisible.preview" :index.sync="actived_image" :images="form.attached_images"></attacheds-previewer>
  </div>
</template>

<script>
import Container from '@/components/container';
import PageHeader from '@/pages/service/page-header';
import AttachedsPreviewer from './attacheds-previewer';
import Mailto from '@/components/Mailto';
export default {
  name: 'FeedbackReview',
  components: {
    Container,
    PageHeader,
    AttachedsPreviewer,
    Mailto,
  },
  data() {
    return {
      dialogsVisible: {
        preview: false,
        // translation: false
      },
      maybeVideoIndexs: [],
      actived_image: 0,
      form: {},
      userInfos: null,
      preferences: null,
      extraData: null,
      trText: '',
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    preview(index) {
      this.actived_image = index;
      this.dialogsVisible.preview = true;
    },
    imageOnloadFail(index) {
      this.maybeVideoIndexs.push(index);
    },
    async translation() {
      const { content } = this.form;

      const loading = this.$loading({
        lock: true,
        text: '翻译中',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.24)',
      });
      try {
        const res = await this.$request({
          method: 'POST',
          withCredentials: false,
          auth: undefined,
          url: 'http://sapi.tide.moreless.io/translate',
          data: {
            content,
          },
        });
        let trText = '';
        const rows = res.data[0];
        for (let row of rows) {
          trText += row[0];
        }
        this.trText = trText;
        // this.dialogsVisible.translation = true
      } catch (e) {
        this.$message.error('翻译失败');
      }
      loading.close();
    },
    init: async function () {
      const res = await this.$request({
        url: `/v1/admin/feedbacks/${this.$route.params.feedback_id}`,
      });
      this.form = Object.assign({}, this.form, res.data);
      if (res.data.preferences) {
        this.preferences = JSON.parse(res.data.preferences);
      }
      if (res.data.extra_data) {
        this.extraData = JSON.parse(res.data.extra_data);
      }
      if (res.data.user_id) {
        const userInfosRes = await this.$request({
          url: `/v1/admin/users/${res.data.user_id}/profile`,
        });
        this.userInfos = userInfosRes.data;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-body {
  font-size: 14px;
}
.content {
  margin-top: 24px;
}
.feedback-preferences {
  margin-top: 16px;
}

.attacheds-previewer {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.attacheds-previewer.show {
  display: block;
}

.attached-image {
  cursor: pointer;
  width: 140px;
  height: 140px;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.attached-image + .attached-image {
  margin-left: 16px;
}
.full-attached-image-container {
  text-align: center;
}
.full-attached-image-link {
  display: inline-block;
  height: 74vh;
}
.full-attached-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.preview-container {
  display: flex;
  flex-direction: column;
}

.attached-image-top {
  margin: 3vh 0 3vh;
  height: 74vh;
}

.attached-image-thumbs {
  height: 16vh;
}

.thumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dialog-footer {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}

.el-icon-close {
  display: block;
  cursor: pointer;
  position: absolute;
  z-index: 11;
  right: 24px;
  top: 24px;
  width: 36px;
  height: 36px;
  font-size: 36px;
  color: #fff;
}

.setting-blocks {
  display: flex;
  flex-direction: row;
  label {
    display: inline-block;
    min-width: 48px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    font-weight: bold;
  }
}

.setting-blocks > div + div {
  padding-left: 12px;
  margin-left: 12px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}
.setting-blocks > div > div + div {
  margin-top: 16px;
}
.feedback-blocks {
  display: flex;
  flex-direction: rows;
}
.feedback-blocks > div {
  padding-right: 16px;
}
.feedback-blocks > div + div {
  padding-left: 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}
.feedback-content {
  margin-top: 16px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.08);
}
.expired {
  color: red;
}
</style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';

export default {
  name: 'EditMemberRole',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      role: null,
      form: {
        id: '',
        nickname: '',
        phone_number: '',
        old_role: 0,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },

  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      await this.$request({
        url: `/v1/admin/admins/${this.$route.params.user_id}`,
        method: 'PUT',
        data,
      });
      await this.init();
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
    async init() {
      try {
        const resp = await this.$request({
          url: `/v1/admin/admins/${this.$route.params.user_id}`,
        });
        this.form = Object.assign({}, this.form, resp.data);
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'EditMixedScene',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        status: 'reviewing',
        name: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        tags: [],
        fresh_until: 0,
        trial_ended_local_time: '',
        trial_started_local_time: '',
        demo_sound_url: '',
        image: '',
        category: 'mixed',
        balance_type: '',
        sub_title: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        primary_color: '0,0,0,1',
        secondary_color: '0,0,0,1',
        cover_data: {
          cover_type: '',
          cover_id: '',
        },
        creator: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        track_infos: [],
        simple_tag_ids: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      const { scene_id: sceneId } = this.$route.params;
      console.log(data);
      await this.$request({
        url: `/v1/admin/market/scenes/${sceneId}`,
        method: 'PUT',
        data,
      });
      await this.init();
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },

    init: async function () {
      const { scene_id: sceneId } = this.$route.params;
      try {
        const res = await this.$request({
          url: `/v1/market/scenes/${sceneId}`,
        });
        this.form = Object.assign({}, this.form, res.data);
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

import Layout from '@/components/layout';

import TransactionsHistory from '@/pages/transactions/history';
import ChargeRecordHistory from '@/pages/transactions/charge_records';
import NotificationsHistory from '@/pages/transactions/notifications';

export default [
  {
    path: '/transactions',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '支付',
    },
    children: [
      {
        path: 'history',
        name: 'TransactionsHistory',
        components: {
          default: TransactionsHistory,
        },
        meta: {
          requireAuth: true,
          label: '交易记录管理',
          newDesign: true,
        },
      },
      {
        path: 'charge_records',
        name: 'ChargeRecordHistory',
        components: {
          default: ChargeRecordHistory,
        },
        meta: {
          requireAuth: true,
          label: '支付记录管理',
          newDesign: true,
        },
      },
      {
        path: 'notifications',
        name: 'NotificationsHistory',
        components: {
          default: NotificationsHistory,
        },
        meta: {
          requireAuth: true,
          label: '通知记录管理',
          newDesign: true,
        },
      },
    ],
  },
];

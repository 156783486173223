<template>
  <div class="preview-wrapper" :style="`zoom:${scale}`" @click="$emit('click')">
    <div
      class="preview"
      :style="{
        zoom: `${1 / scale}`,
        transform: `scale(${scale})`,
        fontFamily: `${quote.fontName}`,
      }"
    >
      <div class="canva">
        <div
          class="dailypic-preview preview-image"
          ref="previewImage"
          :style="{
            backgroundImage: `url(${previewUrl})`,
            backgroundSize: `${cropXScale} ${cropYScale}`,
            backgroundPositionX: `${cropdx}`,
            backgroundPositionY: `${cropdy}`,
          }"
        ></div>
        <div class="page-cover"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TideV2Preview',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    crop: {
      type: Object,
    },
    origin: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: 'zh-Hans',
    },
    imageInfo: {
      type: Object,
    },
  },
  data() {
    return {
      year: '',
      month: '',
      day: '',
      cropScale: {
        x: 'atuo',
        y: 'atuo',
      },
      imgScale: 1,
      screenWidth: 0,
      screenHeight: 0,
    };
  },
  methods: {
    // 正方图
    squareDx() {
      let offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    squareDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    squareXScale() {
      let scale = 'auto';
      let diff = 0.96;
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width;
        width = width * diff;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    squareYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        height = height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },

    // 竖图
    verticalDx() {
      let offset = 0.02;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    verticalDy() {
      let offset = 0.04;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      // const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale
      dy = dy / (this.crop.height / this.screenHeight);
      return `${-dy}px`;
    },
    verticalXScale() {
      let scale = 'auto';
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width * 0.96;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    verticalYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        const imgHeight = this.crop.imgHeight;
        height = height * 0.96;
        scale = `${100 / (height / imgHeight)}%`;
      }
      return scale;
    },

    // 横图
    landscapeDx() {
      const offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    landscapeDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    landscapeXScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (imageInfo.width <= imageInfo.height) {
        let width = this.crop.width;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    landscapeYScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (!imageInfo.width < imageInfo.height) {
        let height = this.crop.height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },
  },
  computed: {
    cropdx() {
      let dx;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dx = this.verticalDx();
      } else if (width > height) {
        dx = this.landscapeDx();
      } else {
        dx = this.squareDx();
      }
      return dx;
    },
    cropdy() {
      let dy;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dy = this.verticalDy();
      } else if (width > height) {
        dy = this.landscapeDy();
      } else {
        dy = this.squareDy();
      }
      return dy;
    },
    cropXScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalXScale();
      } else if (width > height) {
        scale = this.landscapeXScale();
      } else {
        scale = this.squareXScale();
      }
      return scale;
    },
    cropYScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalYScale();
      } else if (width > height) {
        scale = this.landscapeYScale();
      } else {
        scale = this.squareYScale();
      }
      return scale;
    },
    previewUrl() {
      let picUrl = this.data.pic_url;
      if (!this.origin) {
        picUrl = `${picUrl}?imageMogr2/thumbnail/!1024x1024r`;
        // picUrl = `${picUrl}/crop/${this.cropPath}`
      }
      return picUrl;
    },
    cropPath() {
      const scale = this.imgScale;
      const cropsize = `${this.crop.width / scale}x${this.crop.height / scale}`;
      const dx = this.crop.dx / scale;
      const dy = this.crop.dy / scale;
      return `!${cropsize}a${dx}a${dy}`;
    },
    quote() {
      return this.data.content[this.lang].quote;
    },
    author() {
      return this.data.content[this.lang].author;
    },
  },
  created() {
    console.log(new Date(this.data.date).toDateString());
    const dateString = new Date(this.data.date).toDateString();
    [, this.month, this.date, this.year] = dateString.split(' ');
    this.$nextTick(() => {
      const imageInfo = this.imageInfo;
      const $previewImage = this.$refs.previewImage;
      if (!$previewImage) {
        return 1;
      }
      const width = this.$refs.previewImage.clientWidth;
      const height = this.$refs.previewImage.clientHeight;
      // const crop = this.crop
      let scale;
      if (imageInfo.width < imageInfo.height) {
        scale = 210 / width;
      } else {
        scale = 210 / height;
      }
      this.imgScale = scale;
      this.screenWidth = width;
      this.screenHeight = height;
    });
  },
};
</script>
<style lang="less">
.cropper-face {
  background-size: cover !important;
  background: url('~@/assets/mask.png') !important;
}
</style>
<style lang="less" scoped>
.preview-wrapper {
  margin: 0 auto;
  width: 750px;
  height: 1334px;
}
.preview {
  transform-origin: 0 0;
  width: 750px;
  height: 1334px;
}
.preview-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.dailypic-preview {
  width: 750px;
  height: 1334px;
}
.canva {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.page-cover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('~@/assets/tidev2index.png');
}
</style>

<script>
export default {
  name: 'ParticipantsSelecter',
  props: {
    value: [String, Array], // 可以是单选的字符串或多选的数组
    multipleLimit: Number, // 多选限制
  },

  data() {
    return {
      participants: [],
      modelValue: [],
    };
  },

  computed: {
    // 根据 value 的类型动态计算是否启用多选模式
    isMultiple() {
      return Array.isArray(this.value);
    },
  },
  watch: {
    // 监听父组件传递的 value 变化，并根据 isMultiple 同步到内部 modelValue
    value(val) {
      console.log('value', val);
      console.log('this.isMultiple', this.isMultiple);
      this.modelValue = this.isMultiple ? val || [] : val;
    },
    // 监听内部 modelValue 的变化，并通过 input 事件反馈给父组件
    modelValue(val) {
      console.log('modelValue', val);
      console.log('this.isMultiple', this.isMultiple);
      this.$emit('input', this.isMultiple ? val || '' : val);
    },
  },

  created() {
    this.init();
  },
  methods: {
    async init() {
      this.participants = await this.getParticipants();
    },

    async getParticipants() {
      const participantsRes = await this.$request({
        url: '/v1/admin/participants',
      });

      return participantsRes.data.sort((a, b) => {
        return a.sort_key - b.sort_key;
      });
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
};
</script>

<template>
  <el-select v-model="modelValue" :multiple="isMultiple" :multiple-limit="multipleLimit" clearable filterable placeholder="请选择朗诵者">
    <el-option v-for="item in participants" :key="item.id" :label="getTR(item.name_i18n) + '|' + item.language" :value="item.id"></el-option>
  </el-select>
</template>

<style lang="less" scoped></style>

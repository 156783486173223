<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">场景配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.name[lang] && xForm.sub_title[lang] && xForm.description_author[lang] && xForm.description[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="7">
                <el-form-item label="场景名">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="15" :offset="1">
                <el-form-item label="一句话描述">
                  <el-input v-model="xForm.sub_title[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述作者">
                  <el-input v-model="xForm.description_author[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" :rows="3" v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp()">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="快速填充">
          <el-input type="textarea" :rows="2" v-model="shortcut"></el-input>
        </el-form-item>

        <el-form-item label="主色调：">
          <color-picker v-model="xForm.primary_color" style="width: 50%"></color-picker>
        </el-form-item>

        <el-form-item label="封面">
          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">如果存在视频封面，那么该自动截取首帧生成</div>
              <span>封面</span>
            </el-tooltip>
            <image-uploader v-model="xForm.image" :resourceFolder="this.resourceFolder"></image-uploader>
          </el-col>

          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">上传后会自动裁切出首帧和 Demo 视频</div>
              <span>视频</span>
            </el-tooltip>
            <encrypt-video-uploader :hash-key="xForm.video_cover_key" :resourceFolder="this.resourceFolder" :persistentOps="scenesCoverVideoUploadPersistentOps()" @change="scenesCoverVideoHashChanged"></encrypt-video-uploader>
          </el-col>

          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">视频自动前5s</div>
              <span>DEMO 视频(自动生成)</span>
            </el-tooltip>

            <video-uploader v-model="xForm.video_cover_demo_url" :resourceFolder="this.resourceFolder"></video-uploader>
          </el-col>
        </el-form-item>

        <el-form-item label="封面取中心位(暂不生效)">
          <el-col :span="10">
            <el-button type="text" @click="dialogDisplay = true">查看</el-button>
            <span>当前中心位：{{ xForm.video_cover_center }}</span>
          </el-col>
        </el-form-item>

        <el-form-item label="其他规格视频">
          <el-col :span="18">
            <span>这些规格视频只能手动处理(主要给对外商务使用)</span>
            <el-collapse value="activeNames" v-if="xForm.extra_cover_video_map">
              <el-collapse-item v-for="(video, key) in xForm.extra_cover_video_map" :key="key" :title="key">
                <p>色调 HEX: {{ video.primary_color }}</p>
                <el-col :span="6">
                  <span>封面</span>
                  <image-uploader v-model="video.image" onlyShow="true"></image-uploader>
                </el-col>

                <el-col :span="6">
                  <span>视频</span>
                  <encrypt-video-uploader :hash-key="video.hash_key" onlyShow="true"></encrypt-video-uploader>
                </el-col>

                <el-col :span="6">
                  <span>DEMO 视频</span>
                  <video-uploader v-model="video.demo_url" onlyShow="true"></video-uploader>
                </el-col>
              </el-collapse-item>
            </el-collapse>
            <p v-else>暂无其他规格视频</p>
          </el-col>
        </el-form-item>

        <el-form-item label="GUID">
          <el-col :span="9">
            <el-input v-model="xForm.resource.guid" disabled />
          </el-col>
        </el-form-item>

        <el-form-item label="声音场景">
          <el-col :span="4">
            <span>立体声</span>
            <encrypt-audio-uploader :hash-key="xForm.resource.hash_key" :resourceFolder="this.resourceFolder" @change="scenesHashChanged"></encrypt-audio-uploader>
          </el-col>

          <el-col :span="4">
            <span>多声道</span>
            <encrypt-audio-uploader :hash-key="xForm.resource.multichannel_hash_key" :resourceFolder="this.resourceFolder" @change="scenesMultichannelHashChanged"></encrypt-audio-uploader>
          </el-col>

          <el-col :span="4">
            <span>杜比</span>
            <encrypt-audio-uploader :hash-key="xForm.resource.dolby_hash_key" :resourceFolder="this.resourceFolder" @change="scenesDolbyHashChanged"></encrypt-audio-uploader>
          </el-col>

          <el-col :span="4">
            <span>试听音频</span>
            <audio-uploader v-model="xForm.resource.demo_sound_url" :resourceFolder="this.resourceFolder"></audio-uploader>
          </el-col>
        </el-form-item>

        <el-form-item label="最大音量百分比(0-100)：">
          <el-input-number v-model="xForm.resource.max_volume_per"></el-input-number>
        </el-form-item>

        <el-form-item label="作为背景声平衡类型：">
          <el-radio-group v-model="xForm.balance_type">
            <el-radio label="white_noise">白噪音</el-radio>
            <el-radio label="melody">旋律</el-radio>
            <el-radio label="meditation">冥想声音</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="上架区域：">
          <regions-selecter v-model="xForm.regions" style="width: 40%"></regions-selecter>
        </el-form-item>

        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages" style="width: 40%"></languages-selecter>
        </el-form-item>

        <el-form-item label="上架渠道：">
          <channels-selector v-model="xForm.channels" style="width: 40%"></channels-selector>
        </el-form-item>

        <el-form-item label="禁止上架渠道：">
          <channels-selector v-model="xForm.blocked_channels" style="width: 40%"></channels-selector>
        </el-form-item>

        <el-form-item label="加入标签[手动]">
          <el-select v-model="xForm.manual_simple_tag_ids" value-key="id" multiple filterable default-first-option placeholder="选择标签" style="width: 100%">
            <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="加入标签[自动]">
          <el-select v-model="xForm.simple_tag_ids" value-key="id" disabled multiple filterable default-first-option placeholder="选择标签" style="width: 100%">
            <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="搜索关键字">
          <el-select v-model="xForm.search_annotations" value-key="id" multiple filterable default-first-option placeholder="选择关键字" style="width: 100%">
            <el-option v-for="item in annotationOptions" :key="item.value.id" :label="item.value.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <status-radio v-model="xForm.status" style="width: 80%"></status-radio>
        </el-form-item>

        <el-form-item label="新品角标持续时间">
          <el-radio-group v-model="freshUntilLock">
            <el-radio :label="true">不显示</el-radio>
            <el-radio :label="false">
              显示到
              <el-date-picker :disabled="freshUntilLock" size="mini" type="datetime" v-model="freshUntil" placeholder="日期" default-time="23:59:59"></el-date-picker>
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="限时体验选项">
          <el-radio-group v-model="triallLock">
            <el-radio :label="true">不限时</el-radio>
            <el-radio :label="false">限时</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="限时体验时间">
          <el-date-picker :disabled="triallLock" size="medium" type="datetime" v-model="trialStartedAt" placeholder="开始时间" default-time="23:59:59"></el-date-picker>
          <span> 到 </span>
          <el-date-picker :disabled="triallLock" size="medium" type="datetime" v-model="trialEndedAt" placeholder="结束时间" default-time="23:59:59"></el-date-picker>
        </el-form-item>

        <el-form-item label="限时体验登录状态">
          <login-status-selecter v-model="xForm.trial_login_status" style="width: 40%"></login-status-selecter>
        </el-form-item>

        <el-col>
          <el-form-item label="资源上次更新时间">
            <el-col :span="7">
              <el-input :disabled="true" size="medium" v-model="resource_updated_at"></el-input>
            </el-col>
          </el-form-item>
        </el-col>

        <el-col>
          <hr />
        </el-col>

        <el-col>
          <el-form-item label="付费方式">
            <el-select v-model="payment" placeholder="请选择">
              <el-option label="免费" value="free"></el-option>
              <el-option label="订阅" value="subscribe"></el-option>
              <!-- 现在没有内购声音，所以先隐藏 -->
              <!-- <el-option label="仅内购" value="purchase"></el-option> -->
              <!-- <el-option label="不提供" value="soldout"></el-option> -->
            </el-select>
            <el-select v-if="payment === 'subscribe'" v-model="subsAndPurchase" placeholder="请选择" style="margin-left: 8px">
              <el-option label="不提供内购" :value="false"></el-option>
              <!-- 现在没有内购声音，所以先隐藏 -->
              <!-- <el-option label="提供内购" :value="true"></el-option> -->
            </el-select>

            <el-card class="price-card" v-if="payment === 'purchase' || subsAndPurchase">
              <template slot="header">内购配置</template>
              <el-col>
                <el-col :span="7">
                  <el-form-item label="商品ID">
                    <el-input v-model="xForm.purchase_info.product_id"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-form-item label="定价">
                    <el-select v-model="xForm.purchase_info.price" value-key="cny" placeholder="请选择">
                      <el-option v-for="item in priceOptions" :key="item.label" :label="item.label" :value="item.value.cny"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7" :offset="1">
                  <el-form-item label="商品描述">
                    <el-input v-model="xForm.purchase_info.description"></el-input>
                  </el-form-item>
                </el-col>
              </el-col>
              <el-col>
                <el-form-item label="关联商品ID列表">
                  <el-input-tag v-model="xForm.purchase_info.alias"></el-input-tag>
                </el-form-item>
              </el-col>
            </el-card>
          </el-form-item>
        </el-col>
      </el-form>

      <el-dialog title="查看图片(看不到图片就保存后刷新)" :visible.sync="dialogDisplay">
        <el-row>
          <el-col :span="10">
            <div class="cropper-wrapper">
              <div class="cropper-wrapper-2">
                <img class="cropper-padding" :src="`${xForm.image}?imageMogr2/thumbnail/!210x210r`" />
                <vueCropper class="cropper" ref="cropper" :img="`${xForm.image}?imageMogr2/thumbnail/!210x210r`" :auto-crop="true" :fixed="true" :output-size="1" :can-scale="false" :info="false" :canMove="false" output-type="jpeg" @realTime="realTimeData"></vueCropper>
              </div>
            </div>
          </el-col>
          <el-col :offset="4" :span="5">
            <div class="image-size-infos">
              <!-- <div>x: {{ this.crop.dx }}, y: {{ this.crop.dy }}</div> -->
              <!-- <div>center_x: {{ this.crop.center_x }}, center_y: {{ this.crop.center_y }}</div> -->
              <div>中心位置: {{ this.crop.center_x_percent }}%, {{ this.crop.center_y_percent }}%</div>
              <!-- 需要一个确认按钮 -->
              <el-button type="primary" @click="confirmCrop">确认</el-button>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Constant from '@/constant.json';
import priceOptions from '@/store/prices.json';
import Container from '@/components/container';
import ColorPicker from '@/components/color-picker';
import AudioUploader from '@/components/audio-uploader';
import EncryptAudioUploader from '@/components/encrypt-audio-uploader';
import OpenCC from '@/plugins/opencc';
import ElInputTag from '@/components/el-input-tag';
import RegionsSelecter from '@/components/regions-selecter';
import ChannelsSelector from '@/components/channels-selector';
import LanguagesSelecter from '@/components/languages-selecter';
import ImageUploader from '@/components/image-uploader';
import VideoUploader from '@/components/video-uploader';
import EncryptVideoUploader from '@/components/encrypt-video-uploader';
import LoginStatusSelecter from '@/components/login-status-selecter';
import StatusRadio from '@/components/status-radio';
import { qiniuUrlsafeBase64Encode } from '@/utils.js';
import { VueCropper } from 'vue-cropper';

function getPostion(txt) {
  const p = /translate3d\(([-\d.]+)px,\s*([-\d.]+)px,\s*([-\d.]+)px\)/i;
  const m = p.exec(txt);
  if (m !== null) {
    return {
      x: -m[1] + 0,
      y: -m[2] + 0,
    };
  } else {
    return { x: 0, y: 0 };
  }
}

function getCropSize(txt) {
  const p = /([\s\S*?]+)px/;
  const size = p.exec(txt)[1];
  return size;
}

export default {
  name: 'LessonForm',
  components: {
    Container,
    ColorPicker,
    AudioUploader,
    ElInputTag,
    RegionsSelecter,
    ChannelsSelector,
    LanguagesSelecter,
    ImageUploader,
    EncryptAudioUploader,
    VideoUploader,
    EncryptVideoUploader,
    LoginStatusSelecter,
    StatusRadio,
    VueCropper,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = localStorage.getItem('env');
    return {
      loading: false,
      env,

      priceOptions,
      langs: require('@/langs.json'),

      objectTagOptions: [],
      simpleTagOptions: [],
      annotationOptions: [],
      shortcut: '',

      dialogDisplay: {},
      resourceFolder: 'scenes',

      crop: {
        type: Object,
      },
      dialogDisplay: false,
    };
  },
  computed: {
    resource_updated_at() {
      return this.$moment(this.xForm.resource.updated_at / 10000).format('YYYY-MM-DD[T]HH:mm:ss');
    },
    xForm: {
      get() {
        console.log(this.form);
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    freshUntilLock: {
      get() {
        return this.xForm.fresh_until == 0;
      },
      set(val) {
        if (!val) {
          const today = this.$moment();
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.freshUntil = +today;
        } else {
          this.freshUntil = 0;
        }
      },
    },
    freshUntil: {
      get() {
        return this.xForm.fresh_until * 1000;
      },
      set(val) {
        this.xForm.fresh_until = parseInt(new Date(val) / 1000);
      },
    },
    triallLock: {
      get() {
        return !(this.xForm.trial_started_local_time.length || this.xForm.trial_ended_local_time.length);
      },
      set(val) {
        if (!val) {
          const today = this.$moment();
          today.set('hour', 0);
          today.set('minute', 0);
          today.set('second', 0);
          this.xForm.trial_started_local_time = today.format('YYYY-MM-DD[T]HH:mm:ss');
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.xForm.trial_ended_local_time = today.format('YYYY-MM-DD[T]HH:mm:ss');
        } else {
          this.xForm.trial_started_local_time = '';
          this.xForm.trial_ended_local_time = '';
        }
      },
    },
    trialStartedAt: {
      get() {
        return +this.$moment(this.xForm.trial_started_local_time);
      },
      set(val) {
        this.xForm.trial_started_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },
    trialEndedAt: {
      get() {
        return +this.$moment(this.xForm.trial_ended_local_time);
      },
      set(val) {
        this.xForm.trial_ended_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },

    payment: {
      get() {
        if (this.xForm.access[0] === 'free') {
          return 'free';
        } else if (~this.xForm.access.indexOf('subscribe')) {
          return 'subscribe';
        } else if (this.xForm.access[0] === 'purchase') {
          return 'purchase';
        }
        return 'soldout';
      },
      set(payment) {
        if (payment === 'soldout') {
          this.xForm.access = [];
        } else if (payment === 'free') {
          this.xForm.access = ['free'];
        } else if (payment === 'subscribe') {
          this.xForm.access = ['subscribe'];
        } else if (payment === 'purchase') {
          this.xForm.access = ['purchase'];
        }
      },
    },
    subsAndPurchase: {
      get() {
        return Boolean(~this.xForm.access.indexOf('subscribe') && ~this.xForm.access.indexOf('purchase'));
      },
      set(bool) {
        if (bool) {
          this.xForm.access = ['subscribe', 'purchase'];
        } else {
          this.xForm.access = ['subscribe'];
        }
      },
    },
  },
  watch: {
    shortcut(val) {
      const i18nInfos = val.split('\n');
      const fields = ['name', 'sub_title', 'description', 'description_author'];
      if (!i18nInfos.length) {
        this.$message.error('解析失败，无法填充');
      } else {
        i18nInfos.forEach((item, index) => {
          const langInfos = item.split('	');
          if (langInfos.length === 6) {
            this.xForm[fields[index]]['zh-Hans'] = langInfos[0] || langInfos[1];
            this.xForm[fields[index]]['en'] = langInfos[1];
            this.xForm[fields[index]]['ja'] = langInfos[2] || langInfos[1];
            this.xForm[fields[index]]['es'] = langInfos[3] || langInfos[1];
            this.xForm[fields[index]]['ru'] = langInfos[4] || langInfos[1];
            this.xForm[fields[index]]['ko'] = langInfos[5] || langInfos[1];
          }
        });
        this.$nextTick(() => {
          this.s2tw();
        });
      }
    },
    'xForm.name': {
      deep: true,
      handler(val) {
        this.xForm.purchase_info.description = `潮汐-${this.xForm.name['zh-Hans']}场景`;
      },
    },
    'xForm.primary_color': {
      deep: true,
      handler(val) {
        this.xForm.video_cover_primary_color = val;
      },
    },
  },
  methods: {
    realTimeData(data) {
      console.log('实时裁剪框数据:', data);

      const position = getPostion(data.img.transform);
      console.log('裁剪框位置:', position);
      this.crop = {
        width: getCropSize(data.div.width),
        height: getCropSize(data.div.height),
        imgWidth: getCropSize(data.img.width),
        imgHeight: getCropSize(data.img.height),
        dx: position.x,
        dy: position.y,
        center_x: position.x + getCropSize(data.div.width) / 2,
        center_y: position.y + getCropSize(data.div.height) / 2,
        center_x_percent: parseInt(((position.x + getCropSize(data.div.width) / 2) / getCropSize(data.img.width)) * 100),
        center_y_percent: parseInt(((position.y + getCropSize(data.div.height) / 2) / getCropSize(data.img.height)) * 100),
      };
      console.log('裁剪框数据:', this.crop);
    },
    confirmCrop() {
      this.xForm.video_cover_center = `${this.crop.center_x_percent},${this.crop.center_y_percent}`;
      this.dialogDisplay = false;
    },
    async save() {
      this.$emit('save', this.form);
    },
    async fetchTags() {
      this.objectTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: 'scene' },
      });
      this.objectTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchSimpleTags() {
      this.simpleTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/simple_tags',
        params: { offset: 0, limit: 512 },
      });
      this.simpleTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchAnnotations() {
      this.annotationOptions = [];
      const res = await this.$request({
        url: '/v2/admin/search_annotations',
      });
      this.annotationOptions = res.data.map((ann) => {
        return {
          value: ann,
          label: ann.name,
        };
      });
    },
    async s2tw() {
      try {
        this.form.name['zh-Hant'] = this.opencc_tw(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_tw(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_tw(this.form.description['zh-Hans']);
        this.form.description_author['zh-Hant'] = this.opencc_tw(this.form.description_author['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    async s2twp() {
      try {
        this.form.name['zh-Hant'] = this.opencc_twp(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_twp(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_twp(this.form.description['zh-Hans']);
        this.form.description_author['zh-Hant'] = this.opencc_twp(this.form.description_author['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    setProductName() {
      const name = this.xForm.name['zh-Hans'];
      this.xForm.purchase_info.description = `潮汐-${name}场景`;
    },
    scenesCoverVideoHashChanged(data) {
      const { hash, key } = data;
      if (hash === '' || key === '') {
        this.xForm.video_cover_key = '';
        this.xForm.video_cover_demo_url = '';
        this.xForm.image = '';
        return;
      }
      // 加密视频
      this.xForm.video_cover_key = key;
      // demo 视频和视频封面
      this.xForm.video_cover_demo_url = `${Constant.QINIU_OPEN_CDN_DOMAIN}/${this.resourceFolder}/${hash}/demo`;
      this.xForm.image = `${Constant.QINIU_OPEN_CDN_DOMAIN}/${this.resourceFolder}/${hash}/shots`;
    },
    scenesCoverVideoUploadPersistentOps() {
      let ops_list = [];
      // 前 5 秒
      let fops = 'avthumb/mp4/ss/0.0/t/5.0';
      let saveas_key = qiniuUrlsafeBase64Encode(`${Constant.QINIU_OPEN_BUCKET}:${this.resourceFolder}/$(etag)/demo`);
      fops = fops + '|saveas/' + saveas_key;
      ops_list.push(fops);

      // 获取视频首帧
      fops = 'vframe/png/offset/0';
      saveas_key = qiniuUrlsafeBase64Encode(`${Constant.QINIU_OPEN_BUCKET}:${this.resourceFolder}/$(etag)/shots`);
      fops = fops + '|saveas/' + saveas_key;
      ops_list.push(fops);

      let result = ops_list.join(';');
      console.log(result);
      return result;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    scenesHashChanged(data) {
      const { hash, key } = data;
      this.xForm.resource.hash = hash;
      this.xForm.resource.hash_key = key;
    },
    scenesMultichannelHashChanged(data) {
      const { hash, key } = data;
      this.xForm.resource.multichannel_hash = hash;
      this.xForm.resource.multichannel_hash_key = key;
    },
    scenesDolbyHashChanged(data) {
      const { hash, key } = data;
      this.xForm.resource.dolby_hash = hash;
      this.xForm.resource.dolby_hash_key = key;
    },
  },
  created: async function () {
    this.keyword = this.form.author.name;
    this.fetchTags();
    this.fetchSimpleTags();
    this.fetchAnnotations();
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
    this.opencc_tw = await OpenCC.Converter('cn', 'tw');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.soundIcon img {
  background-color: rgba(0, 0, 0, 0.16);
}

.uploadIcon {
  display: inline-block;
}

.price-card {
  margin-top: 24px;
  padding: 0 24px 24px;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.diff {
  color: rgba(230, 162, 60, 1) !important;
  background: rgba(230, 162, 60, 0.12);
}

.scene-tag {
  margin-right: 16px;
  cursor: default;
}

.smail_tip {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
}

.cropper-wrapper {
  display: flex;
  margin: 0 auto;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
.cropper-wrapper-2 {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.cropper-padding {
  display: block;
  visibility: hidden;
}
.vue-cropper.cropper {
  position: absolute;
  left: 0;
  top: 0;
}
.image-size-infos {
}
</style>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">Session</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" size="small" type="danger" @click="clearAllSession">废弃所有Session</el-button>
        </div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column label="过期时间">
            <template slot-scope="scope">
              <span v-if="!scope.row.expired_at">已过期</span>
              <span v-else>{{ $moment(scope.row.expired_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column label="IP">
            <template slot-scope="scope">
              <a target="_blank" :href="`https://realip.cc/?ip=${scope.row.ip}`">{{ scope.row.ip }}</a>
            </template>
          </el-table-column>

          <el-table-column prop="device_product_id" label="设备"></el-table-column>

          <el-table-column prop="system_version" label="系统版本"></el-table-column>

          <el-table-column prop="app_version" label="APP版本"></el-table-column>

          <el-table-column prop="network_status" label="网络环境"></el-table-column>

          <el-table-column prop="system_language" label="语言（地区）"></el-table-column>

          <el-table-column prop="system_region" label="地区"></el-table-column>

          <el-table-column width="120" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
              <el-button type="text" @click="del(scope.row.session_id)" :disabled="!scope.row.expired_at">废弃</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'EditEquip',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      busy: false,
      finish: false,
      JSONVisible: false,
      list: [],
      detail: '',
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    clearAllSession() {
      const that = this;
      this.$confirm('废弃该用户所有session后，该用户所有设备将被踢下线，确定要继续吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async function() {
        await that.$request({
          method: 'DELETE',
          url: `/v1/admin/users/${that.$route.params.user_id}/sessions`,
        });
        that.list.forEach(item => {
          item.expired_at = 0;
        });
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    del(sessionId) {
      const that = this;
      this.$confirm('废弃该session后，使用该session的设备将被踢下线，确定要继续吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async function() {
        await that.$request({
          method: 'DELETE',
          url: `/v1/admin/users/${that.$route.params.user_id}/sessions/${sessionId}`,
        });
        const session = that.list.find(item => {
          return item.session_id === sessionId;
        });
        session.expired_at = 0;
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function() {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/sessions`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    init: async function() {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>

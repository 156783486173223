<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">技巧配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.name[lang] && xForm.description[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="23">
                <el-form-item label="名称">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" :rows="4" v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="Benefits">
                  <el-input type="textarea" :rows="4" v-model="xForm.benefits[lang]"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="23" v-if="lang === 'zh-Hant'">
                <el-form-item>
                  <el-button type="text" @click="s2t4">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-row>
          <el-col :span="10">
            <el-form-item label="封面主色调">
              <color-picker v-model="xForm.primary_color"></color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="封面主色调（暗色）">
              <color-picker v-model="xForm.dark_primary_color"></color-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="上架语言：">
            <languages-selecter v-model="xForm.languages"></languages-selecter>
          </el-form-item>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>封面</span>
                <i class="el-icon-edit" @click="dialogDisplay.imageEdit = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="xForm.image" @click="dialogDisplay.imagePreview = true">
                  <!-- <img class="preview-img" :src="xForm.image"> -->
                  <div class="preview-img">
                    <cover :width="100" :height="100" :src="xForm.image" :mask-colors="xForm.mask_colors"></cover>
                  </div>
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadImageProgress" :on-error="uploadImageError" :on-success="uploadImageSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传专辑封面</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>封面（暗色）</span>
                <i class="el-icon-edit" @click="dialogDisplay.darkimageEdit = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="xForm.dark_image" @click="dialogDisplay.darkimagePreview = true">
                  <!-- <img class="preview-img" :src="xForm.image"> -->
                  <div class="preview-img">
                    <cover :width="100" :height="100" :src="xForm.dark_image" :mask-colors="xForm.mask_colors"></cover>
                  </div>
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadDarkImageProgress" :on-error="uploadDarkImageError" :on-success="uploadDarkImageSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传专辑封面</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="container-head">
          <div class="container-head-title">关联专辑列表</div>
          <div class="container-head-extra">
            <el-button
              :disabled="!$store.getters.hasEditRole"
              type="text"
              size="mini"
              @click="
                sortList = meditations;
                dialogDisplay.sortContents = true;
              "
              >排序</el-button
            >
            <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContents = true">编辑专辑列表</el-button>
          </div>
        </div>
        <div class="form container-body">
          <el-table class="data-table" :data="meditations">
            <el-table-column label="排序" width="55px" align="left">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>

            <el-table-column label="图片">
              <template slot-scope="scope">
                <cover :width="48" :height="48" :src="scope.row.image"></cover>
              </template>
            </el-table-column>

            <el-table-column label="专辑名">
              <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
            </el-table-column>

            <el-table-column label="章节数">
              <template slot-scope="scope">{{ scope.row.sections.length }}</template>
            </el-table-column>

            <el-table-column label="展示地区">
              <template slot-scope="scope">
                <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
                <template v-else>
                  <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
                </template>
              </template>
            </el-table-column>

            <el-table-column label="状态">
              <template slot-scope="scope">
                {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
              </template>
            </el-table-column>

            <el-table-column label="限时体验">
              <template slot-scope="scope">
                <template v-if="scope.row.trial_started_local_time && scope.row.trial_ended_local_time">
                  <div style="font-size: 8px">开始：{{ scope.row.trial_started_local_time }}</div>
                  <div style="font-size: 8px">结束：{{ scope.row.trial_ended_local_time }}</div>
                </template>
                <template v-else>无</template>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right">
              <template slot-scope="scope">
                <el-button type="text" @click="removeItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>

      <el-dialog title="封面预览" :visible.sync="dialogDisplay.imagePreview">
        <img class="preview-dialog-img" :src="xForm.image" />
      </el-dialog>

      <el-dialog title="封面预览" :visible.sync="dialogDisplay.darkimagePreview">
        <img class="preview-dialog-img" :src="xForm.dark_image" />
      </el-dialog>

      <el-dialog title="封面地址" :visible.sync="dialogDisplay.imageEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="xForm.image">
              <i v-if="imageUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadImageProgress" :on-error="uploadImageError" :on-success="uploadImageSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="封面地址" :visible.sync="dialogDisplay.darkimageEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="xForm.dark_image">
              <i v-if="darkimageUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadImageProgress" :on-error="uploadImageError" :on-success="uploadImageSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="选择专辑" :visible.sync="dialogDisplay.editContents">
        <el-tabs v-model="activeTag">
          <el-tab-pane v-for="tag in tags" :key="tag.value" :label="tag.label" :name="tag.value">
            <el-table v-if="tag.value === activeTag" ref="listTable" :data="filtedList" style="width: 100%" @select="onSelect" @select-all="onSelectAll">
              <el-table-column type="selection" width="55"></el-table-column>

              <el-table-column label="图片">
                <template slot-scope="scope">
                  <cover :width="48" :height="48" :src="scope.row.image"></cover>
                </template>
              </el-table-column>

              <el-table-column label="专辑名">
                <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
              </el-table-column>

              <el-table-column label="章节数">
                <template slot-scope="scope">{{ scope.row.sections.length }}</template>
              </el-table-column>

              <el-table-column label="展示地区">
                <template slot-scope="scope">
                  <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
                  <template v-else>
                    <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
                  </template>
                </template>
              </el-table-column>

              <el-table-column label="限时体验">
                <template slot-scope="scope">
                  <template v-if="scope.row.trial_started_local_time && scope.row.trial_ended_local_time">
                    <div style="font-size: 8px">开始：{{ scope.row.trial_started_local_time }}</div>
                    <div style="font-size: 8px">结束：{{ scope.row.trial_ended_local_time }}</div>
                  </template>
                  <template v-else>-</template>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot="header">
                  <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeyword"></el-input>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>

      <el-dialog title="专辑排序" width="40%" :visible.sync="dialogDisplay.sortContents">
        <draggable class="sort-list-container" v-model="sortList">
          <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="sort-item-name">{{ getTR(item.name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSort">保 存</el-button>
          <el-button @click="dialogDisplay.sortContents = false">返 回</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import OpenCC from '@/plugins/opencc';
import Cover from '@/components/cover';
import ColorPicker from '@/components/color-picker';
import Draggable from 'vuedraggable';
import LanguagesSelecter from '@/components/languages-selecter';

export default {
  name: 'SkillForm',
  components: {
    Container,
    Cover,
    ColorPicker,
    Draggable,
    LanguagesSelecter,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      list: [],
      tags: [],
      sortList: [],
      filterKeyword: '',
      activeTag: 'all',
      object_tags: [],
      meditations: [],

      loading: false,
      langs: require('@/langs.json'),
      picToken: '',
      picCDN: '',
      imageUploading: true,
      darkimageUploading: true,
      dialogDisplay: {
        imagePreview: false,
        imageEdit: false,
        darkimagePreview: false,
        darkimageEdit: false,
        sortContents: false,
        editContents: false,
      },
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    filtedList() {
      let filtedList = this.list;
      const activeTag = this.activeTag;
      const filterKeyword = this.filterKeyword;
      if (activeTag !== 'all') {
        const activeOT = this.object_tags.find((v) => v.id === activeTag);
        if (activeOT) {
          filtedList = filtedList.filter((item) => {
            return activeOT.meditation_ids.find((v) => v === item.id);
          });
        }
      }

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return (item.name['en'] || '').includes(filterKeyword) || (item.name['zh-Hans'] || '').includes(filterKeyword) || (item.name['zh-Hant'] || '').includes(filterKeyword);
        });
      }

      return filtedList;
    },
  },
  watch: {
    meditations() {
      this.xForm.related_album_ids = this.meditations.map((v) => v.id);
      console.log(this.xForm.related_album_ids);
    },
    filtedList() {
      this.freshSelected();
    },
    'dialogDisplay.editContents'() {
      this.freshSelected();
    },
  },
  methods: {
    freshSelected() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTable;
        if ($listTables && $listTables.length) {
          const $ref = $listTables[0];
          $ref.clearSelection();
          this.filtedList.forEach((row) => {
            if (this.meditations.some((item) => item.id === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    onSelect(selection, row) {
      if (this.meditations.some((item) => item.id === row.id)) {
        this.meditations = this.meditations.filter((item) => item.id !== row.id);
      } else {
        this.meditations.unshift(row);
      }
    },

    onSelectAll(selection) {
      if (!selection.length) {
        const list = this.meditations.filter((album) => !this.filtedList.some((item) => item.id === album.id));
        this.meditations = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.meditations.some((item) => item.id === album.id)) {
            list.push(album);
          }
        });
        this.meditations = [...this.meditations, ...list];
      }
    },
    removeItem(row) {
      this.meditations = this.meditations.filter((item) => item.id !== row.id);
    },
    async saveSort() {
      this.meditations = this.sortList;
      this.$nextTick(() => {
        this.$emit('save', this.form);
      });
    },

    save() {
      this.$emit('save', this.form);
    },
    async s2t4() {
      try {
        this.xForm.name['zh-Hant'] = this.opencc_twp(this.xForm.name['zh-Hans']);
        this.xForm.description['zh-Hant'] = this.opencc_twp(this.xForm.description['zh-Hans']);
        this.xForm.benefits['zh-Hant'] = this.opencc_twp(this.xForm.benefits['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    beforeUpload: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadImageSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.image = picUrl;
      this.imageUploading = false;
    },
    uploadImageProgress(response) {
      this.imageUploading = true;
    },
    uploadImageError(response) {
      this.imageUploading = false;
    },
    uploadDarkImageSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.dark_image = picUrl;
      this.darkimageUploading = false;
    },
    uploadDarkImageProgress(response) {
      this.darkimageUploading = true;
    },
    uploadDarkImageError(response) {
      this.darkimageUploading = false;
    },
    async init() {
      const [tags, list] = await Promise.all([this.getTags('meditation'), this.getList()]);

      this.tags = tags;
      this.list = list;
      this.meditations = this.xForm.related_album_ids.map((v1) => {
        return this.list.find((v2) => v2.id == v1);
      });
    },
    async getTags(type) {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type },
      });
      this.object_tags = res.data;
      const tags = res.data.map((tag) => {
        return {
          value: tag.id,
          label: this.getTR(tag.name),
        };
      });
      return [{ value: 'all', label: '全部' }, ...tags];
    },
    async getList() {
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: { offset: 0, limit: 255 },
      });
      return res.data;
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created: async function () {
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.uploadIcon {
  display: inline-block;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.checkbox-group-regions {
  display: inline-block;
  margin-right: 16px;
}

.input-new-tag,
.button-new-tag {
  width: 90px;
}

.form-item-label {
  position: relative;
  z-index: 2;
}

.icon-help {
  font-size: 16px;
  vertical-align: sub;
}

.resources-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
}

.resources-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 16px 0;
}

.resources-content {
  margin: 0 16px;
  width: auto;
}

.resources .el-tag {
  cursor: pointer;
}

.lang-span + .lang-span {
  &::before {
    content: ', ';
  }
}

.symmetry-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
}
</style>

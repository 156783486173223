<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:05
 * @LastEditors  : Ruilong Li
 * @LastEditTime : 2020-02-06 14:59:39
 * @Description: 地区选择器
 * @FilePath: /davinci/src/components/regions-selecter.vue
 -->

<script>
import allRegions from '@/regions.json';

const allRegionOptions = Object.keys(allRegions).map((value) => {
  const label = allRegions[value];
  return { value, label, disabled: false };
});

export default {
  name: 'RegionsSelecter',
  props: {
    value: Array,
    selected: Array,
  },
  data() {
    return {
      allRegions: allRegionOptions,
    };
  },
  watch: {
    value(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<template>
  <el-select v-model="value" multiple clearable filterable default-first-option placeholder="请选择区域">
    <el-option v-for="item in allRegions" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

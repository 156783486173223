var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-wrapper",style:(`zoom:${_vm.scale}`),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"preview",style:({
      zoom: `${1 / _vm.scale}`,
      transform: `scale(${_vm.scale})`,
      fontFamily: `${_vm.quote.fontName}`,
    })},[_c('div',{staticClass:"canva"},[_c('div',{ref:"previewImage",staticClass:"dailypic-preview preview-image",style:({
          backgroundImage: `url(${_vm.previewUrl})`,
          backgroundSize: `${_vm.cropXScale} ${_vm.cropYScale}`,
          backgroundPositionX: `${_vm.cropdx}`,
          backgroundPositionY: `${_vm.cropdy}`,
        })}),_c('div',{staticClass:"page-cover"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
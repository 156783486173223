<template>
  <router-view v-if="$route.name !== 'LessonComments'"></router-view>
  <div v-else>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="material-icons">format_quote</i>
          <h3 slot="title">鲁迅语录</h3>
          <p slot="desc">我实在没有说过这样一句话。 — 鲁迅致台静农 （1932年12月13日）</p>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-body">
          <div v-infinite-scroll="fetchList" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="500">
            <div class="list-item" v-for="item in list" :key="item.id">
              <div class="list-main-item">
                <div class="list-item-name">{{ item.content }}</div>
              </div>
              <div>
                <div>Author</div>
                <div>
                  {{ item.user.nickname }}
                  <br />
                  {{ item.user.id }}
                </div>
              </div>
              <div>
                <div>Date</div>
                <div>{{ $moment(item.created_at * 1000).format('YYYY-MM-DD') }}</div>
              </div>
              <div class="list-item-actions">
                <span v-if="item.status === 0">已删</span>
                <el-button v-else :disabled="!$store.getters.hasEditRole" type="text" @click="deleteItem(item)">删除</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
import PageHeader from '@/components/page-header';

export default {
  name: 'LessonComments',
  components: {
    Container,
    PageHeader,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      busy: false,
      finish: false,
      list: [],
      JSONVisible: false,
      detail: '',
      baseRikeUrl,
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    defaultActive() {
      return `/rike/lessons/manager/2018-${this.$route.params.month}`;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'DailypicsManager') {
        this.refetch();
      }
    },
    '$route.params.month'() {
      this.refetch();
    },
  },
  methods: {
    deleteItem(item) {
      const that = this;
      this.$confirm('删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const itemId = item.id;
        await this.$request({
          method: 'DELETE',
          url: `${this.baseRikeUrl}/v1/admin/comments/${itemId}`,
        });
        const indexOfItem = this.list.findIndex((item) => {
          return item.id === itemId;
        });
        this.list.splice(indexOfItem, 1, Object.assign({}, this.list[indexOfItem], { status: 0 }));
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    refetch() {
      this.fetchList();
    },
    fetchList: async function () {
      if (this.infiniteScrollDisabled) {
        return;
      }
      this.busy = true;
      const { year, month, day } = this.$route.params;
      const date = `${year}-${month}-${day}`;
      const res = await this.$request({
        url: `${this.baseRikeUrl}/v1/admin/lessons/${date}/comments`,
        params: {
          offset: this.list.length,
          limit: 20,
        },
      });
      this.list = [...this.list, ...res.data];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
      if (res.data.length < 20) {
        this.finish = true;
      }
    },
  },
  created() {
    this.fetchList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: block;
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}
</style>

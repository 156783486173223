<template>
  <div class="main-nav" :style="env === 'production' ? '' : 'box-shadow: lightblue 50px 0 50px 0px'">
    <router-link class="logo" :to="{ name: 'DashboardIndex' }">
      <img src="../../assets/logo.png" />
      <h1>达芬奇实验室</h1>
    </router-link>
    <el-scrollbar tag="div" wrap-class="menubar-wraper">
      <el-menu class="menubar" :collapse="collapse" :router="true" :unique-opened="true" :default-active="defaultActive">
        <template v-for="nav in navData" :index="nav.index">
          <el-menu-item v-if="!nav.children" :key="nav.index" :index="nav.index">
            <template slot="title">
              <i v-if="nav.icon" class="material-icons">{{ nav.icon }}</i>
              <span>{{ nav.name }}</span>
            </template>
          </el-menu-item>
          <el-submenu v-else-if="nav.index" :key="nav.index" :index="nav.index">
            <template slot="title">
              <i v-if="nav.icon" class="material-icons">{{ nav.icon }}</i>
              <span>{{ nav.name }}</span>
            </template>
            <el-menu-item v-for="item in nav.children" :key="item.index" :index="item.index">
              <i v-if="item.icon" class="material-icons">{{ item.icon }}</i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
const authentication = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return (roleCode & role) === role;
};

const getAdminNav = () => {
  return [
    {
      name: '权限管理',
      index: '/roles',
      icon: 'router',
    },
  ];
};

const getDailypicsNav = (year, month) => {
  return [
    {
      name: '日帖管理',
      index: `/dailypics/manager/${year}-${month}`,
      icon: 'burst_mode',
    },
  ];
};

const getContentsNav = () => {
  return [
    {
      name: '内容管理',
      icon: 'book',
      index: '/contents',
      children: [
        {
          name: '标签管理',
          index: '/simple_tags',
        },

        {
          name: '搜索管理',
          index: '/search_annotations',
        },

        {
          name: '用户推荐管理',
          index: '/recommendations/explorer',
        },

        {
          name: '首页推荐管理',
          index: '/recommendations/home',
        },
        {
          name: '参与者管理',
          index: '/participants',
        },
        {
          name: '通用分组管理',
          index: '/groups',
        },
      ],
    },
  ];
};
const getScenesNav = (currentExploreId, currentSleepId, currentNapId) => {
  return [
    {
      name: '声音场景管理',
      index: '/scenes',
      icon: 'music_note',
      children: [
        {
          name: '声音场景管理',
          index: '/scenes',
        },
        {
          name: '分类管理',
          index: '/scenes/tags',
        },
        {
          name: '推荐位管理',
          index: '/scenes/ad_banners',
        },
        {
          name: '分组管理',
          index: '/scenes/groups',
        },
        {
          name: '声音包管理',
          index: '/scenes/packages/alarm',
        },
      ],
    },

    {
      name: '冥想管理',
      index: '/meditation',
      icon: 'spa',
      children: [
        {
          name: '技巧管理',
          index: '/meditation/skills',
        },
        {
          name: '专辑管理',
          index: '/meditation/albums',
        },
        {
          name: '每日推荐管理',
          index: '/meditation/recommend',
        },

        {
          name: '分类管理',
          index: '/meditation/tags',
        },
        {
          name: '分组管理',
          index: '/meditation/groups',
        },
        {
          name: '推荐位管理',
          index: '/meditation/ad_banners',
        },
        {
          name: '报告页推荐管理',
          index: '/meditation/report_page_recommends',
        },
      ],
    },

    {
      name: '睡眠故事管理',
      index: '/sleep-stories',
      icon: 'local_library',
      children: [
        {
          name: '故事管理',
          index: '/sleep-stories/stories',
        },

        {
          name: '分类管理',
          index: '/sleep-stories/tags',
        },
        {
          name: '分组管理',
          index: '/sleep-stories/groups',
        },
        {
          name: '推荐位管理',
          index: '/sleep-stories/ad_banners',
        },
      ],
    },

    {
      name: '图文管理',
      index: '/article',
      icon: 'book',
      children: [
        {
          name: '图文管理',
          index: '/article',
        },
        {
          name: '分类管理',
          index: '/article/tags',
        },
        {
          name: '分组管理',
          index: '/article/groups',
        },
        {
          name: '推荐位管理',
          index: '/article/ad_banners',
        },
      ],
    },

    {
      name: '探索页管理',
      index: '/explorer_pages',
      icon: 'explore',
      children: [
        {
          name: '探索页配置',
          index: '/explorer_pages',
        },
        {
          name: '潮汐探索推荐位',
          index: '/explore/ad_banners',
        },
        {
          name: '睡眠助手推荐位',
          index: `/sleep_topic_page/ad_banners`,
        },
        {
          name: '小憩助手推荐位',
          index: `/nap_topic_page/ad_banners`,
        },
        {
          name: '主题活动推荐位',
          index: `/theme_page/ad_banners`,
        },
      ],
    },
  ];
};
const getTidesleepNav = () => {
  return [
    {
      name: '潮汐睡眠小程序',
      index: '/tidesleep/manager',
      icon: 'timelapse',
    },
  ];
};
const getOperationNav = () => {
  return [
    {
      name: '活动管理',
      index: '/operation',
      icon: 'attach_money',
      children: [
        {
          name: '公告管理',
          index: '/notice',
        },
        {
          name: '闪屏活动',
          index: '/operation/splashes/',
        },
        {
          name: '兑换活动',
          index: '/operation/redeem-codes/',
        },
        {
          name: '优惠记录',
          index: '/operation/promotion/records',
        },
        {
          name: '优惠资格',
          index: '/operation/promotion/magic_keys',
        },
      ],
    },
  ];
};
const getUsersNav = () => {
  return [
    {
      name: '用户管理',
      index: '/users',
      icon: 'assignment_ind',
      children: [
        {
          name: '用户信息',
          index: '/users/manager',
          icon: 'assignment_ind',
        },
        {
          name: '交易记录管理',
          index: '/transactions/history',
          icon: 'attach_money',
        },
        {
          name: '支付记录管理',
          index: '/transactions/charge_records',
          icon: 'attach_money',
        },
        {
          name: '客户端上报',
          index: '/development/client_metrics',
          icon: 'insert_chart',
        },
        {
          name: '客户端上报(Event)',
          index: '/development/client_metrics_event',
          icon: 'insert_chart',
        },
        {
          name: '用户行为记录',
          index: '/development/business_metrics',
          icon: 'insert_chart',
        },
        {
          name: '通知记录管理',
          index: '/transactions/notifications',
          icon: 'attach_money',
        },
        {
          name: '用户反馈',
          index: '/service',
          icon: 'headset_mic',
        },
      ],
    },
  ];
};
const getRikeNav = (year, month) => {
  return [
    {
      name: '岛读',
      index: '/rike/index',
      icon: 'book',
      children: [
        {
          name: '鲁迅语录',
          index: `/rike/lessons/manager/${year}-${month}`,
          icon: 'format_quote',
        },
        {
          name: '投稿箱',
          index: `/rike/contribute`,
          icon: 'assignment_ind',
        },
        {
          name: '分享投诉',
          index: `/rike/users/query`,
          icon: 'assignment_ind',
        },
        {
          name: '用户查询',
          index: `/rike/reports/manager`,
          icon: 'library_books',
        },
      ],
    },
  ];
};
const getDevToolsNav = () => {
  return [
    {
      name: '开发',
      index: '/development/index',
      icon: 'code',
      children: [
        {
          name: '动态配置管理',
          index: '/development/configs',
          icon: 'settings',
        },
        {
          name: '审阅者管理',
          index: '/development/reviewers',
          icon: 'assignment_ind',
        },
        {
          name: 'OAuth2.0 Clients',
          index: '/development/oauth2_clients',
          icon: 'insert_chart',
        },
        {
          name: 'OAuth2.0 Scopes',
          index: '/development/oauth2_scopes',
          icon: 'insert_chart',
        },
        {
          name: '废弃收据',
          index: '/development/deprecated_receipts',
          icon: 'receipt',
        },
      ],
    },
  ];
};

export default {
  name: 'MainNav',
  props: {
    collapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const $date = this.$moment(new Date());
    const year = $date.format('YYYY');
    const month = $date.format('MM');
    const env = this.$env;

    return {
      year,
      month,
      navData: [],
      env,
    };
  },
  methods: {
    async initNavs() {
      const $date = this.$moment(new Date());
      const year = $date.format('YYYY');
      const month = $date.format('MM');

      const resp = await this.$request({
        url: '/v1/admin/profile',
      });
      const role = resp.data.old_role;
      const navData = this.genNav(role, year, month);
      this.navData = navData;
    },
    genNav(roleCode, year, month) {
      let navData = [];
      if (authentication(roleCode, 1)) {
        if (authentication(roleCode, 10000)) {
          navData = navData.concat(getDailypicsNav(year, month));
        }
        if (authentication(roleCode, 100000)) {
          navData = navData.concat(getContentsNav());
          navData = navData.concat(getScenesNav());
        }
        if (authentication(roleCode, 1000000000)) {
          navData = navData.concat(getUsersNav());
        }
        if (authentication(roleCode, 100000000)) {
          navData = navData.concat(getOperationNav());
        }
        if (authentication(roleCode, 10000000000)) {
          navData = navData.concat(getDevToolsNav());
        }
        if (authentication(roleCode, 100000000000)) {
          navData = navData.concat(getRikeNav(year, month));
        }
        if (authentication(roleCode, 10)) {
          navData = navData.concat(getAdminNav());
        }
        if (authentication(roleCode, 1000000)) {
          navData = navData.concat(getTidesleepNav());
        }
      }

      return navData;
    },
  },
  computed: {
    defaultActive() {
      let currentPath = this.$route.fullPath;
      let fullpath = '';
      let matches = this.$route.matched;
      let matched = null;

      if (matches.length) {
        matched = matches[matches.length - 1];
      }

      if (matched && matched.meta && matched.meta.subjection) {
        const { subjection } = matched.meta;
        console.log(subjection);
        this.navData.forEach(nav => {
          // 从顶层节点找
          if (currentPath.includes(nav.index)) {
            if (nav.index.length > fullpath.length) {
              fullpath = nav.index;
            }
          }

          // 从子节点找
          if (nav.children) {
            nav.children.forEach(subnav => {
              if (currentPath.includes(subnav.index)) {
                if (subnav.index.length > fullpath.length) {
                  fullpath = subnav.index;
                }
              }
            });
          }
        });
      }

      return fullpath;
    },
  },
  created() {
    this.initNavs();
    if (this.env === 'production') {
      document.title = '达芬奇';
    } else {
      document.title = '达芬奇[TEST]';
    }
  },
};
</script>
<style lang="less" scoped>
.logo {
  height: 64px;
  padding-left: 18px;
  display: flex;
  align-items: center;
  background-color: #002140;
  text-decoration: none;
  img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
  h1 {
    margin-left: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
}
.main-nav {
  height: 100vh;
  width: 240px;
  border-right: 1px solid #e6e6e6;
  background-color: #001529;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  z-index: 2;
}

.explore-region {
  display: inline-block;
  vertical-align: sub;
  font-size: 9px;
  transform: scale(0.8);
}

.region-selecter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menubar {
  width: 239px;
  border-right: none;
}
.material-icons {
  font-size: 18px;
}

.el-menu li,
.el-menu span {
  color: rgba(255, 255, 255, 0.65);
}

.el-menu li:hover {
  color: #fff;
}

.el-menu li {
  background-color: #001529;
}

.el-menu-item.is-active {
  color: #fff;
  background-color: #1890ff;
}
</style>
<style lang="less">
.el-submenu .el-submenu__title i {
  margin-right: 5px;
  font-size: 18px;
  width: 24px;
  text-align: center;
}
.el-menu-item i {
  margin-right: 5px;
  font-size: 18px;
  width: 24px;
  text-align: center;
}
.el-submenu .el-submenu__title:hover {
  background-color: transparent;
  > span {
    color: #fff;
  }
}
</style>
<style lang="less">
.menubar-wraper {
  height: 100vh !important;
  margin-top: -64px;
  padding-top: 64px;
}
</style>

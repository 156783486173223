<template>
  <edit-form :form="form" @save="save"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'TidesleepAdd',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        share_text: '',
        like: 'disc',
        type: 'normal',
        color: '255, 255, 255',
        icon_url: '',
        gif_url: '',
        img_url: '',
        share_img_url: '',
        audio_url: '',
        demo_url: '',
        musical_note_unlock: { count: 0 },
        sort_key: 0,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    save: async function () {
      const res = await this.$request({
        url: '/v1/admin/tidesleep/scenes',
        method: 'POST',
        data: this.form,
      });
      const scene = res.data;
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.$router.replace({
        name: 'TidesleepEditor',
        params: { id: scene.id },
      });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

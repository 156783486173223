<template>
  <equip-form :form="form" @change="change" @save="save" @copy="copy"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'EditLesson',
  components: {
    EquipForm: Form,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      baseRikeUrl,
      form: {
        article: '',
        author: {},
        date_by_day: 20180101,
        provenance: '',
        title: '',
        baseRikeUrl,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async copy(date) {
      const rikeUrl = this.$env === 'production' ? 'https://rike-dapi.moreless.io' : 'https://rike-api.moreless.io';
      const authorsRes = await this.$request({
        url: `${rikeUrl}/v1/admin/authors`,
        params: {
          offset: 0,
          limit: 512,
        },
      });
      const authors = authorsRes.data;
      const authorName = this.form.author.name;
      let author = authors.find((author) => {
        return author.name === authorName;
      });

      if (!author) {
        const authorRes = await this.$request({
          url: `${rikeUrl}/v1/admin/authors`,
          method: 'POST',
          data: {
            name: authorName,
          },
        });
        author = authorRes.data;
      }

      const { article, provenance, title } = this.form;
      const copyToDate = this.$moment(date).format('YYYY-MM-DD');
      const dateByDay = parseInt(this.$moment(copyToDate).format('YYYYMMDD'));
      const data = {
        article,
        date_by_day: dateByDay,
        provenance,
        title,
        author,
      };
      try {
        await this.$request({
          url: `${rikeUrl}/v1/admin/lessons/${copyToDate}`,
          method: 'PUT',
          data,
        });
        this.copyDialogVisible = false;
        this.$message({
          message: '复制成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    async save(data) {
      const { year, month, day } = this.$route.params;
      const $date = this.$moment(`${year}-${month}-${day}`);
      try {
        const res = await this.$request({
          url: `${this.baseRikeUrl}/v1/admin/lessons/${$date.format('YYYY-MM-DD')}`,
          method: 'PUT',
          data,
        });
        this.form = res.data;
        if (this.$env === 'dev') {
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 2000,
          });
        } else {
          this.$confirm('保存成功，是否复制到开发环境?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            this.copy($date);
          });
        }
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    init: async function () {
      const { year, month, day } = this.$route.params;
      const $date = this.$moment(`${year}-${month}-${day}`);
      try {
        const res = await this.$request({
          url: `${this.baseRikeUrl}/v1/lessons/${$date.format('YYYY-MM-DD')}`,
        });
        this.form = Object.assign({}, this.form, res.data);
      } catch (e) {
        this.form = Object.assign({}, this.form, {
          date_by_day: parseInt(`${year}${month}${day}`),
        });
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-wrapper",style:(`zoom:${_vm.scale}`),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{ref:"previewImage",staticClass:"preview",style:({
      zoom: `${1 / _vm.scale}`,
      transform: `scale(${_vm.scale})`,
      backgroundImage: `url(${_vm.previewUrl})`,
      backgroundSize: `${_vm.cropXScale} ${_vm.cropYScale}`,
      backgroundPositionX: `${_vm.cropdx}`,
      backgroundPositionY: `${_vm.cropdy}`,
    })},[_c('div',{attrs:{"id":"container"}},[_c('div',{attrs:{"id":"zh-date"}},[_vm._v(_vm._s(_vm.lunar.oDate.getMonth() + 1)+"月·星期"+_vm._s(_vm.lunar.cnDay)+"·"+_vm._s(_vm.lunar.lMonth)+"月"+_vm._s(_vm.lunar.lDate))]),_c('div',{staticClass:"count-down"},[_c('div',{attrs:{"id":"remain-day"}},[_vm._v(_vm._s(_vm.lunar.oDate.getDate()))])]),_c('div',{staticClass:"quote"},[_c('div',{attrs:{"id":"quote-content"}},[_vm._v(_vm._s(_vm.quote.text))]),_c('div',{attrs:{"id":"quote-author"}},[_vm._v(_vm._s(_vm.author.text))]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrcode_wrapper"},[_c('img',{attrs:{"id":"qrcode","src":"http://pics.tide.moreless.io/tidetime/share/qr/2017-12-15.jpg"}})])
}]

export { render, staticRenderFns }
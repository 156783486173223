<template>
  <el-dialog title="选择场景" :visible.sync="display" :append-to-body="appendToBody">
    <el-input class="th-input" v-model="keyword" suffix-icon="el-icon-search" placeholder="搜索场景名称" @keyup.enter.native="scenesRefetch"></el-input>
    <el-table class="scene-table" ref="multipleTable" :data="scenes" v-loading="scenesLoading" v-infinite-scroll="scenesFetchList" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50" @row-click="selectScene">
      <el-table-column label="场景图片">
        <template slot-scope="scope">
          <cover :width="32" :height="32" :src="scope.row.image" :mask-colors="scope.row.mask_colors"></cover>
        </template>
      </el-table-column>
      <el-table-column label="场景名称">
        <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">{{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}</template>
      </el-table-column>
      <el-table-column align="right" width="160">
        <template slot-scope="scope">{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer symmetry-footer">
      <el-button @click="display = false">返 回</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Cover from '@/components/cover';

export default {
  name: 'ScenePicker',
  components: {
    Cover,
  },
  model: {
    prop: 'showDialog',
    event: 'change',
  },
  props: {
    showDialog: Boolean,
    appendToBody: Boolean,
  },
  data() {
    return {
      scenesLoading: false,
      busy: false,
      finish: false,
      scenes: [],
      keyword: '',
    };
  },
  computed: {
    display: {
      get() {
        return this.showDialog;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  methods: {
    async scenesRefetch() {
      this.scenes = [];
      this.finish = false;
      await this.scenesFetchList();
    },
    scenesFetchList: async function() {
      this.busy = true;
      const limit = 50;
      const params = { offset: this.scenes.length, limit };
      if (this.keyword) {
        params.name = this.keyword;
      }
      const res = await this.$request({
        url: '/v1/admin/scenes',
        params,
      });
      const scenes = res.data;
      if (scenes.length < limit) {
        this.finish = true;
      }
      this.scenes = [...this.scenes, ...scenes];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
      this.scenesLoading = false;
    },
    selectScene(scene) {
      this.$emit('selected', scene);
      this.display = false;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created() {},
};
</script>

<template>
  <router-view v-if="$route.name !== 'RolesManager'"></router-view>
  <div v-else>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="material-icons">router</i>
          <h3 slot="title">网管房</h3>
          <p slot="desc">「重启试试。」</p>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">人员管理</div>
          <el-button class="add-btn" size="mini" icon="el-icon-plus" @click="addItem">新增人员</el-button>
        </div>
        <div class="container-body">
          <div v-if="ready">
            <div class="list-item" v-for="item in list" :key="item.id">
              <div class="list-main-item">
                {{ item.id }} | {{ item.nickname }} | {{ item.phone_number }}
                <div>{{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>登录</div>
                <i :class="authentication(item.old_role, 1) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>网管</div>
                <i :class="authentication(item.old_role, 10) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>修改线上</div>
                <i :class="authentication(item.old_role, 100) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>日帖</div>
                <i :class="authentication(item.old_role, 10000) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>内容管理</div>
                <i :class="authentication(item.old_role, 100000) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>潮汐睡眠</div>
                <i :class="authentication(item.old_role, 1000000) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>活动管理</div>
                <i :class="authentication(item.old_role, 100000000) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>用户</div>
                <i :class="authentication(item.old_role, 1000000000) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>开发</div>
                <i :class="authentication(item.old_role, 10000000000) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="item" style="margin-left: 12px; flex-basis: 64px">
                <div>岛读</div>
                <i :class="authentication(item.old_role, 100000000000) ? 'el-icon-check' : 'el-icon-close'" />
              </div>
              <div class="list-item-actions" style="flex-basis: 60px">
                <router-link
                  :to="{
                    name: 'EditMemberRole',
                    params: { user_id: item.id },
                  }"
                  >编辑</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
import PageHeader from '@/components/page-header';

export default {
  name: 'InstituteManager',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      ready: false,
      list: [],
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'RolesManager') {
        this.init();
      }
    },
  },
  methods: {
    authentication(roleCode, role) {
      roleCode = parseInt(roleCode, 2);
      role = parseInt(role, 2);
      return (roleCode & role) === role;
    },
    addItem() {
      this.$router.push({
        name: 'AddMember',
      });
    },
    async init() {
      this.fetchList();
    },
    fetchList: async function() {
      const query = await this.$request({
        url: '/v1/admin/admins',
      });
      this.list = query.data;
    },
  },
  async created() {
    await this.init();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  margin-left: auto;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item {
  align-items: center;
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  .select-btn {
    display: inline-block;
  }
  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;
  .select-btn {
    display: none;
  }
  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.reviewing {
  background-color: rgba(255, 126, 126, 0.12);
}

.item {
  text-align: center;
}

.el-icon-check,
.el-icon-close {
  margin-top: 8px;
  font-size: 20px;
}

.el-icon-check {
  color: #67c23a;
}

.el-icon-close {
  color: #f56c6c;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-wrapper",style:(`zoom:${_vm.scale}`),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"preview",style:({
      zoom: `${1 / _vm.scale}`,
      transform: `scale(${_vm.scale})`,
      fontFamily: `${_vm.quote.fontName}`,
    })},[_c('div',{staticClass:"canva"},[_c('div',{ref:"previewImage",staticClass:"dailypic-preview preview-image",style:({
          backgroundImage: `url(${_vm.previewUrl})`,
          backgroundSize: `${_vm.cropXScale} ${_vm.cropYScale}`,
          backgroundPositionX: `${_vm.cropdx}`,
          backgroundPositionY: `${_vm.cropdy}`,
        })}),_vm._m(0),_c('div',{staticClass:"text-board"},[(_vm.festival.text)?_c('span',{staticClass:"festival",style:({
            backgroundColor: `rgba(${_vm.festival.background_color})`,
            color: `rgba(${_vm.festival.color})`,
          })},[_vm._v(" "+_vm._s(_vm.festival.text)+" ")]):_vm._e(),_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.date))]),_c('p',{staticClass:"month"},[_vm._v(_vm._s(_vm.month)+" "+_vm._s(_vm.year))]),_c('p',{staticClass:"quote"},[_vm._v(_vm._s(_vm.quote.text))]),_c('span',{staticClass:"dash"}),_c('p',{staticClass:"author"},[_vm._v(_vm._s(_vm.author.text))]),_vm._m(1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png")}}),_c('div',{staticClass:"brand-info"},[_c('p',[_vm._v("潮汐")]),_c('p',[_vm._v("@阿喵")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download"},[_c('span',{staticClass:"bootup-text"},[_vm._v("扫码下载")]),_c('img',{staticClass:"qrcode",attrs:{"src":require("@/assets/qrcode.jpeg")}})])
}]

export { render, staticRenderFns }
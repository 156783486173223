<template>
  <div class="preview-wrapper" :style="`zoom:${scale}`" @click="$emit('click')">
    <div
      class="preview"
      :style="{
        zoom: `${1 / scale}`,
        transform: `scale(${scale})`,
        fontFamily: `${quote.fontName}`,
      }"
    >
      <div class="canva">
        <div
          class="preview-image"
          ref="previewImage"
          :style="{
            backgroundImage: `url(${previewUrl})`,
            backgroundSize: `${cropXScale} ${cropYScale}`,
            backgroundPositionX: `${cropdx}`,
            backgroundPositionY: `${cropdy}`,
          }"
        ></div>
        <div class="bg-cover"></div>
        <div>
          <img class="avatar" src="~@/assets/avatar.jpg" />
          <div class="userInfo">
            <p>啊喵</p>
            <p>在潮汐第 6 天醒来</p>
          </div>
        </div>
        <div class="share-card">
          <div class="share-card-cover"></div>
          <div class="share-card-content">
            <img class="share-card-icon" src="~@/assets/glyph_moon_line_white_64@3x.png" />
            <p class="period">昨晚</p>
            <p class="period-duration">10.3 小时</p>
            <div class="share-items">
              <div class="share-item sleep-time">
                <label>入睡</label>
                <span>11:00<i>PM</i></span>
              </div>
              <div class="share-item sleep-quality">
                <label>睡眠质量</label>
                <span>88%</span>
              </div>
              <div class="share-item wake-time">
                <label>醒来</label>
                <span>9:21<i>AM</i></span>
              </div>
            </div>
          </div>
        </div>
        <p class="footnoot">潮汐 · 和大自然一起入睡</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DailypicPreview',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    crop: {
      type: Object,
    },
    origin: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: 'zh-Hans',
    },
    imageInfo: {
      type: Object,
    },
  },
  data() {
    return {
      year: '',
      month: '',
      day: '',
      cropScale: {
        x: 'atuo',
        y: 'atuo',
      },
      imgScale: 1,
      screenWidth: 0,
      screenHeight: 0,
    };
  },
  methods: {
    // 正方图
    squareDx() {
      let offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    squareDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    squareXScale() {
      let scale = 'auto';
      let diff = 0.96;
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width;
        width = width * diff;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    squareYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        height = height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },

    // 竖图
    verticalDx() {
      let offset = 0.02;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    verticalDy() {
      let offset = 0.04;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      // const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale
      dy = dy / (this.crop.height / this.screenHeight);
      return `${-dy}px`;
    },
    verticalXScale() {
      let scale = 'auto';
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width * 0.96;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    verticalYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        const imgHeight = this.crop.imgHeight;
        height = height * 0.96;
        scale = `${100 / (height / imgHeight)}%`;
      }
      return scale;
    },

    // 横图
    landscapeDx() {
      const offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    landscapeDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    landscapeXScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (imageInfo.width <= imageInfo.height) {
        let width = this.crop.width;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    landscapeYScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (!imageInfo.width < imageInfo.height) {
        let height = this.crop.height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },
  },
  computed: {
    cardCrop() {
      const { width, height } = this.imageInfo;
      const crop = this.crop;
      let scale = 1;
      let size = 0;
      if (crop.imgWidth === '210') {
        scale = crop.width / crop.imgWidth;
        size = width;
      } else if (crop.imgHeight === '210') {
        scale = crop.height / crop.imgHeight;
        size = height;
      }
      const positionY = `${parseInt((crop.imgHeight / crop.height) * 100)}%`;
      const positionX = `${parseInt((crop.imgWidth / crop.width) * 100)}%`;
      const cardWidth = parseInt(size * scale);
      const cardHeight = parseInt(size * scale);
      const cardCrop = {
        width: cardWidth,
        height: cardHeight,
        imgWidth: parseInt(width),
        imgHeight: parseInt(height),
        dx: 936 * (crop.dx / crop.width),
        dy: 936 * (crop.dy / crop.height),
        positionX,
        positionY,
      };
      console.log(crop);
      console.log(cardCrop);
      return cardCrop;
    },
    cropdx() {
      let dx;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dx = this.verticalDx();
      } else if (width > height) {
        dx = this.landscapeDx();
      } else {
        dx = this.squareDx();
      }
      return dx;
    },
    cropdy() {
      let dy;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dy = this.verticalDy();
      } else if (width > height) {
        dy = this.landscapeDy();
      } else {
        dy = this.squareDy();
      }
      return dy;
    },
    cropXScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalXScale();
      } else if (width > height) {
        scale = this.landscapeXScale();
      } else {
        scale = this.squareXScale();
      }
      return scale;
    },
    cropYScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalYScale();
      } else if (width > height) {
        scale = this.landscapeYScale();
      } else {
        scale = this.squareYScale();
      }
      return scale;
    },
    previewUrl() {
      let picUrl = this.data.pic_url;
      if (!this.origin) {
        picUrl = `${picUrl}?imageMogr2/thumbnail/!1024x1024r`;
        // picUrl = `${picUrl}/crop/${this.cropPath}`
      }
      return picUrl;
    },
    cropPath() {
      const scale = this.imgScale;
      const cropsize = `${this.crop.width / scale}x${this.crop.height / scale}`;
      const dx = this.crop.dx / scale;
      const dy = this.crop.dy / scale;
      return `!${cropsize}a${dx}a${dy}`;
    },
    quote() {
      return this.data.content[this.lang].quote;
    },
    author() {
      return this.data.content[this.lang].author;
    },
  },
  created() {
    console.log(new Date(this.data.date).toDateString());
    const dateString = new Date(this.data.date).toDateString();
    [, this.month, this.date, this.year] = dateString.split(' ');
    this.$nextTick(() => {
      const imageInfo = this.imageInfo;
      const $previewImage = this.$refs.previewImage;
      if (!$previewImage) {
        return 1;
      }
      const width = this.$refs.previewImage.clientWidth;
      const height = this.$refs.previewImage.clientHeight;
      // const crop = this.crop
      let scale;
      if (imageInfo.width < imageInfo.height) {
        scale = 210 / width;
      } else {
        scale = 210 / height;
      }
      this.imgScale = scale;
      this.screenWidth = width;
      this.screenHeight = height;
    });
  },
};
</script>
<style lang="less">
.cropper-face {
  background-size: cover !important;
  background: url('~@/assets/mask.png') !important;
}
</style>
<style lang="less" scoped>
.preview-wrapper {
  margin: 0 auto;
  width: 1080px;
  height: 1920px;
  overflow: hidden;
}
.preview {
  transform-origin: 0 0;
  width: 1080px;
  height: 1920px;
}
.canva {
  overflow: auto;
  width: 100%;
  height: 100%;
}
.preview-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  filter: blur(28.8px);
  width: 1080px;
  height: 1920px;
}
.bg-cover {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(92, 185, 253, 0.24);
}
.avatar {
  position: relative;
  margin: 120px auto 0;
  display: block;
  width: 144px;
  height: 144px;
  border-radius: 50%;
  border: 4px solid #fff;
  box-sizing: border-box;
}
.userInfo {
  position: relative;
  text-align: center;
  margin: 40px auto 0;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.5);
  p {
    margin: 0;
  }
}
.share-card {
  margin: 96px auto 0;
  width: 936px;
  height: 936px;
  position: relative;
  font-size: 80px;
  line-height: 150px;
  border-radius: 48px;
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  background: linear-gradient(rgb(247, 165, 240), rgb(87, 183, 255));
}
.share-card-cover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.32));
}
.share-card-content {
  position: relative;
}
.share-card-icon {
  display: block;
  margin: 136px 0 0 24px;
  width: 192px;
  height: 192px;
  opacity: 0.3;
}
.period {
  margin: 192px 0 0 64px;
  text-align: left;
  font-size: 34px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.period-duration {
  margin: 8px auto 0 64px;
  text-align: left;
  font-size: 84px;
  font-weight: 400;
  line-height: 118px;
  color: rgba(255, 255, 255, 1);
}
.share-items {
  margin: 32px 64px 0;
  display: flex;
  justify-content: space-between;
  border-top: 6px solid rgba(255, 255, 255, 0.3);
}
.share-item {
  display: block;
  width: 190px;
  font-size: 0;
  label {
    margin-top: 27px;
    display: block;
    font-size: 34px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 8px;
    color: rgba(255, 255, 255, 0.5);
  }
  span {
    margin-top: 17px;
    display: inline-block;
    font-size: 48px;
    font-weight: 400;
    line-height: 66px;
    color: rgba(255, 255, 255, 1);
  }
  i {
    margin-left: 8px;
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
  }
}
.sleep-time {
  text-align: left;
}
.sleep-quality {
  text-align: center;
}
.wake-time {
  text-align: right;
}
.footnoot {
  margin-top: 216px;
  text-align: center;
  position: relative;
  font-size: 32px;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
}
</style>

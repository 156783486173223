<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">DEBUG</div>
      </div>
      <el-form class="form container-body" v-model="form" style="padding-top: 36px">
        <el-form-item label="会员相关：">
          <el-button
            :disabled="!$store.getters.hasEditRole"
            size="medium"
            class="apply save-btn"
            @click="
              paymentType = 'tide-plus';
              dialog.addPayment = true;
            "
            >增加潮汐PLUS时长</el-button
          >

          <el-button
            :disabled="!$store.getters.hasEditRole"
            size="medium"
            class="apply save-btn"
            @click="
              paymentType = 'tide-plus';
              dialog.reducePayment = true;
            "
            >减少潮汐PLUS时长</el-button
          >

          <el-button
            :disabled="!$store.getters.hasEditRole"
            size="medium"
            class="apply save-btn"
            @click="
              paymentType = 'scene-pass';
              dialog.addPayment = true;
            "
            >增加声音卡时长</el-button
          >

          <el-button
            :disabled="!$store.getters.hasEditRole"
            size="medium"
            class="apply save-btn"
            @click="
              paymentType = 'scene-pass';
              dialog.reducePayment = true;
            "
            >减少声音卡时长</el-button
          >

          <el-button :disabled="!$store.getters.hasEditRole" size="medium" class="apply save-btn" @click="dialog.setLifeTimeVip = true">设为终身会员</el-button>

          <el-button :disabled="!$store.getters.hasEditRole" size="medium" class="apply save-btn" @click="dialog.dismissLifeTimeVip = true">罢黜终身会员</el-button>

          <el-button size="medium" class="apply save-btn" @click="dismissVip">罢黜会员（仅开发环境）</el-button>
        </el-form-item>
        <el-form-item label="客户端活动相关：">
          <el-button size="medium" class="apply save-btn" @click="clearActivities">清空活动数据（仅开发环境）</el-button>
        </el-form-item>
        <el-form-item label="冥想小程序相关：">
          <el-button size="medium" class="apply save-btn" @click="clearMinaMissions">清空任务数据（仅开发环境）</el-button>
          <el-button size="medium" class="apply save-btn" @click="dialog.addCheckinData = true">增加签到数据（仅开发环境）</el-button>
          <el-button size="medium" class="apply save-btn" @click="dialog.addInviteData = true">增加邀请数据（仅开发环境）</el-button>
        </el-form-item>
      </el-form>

      <el-dialog title="设为终身会员" width="700px" :visible.sync="dialog.setLifeTimeVip">
        <el-form>
          <div style="margin-bottom: 24px">将用户设为终身会员</div>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.setLifeTimeVip = false">取 消</el-button>
          <el-button type="primary" @click="setLifeTimeVip">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="罢黜终身会员" width="700px" :visible.sync="dialog.dismissLifeTimeVip">
        <el-form>
          <div style="margin-bottom: 24px">去除用户的终身会员</div>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.dismissLifeTimeVip = false">取 消</el-button>
          <el-button type="primary" @click="dismissLifeTimeVip">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="增加订阅时长" width="700px" :visible.sync="dialog.addPayment">
        <el-form>
          <div style="margin-bottom: 24px">为用户增加会员时间，增加的时间即刻开始计算（例：当天中午 12:00 增加了 7 天时间，那么将在7天后的 12:00 过期），如果该用户本身仍剩余有会员时间，则是对剩余时间进行延长</div>
          <el-form-item label="时间">
            <el-col>
              <el-col :span="8">
                <el-input-number :min="1" v-model="newPaymentData.times"></el-input-number>
              </el-col>
              <el-col :span="8" :offset="1">
                <el-radio v-model="newPaymentData.multiple" :label="60">分钟</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="3600">小时</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="86400">天</el-radio>
              </el-col>
            </el-col>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.addPayment = false">取 消</el-button>
          <el-button type="primary" @click="manualAddUserPayment">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="减少订阅时长" width="700px" :visible.sync="dialog.reducePayment">
        <el-form>
          <div style="margin-bottom: 24px">减少用户的会员时间，减少的时间即刻开始计算</div>
          <el-form-item label="时间">
            <el-col>
              <el-col :span="8">
                <el-input-number :min="1" v-model="newPaymentData.times"></el-input-number>
              </el-col>
              <el-col :span="8" :offset="1">
                <el-radio v-model="newPaymentData.multiple" :label="60">分钟</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="3600">小时</el-radio>
                <el-radio v-model="newPaymentData.multiple" :label="86400">天</el-radio>
              </el-col>
            </el-col>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="newPaymentData.note"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.reducePayment = false">取 消</el-button>
          <el-button type="primary" @click="manualReduceUserPayment">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="增加签到数据" width="700px" :visible.sync="dialog.addCheckinData">
        <el-form>
          <el-form-item label="签到时间">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="checkinData.times"></el-input>
            <div>支持常见的时间格式，需要增加多个时间以逗号分割，如 2019/03/01, 2019-03-02 11:29:59, 1551628800</div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.addCheckinData = false">取 消</el-button>
          <el-button @click="fillRecently">使用最近7天</el-button>
          <el-button type="primary" @click="addCheckinData">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="增加邀请数据" width="700px" :visible.sync="dialog.addInviteData">
        <el-form>
          <el-form-item label="邀请数据">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="inviteData.users"></el-input>
            <div>user_id，需要增加多个时以逗号分割</div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialog.addInviteData = false">取 消</el-button>
          <el-button @click="fillFavorite">使用预留数据</el-button>
          <el-button type="primary" @click="addInviteData">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'UserSubscribe',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      dialog: {
        setLifeTimeVip: false,
        dismissLifeTimeVip: false,
        addPayment: false,
        reducePayment: false,
        addCheckinData: false,
        addInviteData: false,
      },
      newPaymentData: {
        note: '',
        times: 1,
        multiple: 60,
      },
      paymentType: 'tide-plus',
      checkinData: {
        times: '',
      },
      inviteData: {
        users: '',
      },
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    manualAddUserPayment: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
      };

      if (this.paymentType === 'scene-pass') {
        data.scene_pass_seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      } else if (this.paymentType === 'tide-plus') {
        data.seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      }

      try {
        await this.$request({
          method: 'POST',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.addPayment = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
          times: 30,
          multiple: 86400,
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    manualReduceUserPayment: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
      };

      if (this.paymentType === 'scene-pass') {
        data.scene_pass_seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      } else if (this.paymentType === 'tide-plus') {
        data.seconds = this.newPaymentData.times * this.newPaymentData.multiple;
      }

      try {
        await this.$request({
          method: 'DELETE',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.reducePayment = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
          times: 30,
          multiple: 86400,
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    setLifeTimeVip: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
        life_time_user: true,
      };
      try {
        await this.$request({
          method: 'POST',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.addPayment = false;
        this.$message({
          message: '设为终身会员成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    dismissLifeTimeVip: async function() {
      const user = this.$store.getters.user_id;
      const data = {
        note: this.newPaymentData.note,
        admin: user,
        life_time_user: false,
      };
      try {
        await this.$request({
          method: 'POST',
          url: `/v1/admin/users/${this.$route.params.user_id}/manual_payment`,
          data,
        });
        this.dialog.addPayment = false;
        this.$message({
          message: '罢黜终身会员成功',
          type: 'success',
        });
        this.newPaymentData = {
          note: '',
        };
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    dismissVip: async function() {
      try {
        await this.$request({
          method: 'DELETE',
          url: `/debug/users/${this.$route.params.user_id}/vip`,
        });
        this.$message({
          message: '罢黜成功',
          type: 'success',
        });
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    async clearActivities() {
      try {
        await this.$request({
          method: 'DELETE',
          url: `/debug/users/${this.$route.params.user_id}/activities`,
        });
        this.$message({
          message: '清理成功',
          type: 'success',
        });
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    async clearMinaMissions() {
      try {
        await this.$request({
          method: 'DELETE',
          url: `/debug/tide_meditation/users/${this.$route.params.user_id}/missions`,
        });
        this.$message({
          message: '清理成功',
          type: 'success',
        });
        this.init();
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },

    fillRecently() {
      this.checkinData.times = [
        this.$moment().format('YYYY-MM-DD'),
        this.$moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        this.$moment()
          .subtract(2, 'days')
          .format('YYYY-MM-DD'),
        this.$moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD'),
        this.$moment()
          .subtract(4, 'days')
          .format('YYYY-MM-DD'),
        this.$moment()
          .subtract(5, 'days')
          .format('YYYY-MM-DD'),
        this.$moment()
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
      ].join(', ');
    },

    async addCheckinData() {
      let times = this.checkinData.times.split(',').map(time => time.trim());
      let invalid = false;
      const invalidValues = [];
      const validValues = [];
      for (let time of times) {
        const $time = this.$moment(time);
        if (!$time.isValid()) {
          invalid = true;
          invalidValues.push(time);
        } else {
          validValues.push($time.unix());
        }
      }
      if (invalid) {
        this.$message.error(`无效的日期格式: ${invalidValues.join(', ')}`);
      } else {
        try {
          await this.$request({
            method: 'POST',
            url: `/debug/tide_meditation/users/${this.$route.params.user_id}/add_checkin_data`,
            data: {
              times: validValues,
            },
          });
          this.dialog.addCheckinData = false;
          this.checkinData.times = '';
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } catch (e) {
          this.$alert(e.response.data.msg, '错误', {
            type: 'error',
          });
        }
      }
    },

    fillFavorite() {
      this.inviteData.users = [
        '59cdea9a8425230005bc0a9f',
        '59cf670776ef2c000534ab91',
        '59cf723543b7300005645a7d',
        '59dde6a6a7ec6a00054f3e46',
        '59decfdf9c7a0000057cff97',
        '59df3ac64a9490000541da57',
        '59e4c6d32f91970005b4448e',
        '59e709ba2f91970005b444a7',
        '59e80b9e33d2b60005cfaf58',
        '59ef013da163460005a208be',
        '59ef08f2c045e8000504035c',
        '59ef0e04c045e8000504035d',
        '59ef0e241829a30005986b32',
        '59ef0e97c045e8000504035e',
        '59f19218d1a6f30005a8d297',
        '59f2bb27d1a6f30005a8d298',
        '59f33e8d745f15000510a6c0',
        '59f4394f42acee0005e6e24b',
        '5a0178661f726b00055f384b',
        '5a0541881f726b00055f3878',
        '5a0670671155cf00056161fb',
        '5a0819e6fd5c990005f02e5c',
        '5a0c4fdb16f9fe00056330e8',
        '5a10018881fe580005ce0514',
        '5a1008511aaf82000588f95c',
        '5a1423c6e8e69d00051e5403',
        '5a1bc1ea4b1b200005a8ff6c',
        '5a1e61ed4b1b200005a902a9',
        '5998d1911b69e6005846257b',
        '5998d1b361ff4b0058e2af82',
        '5998d2d7128fe10054c30578',
        '5998d3f261ff4b0058e2c138',
        '5998d5625c497d005703a813',
        '5998d57d5c497d005703a9e3',
        '591ef6d7570c35006991a528',
        '5998d6b8a0bb9f005713e64c',
        '5998d7225c497d005703c86c',
        '5998d769128fe10054c32ff4',
        '58ce54c2b123db3f6b3f3d45',
        '5998d82aa0bb9f005713f437',
        '598334baa0bb9f005861b00d',
        '5998d807a22b9df82f979f84',
        '5997a3a861ff4b0058d651ba',
        '5998d8ce1b69e600584663ed',
        '5998d8a5128fe10054c339cb',
        '598325382f301e0058e16786',
        '5998d952570c350060714cc6',
        '5998d9bb5c497d005703f642',
        '5998db68570c35356fd415d8',
        '5998d5bfa22b9df82f978a5a',
      ].join(', ');
    },

    async addInviteData() {
      const users = this.inviteData.users.split(',').map(time => time.trim());
      try {
        await this.$request({
          method: 'POST',
          url: `/debug/tide_meditation/users/${this.$route.params.user_id}/add_invite_data`,
          data: {
            users,
          },
        });
        this.dialog.addInviteData = false;
        this.inviteData.users = '';
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        this.$alert(e.response.data.msg, '错误', {
          type: 'error',
        });
      }
    },

    init: async function() {
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, res.data);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

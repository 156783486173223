<template>
  <edit-form :form="form" @save="save" @sync="sync"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'NewsEdit',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        content: {
          'zh-Hans': {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          en: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          'zh-Hant': {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          ja: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          es: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          ru: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
          ko: {
            banner_url: '',
            pic_url: '',
            title: '',
            sub_title: '',
            button_title: '',
            ios_link: '',
            android_link: '',
          },
        },
        status: 'draft',
        sort_key: 2,
        valid_from: 0,
        valid_to: 0,

        show_dialog: true,
        dialog_show_page: 'home',
        ios_versions: [],
        android_versions: [],
        regions: [],
        provinces: [],
        channels: [],

        vip_status: [],
        login_status_v2: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    sync: async function() {
      const form = { ...{}, ...this.form };
      delete form.id;
      delete form.created_at;
      delete form.updated_at;

      if (this.$env === 'dev') {
        this.$prompt('此操作将使测试环境的数据复制到正式环境，请输入 “我很确定” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async ({ value }) => {
          if (value === '我很确定') {
            try {
              await this.$request({
                url: 'https://davinci.moreless.space/api/v1/admin/news',
                method: 'POST',
                data: form,
              });
              this.$message({
                message: '复制成功',
                type: 'success',
              });
            } catch (e) {
              this.$message.error('复制失败');
            }
          } else {
            this.$message.error('输入错误');
          }
        });
      } else if (this.$env === 'production') {
        try {
          await this.$request({
            url: 'https://leonardo.moreless.space/api/v1/admin/news',
            method: 'POST',
            data: form,
          });
          this.$message({
            message: '复制成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('复制失败');
        }
      }
    },
    save: async function() {
      const form = this.form;
      try {
        await this.$request({
          method: 'PUT',
          url: `/v1/admin/news/${this.$route.params.news_id}`,
          data: form,
        });
        this.$message({
          message: '保存成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(`保存失败, ${e.response.data.msg}`);
      }
    },
    init: async function() {
      const res = await this.$request({
        url: `/v1/admin/news/${this.$route.params.news_id}`,
      });
      this.form = Object.assign({}, this.form, res.data);

      // console.log(JSON.stringify(this.form, undefined, 2))
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

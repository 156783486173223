<template>
  <router-view v-if="$route.name !== 'Feedback'"></router-view>
  <div v-else>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="el-icon-service"></i>
          <h3 slot="title">传达室</h3>
          <p slot="desc">潮汐听取领导们批评的地方</p>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-head">
          <div class="container-head-title" style="line-height: 33px">意见反馈</div>
          <div class="container-body-header-extra" style="flex-grow: 1">
            <el-form class="filters" inline>
              <el-form-item>
                <el-cascader change-on-select expand-trigger="click" :options="appOptions" v-model="appFiltter" placeholder="App 信息" @change="activeItemChange"></el-cascader>
              </el-form-item>
              <el-form-item>
                <el-select v-model="marked" placeholder="星标">
                  <el-option label="星标: 全部" :value="null"></el-option>
                  <el-option label="已打星标" :value="true"></el-option>
                  <el-option label="未打星标" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="status" placeholder="状态">
                  <el-option label="状态: 全部" :value="null"></el-option>
                  <el-option label="未处理" :value="1"></el-option>
                  <el-option label="已处理" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker v-model="dates" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button @click="clearFiltters" type="text">清空筛选</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="container-body">
          <el-table :data="list" stripe style="width: 100%" v-infinite-scroll="fetchList" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
            <el-table-column label="意见反馈" width="640">
              <template slot-scope="scope">
                <el-popover placement="top-start" trigger="click">
                  <p class="feedback-content" style="white-space: pre-line; -webkit-box-orient: vertical" slot="reference">{{ scope.row.content }}</p>
                  <div style="padding: 8px">
                    <p style="max-width: 800px; white-space: pre-wrap; font-size: 14px; line-height: 21px; background-color: rgba(0, 0, 0, 0.08); padding: 16px">{{ scope.row.content }}</p>
                    <br />
                    <p>
                      <span v-if="scope.row.status === 1">未处理</span>
                      <span v-else style="color: #67c23a">已处理</span>
                    </p>
                    <p style="font-size: 11px; margin-top: 8px">
                      反馈用户：
                      <a target="_blank" :href="`/users/manager/${scope.row.user_id}/edit`">{{ scope.row.user_nickname }}</a>
                    </p>
                    <p v-if="scope.row.email" style="font-size: 11px; margin-top: 8px">
                      联系邮箱：
                      <mailto
                        :to="scope.row.email"
                        :nickname="scope.row.user_nickname"
                        :user-id="scope.row.user_id"
                        :platform="scope.row.platform"
                        :quote="scope.row.content"
                        :app-version="scope.row.app_version"
                        :system="scope.row.system"
                        :device="scope.row.device"
                        :created-at="scope.row.created_at"
                        >{{ scope.row.email }}</mailto
                      >
                    </p>
                    <p style="font-size: 11px; margin-top: 8px">反馈时间：{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</p>
                    <p style="font-size: 11px; margin-top: 8px">相关信息：{{ scope.row.app_name }} {{ scope.row.app_version }}, {{ scope.row.device }}, {{ scope.row.platform }} {{ scope.row.system }}</p>
                  </div>
                </el-popover>
                <br />
                <p style="font-size: 11px">
                  反馈用户：
                  <a target="_blank" :href="`/users/manager/${scope.row.user_id}/edit`">{{ scope.row.user_nickname }}</a>
                  <template v-if="scope.row.email">
                    <span style="margin-left: 16px">联系邮箱：</span>
                    <mailto
                      :to="scope.row.email"
                      :nickname="scope.row.user_nickname"
                      :user-id="scope.row.user_id"
                      :platform="scope.row.platform"
                      :quote="scope.row.content"
                      :app-version="scope.row.app_version"
                      :system="scope.row.system"
                      :device="scope.row.device"
                      :created-at="scope.row.created_at"
                      >{{ scope.row.email }}</mailto
                    >
                  </template>
                </p>
                <p style="font-size: 11px">反馈时间：{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</p>
              </template>
            </el-table-column>

            <el-table-column label="截图" align="center">
              <template slot-scope="scope">
                <span v-if="!scope.row.attached_images.length">无</span>
                <div v-else class="attached-images" :style="{ height: `${48 + 6 * scope.row.attached_images.length}px`, width: `${48 + 8 * scope.row.attached_images.length}px` }" @click="preview(scope.row.attached_images)">
                  <template v-for="(image, index) in scope.row.attached_images">
                    <video
                      v-if="scope.row.videoIndexs.includes(index)"
                      :key="`attached-video-${image}-${index}-${scope.row.created_at}`"
                      class="attached-image"
                      :style="{
                        left: `${8 * (scope.row.attached_images.length - index)}px`,
                        top: `${6 * (scope.row.attached_images.length - index)}px`,
                        zIndex: scope.row.attached_images.length - index,
                      }"
                      :src="image"
                    >
                      视频
                    </video>
                    <el-image
                      v-else
                      class="attached-image"
                      :style="{
                        left: `${8 * (scope.row.attached_images.length - index)}px`,
                        top: `${6 * (scope.row.attached_images.length - index)}px`,
                        zIndex: scope.row.attached_images.length - index,
                      }"
                      :key="`attached-image-${image}-${index}-${scope.row.created_at}`"
                      :src="image"
                      fit="cover"
                      @load="imageOnload(image)"
                      @error="imageOnloadFail(scope.row, index)"
                    ></el-image>
                  </template>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="相关信息">
              <template slot-scope="scope">
                <div style="font-size: 11px">
                  <p>{{ scope.row.app_name }} {{ scope.row.app_version }}</p>
                  <p>{{ scope.row.device }}</p>
                  <p>{{ scope.row.platform }} {{ scope.row.system }}</p>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status === 1">未处理</span>
                <span v-else style="color: #67c23a">已处理</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right" width="190px">
              <template slot-scope="scope">
                <el-button type="text" @click="review(scope.row)">查看</el-button>
                <el-button type="text" @click="toggleArchive(scope.row)">{{ scope.row.status === 1 ? '关闭' : '开启' }}</el-button>
                <el-button type="text" @click="toggleMarked(scope.row)">{{ scope.row.marked ? '取消星标' : '星标' }}</el-button>
                <el-button type="text" @click="translation(scope.row)">翻译</el-button>
                <a v-if="scope.row.log_file" class="el-button el-button--text" download="log.txt" :href="scope.row.log_file">日志</a>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-dialog title="谷歌翻译" :visible.sync="dialogsVisible.translation">
          <div style="padding: 0 16px; white-space: pre-line">{{ trText }}</div>

          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogsVisible.translation = false">关闭</el-button>
          </div>
        </el-dialog>

        <attacheds-previewer :show.sync="dialogsVisible.preview" :index.sync="activeIndex" :images="previewImages"></attacheds-previewer>
      </div>
    </container>
  </div>
</template>

<script>
// import _ from 'lodash'
import Container from '@/components/container';
import PageHeader from '@/pages/service/page-header';
import AttachedsPreviewer from './attacheds-previewer';
import Mailto from '@/components/Mailto';

const pickerOptions = {
  shortcuts: [
    {
      text: '最近一周',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      },
    },
    {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      },
    },
    {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      },
    },
  ],
};

export default {
  name: 'Feedback',
  components: {
    Container,
    PageHeader,
    AttachedsPreviewer,
    Mailto,
  },
  data() {
    return {
      inited: false,
      marked: null,
      status: null,
      from: null,
      to: null,

      pickerOptions,

      appOptions: [
        {
          value: null,
          label: 'App: 全部',
        },
      ],
      appFiltter: [],
      previewImages: [],
      activeIndex: 0,

      trText: '',
      busy: false,
      finish: false,
      error: false,
      list: [],
      dialogsVisible: {
        translation: false,
        preview: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish || this.error;
    },
    currentReview() {
      const review = this.list.find((item) => {
        return item.value.id === this.replyData.reviewId;
      });
      return review || { value: {} };
    },
    dates: {
      get() {
        const dates = [];
        if (this.from) {
          dates[0] = parseInt(this.from) * 1000;
        }
        if (this.to) {
          dates[1] = parseInt(this.to) * 1000;
        }
        return dates;
      },
      set(dates) {
        if (dates) {
          this.from = parseInt(+dates[0] / 1000);
          this.to = parseInt(+dates[1] / 1000);
        } else {
          this.from = null;
          this.to = null;
        }
      },
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'Feedback') {
        this.refetch();
      }
    },
    '$route.query': {
      deep: true,
      handler() {
        this.refetch();
      },
    },
    appFiltter() {
      if (this.inited) {
        this.refreshFilters();
      }
    },
    marked() {
      if (this.inited) {
        this.refreshFilters();
      }
    },
    status() {
      if (this.inited) {
        this.refreshFilters();
      }
    },
    from() {
      if (this.inited) {
        this.refreshFilters();
      }
    },
    to() {
      if (this.inited) {
        this.refreshFilters();
      }
    },
  },
  methods: {
    imageOnload() {},
    async imageOnloadFail(item, index) {
      item.videoIndexs.push(index);
    },
    async activeItemChange() {
      const values = this.appFiltter;
      let appOptions = this.appOptions;
      // const valsLength = values.length
      const appName = values[0];
      const platform = values[1];
      if (appName) {
        const { data: platforms } = await this.$request({
          url: '/v1/admin/feedbacks/app_platforms',
          params: {
            app_name: appName,
          },
        });
        const appNameOption = appOptions.find((option) => {
          return option.value === appName;
        });
        let platformOptions = [
          {
            label: '平台: 全部',
            value: null,
          },
        ];
        platformOptions = platformOptions.concat(
          platforms.map((platform) => {
            return {
              label: platform,
              value: platform,
              children: [],
            };
          }),
        );
        appNameOption.children = platformOptions;
      }
      if (platform) {
        const { data: versions } = await this.$request({
          url: '/v1/admin/feedbacks/app_versions',
          params: {
            app_name: appName,
            platform,
          },
        });
        const appNameOption = appOptions.find((option) => {
          return option.value === appName;
        });
        const platformOption = appNameOption.children.find((option) => {
          return option.value === platform;
        });
        let versionOptions = [
          {
            label: '版本: 全部',
            value: null,
          },
        ];
        versionOptions = versionOptions.concat(
          versions.map((version) => {
            return {
              label: version,
              value: version,
            };
          }),
        );
        platformOption.children = versionOptions;
      }
      this.appOptions = appOptions;
    },
    clearFiltters() {
      this.$router.replace({
        name: this.$route.name,
      });
    },
    refreshFilters() {
      const { appFiltter, marked, status, from, to } = this;
      const [appName, platform, appVersion] = appFiltter;
      this.$router.replace({
        name: this.$route.name,
        query: {
          app_name: appName || null,
          platform: platform || null,
          app_version: appVersion || null,
          marked: marked || null,
          status: status || null,
          from: from || null,
          to: to || null,
        },
      });
    },
    refreshItem: async function (reviewId) {
      const res = await this.$request({
        url: `http://sapi.tide.moreless.io/itc/${this.$route.params.appid}/reviews/${reviewId}`,
        withCredentials: false,
        auth: undefined,
      });
      const { data } = res.data;
      const review = data.reviews[0];
      const reviewIndex = this.list.findIndex((item) => {
        return item.value.id === review.value.id;
      });
      this.list.splice(reviewIndex, 1, review);
    },
    preview(urls) {
      this.activeIndex = 0;
      this.previewImages = urls;
      this.dialogsVisible.preview = true;
    },
    review(item) {
      this.$router.push({
        name: 'FeedbackReview',
        params: {
          feedback_id: item.id,
        },
      });
    },
    async toggleArchive(data) {
      let status = 1;
      if (data.status === 1) {
        status = 2;
      }
      try {
        await this.$request({
          url: `/v1/admin/feedbacks/${data.id}`,
          method: 'PATCH',
          data: {
            status,
          },
        });
        const feedback = this.list.find((item) => item.id === data.id);
        feedback.status = status;
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    async toggleMarked(data) {
      try {
        if (data.marked) {
          await this.$request({
            url: `/v1/admin/feedbacks/${data.id}/mark`,
            method: 'DELETE',
          });
        } else {
          await this.$request({
            url: `/v1/admin/feedbacks/${data.id}/mark`,
            method: 'PUT',
          });
        }
        const feedback = this.list.find((item) => item.id === data.id);
        feedback.marked = !feedback.marked;
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    async translation(data) {
      const { content } = data;

      const loading = this.$loading({
        lock: true,
        text: '翻译中',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.24)',
      });
      try {
        const res = await this.$request({
          method: 'POST',
          withCredentials: false,
          auth: undefined,
          url: 'http://sapi.tide.moreless.io/translate',
          data: {
            content,
          },
        });
        let trText = '';
        const rows = res.data[0];
        for (let row of rows) {
          trText += row[0];
        }
        this.trText = trText;
        this.dialogsVisible.translation = true;
      } catch (e) {
        this.$message.error('翻译失败');
      }
      loading.close();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetchList();
    },
    fetchList: async function () {
      const { appFiltter, marked, status, from, to } = this;
      const [appName, platform, appVersion] = appFiltter;
      this.busy = true;
      const limit = 50;
      const params = {
        offset: this.list.length,
        limit,
      };
      if (marked || marked === false) {
        params.marked = marked;
      }
      if (status) {
        params.status = status;
      }
      if (from) {
        params.from = from;
      }
      if (to) {
        params.to = to;
      }
      if (appName) {
        params.app_aame = appName;
      }
      if (platform) {
        params.platform = platform;
      }
      if (appVersion) {
        params.app_version = appVersion;
      }
      try {
        const res = await this.$request({
          url: '/v1/admin/feedbacks',
          params,
        });
        const list = res.data;
        list.forEach((item) => {
          item.videoIndexs = [];
        });
        if (list.length < limit) {
          this.finish = true;
        }
        this.list = [...this.list, ...list];
      } catch (e) {
        this.error = true;
      }
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    initFilters: async function () {
      const { app_name: appName, platform, app_version: appVersion, marked, status, from, to } = this.$route.query;
      this.appFiltter = [appName, platform, appVersion];
      this.marked = marked === 'true';
      this.status = isNaN(status) ? null : status === null ? null : parseInt(status);
      this.from = from;
      this.to = to;

      const appOptions = this.appOptions;
      const { data: appNames } = await this.$request({
        url: '/v1/admin/feedbacks/app_names',
      });
      appOptions.filter((option) => {
        return !appNames.includes(option.label);
      });

      const remoteOptions = appNames.map((appName) => {
        return {
          label: appName,
          value: appName,
          children: [],
        };
      });
      this.appOptions = appOptions.concat(remoteOptions);
      await this.activeItemChange();
      this.inited = true;
    },
  },
  created() {
    this.initFilters();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.el-button {
  font: 400 11px system-ui;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  max-width: 640px;
  flex-basis: 480px;
  flex-grow: 1;
}
.list-item:hover {
  .tr-btn {
    display: inline;
  }
}
.tr-btn {
  margin-left: 8px;
  display: none;
}

.filters {
  display: flex;

  justify-content: flex-end;
  margin-left: 32px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.feedback-content {
  font-size: 11px;
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-break: break-word;
  width: fit-content;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.infinite-scroll-disabled {
  text-align: center;
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.nickname {
  font-weight: 500;
}

.review {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 3;
  margin-top: 8px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.48);
}

.extra-infos p {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.32);
}

.attached-images {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}

.attached-image {
  position: absolute;
  top: 0;
  width: 48px;
  height: 48px;
  object-fit: fill;
  cursor: pointer;
  border: 1px solid #fff;
}

.attached-video {
  position: absolute;
  top: 0;
  width: 48px;
  height: 48px;
  object-fit: fill;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  background-color: #fff;
}
</style>

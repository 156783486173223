<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:05
 * @LastEditors  : Ruilong Li
 * @LastEditTime : 2020-02-06 14:59:39
 * @Description: 地区选择器
 * @FilePath: /davinci/src/components/regions-selecter.vue
 -->

<script>
import allRegions from '@/provinces.json';

const allRegionOptions = allRegions.map((value) => {
  return { value: value.english, label: value.name, disabled: false };
});

export default {
  name: 'ProvincesSelector',
  props: {
    value: Array,
    selected: Array,
  },
  data() {
    return {
      allProvinces: allRegionOptions,
    };
  },
  watch: {
    value(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<template>
  <el-select v-model="value" multiple clearable filterable default-first-option placeholder="请选择省份">
    <el-option v-for="item in allProvinces" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

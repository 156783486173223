<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
import { v4 as uuidv4 } from 'uuid';
export default {
  name: 'AddScene',
  components: {
    EquipForm: Form,
  },
  data() {
    const { category_type: categoryType } = this.$route.params;
    return {
      form: {
        id: '',
        status: 'draft',
        blocked_channels: [],
        name: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        category: categoryType,
        balance_type: '',
        fresh_until: 0,
        trial_ended_local_time: '',
        trial_started_local_time: '',
        trial_login_status: [],
        resource: {
          guid: uuidv4(),
          demo_sound_url: '',
          hash_key: '',
          hash: '',
          multichannel_hash_key: '',
          multichannel_hash: '',
          dolby_hash_key: '',
          dolby_hash: '',
        },
        primary_color: '0,0,0,1',
        image: '',
        video_cover_demo_url: '',
        video_cover_key: '',
        video_cover_center: '',
        purchase_info: {
          product_id: '',
          price: 0,
          description: '',
          alias: [],
        },
        description: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        description_author: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        sub_title: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        author: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        access: ['free'],
        simple_tag_ids: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      console.log('save');
      const sceneRes = await this.$request({
        url: `/v1/admin/market/scenes`,
        method: 'POST',
        data,
      });
      const scene = sceneRes.data;
      const { category_type: categoryType } = this.$route.params;
      this.$router.replace({
        name: 'EditScene',
        params: {
          scene_id: scene.id,
          category_type: categoryType,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:00
 * @LastEditTime : 2020-02-15 11:03:34
 * @LastEditors  : Ruilong Li
 * @Description: 探索页基础
 * @FilePath: /davinci/src/past-pages/explore/base/index.vue
 -->

<script>
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import AdBanners from '@/components/ad-banners';

// -----------------------------------------------------------
// MARK: - Class: ScenesAdBanners
// -----------------------------------------------------------
export default {
  name: 'ScenesAdBanners',

  components: {
    Container,
    PageHeader,
    AdBanners,
  },

  // -----------------------------------------------------------
  // MARK: - Props Data
  // -----------------------------------------------------------
  model: {
    prop: 'pageData',
    event: 'change',
  },
  props: {
    pageData: Object,
  },
  // -----------------------------------------------------------
  // MARK: - Views Data
  // -----------------------------------------------------------
  data() {
    return {};
  },

  // -----------------------------------------------------------
  // MARK: - Life Cycle
  // -----------------------------------------------------------
  created() {},

  methods: {},
};
</script>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">music_note</i>
        <h3 slot="title">声音场景推荐位管理</h3>
        <p slot="desc">仅支持 3.5.0 之后版本</p>
      </page-header>
    </div>
    <ad-banners slot="container" type="scene" :regions="['ALL']"></ad-banners>
  </container>
</template>

<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-wrapper",style:(`zoom:${_vm.scale}`),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"preview",style:({
      zoom: `${1 / _vm.scale}`,
      transform: `scale(${_vm.scale})`,
      fontFamily: `${_vm.quote.fontName}`,
    })},[_c('div',{staticClass:"canva"},[_c('div',{ref:"previewImage",staticClass:"preview-image",style:({
          backgroundImage: `url(${_vm.previewUrl})`,
          backgroundSize: `${_vm.cropXScale} ${_vm.cropYScale}`,
          backgroundPositionX: `${_vm.cropdx}`,
          backgroundPositionY: `${_vm.cropdy}`,
        })}),_c('div',{staticClass:"bg-cover"}),_vm._m(0),_c('div',{staticClass:"share-card",style:({
          backgroundImage: `url(${_vm.previewUrl})`,
          backgroundSize: `${_vm.cardCrop.positionX} ${_vm.cardCrop.positionY}`,
          backgroundPositionX: `${-_vm.cardCrop.dx}px`,
          backgroundPositionY: `${-_vm.cardCrop.dy}px`,
        })},[_c('div',{staticClass:"share-card-cover"}),_vm._m(1)]),_c('p',{staticClass:"footnoot"},[_vm._v("潮汐 · 和创造者一起专注")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"avatar",attrs:{"src":require("@/assets/avatar.jpg")}}),_c('div',{staticClass:"userInfo"},[_c('p',[_vm._v("啊喵")]),_c('p',[_vm._v("在潮汐第 6 天专注")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-card-content"},[_c('img',{staticClass:"share-card-icon",attrs:{"src":require("@/assets/glyph_target_line_white_64@3x.png")}}),_c('p',{staticClass:"period"},[_vm._v("今天专注")]),_c('p',{staticClass:"period-duration"},[_vm._v("10.3 小时")]),_c('div',{staticClass:"share-items"},[_c('div',{staticClass:"share-item"},[_c('label',[_vm._v("专注次数")]),_c('span',[_vm._v("3")])]),_c('div',{staticClass:"share-item"},[_c('label',[_vm._v("专心程度")]),_c('span',[_vm._v("88%")])]),_c('div',{staticClass:"share-item"},[_c('label',[_vm._v("最佳效率")]),_c('span',[_vm._v("20:00"),_c('i',[_vm._v("PM")])])])])])
}]

export { render, staticRenderFns }
<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="roles container-body">
        <el-form style="width: 100%" inline>
          <el-form-item label="用户名：">
            <el-input v-model="xForm.nickname" />
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input v-model="xForm.phone_number" />
          </el-form-item>
          <el-form-item label="权限：">
            <el-row>
              <el-checkbox :disabled="xForm.id === user_id" v-model="loginRole">登录</el-checkbox>
              <el-checkbox :disabled="!$store.getters.hasAdminRole" v-model="adminRole">网管</el-checkbox>
              <el-checkbox v-model="productionRole">修改线上</el-checkbox>
              <el-checkbox v-model="dailipicRole">日帖</el-checkbox>
              <el-checkbox v-model="scenesRole">内容管理</el-checkbox>
            </el-row>
            <el-row>
              <el-checkbox v-model="tidesleepRole">潮汐睡眠</el-checkbox>
              <el-checkbox v-model="adRole">活动管理</el-checkbox>
              <el-checkbox v-model="userRole">用户</el-checkbox>
              <el-checkbox v-model="devRole">开发</el-checkbox>
              <el-checkbox v-model="rikeRole">岛读</el-checkbox>
            </el-row>
            <el-row>
              <el-checkbox v-model="reviewsRole">用户反馈(废弃)</el-checkbox>
              <el-checkbox v-model="noticeRole">公告管理(废弃、合并到活动管理)</el-checkbox>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
const authentication = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return (roleCode & role) === role;
};
const setRole = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return parseInt((roleCode | role).toString(2));
};

const removeRole = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return parseInt((roleCode & ~role).toString(2));
};

export default {
  name: 'LessonForm',
  components: {
    Container,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = localStorage.getItem('env');
    return {
      loading: false,
      env,
      user_id: this.$store.getters.user_id,
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    loginRole: {
      get() {
        return authentication(this.xForm.old_role, 1);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 1);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 1);
        }
      },
    },
    adminRole: {
      get() {
        return authentication(this.xForm.old_role, 10);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 10);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 10);
        }
      },
    },
    productionRole: {
      get() {
        return authentication(this.xForm.old_role, 100);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 100);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 100);
        }
      },
    },
    dashboardRole: {
      get() {
        return authentication(this.xForm.old_role, 1000000000000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 1000000000000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 1000000000000);
        }
      },
    },
    reviewsRole: {
      get() {
        return authentication(this.xForm.old_role, 1000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 1000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 1000);
        }
      },
    },
    dailipicRole: {
      get() {
        return authentication(this.xForm.old_role, 10000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 10000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 10000);
        }
      },
    },
    scenesRole: {
      get() {
        return authentication(this.xForm.old_role, 100000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 100000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 100000);
        }
      },
    },
    tidesleepRole: {
      get() {
        return authentication(this.xForm.old_role, 1000000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 1000000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 1000000);
        }
      },
    },
    noticeRole: {
      get() {
        return authentication(this.xForm.old_role, 10000000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 10000000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 10000000);
        }
      },
    },
    adRole: {
      get() {
        return authentication(this.xForm.old_role, 100000000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 100000000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 100000000);
        }
      },
    },
    userRole: {
      get() {
        return authentication(this.xForm.old_role, 1000000000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 1000000000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 1000000000);
        }
      },
    },
    devRole: {
      get() {
        return authentication(this.xForm.old_role, 10000000000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 10000000000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 10000000000);
        }
      },
    },
    rikeRole: {
      get() {
        return authentication(this.xForm.old_role, 100000000000);
      },
      set(val) {
        if (val) {
          this.xForm.old_role = setRole(this.xForm.old_role, 100000000000);
        } else {
          this.xForm.old_role = removeRole(this.xForm.old_role, 100000000000);
        }
      },
    },
  },
  watch: {},
  methods: {
    save() {
      this.$emit('save', this.xForm);
    },
  },
  created() {
    console.log(this.user_id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.roles {
  display: flex;
  flex-direction: row;
}
</style>

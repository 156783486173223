<template>
  <router-view v-if="$route.name !== 'RikeUsersQuery'"></router-view>
  <div v-else>
    <container>
      <div slot="container" class="container">
        <div class="container-head">
          <div class="container-head-title">用户查询</div>
        </div>
        <el-form class="form container-body" v-model="form" label-position="right" label-width="100px" style="padding-top: 36px" @submit.native.prevent="submit">
          <el-form-item label="用户ID：">
            <el-input v-model="form.userid" class="userid-input" name="userid" placeholder="请输入要查看的用户ID"></el-input>
          </el-form-item>
          <el-form-item label>
            <el-button type="primary" native-type="submit" size="small" class="apply save-btn">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </container>
  </div>
</template>

<script>
import _ from 'lodash';
import Container from '@/components/container';

export default {
  name: 'UsersManager',
  components: {
    Container,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      baseRikeUrl,
      searchQuery: {
        userid: '',
      },
      form: {
        userid: '',
      },
    };
  },
  computed: {},
  methods: {
    async submit() {
      this.searchQuery = Object.assign({}, _.cloneDeep(this.form));
      const userid = this.searchQuery.userid;
      if (userid) {
        try {
          const res = await this.$request({
            url: `${this.baseRikeUrl}/v1/admin/users/${userid}`,
          });
          console.log(res);
          this.$router.push({
            name: 'RikeUserComments',
            params: {
              user_id: userid,
            },
          });
        } catch (e) {
          this.$message.error(e.response.data.msg);
        }
      }
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.userid-input {
  width: 180px;
}
</style>

<template>
  <div class="sub-nav">
    <div class="header">
      <div class="sub-nav-left"></div>
      <div class="sub-nav-right">
        <el-dropdown @command="selectEnv" trigger="click">
          <span class="el-dropdown-link">
            {{ $env === 'production' ? '正式环境' : '开发环境' }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="production">正式环境</el-dropdown-item>
            <el-dropdown-item command="dev">开发环境</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="text" style="margin-left: 24px" @click="logout">注销</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubNav',
  data() {
    const env = this.$env;
    return {
      env,
    };
  },
  methods: {
    selectEnv(env) {
      if (env === 'production') {
        location.replace(location.href.replace(location.hostname, 'davinci.moreless.space'));
      } else {
        location.replace(location.href.replace(location.hostname, 'leonardo.moreless.space'));
      }
    },
    async logout() {
      try {
        await this.$request({
          url: '/v1/admin/logout',
          method: 'POST',
        });
      } catch (e) {
        console.log(e);
      }
      location.href = '/login';
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 12px 0 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.sub-nav-right {
  padding: 22px 0;
}
.el-dropdown-link {
  cursor: pointer;
}
</style>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">报告页推荐配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item label="推荐内容：">
          <el-col>
            <div class="cascader-container">
              <el-button type="text"> {{ this.displayName }} </el-button>
              <el-cascader class="cascader" v-model="contentCascaderValues" :options="contentCascaderOptions" :props="contentCascaderProps"></el-cascader>
            </div>
          </el-col>
        </el-form-item>

        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.description[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="7">
                <el-form-item label="推荐语">
                  <el-input v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp()">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="推荐位置">
          <el-select v-model="xForm.location" placeholder="推荐位置" required>
            <el-option v-for="item in locationOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <div v-if="xForm.location === 'focus'">
          <el-form-item label="当日专注次数大于等于">
            <el-input-number :step="1" v-model="xForm.condition.current_day_focus_times_gte"></el-input-number>
          </el-form-item>
          <el-form-item label="当日专注时长大于等于（分钟）">
            <el-input-number :step="1" v-model="xForm.condition.current_day_focus_duration_gte"></el-input-number>
          </el-form-item>
          <el-form-item label="单次专注时长大于等于（分钟）">
            <el-input-number :step="1" v-model="xForm.condition.current_focus_duration_gte"></el-input-number>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import priceOptions from '@/store/prices.json';
import Container from '@/components/container';
import AudioUploader from '@/components/audio-uploader';
import OpenCC from '@/plugins/opencc';

export default {
  name: 'LessonForm',
  components: {
    Container,
    AudioUploader,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      displayName: '',
      contentCascaderOptions: [],
      contentCascaderProps: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value, pathNodes } = node;
          if (level === 0) {
            resolve(this.getContentType());
          } else if (level === 1) {
            resolve(await this.getTags(value));
          } else {
            resolve(await this.getContent(pathNodes[0].value, value));
          }
        },
      },

      loading: false,
      albumOptions: [],
      langs: require('@/langs.json'),
      locationOptions: [
        { label: '小憩报告页', value: 'nap' },
        { label: '睡眠报告页', value: 'sleep' },
        { label: '专注报告页', value: 'focus' },
      ],
    };
  },
  watch: {
    'xForm.meditation_album_id'() {
      console.log('changed');
      this.getDisplayName();
    },
  },
  computed: {
    xForm: {
      get() {
        console.log(this.form);
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    contentCascaderValues: {
      get() {
        const type = this.xForm.type;
        let content = type === 'scene' ? this.xForm.scene : type === 'mixed_scene' ? this.xForm.mixed_scene : type === 'meditation' ? this.xForm.meditation : type === 'article' ? this.xForm.article : type === 'sleep_story' ? this.xForm.sleep_story : null;
        let tagId = null;
        let contentId = null;
        if (content) {
          if (content.tags_v2 && content.tags_v2.length) {
            tagId = content.tags_v2[0].id;
          }
          if (content.id) {
            contentId = content.id;
          }
        }

        return [type, tagId, contentId];
      },
      set(values) {
        if (values.length !== 3) {
          this.$message.error('必须选择内容');
        } else {
          const [type, , content] = values;
          console.log(values);

          if (type === 'scene') {
            this.xForm.type = type;
            this.xForm.scene_id = content.id;
          } else if (type === 'mixed_scene') {
            this.xForm.type = type;
            this.xForm.mixed_scene_id = content.id;
          } else if (type === 'meditation') {
            this.xForm.type = type;
            this.xForm.meditation_album_id = content.id;
          } else if (type === 'article') {
            this.xForm.type = type;
            this.xForm.article_id = content.id;
          } else if (type === 'sleep_story') {
            this.xForm.type = type;
            this.xForm.sleep_story_id = content.id;
          }
        }
      },
    },
  },
  methods: {
    // Level 0
    getContentType() {
      return [
        /*
        {
          value: 'scene',
          label: '声音场景',
          leaf: false
        },
        {
          value: 'mixed_scene',
          label: '混音场景',
          leaf: false
        },
        */
        {
          value: 'meditation',
          label: '冥想练习',
          leaf: false,
        },
        /*
        {
          value: 'article',
          label: '图文内容',
          leaf: false
        },
        {
          value: 'sleep_story',
          label: '睡前故事',
          leaf: false
        }
        */
      ];
    },
    // Level 1
    async getTags(type) {
      // share the same tags between scenes and mixed scenes
      if (type == 'mixed_scene') {
        type = 'scene';
      }
      this.tagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type },
      });
      const tags = res.data.map((tag) => {
        return {
          value: tag,
          label: this.getTR(tag.name),
        };
      });
      return tags;
    },

    // Level 2
    async getContent(type, tag) {
      if (type === 'meditation') {
        return this.getMeditationContents(tag);
      } else if (type === 'scene') {
        return this.getSceneContents(tag);
      } else if (type === 'mixed_scene') {
        return this.getMixedSceneContents(tag.id);
      } else if (type === 'article') {
        return this.getArticleContents(tag.id);
      } else if (type === 'sleep_story') {
        return this.getSleepStoryContents(tag.id);
      }
    },

    async getMeditationContents(tag) {
      const { data: list } = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      let filterList = list.filter((item) => {
        return tag.meditation_ids.some((v) => v === item.id);
      });
      return filterList.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    async getSceneContents(tagId) {
      const { data: list } = await this.$request({
        url: '/v1/admin/scenes',
        params: {
          offset: 0,
          limit: 512,
          type: 'normal',
        },
      });
      let filterList = list.filter((item) => {
        return item.tags_v2.some((tag) => tag.id === tagId);
      });
      return filterList.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    async getMixedSceneContents(tagId) {
      const { data: list } = await this.$request({
        url: '/v1/admin/scenes',
        params: {
          offset: 0,
          limit: 512,
          type: 'mixed',
        },
      });
      let filterList = list.filter((item) => {
        return item.tags_v2.some((tag) => tag.id === tagId);
      });
      return filterList.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    async getArticleContents(tagId) {
      const { data: list } = await this.$request({
        url: '/v1/admin/meditation/articles',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      let filterList = list.filter((item) => {
        return item.tags_v2 && item.tags_v2.some((tag) => tag.id === tagId);
      });
      return filterList.map((item) => {
        return { value: item, label: this.getTR(item.title), leaf: true };
      });
    },

    async getSleepStoryContents(tagId) {
      const { data: list } = await this.$request({
        url: '/v1/admin/sleep_stories',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      let filterList = list.filter((item) => {
        return item.tags && item.tags.some((tag) => tag.id === tagId);
      });
      return filterList.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    async save() {
      this.$emit('save', this.form);
    },
    async fetchAlbums() {
      this.albumOptions = [];
      console.log('albumOptions', this.albumOptions);
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: { offset: 0, limit: 999 },
      });
      this.albumOptions = res.data.map((album) => {
        return {
          value: album.id,
          label: this.getTR(album.name),
        };
      });
    },
    async s2twp() {
      try {
        this.form.description['zh-Hant'] = this.opencc(this.form.description['zh-Hans']);
      } catch (e) {
        console.log(e);
        this.$message.error('转换失败');
      }
    },
    async getName() {
      if (!this.xForm.meditation_album_id) {
        return '未知';
      }
      switch (this.xForm.type) {
        case 'scene':
        // return `声音场景 · ${getName(xForm.scene_id) || '未设置'}`
        case 'mixed_scene':
        // return `混音场景 · ${getName(xForm.mixed_scene_id) || '未设置'}`
        case 'meditation':
          const res = await this.$request({
            url: `/v1/admin/meditation/albums/${this.xForm.meditation_album_id}`,
          });
          return `冥想练习 · ${this.getTR(res.data.name) || '未设置'}`;
        case 'article':
        // return `图文 · ${getName(xForm.article_id) || '未设置'}`
        case 'sleep_story':
        // return `睡前故事 · ${getName(xForm.sleep_story_id) || '未设置'}`
        default:
          return '未知';
      }
    },
    async getDisplayName() {
      this.displayName = await this.getName();
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created: async function () {
    this.fetchAlbums();
    this.getDisplayName();
    this.opencc = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.soundIcon img {
  background-color: rgba(0, 0, 0, 0.16);
}

.uploadIcon {
  display: inline-block;
}

.price-card {
  margin-top: 24px;
  padding: 0 24px 24px;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.diff {
  color: rgba(230, 162, 60, 1) !important;
  background: rgba(230, 162, 60, 0.12);
}

.scene-tag {
  margin-right: 16px;
  cursor: default;
}

.cascader-container {
  display: inline-block;
  position: relative;
}

.cascader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
</style>

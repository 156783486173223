<template>
  <div class="container">
    <div class="container-head">
      <div class="container-head-title">标签列表</div>
      <div class="container-head-extra">
        <el-button
          :disabled="!$store.getters.hasEditRole"
          type="text"
          size="mini"
          @click="
            sortList = list;
            dialogDisplay.tagsSortPanel = true;
          "
          >重新排序</el-button
        >
        <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="gotoAdder">新建标签</el-button>
      </div>
    </div>
    <div class="container-body">
      <el-table :data="list" stripe style="width: 100%">
        <el-table-column label="Id">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="排序" width="100px" align="left">
          <template slot-scope="scope">{{ scope.row.sort_key + 1 }}</template>
        </el-table-column>
        <el-table-column label="Key">
          <template slot-scope="scope">{{ scope.row.key }}</template>
        </el-table-column>
        <el-table-column label="英文">
          <template slot-scope="scope">{{ scope.row.name['en'] }}</template>
        </el-table-column>
        <el-table-column label="简体中文">
          <template slot-scope="scope">{{ scope.row.name['zh-Hans'] }}</template>
        </el-table-column>
        <el-table-column label="繁体中文">
          <template slot-scope="scope">{{ scope.row.name['zh-Hant'] }}</template>
        </el-table-column>
        <el-table-column label="日语">
          <template slot-scope="scope">{{ scope.row.name['ja'] }}</template>
        </el-table-column>
        <el-table-column label="西语">
          <template slot-scope="scope">{{ scope.row.name['es'] }}</template>
        </el-table-column>
        <el-table-column label="俄语">
          <template slot-scope="scope">{{ scope.row.name['ru'] }}</template>
        </el-table-column>
        <el-table-column label="韩语">
          <template slot-scope="scope">{{ scope.row.name['ko'] }}</template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">{{ getStatus(scope.row.status) }} </template>
        </el-table-column>
        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="gotoEditor(scope.row)">编辑</el-button>
            <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeTag(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="标签排序" width="40%" :visible.sync="dialogDisplay.tagsSortPanel">
      <draggable class="sort-list-container" v-model="sortList">
        <div class="sort-item" v-for="(tag, index) in sortList" :key="tag.id">
          <div class="sort-item-key">{{ index + 1 }}</div>
          <div class="sort-item-name">{{ getTR(tag.name) }}</div>
        </div>
      </draggable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveSort">保 存</el-button>
        <el-button @click="dialogDisplay.tagsSortPanel = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'Tags',
  components: {
    Draggable,
  },
  props: {
    type: String,
  },
  data() {
    return {
      list: [],
      sortList: [],
      busy: false,
      finish: false,
      dialogDisplay: {
        tagsSortPanel: false,
      },
    };
  },

  // -----------------------------------------------------------
  // MARK: - Computer Properties
  // -----------------------------------------------------------
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },

  methods: {
    async saveSort() {
      this.list = this.sortList;
      await this.save();
    },
    async save() {
      const data = this.list.map((tag, index) => {
        return Object.assign({}, tag, { sort_key: index });
      });
      try {
        await this.$request({
          url: '/v2/admin/object_tags',
          method: 'PUT',
          data,
        });
        this.list = data;
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    gotoAdder() {
      let routerName = 'ExploreTagsAdder';
      switch (this.type) {
        case 'meditation':
          routerName = 'MedTagsAdder';
          break;
        case 'scene':
          routerName = 'ScenesTagsAdder';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryTagsAdder';
          break;
        case 'article':
          routerName = 'ArticleTagsAdder';
          break;
      }

      this.$router.push({
        name: routerName,
      });
    },

    gotoEditor(tagInfo) {
      let routerName = 'ExploreTagsEditor';
      switch (this.type) {
        case 'meditation':
          routerName = 'MedTagsEditor';
          break;
        case 'scene':
          routerName = 'ScenesTagsEditor';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryTagsEditor';
          break;
        case 'article':
          routerName = 'ArticleTagsEditor';
          break;
      }

      this.$router.push({
        name: routerName,
        params: {
          tag_id: tagInfo.id,
        },
      });
    },

    async removeTag(tagInfo) {
      try {
        await this.$confirm('确定要删除该标签吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.$request({
          url: `/v2/admin/object_tags/${tagInfo.id}`,
          method: 'DELETE',
        });
        this.list = this.list.filter(item => item.id !== tagInfo.id);
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        if (e !== 'cancel') {
          this.$message.error(e.message);
        }
      }
    },

    init() {
      this.refreshList();
    },

    async refreshList() {
      this.list = [];
      await this.fetchList();
    },

    async fetchList() {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: {
          type: this.type,
        },
      });
      this.list = res.data;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      switch (status) {
        case 'published':
          return '已上架';
        case 'silent':
          return '静默上架';
        case 'reviewing':
          return '审阅中';
        case 'draft':
          return '草稿';
      }
      return '';
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import Request from '@/plugins/request';
import Env from '@/plugins/env';
import InfiniteScroll from 'vue-infinite-scroll';
import VueScrollTo from 'vue-scrollto';
import VueMoment from 'vue-moment';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Affix from 'vue-affix';

import Contenteditable from 'vue-contenteditable';
import VueQuillEditor from 'vue-quill-editor';
import { VueJsonp } from 'vue-jsonp';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/material-icons/material-icons.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'iview/dist/styles/iview.css';

// import 'highlight.js/styles/default.css'
// import Highlight from 'vue-markdown-highlight'
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';
// import 'highlight.js/styles/monokai-sublime.css'
import VueClipboard from 'vue-clipboard2';

Element.Input.methods.handleInput = function (event) {
  let value = event.target.value;
  if (this.isComposing) return;
  if (value === this.nativeInputValue) return;
  if (value.includes(' ')) {
    value = value.replace(/\s/g, ' ');
    this.getInput().value = value;
  }
  this.$emit('input', value);
  this.$nextTick(this.setNativeInputValue);
};

// eslint-disable-next-line no-extend-native
String.prototype.format = function (args) {
  let result = this;
  if (arguments.length < 1) {
    return result;
  }

  let data = arguments;
  if (arguments.length === 1 && typeof args === 'object') {
    data = args;
  }
  for (let key in data) {
    let value = data[key];
    if (undefined !== value) {
      result = result.replace(`{${key}}`, value);
    }
  }
  return result;
};

const moment = extendMoment(Moment);

Vue.use(InfiniteScroll);
Vue.use(VueScrollTo);
Vue.use(VueMoment, { moment });
Vue.use(Affix);
Vue.use(Element);
Vue.use(Contenteditable);
Vue.use(VueHighlightJS);
Vue.use(VueQuillEditor);
Vue.use(Request);
Vue.use(Env);
Vue.use(VueJsonp);
Vue.config.productionTip = false;
Vue.use(VueClipboard);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App },
});

<template>
  <div class="user-login-container">
    <div>
      <div class="brand">
        <img class="logo" src="~@/assets/logo.png" />
        <div class="site-name">达芬奇</div>
      </div>
      <div class="site-desc">潮汐发明创作研究所</div>
    </div>
    <div v-if="isSafari">
      <h1>由于技术限制，Safari不支持测试/正式环境复制功能。如需此功能请使用Chrome或其他浏览器，或手动修改设置允许跨域Cookie</h1>
    </div>
    <el-form @submit.native.prevent="onSubmit">
      <el-form-item>
        <el-col :span="6">
          <el-input name="nationcode" :disabled="Boolean(remain) || smsCodeRequesting" v-model="nationcode" placeholder="区号"></el-input>
        </el-col>
        <el-col :span="18">
          <el-input name="phonenumber" :disabled="Boolean(remain) || smsCodeRequesting" v-model="phonenumber" placeholder="手机号"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="24">
          <div class="sms-code">
            <el-input name="smsCode" v-model="smsCode" placeholder="验证码"></el-input>
            <el-button class="request-sms-code-btn" :disabled="Boolean(remain) || smsCodeRequesting || !phonenumber" @click="requestLoginSmsCode">{{ remain ? `重新获取 (${remain})` : smsCodeRequested ? '重新获取' : '获取验证码' }}</el-button>
          </div>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit" class="login-btn">登 录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    const $date = this.$moment(new Date());
    const year = $date.format('YYYY');
    const month = $date.format('MM');
    return {
      year: year,
      month: month,
      defaultOpeneds: [],
      currentRoute: '',
      smsCodeRequesting: false,
      smsCodeRequested: false,
      nationcode: '86',
      phonenumber: '',
      smsCode: '',
      remain: 0,
    };
  },
  watch: {
    $route(to) {
      this.currentRoute = to.path;
      this.defaultOpeneds = to.matched.map((route) => {
        return route.path;
      });
    },
    nationcode() {
      this.smsCodeRequested = false;
    },
    phonenumber() {
      this.smsCodeRequested = false;
    },
  },
  computed: {
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
  },
  created() {
    this.currentRoute = this.$route.path;
    this.defaultOpeneds = this.$route.matched.map((route) => {
      return route.path;
    });
  },
  methods: {
    countDown(remain) {
      remain = remain - 1;
      this.remain = remain;
      if (remain) {
        setTimeout(() => {
          this.countDown(remain);
        }, 1000);
      }
    },
    async requestLoginSmsCode() {
      try {
        this.smsCodeRequesting = true;
        await this.$request({
          url: '/v1/admin/sms',
          method: 'POST',
          data: {
            phone_number: this.phonenumber,
            nation_code: this.nationcode,
          },
        });
        this.smsCodeRequested = true;
        this.countDown(60);
      } catch (e) {
        this.$message.error(e.rawMessage);
      }
      this.smsCodeRequesting = false;
    },
    async onSubmit() {
      try {
        await this.$request({
          url: '/v1/admin/login',
          method: 'POST',
          data: {
            phone_number: this.phonenumber,
            code: this.smsCode,
          },
        });
        const redirect = this.$route.query.redirect;
        if (redirect) {
          location.href = redirect;
        } else {
          location.href = '/';
        }
      } catch (e) {
        this.$message.error(e.rawMessage);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.user-login-container {
  width: 368px;
  margin: 0 auto;
  flex-grow: 0;
  padding-top: 72px;
}

.brand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 48px;
  height: 48px;
}

.site-name {
  font-size: 32px;
  font-weight: 600;
}

.site-desc {
  margin: 12px 0 40px;
  font-size: 13px;
  text-align: center;
  color: rgba(0, 0, 0, 0.48);
}

.sms-code {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.request-sms-code-btn {
  margin-left: 16px;
}

.login-btn {
  width: 100%;
}

.reg-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.reg-qrcode {
  width: 240px;
  height: 240px;
}
</style>

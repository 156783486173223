<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">推荐位配置</div>
      </div>
      <el-form class="form container-body" v-model="form">
        <el-form-item label="名称">
          <el-input v-model="form.activity_name"></el-input>
        </el-form-item>

        <el-form-item>
          <el-tabs type="border-card" v-model="activeLang">
            <el-tab-pane v-for="lang in langs" :key="lang" :label="lang" :name="lang">
              <el-col :span="23">
                <el-form-item label="跳转链接">
                  <el-input v-model="form.content[lang].scheme"></el-input>
                </el-form-item>
              </el-col>

              <el-col>
                <el-form-item label="图层（可拖动排序，第一张为底图）">
                  <el-col :span="24">
                    <draggable class="layers">
                      <div class="preview layer-preview" v-for="(layer, index) in form.content[lang].layers" :key="layer.image">
                        <img class="preview-img" :src="layer.image" />
                        <div class="preview-img-alignment">
                          <el-select v-model="layer.align" size="mini">
                            <el-option value="left" label="左对齐"></el-option>
                            <el-option value="center" label="居中"></el-option>
                            <el-option value="right" label="右对齐"></el-option>
                          </el-select>
                        </div>
                        <div class="layer-actions">
                          <i class="material-icons" @click="previewLayer(index)">remove_red_eye</i>
                          <i class="material-icons" @click="removeLayer(index)">delete</i>
                        </div>
                      </div>
                    </draggable>
                    <div class="uploader-container">
                      <el-upload action="https://upload.qiniup.com" :on-progress="uploadLayerProgress" :on-error="uploadLayerError" :on-success="uploadLayerSuccess" :before-upload="beforeUploadLayer" :data="{ token: picToken }" :show-file-list="false">
                        <div class="uploadPic">
                          <i class="el-icon-upload2"></i>
                          <span>上传图层</span>
                        </div>
                      </el-upload>
                    </div>
                  </el-col>
                  <el-col v-if="lang === 'en'">
                    <el-button type="text" @click="syncDataToOtherLang">将数据覆盖至其他语言</el-button>
                  </el-col>
                </el-form-item>

                <el-form-item label="预览">
                  <el-col :span="24">
                    <div class="layer-container">
                      <img v-for="(layer, index) in form.content[lang].layers" :class="`layer ${layer.align}`" :style="{ zIndex: index + 1 }" :key="layer.image" :src="layer.image" />
                    </div>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="上架地区：">
          <regions-selecter v-model="form.regions"></regions-selecter>
        </el-form-item>

        <el-form-item label="会员状态：">
          <vip-status-selecter v-model="form.vip_status"></vip-status-selecter>
        </el-form-item>

        <el-form-item label="登录状态：">
          <login-status-selecter v-model="form.login_status_v2"></login-status-selecter>
        </el-form-item>

        <el-form-item label="状态：">
          <el-radio-group v-model="form.status">
            <el-radio label="published">发布</el-radio>
            <el-radio label="reviewing">审阅</el-radio>
            <el-radio label="draft">草稿</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="iOS上架版本：">
          <el-input-tag v-model="form.ios_versions"></el-input-tag>
        </el-form-item>
        <el-form-item label="Android上架版本：">
          <el-input-tag v-model="form.android_versions"></el-input-tag>
        </el-form-item>

        <el-form-item label="推荐位位置：">
          <span>第</span>
          <el-input-number v-model="form.row_position" size="mini" :min="0" style="display: inline-block; width: 100px; margin: 0 8px"></el-input-number>
          <span>行</span>
        </el-form-item>

        <el-form-item label="样式：">
          <el-radio-group v-model="form.style">
            <el-radio label="full">通栏</el-radio>
            <el-radio label="full_large">通栏（大）</el-radio>
            <el-radio label="with_margin">卡片</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="分类：" v-if="showObjectTag">
          <el-select v-model="form.object_tag_keys" multiple filterable placeholder="选择分类" style="width: 50%">
            <el-option v-for="item in objectTagOptions" :key="item.value.key" :label="item.value.type + ' | ' + item.value.key + ' | ' + getTR(item.value.name) + ' | ' + getStatus(item.value.status)" :value="item.value.key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-dialog title="查看" :visible.sync="dialogDisplay.layerPreview">
        <img v-if="dialogDisplay.layerPreviewIndex >= 0" class="preview-dialog-img" :src="form.content[activeLang].layers[dialogDisplay.layerPreviewIndex].image" />
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import Draggable from 'vuedraggable';
import RegionsSelecter from '@/components/regions-selecter';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';
import ElInputTag from '@/components/el-input-tag';
import allStatus from '@/status.json';

export default {
  name: 'AdBannerForm',
  components: {
    Container,
    Draggable,
    RegionsSelecter,
    ElInputTag,
    VipStatusSelecter,
    LoginStatusSelecter,
  },
  model: {
    prop: 'form',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = this.$env;
    return {
      loading: false,
      env,
      langs: require('@/langs.json'),
      activeLang: 'zh-Hans',
      objectTagOptions: [],

      busy: false,
      finish: false,
      keyword: '',
      picToken: '',
      picCDN: '',

      dialogDisplay: {
        layerPreview: false,
        layerPreviewIndex: -1,
      },
    };
  },
  computed: {
    showObjectTag() {
      const showObjectTags = ['scene', 'meditation'];
      return showObjectTags.includes(this.form.type);
    },
  },
  watch: {
    'form.style'(val) {
      if (val === 'full_large' && !this.form.ios_versions) {
        this.form.ios_versions = ['3.21.0+'];
      }
    },
  },
  methods: {
    save() {
      this.$emit('save', this.form);
    },
    previewLayer(index) {
      this.dialogDisplay.layerPreviewIndex = index;
      this.dialogDisplay.layerPreview = true;
    },
    removeLayer(index) {
      this.dialogDisplay.layerPreviewIndex = -1;
      this.form.content[this.activeLang].layers.splice(index, 1);
    },
    syncDataToOtherLang() {
      const { layers, scheme } = this.form.content['en'];
      this.form.content['zh-Hans'].layers = layers;
      this.form.content['zh-Hans'].scheme = scheme;
      this.form.content['zh-Hant'].layers = layers;
      this.form.content['zh-Hant'].scheme = scheme;
      this.form.content['ja'].layers = layers;
      this.form.content['ja'].scheme = scheme;
      this.form.content['ko'].layers = layers;
      this.form.content['ko'].scheme = scheme;
      this.form.content['es'].layers = layers;
      this.form.content['es'].scheme = scheme;
      this.form.content['ru'].layers = layers;
      this.form.content['ru'].scheme = scheme;
      this.$message({
        message: '覆盖成功，请手动保存',
        type: 'success',
        duration: 2000,
      });
    },
    async fetchObjectTag() {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: this.form.type },
      });

      this.objectTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },

    // -----------------------------------------------------------
    // MARK: - 上传图层
    // -----------------------------------------------------------
    beforeUploadLayer: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadLayerSuccess(response) {
      this.form.content[this.activeLang].layers.push({
        image: `${this.picCDN}/${response.key}`,
        align: 'center',
      });
      this.layerUploading = false;
    },
    uploadLayerError() {
      this.layerUploading = false;
    },
    uploadLayerProgress() {
      this.layerUploading = true;
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  created() {
    this.fetchObjectTag();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.layers {
  display: inline-flex;
}

.uploader-container {
  display: inline-flex;
  vertical-align: top;
}

.layer-container {
  position: relative;
  width: 480px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #ccc;
}

.layer {
  position: absolute;
  max-width: 480px;
  max-height: 160px;
  object-fit: cover;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  line-height: 28px;
}
.preview:hover .layer-actions {
  opacity: 1;
}
.layer-actions {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);

  i {
    font-size: 16px;
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.48);
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 0.72);
    }
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
</style>

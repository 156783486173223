<template>
  <router-view v-if="$route.name !== 'RikeContribute'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-head">
          <div class="container-head-title">投稿箱</div>
          <div class="container-head-extra">
            <el-radio-group v-model="filterStatus" size="medium">
              <el-radio-button :label="0">未查看</el-radio-button>
              <el-radio-button :label="1">不考虑</el-radio-button>
              <el-radio-button :label="2">备选</el-radio-button>
              <el-radio-button :label="3">已使用</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="container-body" v-infinite-scroll="fetchList" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="500">
          <div v-if="!list.length">
            <div class="list-item" style="justify-content: center">暂无结果</div>
          </div>
          <div v-else>
            <div class="list-item" v-for="item in list" :key="item.id">
              <div class="list-main-item">
                <div class="list-item-name">{{ item.content }}</div>
              </div>
              <div>
                <div>Date</div>
                <div>{{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              </div>
              <div class="list-item-actions">
                <el-select v-model="item.status" size="mini" placeholder="请选择" @change="setItem(item)">
                  <el-option :value="0" label="未查看"></el-option>
                  <el-option :value="1" label="不考虑"></el-option>
                  <el-option :value="2" label="备选"></el-option>
                  <el-option :value="3" label="已使用"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'TidetimeManager',
  components: {
    Container,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      filterStatus: 0,
      busy: false,
      finish: false,
      list: [],
      baseRikeUrl,
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    defaultActive() {
      return `/tidetime/manager/2018-${this.$route.params.month}`;
    },
  },
  watch: {
    filterStatus() {
      this.refetch();
    },
  },
  methods: {
    async setItem(item) {
      const { id, status } = item;
      try {
        await this.$request({
          method: 'PUT',
          url: `${this.baseRikeUrl}/v1/admin/recommend_articles/${id}/status`,
          data: { status },
        });
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    deleteItem(item) {
      this.$confirm('删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const itemId = item.comment.id;
        await this.$request({
          method: 'DELETE',
          url: `${this.baseRikeUrl}/v1/admin/comments/${itemId}`,
        });
        const list = this.list.filter((item) => {
          return item.comment.id !== itemId;
        });
        this.list = list;
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    refetch() {
      this.list = [];
      this.finish = false;
    },
    fetchList: async function () {
      this.busy = true;
      const limit = 50;
      const params = {
        status: this.filterStatus,
        offset: this.list.length,
        limit,
      };
      const res = await this.$request({
        url: `${this.baseRikeUrl}/v1/admin/recommend_articles`,
        params,
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  flex-basis: 240px;
  text-align: right;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 10px;
  }
}
</style>

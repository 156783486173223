<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">场景配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.name[lang] && xForm.sub_title[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="7">
                <el-form-item label="场景名">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="15" :offset="1">
                <el-form-item label="一句话描述">
                  <el-input v-model="xForm.sub_title[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="创作者">
                  <el-input v-model="xForm.creator[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp()">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item>
          <div slot="label" class="form-label">
            <span>创作者头像</span>
            <i class="el-icon-edit" @click="dialogDisplay.avatarEdit = true"></i>
          </div>
          <el-col>
            <div class="preview" v-if="xForm['creator_avatar']" @click="dialogDisplay.avatarPreview = true">
              <img class="preview-img" :src="xForm['creator_avatar']" />
              <div class="preview-img-cover">
                <i class="material-icons">remove_red_eye</i>
                <span>预览</span>
              </div>
            </div>
            <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadAvatarProgress" :on-error="uploadAvatarError" :on-success="uploadAvatarSuccess" :before-upload="beforeUploadAvatar" :data="{ token: picToken }" :show-file-list="false">
              <div class="uploadPic">
                <i class="el-icon-upload2"></i>
                <span>上传头像</span>
              </div>
            </el-upload>
          </el-col>
        </el-form-item>

        <el-form-item label="快速填充">
          <el-input type="textarea" :rows="2" v-model="shortcut"></el-input>
        </el-form-item>

        <el-form-item label="场景状态">
          <el-col>
            <status-radio v-model="xForm.status" style="width: 60%"></status-radio>
          </el-col>
        </el-form-item>

        <el-form-item label="上架区域：">
          <regions-selecter v-model="xForm.regions" style="width: 60%"></regions-selecter>
        </el-form-item>
        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages" style="width: 60%"></languages-selecter>
        </el-form-item>
        <el-form-item label="上架渠道：">
          <channels-selector v-model="xForm.channels" style="width: 60%"></channels-selector>
        </el-form-item>
        <el-form-item label="禁止上架渠道：">
          <channels-selector v-model="xForm.blocked_channels" style="width: 60%"></channels-selector>
        </el-form-item>

        <el-button type="text" @click="addNewTrackInfo()">添加音轨</el-button>
        <div v-for="(track_info, index) in xForm.track_infos" v-bind:key="index">
          <el-form-item :label="`声音场景${index + 1}`">
            <el-col :span="7">
              <el-select v-model="track_info.scene_id" placeholder="选择声音场景" required>
                <el-option v-for="item in sceneOptions" :key="item.value.id" :label="getTR(item.value.name)" :value="item.value.id"></el-option>
              </el-select>
            </el-col>
            <el-col :span="7">
              <el-slider label="音量" v-model="track_info.volume" show-input> </el-slider>
            </el-col>
            <el-col :span="7">
              <el-button type="text" @click="removeTrackInfo(index)">删除音轨</el-button>
            </el-col>
          </el-form-item>
        </div>

        <el-form-item label="作为背景声平衡类型：">
          <el-radio-group v-model="xForm.balance_type">
            <el-radio label="white_noise">白噪音</el-radio>
            <el-radio label="melody">旋律</el-radio>
            <el-radio label="meditation">冥想声音</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="加入标签[手动]">
          <el-col>
            <el-select v-model="xForm.manual_simple_tag_ids" value-key="id" multiple default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="加入标签[自动]">
          <el-col>
            <el-select v-model="xForm.simple_tag_ids" value-key="id" multiple disabled default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="搜索关键字">
          <el-col>
            <el-select v-model="xForm.search_annotations" value-key="id" multiple default-first-option placeholder="选择关键字" style="width: 100%">
              <el-option v-for="item in annotationOptions" :key="item.value.id" :label="item.value.name" :value="item.value"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="新品角标持续时间">
          <el-col>
            <el-radio-group v-model="freshUntilLock">
              <el-radio :label="true">不显示</el-radio>
              <el-radio :label="false">
                显示到
                <el-date-picker :disabled="freshUntilLock" size="medium" type="datetime" v-model="freshUntil" placeholder="日期" default-time="23:59:59"></el-date-picker>
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>

        <el-col>
          <el-col :span="10">
            <el-form-item label="主色调">
              <color-picker v-model="xForm.primary_color"></color-picker>
            </el-form-item>
          </el-col>
        </el-col>

        <el-col :span="3" :offset="1">
          <el-col :span="24">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>封面</span>
                <i class="el-icon-edit" @click="dialogDisplay.coverEdit = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="xForm['image']" @click="dialogDisplay.coverPreview = true">
                  <img class="preview-img" :src="xForm['image']" />
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadCoverProgress" :on-error="uploadCoverError" :on-success="uploadCoverSuccess" :before-upload="beforeUploadCover" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传场景封面</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-col>
      </el-form>

      <el-dialog title="头像预览" :visible.sync="dialogDisplay.avatarPreview">
        <img class="preview-dialog-img" :src="xForm['creator_avatar']" />
      </el-dialog>

      <el-dialog title="头像地址" :visible.sync="dialogDisplay.avatarEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="xForm['creator_avatar']"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog title="封面预览" :visible.sync="dialogDisplay.coverPreview">
        <img class="preview-dialog-img" :src="xForm['image']" />
      </el-dialog>

      <el-dialog title="封面地址" :visible.sync="dialogDisplay.coverEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="xForm['image']"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import ColorPicker from '@/components/color-picker';
import AudioUploader from '@/components/audio-uploader';
import TagsSelecter from '@/components/tags-selecter';
import OpenCC from '@/plugins/opencc';
import RegionsSelecter from '@/components/regions-selecter';
import ChannelsSelector from '@/components/channels-selector';
import LanguagesSelecter from '@/components/languages-selecter';
import StatusRadio from '@/components/status-radio';

export default {
  name: 'LessonForm',
  components: {
    Container,
    ColorPicker,
    AudioUploader,
    TagsSelecter,
    RegionsSelecter,
    ChannelsSelector,
    LanguagesSelecter,
    StatusRadio,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = localStorage.getItem('env');
    return {
      loading: false,
      env,

      langs: require('@/langs.json'),

      simpleTagOptions: [],
      annotationOptions: [],
      sceneOptions: [],
      picToken: '',
      picCDN: '',
      shortcut: '',

      avatarUploading: false,
      coverUploading: false,

      dialogDisplay: {
        coverEdit: false,
        avatarEdit: false,

        coverPreview: false,
        avatarPreview: false,

        showTagSelecter: false,
      },
    };
  },
  computed: {
    xForm: {
      get() {
        console.log(this.form);
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    freshUntilLock: {
      get() {
        return this.xForm.fresh_until == 0;
      },
      set(val) {
        if (!val) {
          const today = this.$moment();
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.freshUntil = +today;
        } else {
          this.freshUntil = 0;
        }
      },
    },
    freshUntil: {
      get() {
        return this.xForm.fresh_until * 1000;
      },
      set(val) {
        this.xForm.fresh_until = parseInt(new Date(val) / 1000);
      },
    },
  },
  watch: {
    shortcut(val) {
      const i18nInfos = val.split('\n');
      const fields = ['name', 'sub_title'];
      if (!i18nInfos.length) {
        this.$message.error('解析失败，无法填充');
      } else {
        i18nInfos.forEach((item, index) => {
          const langInfos = item.split('	');
          if (langInfos.length === 6) {
            this.xForm[fields[index]]['zh-Hans'] = langInfos[0] || langInfos[1];
            this.xForm[fields[index]]['en'] = langInfos[1];
            this.xForm[fields[index]]['ja'] = langInfos[2] || langInfos[1];
            this.xForm[fields[index]]['es'] = langInfos[3] || langInfos[1];
            this.xForm[fields[index]]['ru'] = langInfos[4] || langInfos[1];
            this.xForm[fields[index]]['ko'] = langInfos[5] || langInfos[1];
          }
        });
        this.$nextTick(() => {
          this.s2tw();
        });
      }
    },
    'xForm.name': {
      deep: true,
      handler(name) {},
    },
  },
  methods: {
    async save() {
      this.$emit('save', this.form);
    },
    async fetchScenes() {
      this.sceneOptions = [];
      console.log('sceneOptions', this.sceneOptions);
      const res = await this.$request({
        url: '/v1/admin/scenes',
        params: { type: 'normal', offset: 0, limit: 999 },
      });
      this.sceneOptions = res.data.map((scene) => {
        return {
          value: scene,
          label: this.getTR(scene.name),
        };
      });
    },
    async fetchTags() {
      this.simpleTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/simple_tags',
        params: { offset: 0, limit: 512 },
      });
      this.simpleTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchAnnotations() {
      this.annotationOptions = [];
      const res = await this.$request({
        url: '/v2/admin/search_annotations',
      });
      this.annotationOptions = res.data.map((ann) => {
        return {
          value: ann,
          label: ann.name,
        };
      });
    },
    async s2tw() {
      try {
        this.form.name['zh-Hant'] = this.opencc_tw(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_tw(this.form.sub_title['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    async s2twp() {
      try {
        this.form.name['zh-Hant'] = this.opencc_twp(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_twp(this.form.sub_title['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    beforeUploadAvatar: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadAvatarSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.creator_avatar = picUrl;
      this.avatarUploading = false;
    },
    uploadAvatarProgress(response) {
      this.avatarUploading = true;
    },
    uploadAvatarError(response) {
      this.avatarUploading = false;
    },

    beforeUploadCover: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadCoverSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.image = picUrl;
      this.coverUploading = false;
    },
    uploadCoverProgress(response) {
      this.coverUploading = true;
    },
    uploadCoverError(response) {
      this.coverUploading = false;
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },

    addNewTrackInfo() {
      this.xForm.track_infos.push({
        volume: 0,
        scene: {},
      });
    },

    removeTrackInfo(index) {
      this.xForm.track_infos.splice(index, 1);
    },
  },
  created: async function () {
    this.fetchTags();
    this.fetchAnnotations();
    this.fetchScenes();
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
    this.opencc_tw = await OpenCC.Converter('cn', 'tw');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.soundIcon img {
  background-color: rgba(0, 0, 0, 0.16);
}

.uploadIcon {
  display: inline-block;
}

.price-card {
  margin-top: 24px;
  padding: 0 24px 24px;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.diff {
  color: rgba(230, 162, 60, 1) !important;
  background: rgba(230, 162, 60, 0.12);
}

.scene-tag {
  margin-right: 16px;
  cursor: default;
}
</style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'EditAnnotation',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        name: '',
        en: [],
        'zh-Hans': [],
        'zh-Hant': [],
        ja: [],
        es: [],
        ru: [],
        ko: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      const { annotation_id: annotationId } = this.$route.params;
      await this.$request({
        url: `/v2/admin/search_annotations/${annotationId}`,
        method: 'PUT',
        data,
      });
      await this.init();
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
    init: async function () {
      const { annotation_id: annotationId } = this.$route.params;
      try {
        const res = await this.$request({
          url: `/v2/admin/search_annotations/${annotationId}`,
        });
        this.form = Object.assign({}, this.form, res.data);
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
      console.log(this.form);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <div>
    <sub-nav>
      <router-view slot="subNavLeft" name="subNavLeft"></router-view>
      <router-view slot="subNavRight" name="subNavRight"></router-view>
      <router-view slot="subNavBottom" name="subNavBottom"></router-view>
    </sub-nav>
    <router-view class="layout"></router-view>
  </div>
</template>

<script>
import SubNav from '@/components/nav/sub';
export default {
  name: 'Base',
  components: {
    SubNav,
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.layout {
  padding-bottom: 24px;
}
</style>

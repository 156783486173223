<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 12:03:31
 * @LastEditors  : Ruilong Li
 * @LastEditTime : 2020-02-13 20:09:53
 * @Description: 探索内容管理表单 banner管理组件
 * @FilePath: /davinci/src/past-pages/explore/base/form+components/form+bannersManager.vue
 -->

<script>
import ColorPicker from '@/components/color-picker';
import Cover from '@/components/cover';
import Draggable from 'vuedraggable';
import OpenCC from '@/plugins/opencc';
import ElInputTag from '@/components/el-input-tag';
import LanguagesSelecter from '@/components/languages-selecter';
import RegionsSelecter from '@/components/regions-selecter';
import ChannelsSelector from '@/components/channels-selector';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';

// -----------------------------------------------------------
// MARK: - Class: ExploreContentFormBannersManager
// -----------------------------------------------------------
export default {
  name: 'ExploreBannersManager',
  components: {
    ColorPicker,
    Cover,
    Draggable,
    ElInputTag,
    LanguagesSelecter,
    RegionsSelecter,
    ChannelsSelector,
    VipStatusSelecter,
    LoginStatusSelecter,
  },
  props: {
    value: Array,
    title: String,
  },
  data() {
    const langs = ['en', 'zh-Hans', 'zh-Hant', 'ja', 'es', 'ru', 'ko'];
    return {
      // -----------------------------------------------------------
      // MARK: - About Upload
      // -----------------------------------------------------------
      picCDN: '',
      picToken: '',
      bannerUploading: false,

      // -----------------------------------------------------------
      // MARK: - Constants
      // -----------------------------------------------------------
      langs,

      // -----------------------------------------------------------
      // MARK: - Content Helper Data
      // -----------------------------------------------------------
      activeBannerIndex: 0,
      activeLang: 'zh-Hans',
      banners: this.value,

      toPasteJSON: '',

      dialogDisplay: {
        bannerSortPanel: false,
        bannerPreview: false,
        bannerUrlEdit: false,
        bannerEdit: false,
        pasteBannerJSON: false,
      },
    };
  },
  computed: {
    activeBanner: {
      get() {
        if (!this.banners[this.activeBannerIndex]) {
          return null;
        }
        return this.banners[this.activeBannerIndex];
      },
      set(val) {
        this.banners[this.activeBannerIndex] = val;
      },
    },
    validFrom: {
      get() {
        return this.activeBanner.valid_from * 1000;
      },
      set(val) {
        this.$set(this.banners[this.activeBannerIndex], `valid_from`, parseInt(val / 1000));
      },
    },
    validTo: {
      get() {
        return this.activeBanner.valid_to * 1000;
      },
      set(val) {
        this.$set(this.banners[this.activeBannerIndex], `valid_to`, parseInt(val / 1000));
      },
    },
  },
  watch: {
    value(v) {
      this.banners = v;
    },
    activeBanner: {
      deep: true,
      handler() {
        this.$emit('input', this.banners);
      },
    },
  },
  methods: {
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
    save() {
      this.$emit('save');
    },
    async s2tw() {
      try {
        this.activeBanner.title['zh-Hant'] = this.opencc_tw(this.activeBanner.title['zh-Hans']);
        this.activeBanner.tag['zh-Hant'] = this.opencc_tw(this.activeBanner.tag['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    async s2twp() {
      try {
        this.activeBanner.title['zh-Hant'] = this.opencc_twp(this.activeBanner.title['zh-Hans']);
        this.activeBanner.tag['zh-Hant'] = this.opencc_twp(this.activeBanner.tag['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    removeBanner(index) {
      this.banners.splice(index, 1);
    },
    editBanner(index) {
      this.activeBannerIndex = index;
      this.dialogDisplay.bannerEdit = true;
    },

    reUploadBannerSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.bannerUploading = false;
      this.banners[this.activeBannerIndex].image_i18n[this.activeLang] = picUrl;
      if (this.activeLang === 'en') {
        this.banners[this.activeBannerIndex].image = picUrl;
      }
    },

    addBanner() {
      try {
        const data = JSON.parse(this.toPasteJSON);
        this.banners = [data, ...this.banners];
        this.dialogDisplay.pasteBannerJSON = false;
        this.toPasteJSON = '';
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    uploadBannerSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      const now = parseInt(+new Date() / 1000);
      this.bannerUploading = false;

      this.banners = [
        {
          image: picUrl,
          image_i18n: {
            en: picUrl,
            es: '',
            ja: '',
            ko: '',
            ru: '',
            'zh-Hans': '',
            'zh-Hant': '',
          },
          mask_colors: ['255,255,255,0', '255,255,255,0'],
          primary_color: '255,255,255,0',
          valid_from: now,
          valid_to: now,
          tag: {
            'zh-Hans': '',
            'zh-Hant': '',
            en: '',
            ja: '',
            es: '',
            ru: '',
            ko: '',
          },
          status: 'draft',
          title: {
            'zh-Hans': '',
            'zh-Hant': '',
            en: '',
            ja: '',
            es: '',
            ru: '',
            ko: '',
          },
          url: {
            'zh-Hans': '',
            'zh-Hant': '',
            en: '',
            ja: '',
            es: '',
            ru: '',
            ko: '',
          },
        },
        ...this.banners,
      ];
    },
    uploadBannerProgress() {
      this.bannerUploading = true;
    },
    uploadBannerError() {
      this.bannerUploading = false;
    },

    beforeUploadBanner: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    tableRowClassName({ row: banner, rowIndex }) {
      console.log('rowIndex', rowIndex, 'banner', banner);
      const { valid_from: validFrom, valid_to: validTo } = banner;
      const currentTimeStamp = parseInt(Date.now() / 1000); // 获取当前时间的时间戳
      if (currentTimeStamp < validFrom || currentTimeStamp > validTo) {
        // 如果当前时间戳不在 validFrom 和 validTo 指定的范围内
        return 'warning-row';
      }
      return '';
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created: async function () {
    this.opencc_tw = await OpenCC.Converter('cn', 'tw');
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<template>
  <div>
    <div class="container-head">
      <div class="container-head-title">{{ title }}</div>
      <div class="container-head-extra">
        <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.bannerSortPanel = true">重新排序</el-button>
        <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.pasteBannerJSON = true">新增JSON</el-button>
        <el-upload style="display: inline-block" action="https://upload.qiniup.com" :on-progress="uploadBannerProgress" :on-error="uploadBannerError" :on-success="uploadBannerSuccess" :before-upload="beforeUploadBanner" :data="{ token: picToken }" :show-file-list="false">
          <el-button style="margin-left: 10px" type="text" size="small">新增横幅</el-button>
        </el-upload>
      </div>
    </div>
    <div class="container-body">
      <el-table class="data-table" :row-class-name="tableRowClassName" :data="banners">
        <el-table-column label="排序" width="50px" align="left">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="图片" width="72px">
          <template slot-scope="scope">
            <cover :width="48" :height="48" :src="scope.row.image_i18n.en" :mask-colors="scope.row.mask_colors"></cover>
          </template>
        </el-table-column>
        <el-table-column label="标题">
          <template slot-scope="scope">{{ getTR(scope.row.title) }}</template>
        </el-table-column>
        <el-table-column label="副标题">
          <template slot-scope="scope">{{ getTR(scope.row.tag) }}</template>
        </el-table-column>
        <el-table-column label="生效时间">
          <template slot-scope="scope">{{ $moment(scope.row.valid_from * 1000).format('YYYY-MM-DD HH:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="失效时间">
          <template slot-scope="scope">{{ $moment(scope.row.valid_to * 1000).format('YYYY-MM-DD HH:mm:ss') }}</template>
        </el-table-column>

        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="right">
          <template slot-scope="scope">
            <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">复制JSON</el-button>
            <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="editBanner(scope.$index)">编辑</el-button>
            <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="removeBanner(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="Banner预览" :visible.sync="dialogDisplay.bannerPreview" v-if="banners[activeBannerIndex]">
      <img class="preview-dialog-img" :src="banners[activeBannerIndex].image_i18n[activeLang]" />
    </el-dialog>

    <el-dialog title="新增JSON" :visible.sync="dialogDisplay.pasteBannerJSON">
      <el-form>
        <el-form-item label="JSON">
          <el-input v-model="toPasteJSON" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDisplay.pasteBannerJSON = false">取 消</el-button>
        <el-button type="primary" @click="addBanner">添 加</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Banner图片地址" :visible.sync="dialogDisplay.bannerUrlEdit" v-if="banners[activeBannerIndex]">
      <el-form label-position="top" label-width="120px">
        <el-form-item>
          <el-input v-model="banners[activeBannerIndex].image_i18n[activeLang]">
            <i v-if="bannerUploading" class="el-icon-loading" slot="suffix"></i>
            <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadBannerProgress" :on-error="uploadBannerError" :on-success="reUploadBannerSuccess" :before-upload="beforeUploadBanner" :data="{ token: picToken }" :show-file-list="false">
              <i class="el-icon-upload2"></i>
            </el-upload>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDisplay.bannerUrlEdit = false">返 回</el-button>
      </span>
    </el-dialog>

    <el-dialog title="横幅编辑" width="80%" :visible.sync="dialogDisplay.bannerEdit" v-if="banners[activeBannerIndex]">
      <el-form v-if="banners[activeBannerIndex]">
        <el-form-item>
          <el-tabs type="border-card" v-model="activeLang">
            <el-tab-pane v-for="lang in langs" :key="lang" :name="lang">
              <span slot="label" v-if="banners[activeBannerIndex].title[lang] && activeBanner.url[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-form-item label="标题">
                <el-input v-model="banners[activeBannerIndex].title[lang]"></el-input>
              </el-form-item>
              <el-form-item label="副标题">
                <el-input v-model="banners[activeBannerIndex].tag[lang]"></el-input>
              </el-form-item>
              <el-form-item v-if="lang === 'zh-Hant'">
                <el-button type="text" @click="s2tw">转繁体</el-button>
                <el-button type="text" @click="s2twp">转台湾词汇</el-button>
              </el-form-item>
              <el-form-item label="跳转地址">
                <el-input v-model="banners[activeBannerIndex].url[lang]"></el-input>
              </el-form-item>
              <el-form-item>
                <div slot="label" class="form-label">
                  <span>图片</span>
                  <i class="el-icon-edit" @click="dialogDisplay.bannerUrlEdit = true"></i>
                </div>
                <el-col>
                  <div class="preview" v-if="banners[activeBannerIndex].image_i18n[lang]" @click="dialogDisplay.bannerPreview = true">
                    <img class="preview-img" :src="banners[activeBannerIndex].image_i18n[lang]" />
                    <div class="preview-img-cover">
                      <i class="material-icons">remove_red_eye</i>
                      <span>预览</span>
                    </div>
                  </div>
                  <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadBannerProgress" :on-error="uploadBannerError" :on-success="reUploadBannerSuccess" :before-upload="beforeUploadBanner" :data="{ token: picToken }" :show-file-list="false">
                    <div class="uploadPic">
                      <i class="el-icon-upload2"></i>
                      <span>上传场景封面</span>
                    </div>
                  </el-upload>
                  <span v-if="lang === 'en'">更新英文图片时，旧版图片会一并更新</span>
                </el-col>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-row>
          <el-form-item label="状态：" label-width="100px">
            <el-radio-group v-model="banners[activeBannerIndex].status">
              <el-radio label="published">发布</el-radio>
              <el-radio label="reviewing">审阅</el-radio>
              <el-radio label="draft">草稿</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="展示地区">
            <regions-selecter v-model="banners[activeBannerIndex].regions"></regions-selecter>
          </el-form-item>
          <el-form-item label="上架渠道：">
            <channels-selector v-model="banners[activeBannerIndex].channels"></channels-selector>
          </el-form-item>
          <el-form-item label="上架语言：">
            <languages-selecter v-model="banners[activeBannerIndex].languages"></languages-selecter>
          </el-form-item>
          <el-form-item label="会员状态：">
            <vip-status-selecter v-model="banners[activeBannerIndex].vip_status"></vip-status-selecter>
          </el-form-item>
          <el-form-item label="登录状态：">
            <login-status-selecter v-model="banners[activeBannerIndex].login_status_v2"></login-status-selecter>
          </el-form-item>

          <el-col :span="7">
            <el-form-item label="iOS上架版本：">
              <el-input-tag v-model="banners[activeBannerIndex].ios_versions"></el-input-tag>
            </el-form-item>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-form-item label="Android上架版本：">
              <el-input-tag v-model="banners[activeBannerIndex].android_versions"></el-input-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="主色调：" label-width="100px">
              <color-picker v-model="banners[activeBannerIndex].primary_color"></color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="遮罩渐变1：" label-width="100px">
              <color-picker v-model="banners[activeBannerIndex].mask_colors[0]"></color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="遮罩渐变2：" label-width="100px">
              <color-picker v-model="banners[activeBannerIndex].mask_colors[1]"></color-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="生效时间：" label-width="100px">
          <el-date-picker type="datetime" v-model="validFrom"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效时间：" label-width="100px">
          <el-date-picker type="datetime" v-model="validTo"></el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDisplay.bannerEdit = false">返 回</el-button>
      </span>
    </el-dialog>

    <el-dialog title="横幅排序" width="40%" :visible.sync="dialogDisplay.bannerSortPanel">
      <draggable class="sort-list-container" v-model="banners">
        <div class="sort-item" v-for="(banner, index) in banners" :key="index">
          <div class="sort-item-key">{{ index + 1 }}</div>
          <div class="banner-thumbnail">
            <cover :width="48" :height="48" :src="banner.image_i18n[activeLang]" :mask-colors="banner.mask_colors"></cover>
          </div>
          <div class="sort-item-name">
            <p>{{ getTR(banner.title) }}</p>
            <p class="sort-item-desc">{{ getTR(banner.url) }}</p>
          </div>
        </div>
      </draggable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDisplay.bannerSortPanel = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.data-table .el-form-item {
  width: 180px;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}

.banner {
  width: 48px;
  height: 48px;
  object-fit: cover;
}

.banner-thumbnail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
}
</style>

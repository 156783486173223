import Layout from '@/components/layout';

import Splashes from '@/pages/operation/splashes/index';
import SplashesManager from '@/pages/operation/splashes/manager/index';
import SplashAdd from '@/pages/operation/splashes/manager/add';
import SplashEditor from '@/pages/operation/splashes/manager/edit';

import RedeemCodes from '@/pages/operation/redeem-codes/index';
import RedeemCodesManager from '@/pages/operation/redeem-codes/manager/index';
import RedeemCodeAdd from '@/pages/operation/redeem-codes/manager/add';
import RedeemCodeEditor from '@/pages/operation/redeem-codes/manager/edit';

import Promotions from '@/pages/operation/promotion/index';
import PromotionRecordsManager from '@/pages/operation/promotion/user_records';
import MagicKeysManager from '@/pages/operation/promotion/magic_keys';

export default [
  {
    path: '/operation',
    name: 'Operation',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '活动管理',
    },
    redirect: {
      name: 'SplashesManager',
    },
    children: [
      {
        path: 'promotion',
        meta: {
          requireAuth: true,
          label: '优惠活动',
          newDesign: true,
        },
        component: Promotions,
        children: [
          {
            path: 'records',
            name: 'PromotionRecordsManager',
            component: PromotionRecordsManager,
            meta: {
              requireAuth: true,
              label: '优惠记录管理',
              newDesign: true,
            },
          },
          {
            path: 'magic_keys',
            name: 'MagicKeysManager',
            component: MagicKeysManager,
            meta: {
              requireAuth: true,
              label: '优惠资格管理',
              newDesign: true,
            },
          },
        ],
      },
      {
        path: 'redeem-codes',
        meta: {
          requireAuth: true,
          label: '兑换活动',
          newDesign: true,
        },
        component: RedeemCodes,
        children: [
          {
            path: '/',
            name: 'RedeemCodesManager',
            component: RedeemCodesManager,
            meta: {
              requireAuth: true,
              label: '资源管理',
              newDesign: true,
            },
          },
          {
            path: 'add',
            name: 'RedeemCodeAdd',
            component: RedeemCodeAdd,
            meta: {
              requireAuth: true,
              label: '添加',
              newDesign: true,
            },
          },
          {
            path: ':redeem_code_group_id/edit',
            name: 'RedeemCodeEditor',
            component: RedeemCodeEditor,
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
      {
        path: 'splashes',
        meta: {
          requireAuth: true,
          label: '闪屏活动',
          newDesign: true,
        },
        component: Splashes,
        children: [
          {
            path: '/',
            name: 'SplashesManager',
            component: SplashesManager,
            meta: {
              requireAuth: true,
              label: '资源管理',
              newDesign: true,
            },
          },
          {
            path: 'add',
            name: 'SplashAdd',
            component: SplashAdd,
            meta: {
              requireAuth: true,
              label: '添加',
              newDesign: true,
            },
          },
          {
            path: ':splashes_id/edit',
            name: 'SplashEditor',
            component: SplashEditor,
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },
];

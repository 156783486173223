<template>
  <edit-form :form="form" @save="save"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'EditTidesleep',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        share_text: '',
        like: 'disc',
        type: 'normal',
        color: '255, 255, 255',
        icon_url: '',
        gif_url: '',
        img_url: '',
        share_img_url: '',
        audio_url: '',
        demo_url: '',
        musical_note_unlock: { count: 0 },
        sort_key: 0,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    save: async function () {
      const form = this.form;
      try {
        await this.$request({
          method: 'PUT',
          url: `/v1/admin/tidesleep/scenes/${this.$route.params.id}`,
          data: form,
        });
        this.$message({
          message: '保存成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(`保存失败, ${e.response.data.msg}`);
      }
    },
    init: async function () {
      const res = await this.$request({
        url: `/v1/admin/tidesleep/scenes/${this.$route.params.id}`,
      });
      this.form = Object.assign({}, this.form, res.data);

      console.log(JSON.stringify(res.data, undefined, 2));
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:01
 * @LastEditors: Ruilong Li
 * @LastEditTime: 2019-12-13 21:02:41
 * @Description:  冥想分类管理新增页面
 * @FilePath: /davinci/src/pages/meditation/group/add.vue
 -->
<template>
  <group-form :form="form" @change="change" @save="save">
    <template slot="content-container">
      <div class="container-head">
        <div class="container-head-title">内容列表</div>
      </div>
      <div class="form container-body">
        <el-table class="data-table" :data="[]" empty-text="新建完成后即可编辑内容列表">
          <el-table-column label="排序" width="55px" align="left"></el-table-column>
          <el-table-column label="名称"></el-table-column>

          <el-table-column label="定价"></el-table-column>

          <el-table-column label="可订阅"></el-table-column>

          <el-table-column label="展示地区"></el-table-column>

          <el-table-column label="状态"></el-table-column>

          <el-table-column label="操作" align="right"></el-table-column>
        </el-table>
      </div>
    </template>
  </group-form>
</template>

<script>
import GroupForm from '@/components/groups/form';
export default {
  name: 'ContentsGroupsAdder',
  components: {
    GroupForm,
  },
  props: {
    type: String,
  },
  data() {
    return {
      form: {
        id: '',
        mixed_scenes: [],
        scenes: [],
        meditations: [],
        articles: [],
        contents: [],
        type: 'content',
        is_mini_meditation: false,
        hide_albums_in_meditation_pages: false,
        is_can_share: true,
        status: 'draft',
        style: { preview_count: 0 },

        description: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },

        name: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },

        sub_title: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
      },
    };
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save() {
      try {
        const res = await this.$request({
          url: `/v2/admin/explorer/groups`,
          method: 'POST',
          data: this.form,
        });
        this.$router.replace({
          name: 'ContentsGroupsEditor',
          params: {
            group_id: res.data.id,
          },
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        console.log(e);
        this.$message.error('操作失败');
      }
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

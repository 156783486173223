<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">探索页配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.name[lang] && xForm.description[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="7">
                <el-form-item label="标题">
                  <el-input v-model="xForm.name[lang]" placeholder="目前该字段前端没有用，可以用来备注信息"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="15" :offset="1">
                <el-form-item label="副标题">
                  <el-input v-model="xForm.sub_title[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" :rows="3" v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp()">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="快速填充">
          <el-input type="textarea" :rows="2" v-model="shortcut"></el-input>
        </el-form-item>

        <el-form-item label="type">
          <el-input type="text" v-model="xForm.type" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="上架状态">
          <el-col>
            <el-radio-group v-model="xForm.status">
              <el-radio label="published">上架</el-radio>
              <el-radio label="silent">静默上架</el-radio>
              <el-radio label="reviewing">审阅</el-radio>
              <el-radio label="draft">草稿</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>

        <el-form-item label="上架区域：">
          <regions-selecter v-model="xForm.regions"></regions-selecter>
        </el-form-item>

        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages"></languages-selecter>
        </el-form-item>

        <el-form-item label="会员状态：">
          <vip-status-selecter v-model="xForm.vip_status"></vip-status-selecter>
        </el-form-item>

        <el-form-item label="登录状态：">
          <login-status-selecter v-model="xForm.login_status_v2"></login-status-selecter>
        </el-form-item>

        <el-col>
          <el-col :span="10">
            <el-form-item label="主色调">
              <color-picker v-model="xForm.primary_color"></color-picker>
            </el-form-item>
          </el-col>
        </el-col>

        <el-col :span="5">
          <el-col :span="24">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>封面（目前该字段前端没有用）</span>
                <i class="el-icon-edit" @click="dialogDisplay.coverEdit = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="xForm['image']" @click="dialogDisplay.coverPreview = true">
                  <img class="preview-img" :src="xForm['image']" />
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadCoverProgress" :on-error="uploadCoverError" :on-success="uploadCoverSuccess" :before-upload="beforeUploadCover" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传探索页封面</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-col>
      </el-form>

      <banners-manager v-model="xForm.banners" @input="bannersChanged" title="横幅列表"></banners-manager>

      <div class="container-head">
        <div class="container-head-title">分组列表</div>
        <div class="container-head-extra">
          <el-button
            :disabled="!$store.getters.hasEditRole"
            type="text"
            size="mini"
            @click="
              sortList = groups;
              dialogDisplay.sortContents = true;
            "
            >排序</el-button
          >
          <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContents = true">编辑分组列表</el-button>
        </div>
      </div>

      <div class="form container-body">
        <el-table class="data-table" :data="groups">
          <el-table-column label="排序" width="55px" align="left">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column label="名称">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" @click="gotoGroupEditor(scope.row)">{{ scope.row.id }}</el-link>
              <br />
              {{ getTR(scope.row.name) }}<br />
              {{ scope.row.remark }}
            </template>
          </el-table-column>

          <el-table-column label="类型">
            <template slot-scope="scope">{{ getTypeName(scope.row.type) }}</template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
              <template v-else>
                <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="选择分组" :visible.sync="dialogDisplay.editContents">
        <el-table ref="listTable" :data="filtedList" style="width: 100%" @select="onSelect" @select-all="onSelectAll">
          <el-table-column type="selection" width="55"></el-table-column>

          <el-table-column label="分组名">
            <template slot-scope="scope">{{ scope.row.id }}<br />{{ getTR(scope.row.name) }}<br />{{ scope.row.remark }}</template>
          </el-table-column>

          <el-table-column label="类型">
            <template slot-scope="scope">{{ getTypeName(scope.row.type) }}</template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
              <template v-else>
                <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot="header">
              <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeyword"></el-input>
            </template>
            <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog title="分组排序" width="40%" :visible.sync="dialogDisplay.sortContents">
        <draggable class="sort-list-container" v-model="sortList">
          <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="sort-item-name">{{ getTR(item.name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSort">保 存</el-button>
          <el-button @click="dialogDisplay.sortContents = false">返 回</el-button>
        </span>
      </el-dialog>

      <div class="container-head">
        <div class="container-head-title">推荐位列表</div>
        <div class="container-head-extra">
          <el-button
            :disabled="!$store.getters.hasEditRole"
            type="text"
            size="mini"
            @click="
              sortListADBanner = ad_banners;
              dialogDisplay.sortContentsADBanner = true;
            "
            >排序</el-button
          >
          <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContentsADBanner = true">编辑推荐位列表</el-button>
        </div>
      </div>

      <div class="form container-body">
        <el-table class="data-table" :data="ad_banners">
          <el-table-column label="排序" width="55px" align="left">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column label="名称">
            <template slot-scope="scope">
              {{ scope.row.activity_name }}<br />
              <el-link type="primary" :underline="false" @click="gotoAdbannerEditor(scope.row)">{{ scope.row.id }}</el-link>
            </template>
          </el-table-column>

          <el-table-column label="展示位置">
            <template slot-scope="scope">第 {{ scope.row.row_position }} 行</template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
              <template v-else>
                <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="removeItemADBanner(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="选择推荐位" :visible.sync="dialogDisplay.editContentsADBanner">
        <el-table ref="listTableADBanner" :data="filtedListADBanner" style="width: 100%" @select="onSelectADBanner" @select-all="onSelectAllADBanner">
          <el-table-column type="selection" width="55"></el-table-column>

          <el-table-column label="名称">
            <template slot-scope="scope">{{ scope.row.activity_name }}</template>
          </el-table-column>

          <el-table-column label="展示位置">
            <template slot-scope="scope">第 {{ scope.row.row_position }} 行</template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
              <template v-else>
                <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot="header">
              <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeywordADBanner"></el-input>
            </template>
            <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog title="推荐位排序" width="40%" :visible.sync="dialogDisplay.sortContentsADBanner">
        <draggable class="sort-list-container" v-model="sortListADBanner">
          <div class="sort-item" v-for="(item, index) in sortListADBanner" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="sort-item-name">{{ item.activity_name }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSortADBanner">保 存</el-button>
          <el-button @click="dialogDisplay.sortContentsADBanner = false">返 回</el-button>
        </span>
      </el-dialog>

      <el-dialog title="封面预览" :visible.sync="dialogDisplay.coverPreview">
        <img class="preview-dialog-img" :src="xForm['image']" />
      </el-dialog>

      <el-dialog title="封面地址" :visible.sync="dialogDisplay.coverEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="xForm['image']"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import ColorPicker from '@/components/color-picker';
import AudioUploader from '@/components/audio-uploader';
import OpenCC from '@/plugins/opencc';
import ElInputTag from '@/components/el-input-tag';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
import BannersManager from './banners';
import RegionsSelecter from '@/components/regions-selecter';
import LanguagesSelecter from '@/components/languages-selecter';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';
import allStatus from '@/status.json';

export default {
  name: 'ExplorePageForm',
  components: {
    Container,
    ColorPicker,
    AudioUploader,
    ElInputTag,
    Draggable,
    Cover,
    BannersManager,
    RegionsSelecter,
    LanguagesSelecter,
    VipStatusSelecter,
    LoginStatusSelecter,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = localStorage.getItem('env');
    return {
      loading: false,
      env,

      langs: require('@/langs.json'),

      simpleTagOptions: [],
      shortcut: '',

      list: [],
      sortList: [],

      listADBanner: [],
      sortListADBanner: [],
      filterKeywordADBanner: '',

      picToken: '',
      picCDN: '',
      filterKeyword: '',

      coverUploading: false,

      dialogDisplay: {
        coverEdit: false,
        coverPreview: false,
        sortContents: false,
        editContents: false,
        sortContentsADBanner: false,
        editContentsADBanner: false,
      },
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    freshUntilLock: {
      get() {
        return this.xForm.fresh_until == 0;
      },
      set(val) {
        if (!val) {
          const today = this.$moment();
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.freshUntil = +today;
        } else {
          this.freshUntil = 0;
        }
      },
    },
    groups_ids: {
      get() {
        if (!this.xForm.groups_ids) {
          return [];
        }
        return this.xForm.groups_ids;
      },
      set(val) {
        this.xForm.groups_ids = val;
      },
    },
    ad_banners_ids: {
      get() {
        if (!this.xForm.ad_banners_ids) {
          return [];
        }
        return this.xForm.ad_banners_ids;
      },
      set(val) {
        this.xForm.ad_banners_ids = val;
      },
    },
    groups: {
      get() {
        if (!this.list.length) {
          return [];
        }
        return this.groups_ids.map((v) => this.list.find((scn) => scn.id === v));
      },
      set(val) {
        this.groups_ids = val.map((v) => v.id);
      },
    },
    ad_banners: {
      get() {
        if (!this.listADBanner.length) {
          return [];
        }
        return this.ad_banners_ids.map((v) => this.listADBanner.find((scn) => scn.id === v));
      },
      set(val) {
        this.ad_banners_ids = val.map((v) => v.id);
      },
    },
    freshUntil: {
      get() {
        return this.xForm.fresh_until * 1000;
      },
      set(val) {
        this.xForm.fresh_until = parseInt(new Date(val) / 1000);
      },
    },
    filtedList() {
      let filtedList = this.list;
      const filterKeyword = this.filterKeyword;

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return (item.name['en'] || '').includes(filterKeyword) || (item.name['zh-Hans'] || '').includes(filterKeyword) || (item.name['zh-Hant'] || '').includes(filterKeyword);
        });
      }

      return filtedList;
    },
    filtedListADBanner() {
      let filtedList = this.listADBanner;
      const filterKeyword = this.filterKeywordADBanner;

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return item.activity_name.includes(filterKeyword);
        });
      }

      return filtedList;
    },
  },
  watch: {
    shortcut(val) {
      const i18nInfos = val.split('\n');
      const fields = ['name', 'subtitle', 'description'];
      if (!i18nInfos.length) {
        this.$message.error('解析失败，无法填充');
      } else {
        i18nInfos.forEach((item, index) => {
          const langInfos = item.split('	');
          if (langInfos.length === 6) {
            this.xForm[fields[index]]['zh-Hans'] = langInfos[0] || langInfos[1];
            this.xForm[fields[index]]['en'] = langInfos[1];
            this.xForm[fields[index]]['ja'] = langInfos[2] || langInfos[1];
            this.xForm[fields[index]]['es'] = langInfos[3] || langInfos[1];
            this.xForm[fields[index]]['ru'] = langInfos[4] || langInfos[1];
            this.xForm[fields[index]]['ko'] = langInfos[5] || langInfos[1];
          }
        });
        this.$nextTick(() => {
          this.s2tw();
        });
      }
    },
    filtedList() {
      this.freshSelected();
    },
    'dialogDisplay.editContents'() {
      this.freshSelected();
    },
    filtedListADBanner() {
      this.freshSelectedADBanner();
    },
    'dialogDisplay.editContentsADBanner'() {
      this.freshSelectedADBanner();
    },
  },
  methods: {
    bannersChanged(banners) {
      this.xForm.banners = banners;
    },
    saveSortADBanner() {
      this.ad_banners = this.sortListADBanner;
    },
    freshSelectedADBanner() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTableADBanner;
        if ($listTables) {
          const $ref = $listTables;
          $ref.clearSelection();
          this.filtedListADBanner.forEach((row) => {
            if (this.ad_banners_ids.some((item) => item === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    onSelectADBanner(selection, row) {
      if (this.ad_banners_ids.some((item) => item === row.id)) {
        this.ad_banners_ids = this.ad_banners_ids.filter((item) => item !== row.id);
      } else {
        this.ad_banners_ids.unshift(row.id);
      }
    },
    onSelectAllADBanner(selection) {
      if (!selection.length) {
        const list = this.ad_banners_ids.filter((albumId) => !this.filtedListADBanner.some((item) => item.id === albumId));
        this.ad_banners_ids = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.ad_banners_ids.some((item) => item === album.id)) {
            list.push(album.id);
          }
        });
        this.ad_banners_ids = [...this.ad_banners_ids, ...list];
      }
    },
    saveSort() {
      this.groups = this.sortList;
    },
    freshSelected() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTable;
        if ($listTables) {
          const $ref = $listTables;
          $ref.clearSelection();
          this.filtedList.forEach((row) => {
            if (this.groups_ids.some((item) => item === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    onSelect(selection, row) {
      if (this.groups_ids.some((item) => item === row.id)) {
        this.groups_ids = this.groups_ids.filter((item) => item !== row.id);
      } else {
        this.groups_ids.unshift(row.id);
      }
    },
    onSelectAll(selection) {
      if (!selection.length) {
        const list = this.groups_ids.filter((albumId) => !this.filtedList.some((item) => item.id === albumId));
        this.groups_ids = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.groups_ids.some((item) => item === album.id)) {
            list.push(album.id);
          }
        });
        this.groups_ids = [...this.groups_ids, ...list];
      }
    },
    async save() {
      this.$emit('save', this.xForm);
    },
    async fetchSimpleTags() {
      this.simpleTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/simple_tags',
        params: { offset: 0, limit: 512 },
      });
      this.simpleTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async s2tw() {
      try {
        this.form.name['zh-Hant'] = this.opencc_tw(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_tw(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_tw(this.form.description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    async s2twp() {
      try {
        this.form.name['zh-Hant'] = this.opencc_twp(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_twp(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_twp(this.form.description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    beforeUploadCover: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadCoverSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.image = picUrl;
      this.coverUploading = false;
    },
    uploadCoverProgress(response) {
      this.coverUploading = true;
    },
    uploadCoverError(response) {
      this.coverUploading = false;
    },
    getTypeName(typ) {
      return typ === 'group'
        ? '分类'
        : typ === 'article'
        ? '图文'
        : typ === 'scene'
        ? '场景'
        : typ === 'mixed_scene'
        ? '混音场景'
        : typ === 'dailypic'
        ? '日帖'
        : typ === 'meditation'
        ? '冥想'
        : typ === 'sleep_story'
        ? '睡眠故事'
        : typ === 'ad_banner'
        ? '推荐位'
        : typ === 'content'
        ? '通用'
        : '未知';
    },
    async getGroupList() {
      const res = await this.$request({
        url: '/v2/admin/explorer/groups',
        params: { offset: 0, limit: 255 },
      });
      return res.data;
    },
    getADBannerType() {
      const { category_type: categoryType } = this.$route.params;
      switch (categoryType) {
        case 'home':
          return 'explorer';
        case 'sleep_topic':
        case 'nap_topic':
        case 'theme':
          return categoryType;
      }
    },
    async getADBannerList() {
      const bannerType = this.getADBannerType();
      const res = await this.$request({
        url: '/v2/admin/explorer/embedded_banners',
        params: { offset: 0, limit: 255, type: bannerType },
      });
      return res.data;
    },
    async refreshList() {
      this.list = await this.getGroupList();
      this.form.groups_ids = this.groups_ids.filter((item) => this.list.some((v) => v.id === item));
      this.listADBanner = await this.getADBannerList();
      this.form.ad_banners_ids = this.ad_banners_ids.filter((item) => this.listADBanner.some((v) => v.id === item));
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    gotoGroupEditor(groupInfo) {
      let routerName = 'ExploreGroupsEditor';
      switch (groupInfo.type) {
        case 'meditation':
          routerName = 'MedGroupsEditor';
          break;
        case 'scene':
        case 'mixed_scene':
          routerName = 'ScenesGroupsEditor';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryGroupsEditor';
          break;
        case 'article':
          routerName = 'ArticleGroupsEditor';
          break;
        case 'content':
          routerName = 'ContentsGroupsEditor';
          break;
      }
      this.$router.push({
        name: routerName,
        params: {
          group_id: groupInfo.id,
        },
      });
    },
    gotoAdbannerEditor(adBannerInfo) {
      let routerName = 'ExploreAdBannersEditor';
      switch (adBannerInfo.type) {
        case 'meditation':
          routerName = 'MedAdBannersEditor';
          break;
        case 'scene':
          routerName = 'ScenesAdBannersEditor';
          break;
        case 'explorer':
          routerName = 'ExploreAdBannersEditor';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryAdBannersEditor';
          break;
        case 'article':
          routerName = 'ArticleAdBannersEditor';
          break;
        case 'sleep_topic':
          routerName = 'SleepTopicPageAdBannersEditor';
          break;
        case 'nap_topic':
          routerName = 'NapTopicPageAdBannersEditor';
          break;
        case 'theme':
          routerName = 'ThemePageAdBannersEditor';
          break;
      }
      console.log(adBannerInfo);
      console.log(routerName);
      this.$router.push({
        name: routerName,
        params: {
          ad_banner_id: adBannerInfo.id,
        },
      });
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  async created() {
    await this.fetchSimpleTags();
    await this.refreshList();
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
    this.opencc_tw = await OpenCC.Converter('cn', 'tw');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.soundIcon img {
  background-color: rgba(0, 0, 0, 0.16);
}

.uploadIcon {
  display: inline-block;
}

.price-card {
  margin-top: 24px;
  padding: 0 24px 24px;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.diff {
  color: rgba(230, 162, 60, 1) !important;
  background: rgba(230, 162, 60, 0.12);
}

.scene-tag {
  margin-right: 16px;
  cursor: default;
}
.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

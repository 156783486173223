<template>
  <div class="container">
    <div class="container-head">
      <div class="container-head-title">分组列表</div>
      <div class="container-head-extra">
        <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="gotoAdder">新增分组</el-button>
      </div>
    </div>
    <div class="container-body">
      <el-table :data="list" stripe>
        <el-table-column label="标题和副标题" width="400px">
          <template slot-scope="scope">
            {{ getTR(scope.row.name) }}<br />
            {{ getTR(scope.row.sub_title) }}<br />
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            {{ scope.row.remark }}
          </template>
        </el-table-column>

        <el-table-column label="内容数">
          <template slot-scope="scope">
            {{
              scope.row.type === 'mixed_scene'
                ? (scope.row.mixed_scenes || []).length
                : scope.row.type === 'meditation'
                ? (scope.row.meditations || []).length
                : scope.row.type === 'article'
                ? (scope.row.articles || []).length
                : scope.row.type === 'sleep_story'
                ? (scope.row.sleep_stories || []).length
                : scope.row.type === 'content'
                ? (scope.row.contents || []).length
                : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column label="展示内容数">
          <template slot-scope="scope">{{ scope.row.style.preview_count }}</template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{ scope.row.type === 'scene' ? '声音场景' : scope.row.type === 'mixed_scene' ? '混音场景' : scope.row.type === 'meditation' ? '冥想练习' : scope.row.type === 'article' ? '图文' : scope.row.type === 'sleep_story' ? '睡前故事' : scope.row.type === 'content' ? '通用分组' : '未知' }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 'published' ? '已上架' : scope.row.status === 'draft' ? '草稿' : scope.row.status === 'reviewing' ? '审阅中' : '' }}
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">复制JSON</el-button>
            <el-button type="text" @click="gotoEditor(scope.row)">编辑</el-button>
            <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeGroup(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Groups',
  props: {
    type: String,
  },
  data() {
    return {
      list: [],
      busy: false,
      finish: false,
    };
  },

  // -----------------------------------------------------------
  // MARK: - Computer Properties
  // -----------------------------------------------------------
  computed: {},

  methods: {
    async save() {
      const data = this.list.map((tag, index) => {
        return Object.assign({}, tag, { sort_key: index });
      });
      try {
        await this.$request({
          url: '/v2/admin/explorer/groups',
          method: 'PUT',
          data,
        });
        this.list = data;
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    gotoAdder() {
      let routerName = 'ExploreGroupsAdder';
      switch (this.type) {
        case 'meditation':
          routerName = 'MedGroupsAdder';
          break;
        case 'scene':
        case 'mixed_scene':
          routerName = 'ScenesGroupsAdder';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryGroupsAdder';
          break;
        case 'article':
          routerName = 'ArticleGroupsAdder';
          break;
        case 'content':
          routerName = 'ContentsGroupsAdder';
          break;
      }

      this.$router.push({
        name: routerName,
      });
    },

    gotoEditor(groupInfo) {
      let routerName = 'ExploreGroupsEditor';
      switch (groupInfo.type) {
        case 'meditation':
          routerName = 'MedGroupsEditor';
          break;
        case 'scene':
        case 'mixed_scene':
          routerName = 'ScenesGroupsEditor';
          break;
        case 'sleep_story':
          routerName = 'SleepStoryGroupsEditor';
          break;
        case 'article':
          routerName = 'ArticleGroupsEditor';
          break;
        case 'content':
          routerName = 'ContentsGroupsEditor';
          break;
      }

      this.$router.push({
        name: routerName,
        params: {
          group_id: groupInfo.id,
        },
      });
    },

    async removeGroup(groupInfo) {
      try {
        await this.$confirm('确定要删除该分组吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.$request({
          url: `/v2/admin/explorer/groups/${groupInfo.id}`,
          method: 'DELETE',
        });
        this.list = this.list.filter((item) => item.id !== groupInfo.id);
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        if (e !== 'cancel') {
          this.$message.error(e.message);
        }
      }
    },

    init() {
      this.refreshList();
    },

    async refreshList() {
      this.list = [];
      await this.fetchList();
    },

    async fetchList() {
      const res = await this.$request({
        url: '/v2/admin/explorer/groups',
        params: {
          type: this.type,
        },
      });
      this.list = res.data;
      if (this.type === 'scene') {
        let res = await this.$request({
          url: '/v2/admin/explorer/groups',
          params: {
            type: 'mixed_scene',
          },
        });
        this.list = this.list.concat(res.data);
      }
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

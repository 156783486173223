<template>
  <router-view v-if="$route.name !== 'MedRecommendManager'"></router-view>
  <div v-else>
    <container>
      <div slot="page-header-extra">
        <page-header>
          <i slot="logo" class="material-icons">spa</i>
          <h3 slot="title">隐市小居</h3>
        </page-header>
      </div>
      <div class="container" slot="container">
        <div class="container-head">
          <div class="container-head-title">每日推荐管理</div>
          <div class="container-head-extra">
            <el-button :disabled="true" @click="renew" type="text">一键翻新</el-button>
            <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">新建推荐</el-button>
          </div>
        </div>
        <div class="container-body">
          <el-table :data="list" stripe style="width: 100%">
            <el-table-column label="备注" sortable>
              <template slot-scope="scope">{{ scope.row.remark }}</template>
            </el-table-column>
            <el-table-column label="上架地区">
              <template slot-scope="scope">
                <span class="region" v-for="region in scope.row.regions" v-bind:key="region">
                  {{ region }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="生效时间" prop="valid_from" sortable>
              <template slot-scope="scope">{{ $moment(scope.row.valid_from * 1000).format('YYYY-MM-DD HH:mm:ss') }}</template>
            </el-table-column>
            <el-table-column label="失效时间">
              <template slot-scope="scope">{{ $moment(scope.row.valid_to * 1000).format('YYYY-MM-DD HH:mm:ss') }}</template>
            </el-table-column>

            <el-table-column label="优先级(0最大)">
              <template slot-scope="scope">
                <span>{{ scope.row.sort_key }} </span>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right" width="320">
              <template slot-scope="scope">
                <router-link class="el-button el-button--text" :to="{ name: 'EditMedRecommend', params: { recommend_id: scope.row.id } }">编辑</router-link>
                <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
// import _ from 'lodash'
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import allRegions from '@/regions.json';
import allStatus from '@/status.json';

const regionOptions = Object.keys(allRegions).map((value) => {
  const label = allRegions[value];
  return { value, label };
});

function derangedArray(array) {
  for (var j, x, i = array.length; i; j = parseInt(Math.random() * i), x = array[--i], array[i] = array[j], array[j] = x);
  return array;
}

export default {
  name: 'MedRecommendManager',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      lang: 'zh-Hans',
      allRegions,
      regionOptions: regionOptions,
      langs: [
        {
          value: 'en',
          label: 'en',
        },
        {
          value: 'zh-Hans',
          label: 'zh-Hans',
        },
        {
          value: 'zh-Hant',
          label: 'zh-Hant',
        },
      ],

      filterRegions: [],
      filterStatus: '',

      list: [],

      JSONVisible: false,
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'MedRecommendManager') {
        this.init();
      }
    },
    filterRegions() {
      this.refetch();
    },
    filterStatus() {
      this.refetch();
    },
  },
  computed: {
    idRepeatCounts() {
      const repeatCounts = {};
      this.list.forEach(({ album_id: albumID }) => {
        if (repeatCounts[albumID]) {
          repeatCounts[albumID] = repeatCounts[albumID] + 1;
        } else {
          repeatCounts[albumID] = 1;
        }
      });
      return repeatCounts;
    },
    validFromRepeatCounts() {
      const repeatCounts = {};
      this.list.forEach(({ valid_from }) => {
        if (repeatCounts[valid_from]) {
          repeatCounts[valid_from] = repeatCounts[valid_from] + 1;
        } else {
          repeatCounts[valid_from] = 1;
        }
      });
      return repeatCounts;
    },
    validToRepeatCounts() {
      const repeatCounts = {};
      this.list.forEach(({ valid_to }) => {
        if (repeatCounts[valid_to]) {
          repeatCounts[valid_to] = repeatCounts[valid_to] + 1;
        } else {
          repeatCounts[valid_to] = 1;
        }
      });
      return repeatCounts;
    },
  },
  methods: {
    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.JSONVisible = true;
    },
    addItem() {
      this.$router.push({
        name: 'AddMedRecommend',
      });
    },
    async removeItem(item) {
      const { value } = await this.$prompt('此操作将永久删除该推荐，请输入 “确定删除” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (value !== '确定删除') {
        this.$message.error('输入错误');
      } else {
        try {
          await this.$request({
            url: `/v1/admin/meditation/recommends/${item.id}`,
            method: 'DELETE',
          });
          this.list = this.list.filter((recommend) => recommend.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('操作失败');
        }
      }
    },

    async renew() {
      const res = await this.$request({
        url: '/v1/admin/meditation/recommends',
        params: {
          offset: 0,
          limit: 512,
        },
      });

      const now = parseInt(new Date() / 1000);
      let recommends = res.data.filter((recommend) => {
        return recommend.valid_from > now || recommend.valid_to < now;
      });
      const today = this.$moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      today.add(1, 'day');

      recommends = derangedArray(recommends);

      this.$confirm('此操作会将除正在生效以外的所有推荐专辑随机打乱，然后按天为单位依次填充今天之后的日期，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          recommends.forEach(async (recommend) => {
            recommend.valid_from = parseInt(+today / 1000);
            today.add(1, 'day');
            recommend.valid_to = parseInt(+today / 1000) - 1;

            await this.$request({
              url: `/v1/admin/meditation/recommends/${recommend.id}`,
              method: 'PUT',
              data: recommend,
            });
          });

          this.$message({
            message: '操作成功',
            type: 'success',
          });

          this.init();
        } catch (e) {
          this.$message.error('操作失败');
        }
      });

      this.init();
    },

    async init() {
      this.refetch();
    },
    refetch() {
      this.fetchList();
    },
    fetchList: async function () {
      const params = {};
      if (this.filterStatus) {
        params.status = this.filterStatus;
      }
      if (this.filterRegions.length) {
        params.regions = this.filterRegions;
      }
      const res = await this.$request({
        url: '/v1/admin/meditation/recommends',
        params,
      });
      this.list = res.data;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  async created() {
    await this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.el-table .el-table__body-wrapper .el-table__row.warning-row td {
  background: #e6a23c48;
}

.el-table .el-table__body-wrapper .el-table__row.error-row td {
  background: #f56c6c48;
}

.el-table .el-table__body-wrapper .el-table__row.success-row td {
  background: #67c23a48;
}
</style>
<style lang="less" scoped>
.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  .select-btn {
    display: inline-block;
  }
  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;
  .select-btn {
    display: none;
  }
  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.reviewing {
  background-color: rgba(255, 126, 126, 0.12);
}

.region + .region::before {
  content: ', ';
}
</style>

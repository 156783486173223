let defaultState = {};

export default {
  state: defaultState,
  mutations: {
    DRAW(state, { pic_url, date, layout_template, content }) {
      Object.assign(state, { pic_url, date, layout_template, content });
    },
  },
  actions: {
    draw({ commit }, data) {
      commit('DRAW', data);
    },
  },
  getters: {
    getCanvaData: ({ pic_url, date, layout_template, content }) => {
      return { pic_url, date, layout_template, content };
    },
  },
};

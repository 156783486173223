<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="act-btn" @click="save">保存</el-button>
        <template v-if="$store.getters.hasEditRole">
          <div class="el-dropdown act-btn" v-if="!notChanged">
            <el-button-group>
              <el-button type="default" size="small" disabled>同步至所有平台</el-button>
              <el-button type="default" size="small" disabled class="el-dropdown__caret-button">
                <i class="el-dropdown__icon el-icon-arrow-down"></i>
              </el-button>
            </el-button-group>
          </div>
          <el-dropdown v-else class="act-btn" size="small" split-button @click="syncToAll" @command="syncToSomeone">
            同步至所有平台
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="production">同步至正式环境</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <el-button type="plain" size="small" class="act-btn" @click="nextDay">下一天</el-button>
        <el-button type="plain" size="small" class="act-btn" @click="prevDay">上一天</el-button>
        <el-button type="plain" size="small" class="act-btn" @click="showJSON">JSON</el-button>
      </div>
      <el-dialog title="JSON" :visible.sync="JSONVisible">
        <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
      </el-dialog>
    </div>
    <div slot="container" class="container">
      <div class="container-rows">
        <div class="container">
          <div>
            <el-form class="form container-body" v-model="form" label-width="120px">
              <el-form-item label="快速填充" style="margin-bottom: 0">
                <el-input type="textarea" :rows="2" v-model="shortcut" placeholder="英文引述, 英文标题, 英文作者, 英文节日, 中文引述, 中文标题, 中文作者, 中文节日"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="container">
            <div style="marginleft: 12px; marginright: 12px">
              <el-menu mode="horizontal" :default-active="activeLang" @select="activeLang = $event">
                <el-menu-item index="zh-Hans">简中</el-menu-item>
                <el-menu-item index="zh-Hant">繁中</el-menu-item>
                <el-menu-item index="en">英文</el-menu-item>
              </el-menu>
            </div>
            <el-form class="form container-body" v-model="form" label-width="120px">
              <el-form-item label="文摘">
                <el-input type="textarea" :rows="3" v-model="form.content[activeLang].quote.text"></el-input>
              </el-form-item>

              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="作者">
                    <el-input v-model="form.content[activeLang].author.text"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="作者头衔">
                    <el-input v-model="form.content[activeLang].author_title.text"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item label="标签">
                    <el-input v-model="form.content[activeLang].festival.text"></el-input>
                    <div v-if="lunar || lunar.festival.length || lunar.term" class="festival-tips">
                      <span>当天是</span>
                      <span class="festival" v-for="(festival, idnex) in lunar.festival" :key="festival.desc">{{ idnex > 0 ? ', ' : '' }}{{ festival.desc }}</span>
                      <span v-if="lunar.term" class="festival">{{ lunar.festival.length ? ', ' : '' }}{{ lunar.term }}</span>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item v-if="activeLang === 'zh-Hant'">
                    <el-button type="text" @click="s2tw">转繁体</el-button>
                    <el-button type="text" @click="s2twp">转台湾词汇</el-button>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="24">
                  <el-form-item label="标签样式">
                    <el-radio-group v-model="festivalStyle">
                      <el-radio border :label="0">
                        <span class="festivalTag">无</span>
                      </el-radio>
                      <el-radio border :label="1">
                        <span class="festivalTag" style="background-color: rgba(214, 168, 65, 0.9); color: rgba(255, 255, 255, 0.7)">样式一</span>
                      </el-radio>
                      <el-radio border :label="2">
                        <span class="festivalTag" style="background-color: rgba(0, 0, 0, 0.24); color: rgba(255, 255, 255, 0.5)">样式二</span>
                      </el-radio>
                      <el-radio border :label="3">
                        <span class="festivalTag" style="background-color: rgba(97, 144, 232, 0.9); color: rgba(255, 255, 255, 0.7)">样式三</span>
                      </el-radio>
                      <el-radio border :label="4">
                        <span class="festivalTag" style="background-color: rgba(255, 255, 255, 0.9); color: rgba(0, 0, 0, 0.8)">样式四</span>
                      </el-radio>
                      <el-radio border :label="5">
                        <span class="festivalTag" style="background-color: rgba(255, 73, 73, 0.9); color: rgba(255, 255, 255, 0.7)">样式五</span>
                      </el-radio>
                      <el-radio border :label="6">
                        <span class="festivalTag" style="background-color: rgba(19, 206, 102, 0.9); color: rgba(255, 255, 255, 0.7)">样式六</span>
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-col :span="12">
                <el-form-item label="标签跳转">
                  <el-col><scheme-input v-model="form.content[activeLang].festival_link" placeholder="tide://"></scheme-input></el-col>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="标签图标">
                  <el-col><image-uploader v-model="form.content[activeLang].festival_icon" resourceFolder="scenes"></image-uploader></el-col>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="水印图片">
                  <el-col>
                    <div class="preview" v-if="form.watermark.url" @click="dialogDisplay.watermark = true">
                      <img class="preview-img" :src="form.watermark.url" />
                      <div class="preview-img-cover">
                        <i class="material-icons">edit</i>
                        <span>编辑</span>
                      </div>
                    </div>
                    <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadWatermarkProgress" :on-error="uploadWatermarkError" :on-success="uploadWatermarkSuccess" :before-upload="beforeUploadWatermark" :data="{ token: picToken }" :show-file-list="false">
                      <div class="uploadPic">
                        <i class="el-icon-upload2"></i>
                        <span>上传封面</span>
                      </div>
                    </el-upload>
                    <el-dialog title="水印设置" :visible.sync="dialogDisplay.watermark">
                      <el-form label-position="right">
                        <el-form-item label="URL" label-width="80px">
                          <el-input v-model="form.watermark.url"></el-input>
                        </el-form-item>
                      </el-form>
                      <img class="preview-dialog-img" :src="form.watermark.url" />
                    </el-dialog>
                  </el-col>
                </el-form-item>
              </el-col>

              <!-- <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="水印宽度(640)">
                  <el-input-number :min="0" v-model="form.watermark.width"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="水印高度(165)">
                  <el-input-number :min="0" v-model="form.watermark.height"></el-input-number>
                </el-form-item>
              </el-col>
              </el-row>-->
            </el-form>
          </div>
        </div>

        <div class="sub-container">
          <div class="container-head">
            <div class="container-head-title">日帖剪裁</div>
          </div>
          <el-form class="form container-body" v-model="form" label-width="120px">
            <darkroom :form="form" :image-info="imageInfo" :crop="crop" :crop-scale="cropScale" @realTime="realTime" @updateImage="updateImage"></darkroom>
          </el-form>
        </div>
      </div>
    </div>

    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">预览</div>
      </div>
      <el-form class="form container-body" v-model="form" label-width="120px">
        <previews :form="form" :image-info="imageInfo" :crop="crop" :active-lang="activeLang"></previews>
      </el-form>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
const authentication = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return (roleCode & role) === role;
};

function getPostion(txt) {
  const p = /translate3d\(([\s\S*?]+)px,([\s\S*?]+)px,0/gi;
  const m = p.exec(txt);
  if (m !== null) {
    return {
      x: -m[1] + 0,
      y: -m[2] + 0,
    };
  } else {
    return { x: 0, y: 0 };
  }
}

function getCropSize(txt) {
  const p = /([\s\S*?]+)px/;
  const size = p.exec(txt)[1];
  return size;
}
import _ from 'lodash';
import lunar from '@/plugins/lunar';
import ImageUploader from '@/components/image-uploader';
import SchemeInput from '@/components/scheme-input';
import Container from '@/components/container';
import Darkroom from './darkroom';
import Previews from './previews';
import OpenCC from '@/plugins/opencc';

export default {
  name: 'EquipForm',
  components: {
    Container,
    Darkroom,
    Previews,
    ImageUploader,
    SchemeInput,
  },
  props: {
    form: {
      type: Object,
    },
    oData: {
      type: Object,
    },
  },
  data() {
    return {
      dialogDisplay: {
        watermark: false,
      },
      watermarkUploading: false,
      picToken: '',
      picCDN: '',
      notChanged: true,
      activeLang: 'zh-Hans',
      JSONVisible: false,
      imageInfo: null,
      cropScale: 1,
      crop: {
        width: 0,
        height: 0,
        imgWidth: 0,
        imgHeight: 0,
        dx: 0,
        dy: 0,
      },
      lunar: {},
      shortcut: '',
    };
  },
  computed: {
    detail: {
      get() {
        return JSON.stringify(this.form, undefined, 2);
      },
      set(value) {
        this.$emit('update', JSON.parse(value));
      },
    },
    cropPath() {
      const scale = this.cropScale;
      const cropsize = `${parseInt(this.crop.width / scale)}x${parseInt(this.crop.height / scale)}`;
      const dx = parseInt(this.crop.dx / scale);
      const dy = parseInt(this.crop.dy / scale);
      return `!${cropsize}a${dx}a${dy}`;
    },
    festivalStyle: {
      get() {
        const { festival } = this.form.content[this.activeLang];
        if (festival.background_color === '214, 168, 65, 0.9' && festival.color === '255, 255, 255, 0.7') {
          return 1;
        } else if (festival.background_color === '0, 0, 0, 0.24' && festival.color === '255, 255, 255, 0.5') {
          return 2;
        } else if (festival.background_color === '97, 144, 232, 0.9' && festival.color === '255, 255, 255, 0.7') {
          return 3;
        } else if (festival.background_color === '255, 255, 255, 0.9' && festival.color === '0, 0, 0, 0.8') {
          return 4;
        } else if (festival.background_color === '255, 73, 73, 0.9' && festival.color === '255, 255, 255, 0.7') {
          return 5;
        } else if (festival.background_color === '19, 206, 102, 0.9' && festival.color === '255, 255, 255, 0.7') {
          return 6;
        } else {
          return 0;
        }
      },
      set(festivalStyle) {
        const { festival } = this.form.content[this.activeLang];
        switch (festivalStyle) {
          case 1:
            festival.background_color = '214, 168, 65, 0.9';
            festival.color = '255, 255, 255, 0.7';
            break;
          case 2:
            festival.background_color = '0, 0, 0, 0.24';
            festival.color = '255, 255, 255, 0.5';
            break;
          case 3:
            festival.background_color = '97, 144, 232, 0.9';
            festival.color = '255, 255, 255, 0.7';
            break;
          case 4:
            festival.background_color = '255, 255, 255, 0.9';
            festival.color = '0, 0, 0, 0.8';
            break;
          case 5:
            festival.background_color = '255, 73, 73, 0.9';
            festival.color = '255, 255, 255, 0.7';
            break;
          case 6:
            festival.background_color = '19, 206, 102, 0.9';
            festival.color = '255, 255, 255, 0.7';
            break;
          default:
            festival.background_color = '';
            festival.color = '';
        }
      },
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.notChanged = _.isEqual(val, this.oData);
      },
    },
    'form.date'(val) {
      this.lunar = lunar(val);
    },
    shortcut(val) {
      const quoteInfos = val.split('	');
      if (quoteInfos.length !== 8) {
        this.$message.error('解析失败，无法填充');
      } else {
        const [en_quote, en_author_title, en_author, en_festival, zh_hans_quote, zh_hans_author_title, zh_hans_author, zh_hans_festival] = quoteInfos;
        this.$nextTick(() => {
          this.form.content.en.quote.text = en_quote;
          this.form.content.en.author_title.text = en_author_title;
          this.form.content.en.author.text = en_author;
          this.form.content.en.festival.text = en_festival;
          this.form.content['zh-Hans'].quote.text = zh_hans_quote;
          this.form.content['zh-Hans'].author_title.text = zh_hans_author_title;
          this.form.content['zh-Hans'].author.text = zh_hans_author;
          this.form.content['zh-Hans'].festival.text = zh_hans_festival;
          this.s2tw();
        });
      }
    },
  },
  methods: {
    beforeUploadWatermark: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadWatermarkSuccess(response) {
      this.form.watermark.url = `${this.picCDN}/${response.key}`;
      this.watermarkUploading = false;
    },
    uploadWatermarkError(response) {
      this.watermarkUploading = false;
    },
    uploadWatermarkProgress(response) {
      this.watermarkUploading = true;
    },
    realTime(data) {
      const position = getPostion(data.img.transform);
      this.crop = {
        ...this.crop,
        width: getCropSize(data.div.width),
        height: getCropSize(data.div.height),
        imgWidth: getCropSize(data.img.width),
        imgHeight: getCropSize(data.img.height),
        dx: position.x,
        dy: position.y,
      };
    },
    updateImage(data) {
      const { cropScale, imageInfo } = data;
      this.cropScale = cropScale;
      this.imageInfo = imageInfo;
    },
    async s2tw() {
      try {
        this.form.content['zh-Hant']['author']['text'] = this.opencc_tw(this.form.content['zh-Hans']['author']['text']);
        this.form.content['zh-Hant']['author_title']['text'] = this.opencc_tw(this.form.content['zh-Hans']['author_title']['text']);
        this.form.content['zh-Hant']['festival']['text'] = this.opencc_tw(this.form.content['zh-Hans']['festival']['text']);
        this.form.content['zh-Hant']['quote']['text'] = this.opencc_tw(this.form.content['zh-Hans']['quote']['text']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    async s2twp() {
      try {
        this.form.content['zh-Hant']['author']['text'] = this.opencc_twp(this.form.content['zh-Hans']['author']['text']);
        this.form.content['zh-Hant']['author_title']['text'] = this.opencc_twp(this.form.content['zh-Hans']['author_title']['text']);
        this.form.content['zh-Hant']['festival']['text'] = this.opencc_twp(this.form.content['zh-Hans']['festival']['text']);
        this.form.content['zh-Hant']['quote']['text'] = this.opencc_twp(this.form.content['zh-Hans']['quote']['text']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    save() {
      const data = Object.assign({}, this.form);
      delete data.pic_url;
      data.watermark.width = 640;
      data.watermark.height = 165;
      if (this.cropPath) {
        const url = data.raw_pic.split('?')[0];
        if (url) {
          data.raw_pic = `${url}?imageMogr2/crop/${this.cropPath}/quality/100`;
        }
      }
      this.$emit('save', data);
    },
    showJSON() {
      this.JSONVisible = true;
    },
    nextDay() {
      const $date = this.$moment(this.form.date).add(1, 'days');
      this.$router.push({
        name: 'DailypicEditor',
        params: {
          year: $date.format('YYYY'),
          month: $date.format('MM'),
          day: $date.format('DD'),
        },
      });
    },
    prevDay() {
      const $date = this.$moment(this.form.date).subtract(1, 'days');
      this.$router.push({
        name: 'DailypicEditor',
        params: {
          year: $date.format('YYYY'),
          month: $date.format('MM'),
          day: $date.format('DD'),
        },
      });
    },
    syncToProduction: async function () {
      const date = `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`;
      const dailypicsResp = await this.$request({
        url: `v1/dailypics/${date}`,
      });
      const data = dailypicsResp.data;
      try {
        await this.$request({
          baseURL: 'https://davinci.moreless.space/api',
          url: 'v1/admin/dailypics',
          method: 'POST',
          data: data,
        });
        this.$message({
          message: '同步成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error('同步数据至 正式环境 失败');
      }
    },

    syncToAll() {
      if (this.$env === 'dev') {
        this.$prompt('此操作将使测试环境的数据同步到正式环境，请输入 “同步到正式环境” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if (value === '同步到正式环境') {
            this.syncToProduction();
          } else {
            this.$message.error('输入错误');
          }
        });
      } else if (this.$env === 'production') {
        this.$confirm('此操作将影响到相关平台, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.syncToProduction();
        });
      }
    },

    syncToSomeone(command) {
      if (this.$env === 'dev') {
        this.$prompt('此操作将使测试环境的数据同步到正式环境，请输入 “这是测试环境” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if (value === '这是测试环境') {
            switch (command) {
              case 'production':
                this.syncToProduction();
                break;
            }
          } else {
            this.$message.error('输入错误');
          }
        });
      } else if (this.$env === 'production') {
        this.$confirm('此操作将影响到相关平台, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          switch (command) {
            case 'production':
              this.syncToProduction();
              break;
          }
        });
      }
    },
  },
  created: async function () {
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
    this.opencc_tw = await OpenCC.Converter('cn', 'tw');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-rows {
  display: flex;
  flex-direction: rows;
}
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .act-btn + .act-btn {
    margin-left: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}

.festivalTag {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  border-radius: 4px;
}

.icon {
  margin-left: 4px;
}
.el-radio.is-bordered {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0 20px 0 10px;
  display: inline-flex;
  align-items: center;
}

.material-icons {
  font-size: 18px;
}
.form-label {
  position: relative;
  z-index: 2;
}
.el-form-item__label > .form-label i {
  opacity: 0;
}
.el-form-item:hover > .el-form-item__label > .form-label i {
  opacity: 1;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 16px auto 0;
  max-width: 100%;
}

.festival-tips {
  font-size: 11px;
}
</style>

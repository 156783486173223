<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddScene',
  components: {
    EquipForm: Form,
  },
  data() {
    const { category_type: categoryType } = this.$route.params;
    return {
      form: {
        id: '',
        play_mode: 'random',
        status: 'draft',
        name: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        category: categoryType,
        fresh_until: 0,
        image: '',
        sub_title: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        description: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        primary_color: '0,0,0,1',
        scenes_ids: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      console.log('save');
      const sceneRes = await this.$request({
        url: `/v1/admin/scene_groups`,
        method: 'POST',
        data,
      });
      const scene = sceneRes.data;
      const { category_type: categoryType } = this.$route.params;
      this.$router.replace({
        name: 'ScenePackagesEditor',
        params: {
          package_id: scene.id,
          category_type: categoryType,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div class="container" slot="container">
      <div class="container-body-header">
        <div class="container-body-header-title">用户评论</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" size="small" @click="cleanAllComments">全部清除</el-button>
          <el-button v-if="form.comment_limit" :disabled="!$store.getters.hasEditRole" size="small" @click="unCommentLimit">解除禁言</el-button>
          <el-button v-else :disabled="!$store.getters.hasEditRole" type="danger" size="small" @click="commentLimit">禁言</el-button>
        </div>
      </div>
      <div class="container-body">
        <div class="list-item" v-for="item in list" :key="item.id">
          <div class="list-main-item">
            <div class="list-item-name">{{ item.content }}</div>
            <div v-if="item.reply_to.id">
              (回复
              <router-link :to="{ name: 'RikeUserComments', params: { user_id: item.reply_to.user_id } }">{{ item.reply_to.user_nickname }}</router-link
              >)
            </div>
          </div>
          <div>
            <div>Date</div>
            <div>{{ $moment(item.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </div>
          <div class="list-item-actions">
            <span v-if="item.status === 0">已删</span>
            <el-button v-else :disabled="!$store.getters.hasEditRole" type="text" @click="deleteItem(item)">删除</el-button>
          </div>
        </div>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';

export default {
  name: 'UserForm',
  components: {
    Container,
    PageHeader,
  },
  props: {
    form: {
      type: Object,
    },
    list: {
      type: Array,
    },
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      phoneLock: true,
      emailLock: true,
      newNationCode: '',
      newPhone: '',
      newEmail: '',
      baseRikeUrl,
    };
  },
  computed: {},
  watch: {},
  methods: {
    cleanAllComments() {
      this.$confirm('真的要删除该用户的所有评论吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$request({
          method: 'DELETE',
          url: `${this.baseRikeUrl}/v1/admin/users/${this.$route.params.user_id}/all_comments`,
        });
        this.list.forEach((item) => {
          item.status = 0;
        });
      });
    },
    commentLimit() {
      this.$confirm('真的将该用户禁言吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$request({
          method: 'PUT',
          url: `${this.baseRikeUrl}/v1/admin/users/${this.$route.params.user_id}/comment_limit`,
        });
        this.form.comment_limit = true;
      });
    },
    unCommentLimit() {
      this.$confirm('确定要为该用户解除禁言吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$request({
          method: 'DELETE',
          url: `${this.baseRikeUrl}/v1/admin/users/${this.$route.params.user_id}/comment_limit`,
        });
        this.form.comment_limit = false;
      });
    },

    deleteItem(item) {
      const that = this;
      this.$confirm('删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const itemId = item.id;
        await that.$request({
          method: 'DELETE',
          url: `${this.baseRikeUrl}/v1/admin/comments/${itemId}`,
        });
        const indexOfItem = this.list.findIndex((item) => {
          return item.id === itemId;
        });
        this.list.splice(indexOfItem, 1, Object.assign({}, this.list[indexOfItem], { status: 0 }));
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
  },
  created: async function () {
    console.log(this.form);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-head-extra {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: block;
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.el-button + span {
  margin-left: 10px;
}
</style>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">交易记录</div>
      </div>
      <div class="container-body">
        <el-table :data="list">
          <el-table-column prop="channel" label="渠道"></el-table-column>
          <el-table-column prop="type" label="类型"></el-table-column>
          <el-table-column prop="status" label="状态"></el-table-column>
          <el-table-column prop="product_id" label="SKU"></el-table-column>
          <el-table-column prop="txn_id" label="内部交易ID"></el-table-column>
          <el-table-column prop="external_txn_id" label="外部交易ID"></el-table-column>

          <el-table-column prop="created_at" label="创建时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="updated_at" label="更新时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column width="240" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'EditEquip',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      JSONVisible: false,
      list: [],
      detail: '',
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    refetch() {
      this.list = [];
      this.fetch();
    },
    fetch: async function () {
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/transactions`,
      });
      this.list = res.data;
    },
    init: async function () {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
    },
  },
  created() {
    this.init();
    this.fetch();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>

/* eslint-disable */
const getUrlParams = function (callback) {
  let queryString = {};
  let query = window.location.search.substring(1);
  const vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    // If first entry with this name
    if (typeof queryString[pair[0]] === 'undefined') {
      queryString[pair[0]] = decodeURIComponent(pair[1]);
      // If second entry with this name
    } else if (typeof queryString[pair[0]] === 'string') {
      var arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
      queryString[pair[0]] = arr;
      // If third or later entry with this name
    } else {
      queryString[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return queryString;
};

const changeUrlParam = function (url, paramName, paramValue) {
  if (paramValue == null) {
    paramValue = '';
  }
  const pattern = new RegExp('\\b(' + paramName + '=).*?(&|$)');
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + paramValue + '$2');
  }
  return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
};

const qiniuUrlsafeBase64Encode = function (str) {
  // 首先进行 UTF-8 编码
  const utf8Bytes = new TextEncoder().encode(str);
  // 将 UTF-8 编码的字节数组转换为 Base64
  const base64 = btoa(String.fromCharCode.apply(null, utf8Bytes));
  // https://developer.qiniu.com/kodo/1231/appendix#1
  // URL安全的Base64编码适用于以URL方式传递Base64编码结果的场景。
  // 该编码方式的基本过程是先将内容以Base64格式编码为字符串，然后检查该结果字符串，将字符串中的加号+换成中划线-，并且将斜杠/换成下划线_。
  return base64.replace(/\+/g, '-').replace(/\//g, '_');
};

export { getUrlParams, changeUrlParam, qiniuUrlsafeBase64Encode };

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button v-if="$env === 'dev'" :disabled="!$store.getters.hasEditRole" type="plain" size="small" class="save-btn" @click="showCopyDialog">复制至正式环境</el-button>
        <el-button v-else type="plain" size="small" class="save-btn" @click="showCopyDialog">复制至测试环境</el-button>
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
        <el-button type="plain" size="small" class="save-btn" @click="nextDay">下一天</el-button>
        <el-button type="plain" size="small" class="save-btn" @click="prevDay">上一天</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">{{ xForm.date_by_day }}</div>
      </div>
      <el-form class="form container-body" v-model="xForm" label-width="120px">
        <el-form-item label="主题">
          <el-input v-model="xForm.title"></el-input>
        </el-form-item>

        <el-form-item label="图文">
          <el-input type="textarea" v-model="xForm.article" :rows="12"></el-input>
        </el-form-item>

        <el-form-item label="出处">
          <el-input v-model="xForm.provenance"></el-input>
        </el-form-item>

        <el-form-item label="作者">
          <el-select v-model="selectedAuthorName" filterable allow-create default-first-option remote @visible-change="showSelect" :remote-method="remoteMethod" :loading="loading" placeholder="请选择图文作者">
            <el-option v-for="author in filteredAuthors" :key="author.name" :label="author.name" :value="author.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-dialog title="复制图文" :visible.sync="copyDialogVisible">
        <el-form label-position="right">
          <el-form-item label="日期：">
            <el-date-picker v-model="copyToDate" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="copyDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="copy">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </container>
</template>

<script>
const authentication = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return (roleCode & role) === role;
};

import Container from '@/components/container';
export default {
  name: 'LessonForm',
  components: {
    Container,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      loading: false,
      baseRikeUrl,
      authors: [],
      keyword: '',
      copyDialogVisible: false,
      permission: false,
      copyToDate: this.$moment()
        .add(1, 'days')
        .format('YYYY-MM-DD'),
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    selectedAuthorName: {
      get() {
        return this.form.author.name;
      },
      async set(authorName) {
        const author = this.authors.find(author => author.name === authorName);
        if (author) {
          this.xForm.author = author;
        } else {
          const res = await this.$request({
            url: `${this.baseRikeUrl}/v1/admin/authors`,
            method: 'POST',
            data: {
              name: authorName,
            },
          });
          this.authors = [...this.authors, res.data];
          this.xForm.author = { id: res.data.id, name: res.data.name };
        }
      },
    },
    filteredAuthors() {
      if (!this.keyword) {
        return this.authors;
      } else if (this.authors.length) {
        return this.authors.filter(author => {
          return ~author.name.indexOf(this.keyword);
        });
      } else {
        return [this.form.author];
      }
    },
  },
  watch: {},
  methods: {
    showCopyDialog() {
      const { date_by_day: dateByDay } = this.form;
      const year = `${dateByDay}`.slice(0, 4);
      const month = `${dateByDay}`.slice(4, 6);
      const day = `${dateByDay}`.slice(6, 8);
      this.copyToDate = this.$moment(`${year}-${month}-${day}`).format('YYYY-MM-DD');
      this.copyDialogVisible = true;
    },
    copy() {
      if (this.$env === 'dev') {
        this.$confirm('将替换正式环境当天的内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          this.$emit('copy', this.copyToDate);
        });
      } else {
        this.$emit('copy', this.copyToDate);
      }
    },
    async showSelect() {
      if (!this.xForm.author.name) {
        if (!this.authors.length) {
          this.loading = true;
          const res = await this.$request({
            url: `${this.baseRikeUrl}/v1/admin/authors`,
            params: {
              offset: 0,
              limit: 512,
            },
          });
          this.authors = res.data;
          this.loading = false;
        }
      }
      this.keyword = this.xForm.author.name;
    },

    async remoteMethod(keyword) {
      this.keyword = keyword;
      if (!this.authors.length) {
        this.loading = true;
        const res = await this.$request({
          url: `${this.baseRikeUrl}/v1/admin/authors`,
          params: {
            offset: 0,
            limit: 512,
          },
        });
        this.authors = res.data;
        this.loading = false;
      }
    },
    save() {
      this.$emit('save', this.form);
    },
    nextDay() {
      const { date_by_day: dateByDay } = this.form;
      const year = `${dateByDay}`.slice(0, 4);
      const month = `${dateByDay}`.slice(4, 6);
      const day = `${dateByDay}`.slice(6, 8);
      const $date = this.$moment(`${year}-${month}-${day}`);
      $date.add(1, 'days');
      this.$router.push({
        name: 'LessonEditor',
        params: {
          year: $date.format('YYYY'),
          month: $date.format('MM'),
          day: $date.format('DD'),
        },
      });
    },
    prevDay() {
      const { date_by_day: dateByDay } = this.form;
      const year = `${dateByDay}`.slice(0, 4);
      const month = `${dateByDay}`.slice(4, 6);
      const day = `${dateByDay}`.slice(6, 8);
      const $date = this.$moment(`${year}-${month}-${day}`);
      $date.subtract(1, 'days');
      this.$router.push({
        name: 'LessonEditor',
        params: {
          year: $date.format('YYYY'),
          month: $date.format('MM'),
          day: $date.format('DD'),
        },
      });
    },
  },
  async created() {
    this.keyword = this.form.author.name;
    const role = this.$store.getters.role;
    this.permission = authentication(role, 100);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 28px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.soundIcon img {
  background-color: rgba(0, 0, 0, 0.16);
}
</style>

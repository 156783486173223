<template>
  <router-view v-if="$route.name !== 'Reviewers'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">审阅者管理</div>
          <div class="container-body-header-extra"></div>
        </div>
        <div class="container-body">
          <div class="container-body-left">
            <el-row>
              <el-button @click="addReviewerDialogShow()" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)">新增审阅者</el-button>
            </el-row>
          </div>

          <el-table :data="reviewers_with_userinfo">
            <el-table-column label="头像">
              <template slot-scope="scope">
                <img class="avatar" :src="scope.row.user.avatar || 'https://pics.tide.moreless.io/avatar/Fvut7zZlzpasbn9Uw-FZGzDpcM53'" />
              </template>
            </el-table-column>
            <el-table-column prop="user_id" label="userid"></el-table-column>
            <el-table-column prop="user.sid" label="SID"></el-table-column>
            <el-table-column prop="user.nickname" label="昵称"></el-table-column>
            <el-table-column label="真实数据权限">
              <template slot-scope="scope">
                <span>{{ scope.row.reviewer.has_real_stats }}</span>
              </template>
            </el-table-column>
            <el-table-column width="120" label="操作">
              <template slot-scope="scope">
                <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </container>

    <!-- 增加审阅用户 -->
    <el-dialog title="增加审阅用户" :visible.sync="addReviewerDialog.dialogShow">
      <el-form>
        <el-form-item label="用户 uid">
          <el-input placeholder="用户 uid" v-model="addReviewerDialog.user_id"></el-input>
        </el-form-item>
        <el-form-item label="真实数据权限">
          <el-radio-group v-model="addReviewerDialog.has_real_stats">
            <el-radio :label="true">有</el-radio>
            <el-radio :label="false">无</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addReviewerDialog.dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="addReviewerSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'ClientLog',
  components: {
    Container,
  },
  data() {
    return {
      searchBusy: false,
      busy: false,
      finish: false,
      reviewers_with_userinfo: [],
      reviewers: [],
      searchRes: [],
      addReviewerDialog: {
        dialogTitle: '',
        dialogShow: false,
        user_id: '',
        has_real_stats: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'Reviewers') {
        this.refetch();
      }
    },
  },
  methods: {
    async addReviewerSave() {
      const reviewers = this.reviewers.filter(i => i.user_id !== this.addReviewerDialog.user_id);
      const new_reviewers = [
        ...reviewers,
        {
          user_id: this.addReviewerDialog.user_id,
          has_real_stats: this.addReviewerDialog.has_real_stats,
        },
      ];
      this.setReviewers(new_reviewers);
      this.addReviewerDialog.dialogShow = false;
    },
    addReviewerDialogShow() {
      this.addReviewerDialog.dialogShow = true;
      this.addReviewerDialog.user_id = '';
      this.addReviewerDialog.has_real_stats = false;
    },
    async removeItem(item) {
      const reviewers = this.reviewers.filter(i => i.user_id !== item.user_id);
      this.setReviewers(reviewers);
    },
    async setReviewers(reviewers) {
      try {
        const res = await this.$request({
          method: 'PUT',
          url: '/v1/admin/reviewers',
          data: {
            reviewers,
          },
        });
        console.log(res);
        this.$message({
          message: '设置成功',
          type: 'success',
        });
        this.refetch();
      } catch (e) {
        this.$message.error('设置失败');
      }
    },
    refetch() {
      this.finish = false;
      this.fetch();
    },
    fetch: async function() {
      this.busy = true;
      try {
        const res = await this.$request({
          url: '/v1/admin/reviewers',
        });
        this.reviewers = res.data.reviewers;
        this.reviewers_with_userinfo = res.data.reviewers_with_userinfo;
      } catch (e) {
        console.log(e);
      }
    },
    search: async function(sid) {
      this.searchBusy = true;
      const res = await this.$request({
        url: '/v1/admin/users',
        params: {
          fuzzy: true,
          limit: 10,
          offset: 0,
          sid,
        },
      });
      const users = res.data;
      this.searchRes = users;
      this.searchBusy = false;
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.mini-avatar {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
.select-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
</style>

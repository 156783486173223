<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">分组配置</div>
      </div>
      <el-form class="form container-body" v-model="form">
        <el-form-item>
          <el-tabs type="border-card" v-model="activeLang">
            <el-tab-pane v-for="lang in langs" :key="lang" :label="lang" :name="lang">
              <el-col :span="23">
                <el-form-item label="标题">
                  <el-input v-model="form.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="副标题">
                  <el-input v-model="form.sub_title[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" v-model="form.description[lang]"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="23" v-if="lang === 'zh-Hant'">
                <el-form-item>
                  <el-button type="text" @click="s2twp">简转繁</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="快速填充">
          <el-input type="textarea" :rows="2" v-model="shortcut" placeholder="中文标题, 中文副标题, 中文描述, 英文标题, 英文副标题, 英文描述"></el-input>
        </el-form-item>

        <el-form-item label="分组类型：" v-if="form.type.includes('scene')">
          <el-radio-group v-model="form.type">
            <el-radio :key="'mixed_scene'" label="mixed_scene">混音分组</el-radio>
            <el-radio :key="'scene'" label="scene">声音分组</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="状态：">
          <status-radio v-model="form.status" style="width: 40%"></status-radio>
        </el-form-item>

        <el-form-item label="上架区域：">
          <regions-selecter v-model="form.regions" style="width: 20%"></regions-selecter>
        </el-form-item>

        <el-form-item label="上架语言：">
          <languages-selecter v-model="form.languages" style="width: 20%"></languages-selecter>
        </el-form-item>

        <el-form-item label="iOS上架版本：">
          <el-input-tag v-model="form.ios_versions" style="width: 20%"></el-input-tag>
        </el-form-item>
        <el-form-item label="Android上架版本：">
          <el-input-tag v-model="form.android_versions" style="width: 20%"></el-input-tag>
        </el-form-item>

        <el-form-item label="会员状态：">
          <vip-status-selecter v-model="form.vip_status" style="width: 20%"></vip-status-selecter>
        </el-form-item>

        <el-form-item label="登录状态：">
          <login-status-selecter v-model="form.login_status_v2" style="width: 20%"></login-status-selecter>
        </el-form-item>

        <el-form-item label="探索页展示">
          <span>前</span>
          <el-input-number v-model="form.style.preview_count" size="mini" :min="0" style="display: inline-block; width: 100px; margin: 0 8px"></el-input-number>
          <span>个</span>
        </el-form-item>

        <el-form-item label="分组属性：" v-if="form.type === 'meditation'">
          <el-radio-group v-model="form.is_mini_meditation">
            <el-radio :label="true">轻冥想</el-radio>
            <el-radio :label="false">非轻冥想</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否在冥想探索页隐藏该分组下的专辑：" v-if="form.type === 'meditation'">
          <el-radio-group v-model="form.hide_albums_in_meditation_pages">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否允许分享：">
          <el-radio-group v-model="form.is_can_share">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="container">
      <slot name="content-container"></slot>
    </div>

    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import OpenCC from '@/plugins/opencc';
import ElInputTag from '@/components/el-input-tag';
import RegionsSelecter from '@/components/regions-selecter';
import LanguagesSelecter from '@/components/languages-selecter';
import StatusRadio from '@/components/status-radio';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';

export default {
  name: 'TagForm',
  components: {
    Container,
    ElInputTag,
    RegionsSelecter,
    LanguagesSelecter,
    StatusRadio,
    VipStatusSelecter,
    LoginStatusSelecter,
  },
  model: {
    prop: 'form',
    event: 'change',
  },
  props: {
    form: {},
  },
  data() {
    const env = this.$env;
    return {
      loading: false,
      env,
      langs: require('@/langs.json'),
      activeLang: 'zh-Hans',
      shortcut: '',

      busy: false,
      finish: false,
    };
  },
  watch: {
    shortcut(val) {
      const shortcutInfo = val.split('	');
      console.log(shortcutInfo);
      console.log('--- shortcutInfo ---');
      console.log(shortcutInfo.length);
      if (shortcutInfo.length !== 6) {
        this.$message.error('解析失败，无法填充');
      } else {
        let [zh_hans_name, zh_hans_sub_title, zh_hans_description, en_name, en_sub_title, en_description] = shortcutInfo;

        zh_hans_name = zh_hans_name
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        zh_hans_sub_title = zh_hans_sub_title
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        zh_hans_description = zh_hans_description
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        en_name = en_name
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        en_sub_title = en_sub_title
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        en_description = en_description
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();

        this.$nextTick(() => {
          this.form.name['zh-Hans'] = zh_hans_name;
          this.form.name.en = en_name;
          this.form.name.es = en_name;
          this.form.name.ru = en_name;
          this.form.name.ko = en_name;
          this.form.name.ja = en_name;

          this.form.sub_title['zh-Hans'] = zh_hans_sub_title;
          this.form.sub_title.en = en_sub_title;
          this.form.sub_title.es = en_sub_title;
          this.form.sub_title.ru = en_sub_title;
          this.form.sub_title.ko = en_sub_title;
          this.form.sub_title.ja = en_sub_title;

          this.form.description['zh-Hans'] = zh_hans_description;
          this.form.description.en = en_description;
          this.form.description.es = en_description;
          this.form.description.ru = en_description;
          this.form.description.ko = en_description;
          this.form.description.ja = en_description;

          this.s2twp();
        });
      }
    },
  },
  methods: {
    save() {
      this.$emit('save', this.form);
    },

    async s2twp() {
      try {
        this.form.name['zh-Hant'] = this.opencc_twp(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_twp(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_twp(this.form.description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created: async function () {
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.layers {
  display: inline-flex;
}

.uploader-container {
  display: inline-flex;
  vertical-align: top;
}

.layer-container {
  position: relative;
  width: 480px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.layer {
  position: absolute;
  max-width: 480px;
  max-height: 160px;
  object-fit: cover;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  line-height: 28px;
}
.preview:hover .layer-actions {
  opacity: 1;
}
.layer-actions {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);

  i {
    font-size: 16px;
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.48);
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 0.72);
    }
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
</style>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">图文配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item label="推荐内容：">
          <el-col>
            <div class="cascader-container">
              <el-button type="text">
                {{
                  xForm.type === 'scene'
                    ? `声音场景 · ${getTR(xForm.scene.name) || '未设置'}`
                    : xForm.type === 'mixed_scene'
                    ? `混音场景 · ${getTR(xForm.mixed_scene.name) || '未设置'}`
                    : xForm.type === 'meditation'
                    ? `冥想练习 · ${getTR(xForm.meditation.name) || '未设置'}`
                    : xForm.type === 'article'
                    ? `图文 · ${getTR(xForm.article.title) || '未设置'}`
                    : xForm.type === 'sleep_story'
                    ? `睡前故事 · ${getTR(xForm.sleep_story.name) || '未设置'}`
                    : '未知'
                }}
              </el-button>
              <el-cascader class="cascader" v-model="contentCascaderValues" :options="contentCascaderOptions" :props="contentCascaderProps" @expand-change="expandChange"></el-cascader>
            </div>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-tabs type="border-card" v-model="activeLang">
            <el-tab-pane v-for="lang in langs" :key="lang" :label="lang" :name="lang">
              <el-col :span="23" v-if="$route.params.recommendation_location === 'home'">
                <el-form-item label="名称">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23" v-if="$route.params.recommendation_location === 'explorer'">
                <el-form-item label="推荐语">
                  <el-input type="textarea" :rows="3" v-model="xForm.recommend_content[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23" v-if="$route.params.recommendation_location === 'explorer'">
                <el-form-item label="用户昵称">
                  <el-input v-model="xForm.author_nickname[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23" v-if="$route.params.recommendation_location === 'explorer'">
                <el-form-item label="用户头像">
                  <el-col>
                    <div class="preview" v-if="xForm.avatar[lang]" @click="dialogDisplay.avatarPreview = true">
                      <cover :width="100" :height="100" :src="xForm.avatar[lang]"></cover>
                      <div class="preview-img-cover">
                        <i class="material-icons">remove_red_eye</i>
                        <span>预览</span>
                      </div>
                    </div>
                    <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadAvatarProgress" :on-error="uploadAvatarError" :on-success="uploadAvatarSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                      <div class="uploadPic">
                        <i class="el-icon-upload2"></i>
                        <span>上传封面</span>
                      </div>
                    </el-upload>
                  </el-col>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp">简转繁</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="快速填充" v-if="$route.params.recommendation_location === 'explorer'">
          <el-input type="textarea" :rows="2" v-model="shortcut" placeholder="中文推荐内容, 中文作者昵称, 英文推荐内容, 英文作者昵称"></el-input>
        </el-form-item>

        <el-form-item label="上架地区：" v-if="$route.params.recommendation_location === 'home'">
          <regions-selecter v-model="xForm.regions"></regions-selecter>
        </el-form-item>

        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages"></languages-selecter>
        </el-form-item>
        <el-form-item label="会员状态：">
          <vip-status-selecter v-model="xForm.vip_status"></vip-status-selecter>
        </el-form-item>
        <el-form-item label="登录状态：">
          <login-status-selecter v-model="xForm.login_status_v2"></login-status-selecter>
        </el-form-item>

        <el-form-item label="状态：">
          <status-radio v-model="xForm.status"></status-radio>
        </el-form-item>

        <el-form-item label="（本地）生效时间：" v-if="$route.params.recommendation_location === 'home'">
          <el-date-picker size="medium" type="datetime" v-model="localStartedAt" placeholder="开始时间"></el-date-picker>
          <span>到</span>
          <el-date-picker size="medium" type="datetime" v-model="localEndedAt" placeholder="结束时间"></el-date-picker>
        </el-form-item>

        <el-form-item label="置顶显示：" v-if="$route.params.recommendation_location === 'home'">
          <el-radio-group v-model="form.style.display_on_top">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <el-dialog title="用户头像" :visible.sync="dialogDisplay.avatarPreview">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <cover :width="360" :height="360" :src="xForm.avatar[activeLang]"></cover>
          </el-form-item>
          <el-form-item>
            <el-input v-model="xForm.avatar[activeLang]">
              <i v-if="avatarUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadAvatarProgress" :on-error="uploadAvatarError" :on-success="uploadAvatarSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.avatarPreview = false">返 回</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
import ColorPicker from '@/components/color-picker';
import OpenCC from '@/plugins/opencc';
import RegionsSelecter from '@/components/regions-selecter';
import StatusRadio from '@/components/status-radio';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';
import LanguagesSelecter from '@/components/languages-selecter';

export default {
  name: 'RecommendationForm',
  components: {
    Container,
    Draggable,
    Cover,
    ColorPicker,
    RegionsSelecter,
    StatusRadio,
    VipStatusSelecter,
    LoginStatusSelecter,
    LanguagesSelecter,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = this.$env;
    return {
      contentCascaderOptions: [],
      picToken: '',
      picCDN: '',
      loading: false,
      avatarUploading: false,
      env,
      shortcut: '',
      activeLang: 'zh-Hans',
      langs: require('@/langs.json'),

      contentCascaderProps: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value, pathNodes } = node;
          if (level === 0) {
            resolve(this.getContentType());
          } else if (level === 1) {
            resolve(await this.getTags(value));
          } else {
            resolve(await this.getContent(pathNodes[0].value, value));
          }
        },
      },

      dialogDisplay: {
        avatarPreview: false,
        contentPicker: false,
      },
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    localStartedAt: {
      get() {
        return +this.$moment(this.xForm.start_local_time);
      },
      set(val) {
        this.xForm.start_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },
    localEndedAt: {
      get() {
        return +this.$moment(this.xForm.end_local_time);
      },
      set(val) {
        this.xForm.end_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },
    contentCascaderValues: {
      get() {
        const type = this.xForm.type;
        let content = type === 'scene' ? this.xForm.scene : type === 'mixed_scene' ? this.xForm.mixed_scene : type === 'meditation' ? this.xForm.meditation : type === 'article' ? this.xForm.article : type === 'sleep_story' ? this.xForm.sleep_story : null;
        let tagId = null;
        let contentId = null;
        if (content) {
          if (content.tags_v2 && content.tags_v2.length) {
            tagId = content.tags_v2[0].id;
          }
          if (content.id) {
            contentId = content.id;
          }
        }

        return [type, tagId, contentId];
      },
      set(values) {
        if (values.length !== 3) {
          this.$message.error('必须选择内容');
        } else {
          const [type, , content] = values;

          if (type === 'scene') {
            this.xForm.type = type;
            this.xForm.scene = content;
          } else if (type === 'mixed_scene') {
            this.xForm.type = type;
            this.xForm.mixed_scene = content;
          } else if (type === 'meditation') {
            this.xForm.type = type;
            this.xForm.meditation = content;
          } else if (type === 'article') {
            this.xForm.type = type;
            this.xForm.article = content;
          } else if (type === 'sleep_story') {
            this.xForm.type = type;
            this.xForm.sleep_story = content;
          }
        }
      },
    },
  },

  watch: {
    shortcut(val) {
      const shortcutInfo = val.split('	');
      console.log(shortcutInfo);
      console.log('--- shortcutInfo ---');
      console.log(shortcutInfo.length);
      if (shortcutInfo.length !== 4) {
        this.$message.error('解析失败，无法填充');
      } else {
        let [zh_hans_recommend_content, zh_hans_author_nickname, en_recommend_content, en_author_nickname] = shortcutInfo;

        zh_hans_recommend_content = zh_hans_recommend_content.replace(/^\"|\"$/g, '');
        zh_hans_author_nickname = zh_hans_author_nickname.replace(/^\"|\"$/g, '');
        en_recommend_content = en_recommend_content
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        en_author_nickname = en_author_nickname
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();

        this.$nextTick(() => {
          this.xForm.recommend_content['zh-Hans'] = zh_hans_recommend_content;
          this.xForm.recommend_content.en = en_recommend_content;
          this.xForm.recommend_content.es = en_recommend_content;
          this.xForm.recommend_content.ru = en_recommend_content;
          this.xForm.recommend_content.ko = en_recommend_content;
          this.xForm.recommend_content.ja = en_recommend_content;

          this.xForm.author_nickname['zh-Hans'] = zh_hans_author_nickname;
          this.xForm.author_nickname.en = en_author_nickname;
          this.xForm.author_nickname.es = en_author_nickname;
          this.xForm.author_nickname.ru = en_author_nickname;
          this.xForm.author_nickname.ko = en_author_nickname;
          this.xForm.author_nickname.ja = en_author_nickname;

          this.s2twp();
        });
      }
    },
  },

  methods: {
    save() {
      this.$emit('save', this.form);
    },

    // -----------------------------------------------------------
    // MARK: - 内容选择器
    // -----------------------------------------------------------
    showContentPicker() {
      this.showContentPicker = true;
    },

    expandChange() {
      console.log('expandChange');
    },

    // Level 0
    getContentType() {
      return [
        {
          value: 'scene',
          label: '声音场景',
          leaf: false,
        },
        {
          value: 'mixed_scene',
          label: '混音场景',
          leaf: false,
        },
        {
          value: 'meditation',
          label: '冥想练习',
          leaf: false,
        },
        {
          value: 'article',
          label: '图文内容',
          leaf: false,
        },
        {
          value: 'sleep_story',
          label: '睡前故事',
          leaf: false,
        },
      ];
    },

    // Level 1
    async getTags(type) {
      // share the same tags between scenes and mixed scenes
      if (type == 'mixed_scene') {
        type = 'scene';
      }
      this.tagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type },
      });
      const tags = res.data.map((tag) => {
        return {
          value: tag.id,
          label: this.getTR(tag.name),
        };
      });
      // tags 最前面增加一个 ALL， id 为空
      tags.unshift({
        value: '',
        label: '全部',
      });
      return tags;
    },

    // Level 2
    async getContent(type, tagId) {
      if (type === 'meditation') {
        return this.getMeditationContents(tagId);
      } else if (type === 'scene') {
        return this.getSceneContents(tagId);
      } else if (type === 'mixed_scene') {
        return this.getMixedSceneContents(tagId);
      } else if (type === 'article') {
        return this.getArticleContents(tagId);
      } else if (type === 'sleep_story') {
        return this.getSleepStoryContents(tagId);
      }
    },

    async getMeditationContents(tagId) {
      let { data: list } = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      if (tagId != '') {
        const { data: tag } = await this.$request({
          url: `/v2/admin/object_tags/${tagId}`,
        });
        list = list.filter((item) => {
          return tag.meditation_ids.includes(item.id);
        });
      }

      return list.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    async getSceneContents(tagId) {
      let { data: list } = await this.$request({
        url: '/v1/admin/scenes',
        params: {
          offset: 0,
          limit: 512,
          type: 'normal',
        },
      });
      if (tagId != '') {
        const { data: tag } = await this.$request({
          url: `/v2/admin/object_tags/${tagId}`,
        });
        list = list.filter((item) => {
          return tag.scene_ids.includes(item.id);
        });
      }
      return list.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    async getMixedSceneContents(tagId) {
      let { data: list } = await this.$request({
        url: '/v1/admin/scenes',
        params: {
          offset: 0,
          limit: 512,
          type: 'mixed',
        },
      });
      if (tagId != '') {
        const { data: tag } = await this.$request({
          url: `/v2/admin/object_tags/${tagId}`,
        });
        list = list.filter((item) => {
          return tag.scene_ids.includes(item.id);
        });
      }
      return list.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    async getArticleContents(tagId) {
      let { data: list } = await this.$request({
        url: '/v1/admin/meditation/articles',
        params: {
          offset: 0,
          limit: 512,
        },
      });

      if (tagId != '') {
        const { data: tag } = await this.$request({
          url: `/v2/admin/object_tags/${tagId}`,
        });
        list = list.filter((item) => {
          return tag.article_ids.includes(item.id);
        });
      }
      return list.map((item) => {
        return { value: item, label: this.getTR(item.title), leaf: true };
      });
    },

    async getSleepStoryContents(tagId) {
      let { data: list } = await this.$request({
        url: '/v1/admin/sleep_stories',
        params: {
          offset: 0,
          limit: 512,
        },
      });

      if (tagId != '') {
        const { data: tag } = await this.$request({
          url: `/v2/admin/object_tags/${tagId}`,
        });
        list = list.filter((item) => {
          return tag.sleep_story_ids.includes(item.id);
        });
      }
      return list.map((item) => {
        return { value: item, label: this.getTR(item.name), leaf: true };
      });
    },

    // -----------------------------------------------------------
    // MARK: - 上传头像
    // -----------------------------------------------------------
    beforeUpload: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadAvatarSuccess(response, file) {
      this.avatarUploading = false;
      this.xForm.avatar[this.activeLang] = `https://pics.tide.moreless.io/${response.key}`;
    },
    uploadAvatarProgress(response) {
      this.avatarUploading = true;
    },
    uploadAvatarError(response) {
      this.avatarUploading = false;
    },

    async s2twp() {
      try {
        this.xForm.recommend_content['zh-Hant'] = this.opencc_twp(this.xForm.recommend_content['zh-Hans']);
        this.xForm.name['zh-Hant'] = this.opencc_twp(this.xForm.name['zh-Hans']);
        this.xForm.author_nickname['zh-Hant'] = this.opencc_twp(this.xForm.author_nickname['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created: async function () {
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}

.album-table .el-form-item {
  width: 180px;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.uploadIcon {
  display: inline-block;
}

.cascader-container {
  display: inline-block;
  position: relative;
}

.cascader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
</style>

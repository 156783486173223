import Request from '@/plugins/request';
const { $request } = Request;

const authentication = (roleCode, role) => {
  roleCode = parseInt(roleCode, 2);
  role = parseInt(role, 2);
  return (roleCode & role) === role;
};

export default {
  state: {
    role: 0,
    user_id: '',
  },
  mutations: {
    SET_ROLE(state, role) {
      state.role = role;
    },
    SET_USER(state, user_id) {
      state.user_id = user_id;
    },
  },
  actions: {
    async getRole({ commit }) {
      const resp = await $request({
        url: '/v1/admin/profile',
      });
      console.log('getRole', resp.data);
      commit('SET_ROLE', resp.data.old_role);
      commit('SET_USER', resp.data.id);
    },
  },
  getters: {
    role: ({ role }) => {
      return role;
    },
    user_id: ({ user_id }) => {
      return user_id;
    },
    hasRole: ({ role }) => {
      return checkRole => {
        return authentication(role, checkRole);
      };
    },
    hasEditRole: ({ role }) => {
      return authentication(role, 100);
    },
    hasAdminRole: ({ role }) => {
      return authentication(role, 10);
    },
  },
};

<template>
  <router-view v-if="$route.name !== 'TidesleepManager'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">潮汐睡眠场景</div>
          <div class="container-body-header-extra">
            <el-button :disabled="!$store.getters.hasEditRole" name="nickname" size="small" @click="sortPanelVisible = true">重新排序</el-button>
          </div>
        </div>
        <div class="container-body">
          <el-button :disabled="!$store.getters.hasEditRole" class="add-btn" @click="addItem" plain icon="el-icon-plus">添加</el-button>
        </div>
        <div class="container-body">
          <div v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="500">
            <div class="list-item" v-for="(item, index) in list" :key="item.id">
              <img class="avatar" :alt="index + 1" :src="`${item.img_url}?imageMogr2/thumbnail/!120x120r`" />
              <div class="list-main-item">
                <div class="list-item-name">{{ item.name }}</div>
                <div>{{ item.description }}</div>
              </div>
              <div class="list-item-actions">
                <el-button type="text" @click="editItem(item)">编辑</el-button>
                <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="deleteItem(item)">删除</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialogs" slot="container">
        <el-dialog title="潮汐睡眠场景排序" width="80%" :before-close="handleClose" :visible.sync="sortPanelVisible">
          <draggable class="sort-list-container" v-model="list">
            <div class="scene" v-for="(item, index) in list" :key="item.id" :style="`background-image:url(${item.img_url})`">
              <div class="cover">
                <div>{{ index + 1 }}</div>
                <div>{{ item.name }}</div>
              </div>
            </div>
          </draggable>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="saveSortList">保存</el-button>
          </span>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import Container from '@/components/container';
export default {
  name: 'SplashesManager',
  components: {
    Container,
    Draggable,
  },
  data() {
    return {
      searchQuery: {
        nickname: '',
        email: '',
        phone_number: '',
        nation_code: '',
      },
      form: {
        nickname: '',
        email: '',
        phone_number: '',
        nation_code: '',
      },
      sortPanelVisible: false,
      expand: false,
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'TidesleepManager') {
        this.refetch();
      }
    },
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？').then(() => {
        this.refetch();
        if (typeof done === 'function') {
          done();
        } else {
          this.sortPanelVisible = false;
        }
      });
    },
    saveSortList() {
      this.sortPanelVisible = false;
      this.list.forEach(async (item, index) => {
        await this.$request({
          method: 'PUT',
          url: `/v1/admin/tidesleep/scenes/${item.id}`,
          data: Object.assign(item, { sort_key: index }),
        });
      });
      this.$message({
        message: '保存成功',
        type: 'success',
      });
    },
    addItem() {
      this.$router.push({
        name: 'TidesleepAdd',
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'TidesleepEditor',
        params: {
          id: item.id,
        },
      });
    },
    deleteItem(item) {
      const that = this;
      this.$confirm('此操作将永久删除该场景, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async function () {
        const itemId = item.id;
        await that.$request({
          method: 'DELETE',
          url: `/v1/admin/tidesleep/scenes/${itemId}`,
        });
        that.list = that.list.filter((item) => {
          return item.id !== itemId;
        });
        that.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: '/v1/admin/tidesleep/scenes',
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const scenes = res.data;
      if (scenes.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...scenes];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  margin: 0 16px;
  flex: 1;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.sort-list-container {
  // display: flex;
}
.scene {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 8px;
}
.cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.32);
}
</style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddScene',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        sort_key: 0,
        name: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        description: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        primary_color: '0,0,0,1',
        image: '',
        video_cover_demo_url: '',
        video_cover_key: '',
        regions: ['ALL'],
        provinces: [],
        tags: [],
        style: { show_detail_page: true, size: 'small' },
        status: 'draft',
        blocked_channels: [],
        duration_description: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        fresh_until: 0,
        trial_ended_local_time: '',
        trial_started_local_time: '',
        trial_login_status: [],
        sections: [],
        require_continuous: false,
        is_orderly: false,
        simple_tag_ids: [],
        speaker_id_preference: '',
        languages: [],
        channels: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    verifySpeakerIntegrity(data) {
      /* 判断所有资源都需要有导师字段 */
      let fail = false;
      data.sections.forEach((section) => {
        section.resources_v2.forEach((resource) => {
          if (!resource.speaker_id) {
            fail = true;
          }
        });

        if (section.cognitions) {
          section.cognitions.forEach((resource) => {
            if (!resource.speaker_id) {
              fail = true;
            }
          });
        }
      });

      if (fail) {
        this.$message.error('所有资源都需要填写朗读者信息【认知也需要】');
        return !fail;
      }

      /* 判断每一个章节的导致资源都有 */
      let speaker_id_set = new Set();
      data.sections.forEach((section) => {
        section.resources_v2.forEach((resource) => {
          speaker_id_set.add(resource.speaker_id);
        });
      });
      const isSameSet = (s1, s2) => {
        if (s1.size !== s2.size) {
          return false;
        }
        return [...s1].every((i) => s2.has(i));
      };

      data.sections.forEach((section) => {
        let cur_speaker_id_set = new Set();
        section.resources_v2.forEach((resource) => {
          cur_speaker_id_set.add(resource.speaker_id);
        });
        if (!isSameSet(speaker_id_set, cur_speaker_id_set)) {
          fail = true;
        }
      });
      if (fail) {
        this.$message.error('存在有些章节的练习没有对应的导师');
        return !fail;
      }

      return !fail;
    },
    async save(data) {
      if (!this.verifySpeakerIntegrity(data)) {
        return;
      }
      data.sections.forEach((section) => {
        section.resources_v2.sort((a, b) => {
          if (a.languages[0] === 'en' && b.languages[0] !== 'en') {
            return -1;
          } else if (b.languages[0] === 'en' && a.languages[0] !== 'en') {
            return 1;
          }

          return a.duration - b.duration;
        });
      });
      try {
        const res = await this.$request({
          url: '/v1/admin/meditation/albums',
          method: 'POST',
          data,
        });
        const album = res.data;
        this.$router.replace({
          name: 'EditMedAlbum',
          params: {
            album_id: album.id,
          },
        });
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'RecommendationsAdder',
  components: {
    EquipForm: Form,
  },
  data() {
    const { recommendation_location: location } = this.$route.params;
    return {
      form: {
        scene: { name: {} },
        mixed_scene: { name: {} },
        article: { title: {} },
        meditation: { name: {} },
        type: 'meditation',
        location: location,
        regions: ['ALL'],
        languages: [],
        vip_status: [],
        login_status_v2: [],
        start_local_time: '',
        end_local_time: '',
        style: {
          display_on_top: false,
        },
        name: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        author_nickname: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        avatar: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        recommend_content: {
          en: '',
          es: '',
          ja: '',
          ko: '',
          ru: '',
          'zh-Hans': '',
          'zh-Hant': '',
        },
        status: 'draft',
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      switch (data.type) {
        case 'meditation':
          delete data.mixed_scene;
          delete data.article;
          delete data.scene;
          delete data.sleep_story;
          break;
        case 'scene':
          delete data.mixed_scene;
          delete data.article;
          delete data.meditation;
          delete data.sleep_story;
          break;
        case 'article':
          delete data.mixed_scene;
          delete data.scene;
          delete data.meditation;
          delete data.sleep_story;
          break;
        case 'sleep_story':
          delete data.mixed_scene;
          delete data.scene;
          delete data.meditation;
          delete data.article;
          break;
        case 'mixed_scene':
          delete data.scene;
          delete data.article;
          delete data.meditation;
          delete data.sleep_story;
          break;
      }

      try {
        const res = await this.$request({
          url: '/v2/admin/explorer/recommendations',
          method: 'POST',
          data,
        });
        const recommend = res.data;
        this.$router.replace({
          name: 'RecommendationsEditor',
          params: {
            recommend_id: recommend.id,
          },
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error(e.message);
      }
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

import Request from '@/plugins/request';
const { $request } = Request;

export default {
  state: {
    calendar: [],
  },
  mutations: {
    SET(state, dailypics) {
      state.calendar = dailypics;
    },
  },
  actions: {
    getDailypics: async function ({ commit }, { from, to }) {
      const dailypicsResp = await $request({
        url: '/v1/dailypics',
        params: {
          from,
          to,
        },
      });
      const dailypics = dailypicsResp.data;
      console.log(dailypics);
      commit('SET', dailypics);
    },
    setDailypic: async function ({ commit }, data) {
      const dailypicResp = await $request({
        url: 'v1/admin/dailypics',
        method: 'POST',
        data: data,
      });
      console.log(dailypics);
      const dailypics = [dailypicResp.data];
      commit('SET', dailypics);
    },
  },
  getters: {
    dailypic:
      ({ calendar }) =>
      (date) => {
        return calendar.find((dailypic) => {
          return dailypic.date === date;
        });
      },
    dailypics: ({ calendar }) => {
      return calendar;
    },
  },
};

<template>
  <div class="container">
    <div class="container-head">
      <div class="container-head-title">参与者列表</div>
      <div class="container-head-extra">
        <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="gotoAdder">新建参与者</el-button>
        <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="showListSortPanel">重新排序</el-button>
      </div>
    </div>
    <div class="container-body">
      <el-table :data="list" stripe style="width: 100%">
        <el-table-column :label="namespace">
          <template slot-scope="scope">
            <div class="participant-info">
              <cover v-if="scope.row.avatar.length" :width="48" :height="48" :src="scope.row.avatar"></cover>
              {{ getTR(scope.row.name_i18n) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ID">
          <template slot-scope="scope">
            <el-button type="text" @click="copyToBoard(scope.row.id)">{{ scope.row.id }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="嘉宾">
          <template slot-scope="scope">{{ scope.row.special ? '是' : '否' }}</template>
        </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">{{ scope.row.role }}</template>
        </el-table-column>
        <el-table-column label="语言">
          <template slot-scope="scope">{{ scope.row.language }}</template>
        </el-table-column>
        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="gotoEditor(scope.row)">编辑</el-button>
            <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="重新排序" width="40%" :visible.sync="dialogDisplay.listSortPanel">
      <draggable class="sort-list-container" v-model="sortList">
        <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
          <div class="sort-item-key">{{ index + 1 }}</div>
          <cover :width="48" :height="48" :src="item.avatar"></cover>
          <div class="sort-item-name">{{ getTR(item.name_i18n) }}</div>
        </div>
      </draggable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveSort">保 存</el-button>
        <el-button @click="dialogDisplay.listSortPanel = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Cover from '@/components/cover';
import Draggable from 'vuedraggable';

export default {
  name: 'Participants',
  components: {
    Cover,
    Draggable,
  },
  props: {
    type: String,
  },
  data() {
    let namespace = '朗读者';
    if (this.type === 'author') {
      namespace = '作者';
    }

    return {
      namespace,
      list: [],
      busy: false,
      finish: false,
      sortList: [],
      dialogDisplay: {
        listSortPanel: false,
      },
    };
  },

  // -----------------------------------------------------------
  // MARK: - Computer Properties
  // -----------------------------------------------------------
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },

  methods: {
    showListSortPanel() {
      this.sortList = this.list;
      this.dialogDisplay.listSortPanel = true;
    },
    gotoAdder() {
      this.$router.push({
        name: 'ParticipantAdder',
      });
    },

    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },

    gotoEditor(itemInfo) {
      this.$router.push({
        name: 'ParticipantEditor',
        params: {
          participant_id: itemInfo.id,
        },
      });
    },

    async removeItem(itemInfo) {
      try {
        await this.$confirm(`确定要删除该${this.namespace}吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.$request({
          url: `/v1/admin/participants/${itemInfo.id}`,
          method: 'DELETE',
        });
        this.list = this.list.filter(item => item.id !== itemInfo.id);
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        if (e !== 'cancel') {
          this.$message.error(e.message);
        }
      }
    },
    async saveSort() {
      const data = this.sortList.map((item, idx) => {
        return { id: item.id, sort_key: idx };
      });
      try {
        await this.$request({
          url: '/v1/admin/participants/sort_keys',
          method: 'PUT',
          data,
        });
        this.scenes = this.sortList;
        this.dialogDisplay.listSortPanel = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(e.message);
      }
    },

    init() {
      this.refreshList();
    },

    async refreshList() {
      this.list = [];
      await this.fetchList();
    },

    async fetchList() {
      const res = await this.$request({
        url: '/v1/admin/participants',
      });
      this.list = res.data;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.participant-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.participant-info /deep/ .cover {
  margin-right: 8px;
}

.sort-list-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}
</style>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddMixedScene',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        status: 'draft',
        blocked_channels: [],
        name: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        fresh_until: 0,
        trial_ended_local_time: '',
        trial_started_local_time: '',
        image: '',
        category: 'mixed',
        balance_type: '',
        creator: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        sub_title: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        primary_color: '0,0,0,1',
        secondary_color: '0,0,0,1',
        cover_data: {
          cover_type: 'gradient',
          cover_id: '',
        },
        track_infos: [],
        simple_tag_ids: [],
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      const sceneRes = await this.$request({
        url: `/v1/admin/market/scenes`,
        method: 'POST',
        data,
      });
      const scene = sceneRes.data;
      this.$router.replace({
        name: 'EditMixedScene',
        params: {
          scene_id: scene.id,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<script>
import allChannels from '@/channels.json';

const allChannelOptions = Object.keys(allChannels).map((value) => {
  const label = allChannels[value];
  return { value, label, disabled: false };
});

export default {
  name: 'ChannelsSelector',
  props: {
    value: Array,
    selected: Array,
  },
  data() {
    return {
      allChannels: allChannelOptions,
    };
  },
  watch: {
    value(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<template>
  <el-select v-model="value" multiple clearable filterable default-first-option placeholder="请选择渠道">
    <el-option v-for="item in allChannels" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

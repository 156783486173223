<template>
  <router-view v-if="$route.name !== 'RikeReportsManager'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-head">
          <div class="container-head-title">用户查询</div>
        </div>
        <div class="container-body" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="500">
          <div v-if="!list.length">
            <div class="list-item" style="justify-content: center">暂无结果</div>
          </div>
          <div v-else>
            <div class="list-item" v-for="item in list" :key="item.id">
              <div class="list-main-item">
                <div class="list-item-name">{{ item.comment.content }}</div>
              </div>
              <div>
                <div>举报次数</div>
                <div>{{ item.reports.length }}</div>
              </div>
              <div>
                <div>Date</div>
                <div>{{ $moment(item.created_at * 1000).format('YYYY-MM-DD') }}</div>
              </div>
              <div class="list-item-actions">
                <router-link :to="{ name: 'RikeUserComments', params: { user_id: item.comment.user_id } }">审问嫌疑人</router-link>
                <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="deleteItem(item)">毁尸灭迹</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'TidetimeManager',
  components: {
    Container,
  },
  data() {
    const baseRikeUrl = this.$env === 'production' ? 'https://rike-api.moreless.io' : 'https://rike-dapi.moreless.io';
    return {
      busy: false,
      finish: false,
      list: [],
      baseRikeUrl,
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    defaultActive() {
      return `/tidetime/manager/2018-${this.$route.params.month}`;
    },
  },
  methods: {
    editItem(item) {
      const $date = this.$moment(item.date);
      this.$router.push({
        name: 'TidetimeEditor',
        params: {
          day: $date.format('DD'),
        },
      });
    },
    deleteItem(item) {
      this.$confirm('删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const itemId = item.comment.id;
        await this.$request({
          method: 'DELETE',
          url: `${this.baseRikeUrl}/v1/admin/comments/${itemId}`,
        });
        const list = this.list.filter((item) => {
          return item.comment.id !== itemId;
        });
        this.list = list;
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      });
    },
    refetch() {
      this.list = [];
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `${this.baseRikeUrl}/v1/admin/reports`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const reports = res.data;
      if (reports.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...reports];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
  created() {
    // this.fetchList()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  flex-basis: 240px;
  text-align: right;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 10px;
  }
}
</style>

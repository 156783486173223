<template>
  <div>
    <div class="user-panel">
      <div class="logo">
        <slot name="logo"></slot>
      </div>
      <div class="infos">
        <h3>
          <slot name="title"></slot>
        </h3>
        <p>
          <slot name="desc"></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersPageHeader',
  props: {
    form: {
      type: Object,
    },
    activeLang: {
      type: String,
    },
  },
  computed: {
    defaultActive() {
      return this.$route.path;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav-tab {
  margin-bottom: -18px;
}
.nav-tab .el-menu-item {
  line-height: 45px;
  height: 45px;
}

.user-panel {
  display: flex;
  margin-bottom: 16px;
}
.infos {
  position: relative;
  top: 4px;
  margin-left: 24px;
  h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 12px;
  }
  p {
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    font-size: 14px;
  }
  span + span:before {
    content: ' | ';
  }
}
.logo i {
  max-width: 72px;
  max-height: 72px;
  font-size: 72px;
}
</style>

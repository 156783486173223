<template>
  <div class="container">
    <sub-nav></sub-nav>
    <div class="layout main">
      <div class="img-block">
        <div class="forbid-image"></div>
      </div>
      <div>
        <h1>403</h1>
        <p>抱歉，你没有访问的权限</p>
        <el-button type="primary" @click="logout">返回登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from '@/components/nav/sub';
export default {
  name: 'Dashboard',
  components: {
    SubNav,
  },
  methods: {
    async logout() {
      try {
        await this.$request({
          url: '/v1/admin/logout',
          method: 'POST',
        });
      } catch (e) {
        console.log(e);
      }
      location.href = '/login';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
p {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.main {
  display: flex;
  min-height: 500px;
  height: 80%;
  width: 100%;
  align-items: center;
}
.img-block {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.forbid-image {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-image: url('~@/assets/forbid.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
</style>

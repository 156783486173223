<template>
  <div class="preview-wrapper" :style="`zoom:${scale}`" @click="$emit('click')">
    <div
      class="preview"
      ref="previewImage"
      :style="{
        zoom: `${1 / scale}`,
        transform: `scale(${scale})`,
        backgroundImage: `url(${previewUrl})`,
        backgroundSize: `${cropXScale} ${cropYScale}`,
        backgroundPositionX: `${cropdx}`,
        backgroundPositionY: `${cropdy}`,
      }"
    >
      <div id="container">
        <div id="zh-date">{{ lunar.oDate.getMonth() + 1 }}月·星期{{ lunar.cnDay }}·{{ lunar.lMonth }}月{{ lunar.lDate }}</div>
        <div class="count-down">
          <div id="remain-day">{{ lunar.oDate.getDate() }}</div>
        </div>
        <div class="quote">
          <div id="quote-content">{{ quote.text }}</div>
          <div id="quote-author">{{ author.text }}</div>
          <div class="qrcode_wrapper"><img id="qrcode" src="http://pics.tide.moreless.io/tidetime/share/qr/2017-12-15.jpg" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lunar from '@/plugins/lunar';
export default {
  name: 'DailypicPreview',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    crop: {
      type: Object,
    },
    origin: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: 'zh-Hans',
    },
    imageInfo: {
      type: Object,
    },
  },
  data() {
    return {
      year: '',
      month: '',
      day: '',
      lunar: {},
      cropScale: {
        x: 'atuo',
        y: 'atuo',
      },
      imgScale: 1,
      screenWidth: 0,
      screenHeight: 0,
    };
  },
  methods: {
    // 正方图
    squareDx() {
      let offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    squareDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    squareXScale() {
      let scale = 'auto';
      let diff = 0.96;
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width;
        width = width * diff;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    squareYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        height = height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },

    // 竖图
    verticalDx() {
      let offset = 0.02;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    verticalDy() {
      let offset = 0.04;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      // const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale
      dy = dy / (this.crop.height / this.screenHeight);
      return `${-dy}px`;
    },
    verticalXScale() {
      let scale = 'auto';
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width * 0.96;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    verticalYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        const imgHeight = this.crop.imgHeight;
        height = height * 0.96;
        scale = `${100 / (height / imgHeight)}%`;
      }
      return scale;
    },

    // 横图
    landscapeDx() {
      const offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    landscapeDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    landscapeXScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (imageInfo.width <= imageInfo.height) {
        let width = this.crop.width;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    landscapeYScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (!imageInfo.width < imageInfo.height) {
        let height = this.crop.height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },
  },
  computed: {
    cropdx() {
      let dx;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dx = this.verticalDx();
      } else if (width > height) {
        dx = this.landscapeDx();
      } else {
        dx = this.squareDx();
      }
      return dx;
    },
    cropdy() {
      let dy;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dy = this.verticalDy();
      } else if (width > height) {
        dy = this.landscapeDy();
      } else {
        dy = this.squareDy();
      }
      return dy;
    },
    cropXScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalXScale();
      } else if (width > height) {
        scale = this.landscapeXScale();
      } else {
        scale = this.squareXScale();
      }
      return scale;
    },
    cropYScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalYScale();
      } else if (width > height) {
        scale = this.landscapeYScale();
      } else {
        scale = this.squareYScale();
      }
      return scale;
    },
    previewUrl() {
      let picUrl = this.data.pic_url;
      if (!this.origin) {
        picUrl = `${picUrl}?imageMogr2/thumbnail/!1024x1024r`;
        // picUrl = `${picUrl}/crop/${this.cropPath}`
      }
      return picUrl;
    },
    cropPath() {
      const scale = this.imgScale;
      const cropsize = `${this.crop.width / scale}x${this.crop.height / scale}`;
      const dx = this.crop.dx / scale;
      const dy = this.crop.dy / scale;
      return `!${cropsize}a${dx}a${dy}`;
    },
    quote() {
      return this.data.content[this.lang].quote;
    },
    author() {
      return this.data.content[this.lang].author;
    },
  },
  created() {
    const date = new Date(this.data.date);
    this.lunar = lunar(date);
    this.$nextTick(() => {
      const imageInfo = this.imageInfo;
      const $previewImage = this.$refs.previewImage;
      if (!$previewImage) {
        return 1;
      }
      const width = this.$refs.previewImage.clientWidth;
      const height = this.$refs.previewImage.clientHeight;
      // const crop = this.crop
      let scale;
      if (imageInfo.width < imageInfo.height) {
        scale = 210 / width;
      } else {
        scale = 210 / height;
      }
      this.imgScale = scale;
      this.screenWidth = width;
      this.screenHeight = height;
    });
  },
};
</script>
<style lang="less">
.cropper-face {
  background-size: cover !important;
  background: url('~@/assets/mask.png') !important;
}
</style>
<style lang="less" scoped>
.preview-wrapper {
  margin: 0 auto;
  width: 1080px;
  height: 1620px;
  overflow: hidden;
}
.preview {
  transform-origin: 0 0;
  width: 1080px;
  height: 1620px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  background-color: black;
}
.preview::before {
  content: ' ';
  background-color: rgba(0, 0, 0, 0.24);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
#container {
  width: 984px;
  height: 1524px;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  text-align: center;
  border: 3px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#zh-date {
  display: inline-block;
  padding: 20px 56px;
  font-family: 'PingFangSC', 'PingFang SC';
  color: #9faaa3;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 4px;
  text-indent: 4px;
  text-align: center;
  background-color: #fff;
}
#count-down {
  /*margin-top: 189px;*/
  font-family: 'PingFangSC', 'PingFang SC';
  font-weight: 800;
  color: #fff;
  font-size: 40.5px;
  line-height: 40.5px;
  letter-spacing: 16px;
  margin-left: 16px;
}
#remain-day {
  margin-top: 32px;
  font-family: 'Futura';
  font-weight: 400;
  color: #fff;
  font-size: 297px;
  line-height: 297px;
}
.quote {
  /*position: absolute;*/
  height: 410px;
  width: 100%;
  text-align: left;
}
#quote-content {
  padding-top: 48px;
  margin: 0 80px;
  border-top: 2px solid #fff;
  font-family: 'PingFangSC', 'PingFang SC';
  font-weight: 800;
  color: #fff;
  font-size: 40px;
  line-height: 72px;
  letter-spacing: 2px;
  white-space: pre-wrap;
}
#quote-author {
  margin: 32px 80px 80px;
  font-family: 'PingFangSC', 'PingFang SC';
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  font-size: 33px;
  line-height: 33px;
}
.qrcode_wrapper {
  position: absolute;
  right: 64px;
  bottom: 64px;
  width: 144px;
  height: 144px;
  border-radius: 50%;
  overflow: hidden;
}
#qrcode {
  box-sizing: content-box;
  padding: 8px;
  width: 128px;
  height: 128px;
  background-color: #fff;
}
</style>

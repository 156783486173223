<template>
  <edit-form :form="form" @save="save"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'SplashAdd',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        resource: {
          image: {
            background: {
              url: '',
              alignment: '',
            },
            background_color: '255,255,255,1.00',
            layers: [],
          },
        },
        status: 'draft',
        show_as_ads: false,
        start_time: 0,
        end_time: 0,
        show_duration: 3,
        max_show_count: 3,
        min_interval: 3600,
        can_skip: true,
        link: '',
        vip_visible: false,
        regions: [],
        channels: [],
        updated_at: 0,
        created_at: 0,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    save: async function() {
      const res = await this.$request({
        url: '/v1/admin/splashes',
        method: 'POST',
        data: this.form,
      });
      const splashes = res.data;
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.$router.replace({
        name: 'SplashEditor',
        params: { splashes_id: splashes.id },
      });
    },
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <div class="preview-wrapper" :class="device" :style="`zoom:${scale}`" @click="$emit('click')">
    <div
      class="preview"
      :style="{
        zoom: `${1 / scale}`,
        transform: `scale(${scale})`,
        fontFamily: `${quote.fontName}`,
      }"
    >
      <div class="device"></div>
      <div class="canva">
        <div
          class="dailypic preview-image"
          ref="previewImage"
          :style="{
            backgroundImage: `url(${previewUrl})`,
            backgroundSize: `${cropXScale} ${cropYScale}`,
            backgroundPositionX: `${cropdx}`,
            backgroundPositionY: `${cropdy}`,
          }"
        ></div>
        <div class="text-board">
          <p class="date" :style="`color: rgba(${quote.color}); border-color: rgba(${quote.color})`">{{ month }} {{ date }}</p>
          <p
            class="quote"
            :style="{
              color: `rgba(${quote.color})`,
              fontFamily: `'${quote['font_name']}'`,
              fontSize: `${quote['font_size']}px`,
              lineHeight: `${quote['font_size'] + quote['line_spacing']}px`,
            }"
          >
            {{ quote.text }}
          </p>
          <span class="dash" :style="`background-color: rgba(${quote.color})`"></span>
          <p
            class="author"
            :style="{
              color: `rgba(${author.color})`,
              fontFamily: `'${author['font_name']}'`,
              fontSize: `${author['font_size']}px`,
              lineHeight: `${author['font_size'] + author['line_spacing']}px`,
            }"
          >
            {{ author.text }}
          </p>
          <div class="footer">
            <div class="brand">
              <img class="logo" src="~@/assets/logo.png" />
              <div class="brand-info">
                <p>潮汐</p>
                <p>@阿喵</p>
              </div>
            </div>
            <div class="download">
              <span class="bootup-text">扫码下载</span>
              <img class="qrcode" src="~@/assets/qrcode.jpeg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    device: {
      type: String,
    },
    data: {
      type: Object,
    },
    crop: {
      type: Object,
    },
    origin: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: 'zh-Hans',
    },
    imageInfo: {
      type: Object,
    },
  },
  data() {
    return {
      month: '',
      day: '',
      cropScale: {
        x: 'atuo',
        y: 'atuo',
      },
      imgScale: 1,
      screenWidth: 0,
      screenHeight: 0,
    };
  },
  methods: {
    // 正方图
    squareDx() {
      let offset = 0.03;
      if (this.device === 'iPad-Landscape') {
        offset = 0.04;
      }
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    squareDy() {
      let offset = 0.02;
      if (this.device === 'iPad-Landscape') {
        offset = 0.28;
      }
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    squareXScale() {
      let scale = 'auto';
      let diff = 0.96;
      if (this.device === 'iPad-Landscape') {
        diff = 0.77;
      }
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width;
        width = width * diff;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    squareYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        height = height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },

    // 竖图
    verticalDx() {
      let offset = 0.02;
      if (this.device === 'iPad-Landscape') {
        offset = 0.04;
      }
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    verticalDy() {
      let offset = 0.04;
      if (this.device === 'iPad-Landscape') {
        offset = 0.762;
      }
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      // const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale
      dy = dy / (this.crop.height / this.screenHeight);
      return `${-dy}px`;
    },
    verticalXScale() {
      let scale = 'auto';
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width;
        if (this.device === 'iPad-Landscape') {
          width = width * 0.762;
        } else {
          width = width * 0.96;
        }
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    verticalYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        const imgHeight = this.crop.imgHeight;
        height = height * 0.96;
        scale = `${100 / (height / imgHeight)}%`;
      }
      return scale;
    },

    // 横图
    landscapeDx() {
      const offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    landscapeDy() {
      let offset = 0.02;
      if (this.device === 'iPad-Landscape') {
        offset = 0.21;
      }
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    landscapeXScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (imageInfo.width <= imageInfo.height) {
        let width = this.crop.width;
        if (this.device === 'iPad-Landscape') {
          width = width * 0.58;
        }
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    landscapeYScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (!imageInfo.width < imageInfo.height) {
        let height = this.crop.height;
        if (this.device === 'iPad-Landscape') {
          height = height * 0.58;
        } else {
          height = height * 0.96;
        }
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },
  },
  computed: {
    cropdx() {
      let dx;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dx = this.verticalDx();
      } else if (width > height) {
        dx = this.landscapeDx();
      } else {
        dx = this.squareDx();
      }
      return dx;
    },
    cropdy() {
      let dy;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dy = this.verticalDy();
      } else if (width > height) {
        dy = this.landscapeDy();
      } else {
        dy = this.squareDy();
      }
      return dy;
    },
    cropXScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalXScale();
      } else if (width > height) {
        scale = this.landscapeXScale();
      } else {
        scale = this.squareXScale();
      }
      return scale;
    },
    cropYScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalYScale();
      } else if (width > height) {
        scale = this.landscapeYScale();
      } else {
        scale = this.squareYScale();
      }
      return scale;
    },
    previewUrl() {
      let picUrl = this.data.pic_url;
      if (!this.origin) {
        picUrl = `${picUrl}?imageMogr2/thumbnail/!1024x1024r`;
        // picUrl = `${picUrl}/crop/${this.cropPath}`
      }
      return picUrl;
    },
    cropPath() {
      const scale = this.imgScale;
      const cropsize = `${this.crop.width / scale}x${this.crop.height / scale}`;
      const dx = this.crop.dx / scale;
      const dy = this.crop.dy / scale;
      return `!${cropsize}a${dx}a${dy}`;
    },
    quote() {
      return this.data.content[this.lang].quote;
    },
    author() {
      return this.data.content[this.lang].author;
    },
  },
  created() {
    const dateString = new Date(this.data.date).toDateString();
    [, this.month, this.date] = dateString.split(' ');
    this.$nextTick(() => {
      const imageInfo = this.imageInfo;
      const $previewImage = this.$refs.previewImage;
      if (!$previewImage) {
        return 1;
      }
      const width = this.$refs.previewImage.clientWidth;
      const height = this.$refs.previewImage.clientHeight;
      // const crop = this.crop
      let scale;
      if (imageInfo.width < imageInfo.height) {
        scale = 210 / width;
      } else {
        scale = 210 / height;
      }
      this.imgScale = scale;
      this.screenWidth = width;
      this.screenHeight = height;
    });
  },
};
</script>
<style lang="less">
.cropper-face {
  background-size: cover !important;
  background: url('~@/assets/mask.png') !important;
}
</style>
<style lang="less" scoped>
.preview-wrapper {
  margin: 0 auto;
}
.preview {
  transform-origin: 0 0;
}
.iPad {
  width: 866px;
  height: 1200px;
  .device {
    z-index: 1;
    position: relative;
    width: 866px;
    height: 1200px;
    background: url('~@/assets/ipad.png');
    background-size: cover;
  }
  .canva {
    z-index: 2;
    position: absolute;
    top: 82px;
    left: 45px;
    width: 776px;
    height: 1035px;
  }
}
.iPad-Landscape {
  width: 800px;
  height: 577.3px;
  .device {
    z-index: 1;
    position: relative;
    width: 800px;
    height: 577.3px;
    background: url('~@/assets/ipad-landscape.png');
    background-size: cover;
  }
  .canva {
    z-index: 2;
    position: absolute;
    left: 54.6px;
    top: 30px;
    height: 517.3px;
    width: 691px;
  }
}
.iPhone-X {
  width: 358px;
  height: 718px;
  .device {
    position: relative;
    width: 358px;
    height: 718px;
    background: url('~@/assets/iphone-x.png');
    background-size: cover;
    z-index: 2;
  }
  .canva {
    position: absolute;
    left: 21px;
    top: 20px;
    width: 314px;
    height: 676px;
    z-index: 1;
  }
}
.iPhone-8 {
  width: 345px;
  height: 692px;
  .device {
    z-index: 1;
    position: relative;
    width: 345px;
    height: 692px;
    background: url('~@/assets/iphone-8.png');
    background-size: cover;
  }
  .canva {
    z-index: 2;
    position: absolute;
    top: 82px;
    left: 26px;
    width: 296px;
    height: 526px;
  }
}
.iPhone-7-Plus {
  width: 389.5px;
  height: 791px;
  .device {
    z-index: 1;
    position: relative;
    width: 389.5px;
    height: 791px;
    background: url('~@/assets/iphone-7-plus.png');
    background-size: cover;
  }
  .canva {
    z-index: 2;
    position: absolute;
    top: 104px;
    left: 31px;
    width: 330px;
    height: 588px;
  }
}
.iPhone-SE {
  width: 295px;
  height: 619px;
  .device {
    z-index: 1;
    position: relative;
    width: 295px;
    height: 619px;
    background: url('~@/assets/iphone-se.png');
    background-size: cover;
  }
  .canva {
    z-index: 2;
    position: absolute;
    top: 92px;
    left: 24px;
    width: 250px;
    height: 442px;
  }
}
.preview {
  display: inline-block;
  // transform: scale(1);
  font-family: PingFangSC-Regular, sans-serif;
  position: relative;
  text-align: left;
  .dailypic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 center;
  }
  .text-board {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: left;
    position: absolute;
    box-sizing: border-box;
    padding: 18px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    > * {
      margin: 0;
    }
  }
  .date {
    align-self: flex-start;
    display: inline-block;
    font-size: 8px;
    line-height: 11px;
    letter-spacing: 4px;
    padding: 6px 11px;
    border-width: 1px;
    border-style: solid;
  }
  .quote {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 3px;
    line-height: 22.5px;
    white-space: pre-wrap;
  }
  .dash {
    margin: 10px 0 12px;
    display: block;
    width: 16px;
    height: 1.5px;
  }
  .author {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 0.5);
  }
  .footer {
    margin-top: 46px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: 22px;
    height: 22px;
    border-radius: 4.8px;
    vertical-align: middle;
  }
  .brand-info {
    margin-left: 10px;
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    font-size: 8px;
    line-height: 11.5px;
    vertical-align: middle;
    > * {
      margin: 0;
    }
  }
  .download {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .bootup-text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 6px;
    writing-mode: vertical-rl;
    vertical-align: middle;
    margin-right: 8px;
  }
  .qrcode {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
  .preview-image {
    background-color: #fff;
  }
}
</style>

import Layout from '@/components/layout';

import AnnotationsManage from '@/pages/search_annotations/index';
import AddAnnotation from '@/pages/search_annotations/add';
import EditAnnotation from '@/pages/search_annotations/edit';

import TagsManage from '@/pages/simple_tags/index';
import AddTag from '@/pages/simple_tags/add';
import EditTag from '@/pages/simple_tags/edit';

// -----------------------------------------------------------
// MARK: - 探索页管理
// -----------------------------------------------------------

import ExplorePageManager from '@/pages/explore/index';
import ExplorePageEditor from '@/pages/explore/edit';
import ExplorePageAdder from '@/pages/explore/add';

import ExplorePageAdBanners from '@/pages/explore/ad-banners/index';
import ExploreAdBannersAdder from '@/pages/explore/ad-banners/add';
import ExploreAdBannersEditor from '@/pages/explore/ad-banners/edit';

// -----------------------------------------------------------
// MARK: - 睡眠内容管理
// -----------------------------------------------------------

import SleepTopicPageAdBanners from '@/pages/sleep-topic/ad-banners/index';
import SleepTopicPageAdBannersAdder from '@/pages/sleep-topic/ad-banners/add';
import SleepTopicPageAdBannersEditor from '@/pages/sleep-topic/ad-banners/edit';

// -----------------------------------------------------------
// MARK: - 小憩内容管理
// -----------------------------------------------------------

import NapTopicPageAdBanners from '@/pages/nap-topic/ad-banners/index';
import NapTopicPageAdBannersAdder from '@/pages/nap-topic/ad-banners/add';
import NapTopicPageAdBannersEditor from '@/pages/nap-topic/ad-banners/edit';

// -----------------------------------------------------------
// MARK: - 主题探索管理
// -----------------------------------------------------------

import ThemePageAdBanners from '@/pages/theme-topic/ad-banners/index';
import ThemePageAdBannersAdder from '@/pages/theme-topic/ad-banners/add';
import ThemePageAdBannersEditor from '@/pages/theme-topic/ad-banners/edit';

// -----------------------------------------------------------
// MARK: - 自然声音管理
// -----------------------------------------------------------

import ScenesManage from '@/pages/scenes/index';
import AddScene from '@/pages/scenes/add';
import EditScene from '@/pages/scenes/edit';

import ScenesAdBanners from '@/pages/scenes/ad-banners/index';
import ScenesAdBannersAdder from '@/pages/scenes/ad-banners/add';
import ScenesAdBannersEditor from '@/pages/scenes/ad-banners/edit';

import ScenesTags from '@/pages/scenes/tags/index';
import ScenesTagsAdder from '@/pages/scenes/tags/add';
import ScenesTagsEditor from '@/pages/scenes/tags/edit';

import ScenesGroups from '@/pages/scenes/groups/index';
import ScenesGroupsAdder from '@/pages/scenes/groups/add';
import ScenesGroupsEditor from '@/pages/scenes/groups/edit';

import AddMixedScene from '@/pages/scenes/mixed/add';
import EditMixedScene from '@/pages/scenes/mixed/edit';

import ScenePackagesManage from '@/pages/scenes/packages/index';
import ScenePackagesAdder from '@/pages/scenes/packages/add';
import ScenePackagesEditor from '@/pages/scenes/packages/edit';

// -----------------------------------------------------------
// MARK: - 冥想管理
// -----------------------------------------------------------

import MedAlbumManager from '@/pages/meditation/album/index';
import AddMedAlbum from '@/pages/meditation/album/add';
import EditMedAlbum from '@/pages/meditation/album/edit';

import MedSkillManager from '@/pages/meditation/skill/index';
import AddMedSkill from '@/pages/meditation/skill/add';
import EditMedSkill from '@/pages/meditation/skill/edit';

import MedRecommendManager from '@/pages/meditation/recommend/index';
import AddMedRecommend from '@/pages/meditation/recommend/add';
import EditMedRecommend from '@/pages/meditation/recommend/edit';

import MedAdBanners from '@/pages/meditation/ad-banners/index';
import MedAdBannersAdder from '@/pages/meditation/ad-banners/add';
import MedAdBannersEditor from '@/pages/meditation/ad-banners/edit';

import MedTags from '@/pages/meditation/tags/index';
import MedTagsAdder from '@/pages/meditation/tags/add';
import MedTagsEditor from '@/pages/meditation/tags/edit';

import MedGroups from '@/pages/meditation/groups/index';
import MedGroupsAdder from '@/pages/meditation/groups/add';
import MedGroupsEditor from '@/pages/meditation/groups/edit';

import MedReportPageRecommends from '@/pages/meditation/report_page_recommend/index';
import AddMedReportPageRecommend from '@/pages/meditation/report_page_recommend/add';
import EditMedReportPageRecommend from '@/pages/meditation/report_page_recommend/edit';

// -----------------------------------------------------------
// MARK: - 睡眠故事管理
// -----------------------------------------------------------

import StoriesManager from '@/pages/sleep-stories/stories/index';
import StoriesAdder from '@/pages/sleep-stories/stories/add';
import StoriesEditor from '@/pages/sleep-stories/stories/edit';

import SleepStoryAdBanners from '@/pages/sleep-stories/ad-banners/index';
import SleepStoryAdBannersAdder from '@/pages/sleep-stories/ad-banners/add';
import SleepStoryAdBannersEditor from '@/pages/sleep-stories/ad-banners/edit';

import SleepStoryTags from '@/pages/sleep-stories/tags/index';
import SleepStoryTagsAdder from '@/pages/sleep-stories/tags/add';
import SleepStoryTagsEditor from '@/pages/sleep-stories/tags/edit';

import SleepStoryGroups from '@/pages/sleep-stories/groups/index';
import SleepStoryGroupsAdder from '@/pages/sleep-stories/groups/add';
import SleepStoryGroupsEditor from '@/pages/sleep-stories/groups/edit';

// -----------------------------------------------------------
// MARK: - 图文管理
// -----------------------------------------------------------

import MedArticleManager from '@/pages/article/index';
import AddMedArticle from '@/pages/article/add';
import EditMedArticle from '@/pages/article/edit';
import EditMedArticleContent from '@/pages/article/edit/content';

import ArticleAdBanners from '@/pages/article/ad-banners/index';
import ArticleAdBannersAdder from '@/pages/article/ad-banners/add';
import ArticleAdBannersEditor from '@/pages/article/ad-banners/edit';

import ArticleTags from '@/pages/article/tags/index';
import ArticleTagsAdder from '@/pages/article/tags/add';
import ArticleTagsEditor from '@/pages/article/tags/edit';

import ArticleGroups from '@/pages/article/groups/index';
import ArticleGroupsAdder from '@/pages/article/groups/add';
import ArticleGroupsEditor from '@/pages/article/groups/edit';

import Recommendations from '@/pages/recommendations/index';
import RecommendationsAdder from '@/pages/recommendations/add';
import RecommendationsEditor from '@/pages/recommendations/edit';

import ParticipantsManager from '@/pages/resource/participants/index_top';
import ParticipantAdder from '@/pages/resource/participants/add';
import ParticipantEditor from '@/pages/resource/participants/edit';

import ContentsGroupsManager from '@/pages/groups/index';
import ContentsGroupsAdder from '@/pages/groups/add';
import ContentsGroupsEditor from '@/pages/groups/edit';

export default [
  {
    path: '/simple_tags',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '标签管理',
    },
    children: [
      {
        path: '/',
        name: 'TagsManage',
        components: {
          default: TagsManage,
        },
        meta: {
          requireAuth: true,
          label: '搜索管理',
          newDesign: true,
        },
      },
      {
        path: 'add',
        name: 'AddTag',
        components: {
          default: AddTag,
        },
        meta: {
          requireAuth: true,
          label: '新增',
          newDesign: true,
        },
      },
      {
        path: ':tag_id/edit',
        name: 'EditTag',
        components: {
          default: EditTag,
        },
        meta: {
          requireAuth: true,
          label: '编辑',
          newDesign: true,
        },
      },
    ],
  },
  {
    path: '/search_annotations',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '搜索管理',
    },
    children: [
      {
        path: '/',
        name: 'AnnotationsManage',
        components: {
          default: AnnotationsManage,
        },
        meta: {
          requireAuth: true,
          label: '搜索管理',
          newDesign: true,
        },
      },
      {
        path: 'add',
        name: 'AddAnnotation',
        components: {
          default: AddAnnotation,
        },
        meta: {
          requireAuth: true,
          label: '新增',
          newDesign: true,
        },
      },
      {
        path: ':annotation_id/edit',
        name: 'EditAnnotation',
        components: {
          default: EditAnnotation,
        },
        meta: {
          requireAuth: true,
          label: '编辑',
          newDesign: true,
        },
      },
    ],
  },
  {
    path: '/scenes',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '声乐研究所',
    },
    children: [
      {
        path: '/',
        redirect: {
          name: 'ScenesManage',
          params: {
            category_type: 'normal',
          },
        },
      },
      // -----------------------------------------------------------
      // MARK: - 场景推荐位
      // -----------------------------------------------------------

      {
        path: 'ad_banners',
        name: 'ScenesAdBanners',
        component: ScenesAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理',
          description: '在探索页中间插入推荐，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'ScenesAdBannersAdder',
        component: ScenesAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于探索页的推荐位，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'ScenesAdBannersEditor',
        component: ScenesAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于探索页的推荐位',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 场景TAG
      // -----------------------------------------------------------

      {
        path: 'tags',
        name: 'ScenesTags',
        component: ScenesTags,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '标签管理',
          description: '声音场景标签管理，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'tags/add',
        name: 'ScenesTagsAdder',
        component: ScenesTagsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建标签',
          description: '新建用于声音场景的标签，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'tags/edit/:tag_id',
        name: 'ScenesTagsEditor',
        component: ScenesTagsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑标签',
          description: '编辑用于声音场景的标签',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 声音场景分组
      // -----------------------------------------------------------

      {
        path: 'groups',
        name: 'ScenesGroups',
        component: ScenesGroups,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '分组管理',
          description: '声音场景分组管理，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'groups/add',
        name: 'ScenesGroupsAdder',
        component: ScenesGroupsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建分组',
          description: '新建用于声音场景的分组，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'groups/edit/:group_id',
        name: 'ScenesGroupsEditor',
        component: ScenesGroupsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑分组',
          description: '编辑用于声音场景的分组',
        },
      },
      {
        path: 'mixed/add',
        name: 'AddMixedScene',
        component: AddMixedScene,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建混音',
          description: '新建混音',
        },
      },
      {
        path: 'mixed/:scene_id/edit',
        name: 'EditMixedScene',
        component: EditMixedScene,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑混音',
          description: '编辑混音',
        },
      },
      {
        path: ':category_type',
        name: 'ScenesManage',
        components: {
          default: ScenesManage,
        },
        meta: {
          requireAuth: true,
          label: '场景管理',
          newDesign: true,
        },
      },
      {
        path: 'packages/:category_type',
        name: 'ScenePackagesManage',
        component: ScenePackagesManage,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '声音包管理',
          description: '声音包管理',
        },
      },
      {
        path: 'packages/:category_type/add',
        name: 'ScenePackagesAdder',
        component: ScenePackagesAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新增声音包',
          description: '新增声音包',
        },
      },
      {
        path: 'packages/:category_type/:package_id/edit',
        name: 'ScenePackagesEditor',
        component: ScenePackagesEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑声音包',
          description: '编辑声音包',
        },
      },
      {
        path: ':category_type/add',
        name: 'AddScene',
        components: {
          default: AddScene,
        },
        meta: {
          requireAuth: true,
          label: '新增',
          newDesign: true,
        },
      },
      {
        path: ':category_type/:scene_id/edit',
        name: 'EditScene',
        components: {
          default: EditScene,
        },
        meta: {
          requireAuth: true,
          label: '编辑',
          newDesign: true,
        },
      },
    ],
  },
  {
    path: '/explorer_pages',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '探索页',
    },
    children: [
      {
        path: '/',
        redirect: {
          name: 'ExplorePageManager',
          params: {
            category_type: 'home',
          },
        },
      },
      {
        path: ':category_type',
        name: 'ExplorePageManager',
        components: {
          default: ExplorePageManager,
        },
        meta: {
          requireAuth: true,
          label: '探索页管理',
          newDesign: true,
        },
      },
      {
        path: ':category_type/add',
        name: 'ExplorePageAdder',
        component: ExplorePageAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新增探索页',
          description: '新增探索页',
        },
      },
      {
        path: ':category_type/:package_id/edit',
        name: 'ExplorePageEditor',
        component: ExplorePageEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑探索页',
          description: '编辑探索页',
        },
      },
    ],
  },
  {
    path: '/explore',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '探索页',
    },
    children: [
      {
        path: 'ad_banners',
        name: 'ExplorePageAdBanners',
        component: ExplorePageAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理 - 潮汐探索',
          description: '在探索页中间插入推荐，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'ExploreAdBannersAdder',
        component: ExploreAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于探索页的推荐位，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'ExploreAdBannersEditor',
        component: ExploreAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于探索页的推荐位',
        },
      },
    ],
  },

  {
    path: '/sleep_topic_page',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '睡眠内容管理',
    },
    children: [
      {
        path: 'ad_banners',
        name: 'SleepTopicPageAdBanners',
        component: SleepTopicPageAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理 - 睡眠助手',
          description: '在睡眠内容页中间插入推荐',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'SleepTopicPageAdBannersAdder',
        component: SleepTopicPageAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于睡眠内容页的推荐位',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'SleepTopicPageAdBannersEditor',
        component: SleepTopicPageAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于睡眠内容页的推荐位',
        },
      },
    ],
  },

  {
    path: '/nap_topic_page',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '探索页',
    },
    children: [
      {
        path: 'ad_banners',
        name: 'NapTopicPageAdBanners',
        component: NapTopicPageAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理 - 小憩助手',
          description: '在小憩内容页中间插入推荐',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'NapTopicPageAdBannersAdder',
        component: NapTopicPageAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于小憩内容页的推荐位',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'NapTopicPageAdBannersEditor',
        component: NapTopicPageAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于小憩内容页的推荐位',
        },
      },
    ],
  },

  {
    path: '/theme_page',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '探索页',
    },
    children: [
      {
        path: 'ad_banners',
        name: 'ThemePageAdBanners',
        component: ThemePageAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理 - 主题活动',
          description: '主题活动页推荐位',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'ThemePageAdBannersAdder',
        component: ThemePageAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于主题活动页的推荐位',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'ThemePageAdBannersEditor',
        component: ThemePageAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于主题活动页的推荐位',
        },
      },
    ],
  },

  {
    path: '/recommendations',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '探索页',
    },
    children: [
      {
        path: '/',
        redirect: {
          name: 'Recommendations',
          params: {
            recommendation_location: 'explorer',
          },
        },
      },
      {
        path: ':recommendation_location',
        name: 'Recommendations',
        components: {
          default: Recommendations,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐管理',
          description: '用户推荐管理，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: ':recommendation_location/add',
        name: 'RecommendationsAdder',
        components: {
          default: RecommendationsAdder,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐',
          description: '仅支持 3.5.0 之后版本',
        },
      },
      {
        path: ':recommendation_location/edit/:recommend_id',
        name: 'RecommendationsEditor',
        components: {
          default: RecommendationsEditor,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐',
          description: '仅支持 3.5.0 之后版本',
        },
      },
    ],
  },

  {
    path: '/meditation',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '专辑管理',
    },
    children: [
      {
        path: 'albums',
        name: 'MedAlbumManager',
        components: {
          default: MedAlbumManager,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '管理',
        },
        children: [
          {
            path: 'add',
            name: 'AddMedAlbum',
            component: AddMedAlbum,
            meta: {
              requireAuth: true,
              newDesign: true,
              label: '新增专辑',
            },
          },
          {
            path: ':album_id/edit',
            name: 'EditMedAlbum',
            component: EditMedAlbum,
            meta: {
              requireAuth: true,
              newDesign: true,
              label: '编辑专辑',
            },
          },
        ],
      },

      {
        path: 'skills',
        name: 'MedSkillManager',
        components: {
          default: MedSkillManager,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '管理',
        },
        children: [
          {
            path: 'add',
            name: 'AddMedSkill',
            component: AddMedSkill,
            meta: {
              requireAuth: true,
              newDesign: true,
              label: '新增技巧',
            },
          },
          {
            path: ':skill_id/edit',
            name: 'EditMedSkill',
            component: EditMedSkill,
            meta: {
              requireAuth: true,
              newDesign: true,
              label: '编辑专辑',
            },
          },
        ],
      },

      // -----------------------------------------------------------
      // MARK: - 冥想推荐位管理
      // -----------------------------------------------------------

      {
        path: 'ad_banners',
        name: 'MeditationAdBanners',
        component: MedAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理',
          description: '在探索页中间插入推荐，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'MedAdBannersAdder',
        component: MedAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于探索页的推荐位，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'MedAdBannersEditor',
        component: MedAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于探索页的推荐位',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 冥想TAG
      // -----------------------------------------------------------

      {
        path: 'tags',
        name: 'MedTags',
        component: MedTags,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '标签管理',
          description: '冥想练习标签管理，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'tags/add',
        name: 'MedTagsAdder',
        component: MedTagsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建标签',
          description: '新建用于冥想练习的标签，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'tags/edit/:tag_id',
        name: 'MedTagsEditor',
        component: MedTagsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑标签',
          description: '编辑用于冥想练习的标签',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 冥想练习分组
      // -----------------------------------------------------------

      {
        path: 'groups',
        name: 'MedGroups',
        component: MedGroups,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '分组管理',
          description: '冥想练习分组管理，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'groups/add',
        name: 'MedGroupsAdder',
        component: MedGroupsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建分组',
          description: '新建用于冥想练习的分组，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'groups/edit/:group_id',
        name: 'MedGroupsEditor',
        component: MedGroupsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑分组',
          description: '编辑用于冥想练习的分组',
        },
      },
    ],
  },

  {
    path: '/participants',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '参与者管理',
    },
    children: [
      {
        path: '/',
        name: 'ParticipantsManager',
        components: {
          default: ParticipantsManager,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '管理',
        },
      },
      {
        path: 'add',
        name: 'ParticipantAdder',
        component: ParticipantAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建参与者',
          description: '新建参与者',
        },
      },
      {
        path: 'edit/:participant_id',
        name: 'ParticipantEditor',
        component: ParticipantEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑参与者',
          description: '编辑参与者',
        },
      },
    ],
  },

  {
    path: '/groups',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '通用分组管理',
    },
    children: [
      {
        path: '/',
        name: 'ContentsGroupsManager',
        components: {
          default: ContentsGroupsManager,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '管理',
        },
      },
      {
        path: 'add',
        name: 'ContentsGroupsAdder',
        component: ContentsGroupsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建通用分组',
          description: '新建通用分组',
        },
      },
      {
        path: 'edit/:group_id',
        name: 'ContentsGroupsEditor',
        component: ContentsGroupsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑通用分组',
          description: '编辑通用分组',
        },
      },
    ],
  },

  {
    path: '/sleep-stories',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '睡眠故事管理',
    },
    children: [
      {
        path: 'stories',
        name: 'StoriesManager',
        components: {
          default: StoriesManager,
        },
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '管理',
        },
      },

      {
        path: 'stories/add',
        name: 'StoriesAdder',
        component: StoriesAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建故事',
          description: '新建睡眠故事',
        },
      },
      {
        path: 'stories/edit/:story_id',
        name: 'StoriesEditor',
        component: StoriesEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑故事',
          description: '编辑睡眠故事',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 睡眠故事推荐位管理
      // -----------------------------------------------------------

      {
        path: 'ad_banners',
        name: 'SleepStoryAdBanners',
        component: SleepStoryAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理',
          description: '在探索页中间插入推荐',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'SleepStoryAdBannersAdder',
        component: SleepStoryAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于探索页的推荐位',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'SleepStoryAdBannersEditor',
        component: SleepStoryAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于探索页的推荐位',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 睡眠故事TAG
      // -----------------------------------------------------------

      {
        path: 'tags',
        name: 'SleepStoryTags',
        component: SleepStoryTags,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '标签管理',
          description: '睡眠故事标签管理',
        },
      },
      {
        path: 'tags/add',
        name: 'SleepStoryTagsAdder',
        component: SleepStoryTagsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建标签',
          description: '新建用于睡眠故事的标签',
        },
      },
      {
        path: 'tags/edit/:tag_id',
        name: 'SleepStoryTagsEditor',
        component: SleepStoryTagsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑标签',
          description: '编辑用于睡眠故事的标签',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 睡眠故事分组
      // -----------------------------------------------------------

      {
        path: 'groups',
        name: 'SleepStoryGroups',
        component: SleepStoryGroups,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '分组管理',
          description: '睡眠故事分组管理',
        },
      },
      {
        path: 'groups/add',
        name: 'SleepStoryGroupsAdder',
        component: SleepStoryGroupsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建分组',
          description: '新建用于睡眠故事的分组',
        },
      },
      {
        path: 'groups/edit/:group_id',
        name: 'SleepStoryGroupsEditor',
        component: SleepStoryGroupsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑分组',
          description: '编辑用于睡眠故事的分组',
        },
      },
    ],
  },

  {
    path: '/article',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '图文管理',
    },
    children: [
      {
        path: '/',
        name: 'MedArticleManager',
        components: {
          default: MedArticleManager,
        },
        meta: {
          requireAuth: true,
          label: '管理',
          newDesign: true,
        },
      },
      {
        path: 'add',
        name: 'AddMedArticle',
        component: AddMedArticle,
        meta: {
          requireAuth: true,
          label: '新增图文',
          newDesign: true,
        },
      },
      {
        path: ':article_id/edit',
        name: 'EditMedArticle',
        component: EditMedArticle,
        meta: {
          requireAuth: true,
          label: '编辑图文',
          newDesign: true,
        },
      },
      {
        path: ':article_id/edit/content',
        name: 'EditMedArticleContent',
        component: EditMedArticleContent,
        meta: {
          requireAuth: true,
          label: '编辑图文',
          newDesign: true,
        },
      },

      // -----------------------------------------------------------
      // MARK: - 图文推荐位
      // -----------------------------------------------------------

      {
        path: 'ad_banners',
        name: 'ArticleAdBanners',
        component: ArticleAdBanners,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '推荐位管理',
          description: '在图文列表中间插入推荐，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/add',
        name: 'ArticleAdBannersAdder',
        component: ArticleAdBannersAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建推荐位',
          description: '新建用于图文列表的推荐位，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'ad_banners/edit/:ad_banner_id',
        name: 'ArticleAdBannersEditor',
        component: ArticleAdBannersEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑推荐位',
          description: '编辑用于图文列表的推荐位',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 图文TAG
      // -----------------------------------------------------------

      {
        path: 'tags',
        name: 'ArticleTags',
        component: ArticleTags,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '标签管理',
          description: '图文标签管理，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'tags/add',
        name: 'ArticleTagsAdder',
        component: ArticleTagsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建标签',
          description: '新建用于图文的标签，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'tags/edit/:tag_id',
        name: 'ArticleTagsEditor',
        component: ArticleTagsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑标签',
          description: '编辑用于图文的标签',
        },
      },

      // -----------------------------------------------------------
      // MARK: - 图文分组
      // -----------------------------------------------------------

      {
        path: 'groups',
        name: 'ArticleGroups',
        component: ArticleGroups,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '分组管理',
          description: '图文分组管理，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'groups/add',
        name: 'ArticleGroupsAdder',
        component: ArticleGroupsAdder,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '新建分组',
          description: '新建用于图文的分组，仅支持 3.5.0 之后版本',
        },
      },
      {
        path: 'groups/edit/:group_id',
        name: 'ArticleGroupsEditor',
        component: ArticleGroupsEditor,
        meta: {
          requireAuth: true,
          newDesign: true,
          label: '编辑分组',
          description: '编辑用于图文的分组',
        },
      },
    ],
  },

  {
    path: '/meditation/report_page_recommends',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '报告页推荐',
    },
    children: [
      {
        path: '/',
        name: 'MedReportPageRecommends',
        components: {
          default: MedReportPageRecommends,
        },
        meta: {
          requireAuth: true,
          label: '管理',
          newDesign: true,
        },
        children: [
          {
            path: 'add',
            name: 'AddMedReportPageRecommend',
            component: AddMedReportPageRecommend,
            meta: {
              requireAuth: true,
              label: '新增推荐',
              newDesign: true,
            },
          },
          {
            path: ':recommend_id/edit',
            name: 'EditMedReportPageRecommend',
            component: EditMedReportPageRecommend,
            meta: {
              requireAuth: true,
              label: '编辑推荐',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: '/meditation/recommend',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '隐市小居',
    },
    children: [
      {
        path: '/',
        name: 'MedRecommendManager',
        components: {
          default: MedRecommendManager,
        },
        meta: {
          requireAuth: true,
          label: '管理',
          newDesign: true,
        },
        children: [
          {
            path: 'add',
            name: 'AddMedRecommend',
            component: AddMedRecommend,
            meta: {
              requireAuth: true,
              label: '新增分类',
              newDesign: true,
            },
          },
          {
            path: ':recommend_id/edit',
            name: 'EditMedRecommend',
            component: EditMedRecommend,
            meta: {
              requireAuth: true,
              label: '编辑分类',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },
];

<template>
  <edit-form :form="form" @save="save" @sync="sync" @toggleCode="toggleCode"></edit-form>
</template>

<script>
import Form from './form';
export default {
  name: 'RedeemCodeEdit',
  components: {
    EditForm: Form,
  },
  data() {
    const today = this.$moment().set({
      hour: 0,
      minute: 0,
      second: 1,
      millisecond: 0,
    });
    return {
      form: {
        content: {
          tide_plus_period: 3600,
        },
        count: 100,
        valid_from: parseInt(today.add(1, 'days') / 1000),
        valid_to: parseInt(today.add(1, 'years') / 1000),
        name: '',
        aborted: false,
        only_non_vip: '0',
        maximum_usage_count: 1,
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    async toggleCode(form) {
      this.$confirm(`此操作将使该兑换活动${form.aborted ? '生效' : '失效'}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          await this.$request({
            method: 'PATCH',
            url: `/v1/admin/redeem_code_groups/${form.id}`,
            data: {
              aborted: !form.aborted,
            },
          });
          this.form.aborted = !this.form.aborted;
        } catch (e) {
          this.$message.error(e.response.data.msg);
        }
      });
    },
    sync: async function() {
      const form = { ...{}, ...this.form };
      delete form.id;
      delete form.created_at;
      delete form.updated_at;

      if (this.$env === 'dev') {
        this.$prompt('此操作将使测试环境的数据复制到正式环境，请输入 “没有问题” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(async ({ value }) => {
          if (value === '没有问题') {
            try {
              await this.$request({
                url: 'https://davinci.moreless.space/api/v1/admin/redeem_code_groups',
                method: 'POST',
                data: form,
              });
              this.$message({
                message: '复制成功',
                type: 'success',
              });
            } catch (e) {
              this.$message.error('复制失败');
            }
          } else {
            this.$message.error('输入错误');
          }
        });
      } else if (this.$env === 'production') {
        try {
          await this.$request({
            url: 'https://leonardo.moreless.space/api/v1/admin/redeem_code_groups',
            method: 'POST',
            data: form,
          });
          this.$message({
            message: '复制成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('复制失败');
        }
      }
    },
    save: async function() {
      const form = this.form;
      try {
        await this.$request({
          method: 'PUT',
          url: `/v1/admin/redeem_code_groups/${this.$route.params.redeem_code_group_id}`,
          data: form,
        });
        this.$message({
          message: '保存成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(`保存失败, ${e.response.data.msg}`);
      }
    },
    init: async function() {
      const res = await this.$request({
        url: `/v1/admin/redeem_code_groups/${this.$route.params.redeem_code_group_id}`,
      });
      this.form = Object.assign({}, this.form, res.data);
      this.form.only_non_vip = this.form.only_non_vip ? '1' : '0';
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

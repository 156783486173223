import { render, staticRenderFns } from "./tide-v2-preview.vue?vue&type=template&id=d2b93fa4&scoped=true"
import script from "./tide-v2-preview.vue?vue&type=script&lang=js"
export * from "./tide-v2-preview.vue?vue&type=script&lang=js"
import style0 from "./tide-v2-preview.vue?vue&type=style&index=0&id=d2b93fa4&prod&lang=less"
import style1 from "./tide-v2-preview.vue?vue&type=style&index=1&id=d2b93fa4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2b93fa4",
  null
  
)

export default component.exports
<!--
 * @Author: Ruilong Li
 * @Date: 2020-08-01 11:56:02
 * @LastEditors  : Ruilong Li
 * @LastEditTime : 2020-08-01 14:41:47
 * @Description:  颜色picker
 * @FilePath: /davinci/src/components/scheme-input.vue
 -->
<template>
  <div>
    <el-input :placeholder="placeholder" v-model="value" @input="onInput">
      <el-dropdown trigger="click" @command="handleCommand" slot="suffix">
        <i class="el-icon-link el-input__icon"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="scene">声音场景</el-dropdown-item>
          <el-dropdown-item command="album">冥想练习</el-dropdown-item>
          <el-dropdown-item command="sleep_story">睡眠故事</el-dropdown-item>
          <el-dropdown-item command="web_inside">APP 内网页</el-dropdown-item>
          <el-dropdown-item command="web_outside">APP 外网页</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-input>
    <scene-picker v-model="dialogDisplay.scenePicker" @selected="selectedScene" append-to-body></scene-picker>
    <album-picker v-model="dialogDisplay.albumPicker" @selected="selectedAlbum" append-to-body></album-picker>
    <sleep-story-picker v-model="dialogDisplay.sleepStoryPicker" @selected="selectedSleepStory" append-to-body></sleep-story-picker>
  </div>
</template>

<script>
import ScenePicker from '@/components/resource-picker/scene-picker';
import AlbumPicker from '@/components/resource-picker/album-picker';
import SleepStoryPicker from '@/components/resource-picker/sleepstory-picker';

export default {
  name: 'SchemeInput',
  components: { ScenePicker, AlbumPicker, SleepStoryPicker },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      required: false,
      default: '链接',
    },
  },
  data() {
    return {
      dialogDisplay: {
        scenePicker: false,
        albumPicker: false,
        sleepStoryPicker: false,
        webInSideInputDialog: false,
        webOutSideInputDialog: false,
      },
    };
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    handleCommand(command) {
      switch (command) {
        case 'scene':
          this.dialogDisplay.scenePicker = true;
          break;
        case 'album':
          this.dialogDisplay.albumPicker = true;
          break;
        case 'sleep_story':
          this.dialogDisplay.sleepStoryPicker = true;
          break;
        case 'web_inside':
          this.webInSideInput();
          break;
        case 'web_outside':
          this.webOutSideInput();
          break;
      }
    },
    selectedScene(scene) {
      const URL = `tide://scene?sceneID=${scene.id}`;
      this.onInput(URL);
    },
    selectedAlbum(album) {
      const URL = `tide://album?albumID=${album.id}`;
      this.onInput(URL);
    },
    selectedSleepStory(story) {
      const URL = `tide://sleep_story?sleepStoryID=${story.id}`;
      this.onInput(URL);
    },
    async webInSideInput() {
      try {
        const res = await this.$prompt('请输入URL', 'APP 内网页', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        const targetURL = encodeURIComponent(res.value);
        const URL = `tide://web?url=${targetURL}`;
        this.onInput(URL);
      } catch (e) {
        console.log(e);
      }
    },
    async webOutSideInput() {
      try {
        const res = await this.$prompt('请输入URL', 'APP 外网页(3.6.0后可用)', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        const targetURL = encodeURIComponent(res.value);
        const URL = `tide://web_explorer?url=${targetURL}`;
        this.onInput(URL);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">支付记录</div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column label="时间">
            <template slot-scope="scope">
              <span>创建时间：{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              <br />
              <span>更新时间：{{ $moment(scope.row.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="channel" label="渠道"></el-table-column>
          <el-table-column prop="external_txn_id" label="交易ID"></el-table-column>
          <el-table-column prop="product_id" label="SKU"></el-table-column>
          <el-table-column label="是否免费">
            <template slot-scope="scope">
              <p>{{ scope.row.is_free }}</p>
            </template>
          </el-table-column>

          <el-table-column label="相关信息">
            <template slot-scope="scope">
              <div>
                <template v-if="scope.row.admin_id">
                  <p>操作员: {{ scope.row.admin_id }}</p>
                  <el-tooltip class="item" effect="light" placement="top-start">
                    <p class="note">备注: {{ scope.row.note }}</p>
                    <div slot="content" style="white-space: pre-wrap">{{ scope.row.note }}</div>
                  </el-tooltip>
                </template>
                <div style="margin-top: 10px">
                  <template v-if="scope.row.changes.vip.after.is_lifetime_member || scope.row.changes.vip.after.expired_at > 0">
                    <p>潮汐 Plus 到期时间:</p>
                    <p>
                      {{ scope.row.changes.vip.before.is_lifetime_member ? '终身会员' : scope.row.changes.vip.before.expired_at ? $moment(scope.row.changes.vip.before.expired_at * 1000).format('YYYY-MM-DD') : 'None' }}
                      ->
                      {{ scope.row.changes.vip.after.is_lifetime_member ? '终身会员' : $moment(scope.row.changes.vip.after.expired_at * 1000).format('YYYY-MM-DD') }}
                    </p>
                  </template>
                </div>
                <div style="margin-top: 10px">
                  <template v-if="scope.row.changes.scene_pass.after.is_lifetime_member || scope.row.changes.scene_pass.after.expired_at > 0">
                    <p>声音卡到期时间:</p>
                    <p>
                      {{ scope.row.changes.scene_pass.before.is_lifetime_member ? '终身会员' : scope.row.changes.scene_pass.before.expired_at ? $moment(scope.row.changes.scene_pass.before.expired_at * 1000).format('YYYY-MM-DD') : 'None' }}
                      ->
                      {{ scope.row.changes.scene_pass.after.is_lifetime_member ? '终身会员' : $moment(scope.row.changes.scene_pass.after.expired_at * 1000).format('YYYY-MM-DD') }}
                    </p>
                  </template>
                </div>
              </div>
              <div style="margin-top: 10px">
                <template v-if="scope.row.changes.purchased_scenes.added == '[]'">
                  <p>购买声音场景ID:</p>
                  <p>{{ JSON.stringify(scope.row.changes.purchased_scenes.added) }}</p>
                </template>
              </div>
              <div style="margin-top: 10px">
                <template v-if="scope.row.changes.purchased_records.added == '[]'">
                  <p>购买记录ID:</p>
                  <p>{{ JSON.stringify(scope.row.changes.purchased_records.added) }}</p>
                </template>
              </div>
            </template>
          </el-table-column>

          <el-table-column width="160" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
              <el-button type="text" @click="showAffects(scope.row)">查看变化</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
        <el-dialog title="变化详情" :visible.sync="affectsVisible">
          <div v-for="(affect, index) in affects" :key="index">
            <h4>{{ `第 ${index + 1} 个变化` }}</h4>
            <code-diff output-format="side-by-side" :old-string="JSON.stringify(affect.before, undefined, 2)" :new-string="JSON.stringify(affect.after, undefined, 2)" :context="4" />
          </div>
        </el-dialog>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
import CodeDiff from '@/components/code-diff';
export default {
  name: 'EditEquip',
  components: {
    Container,
    PageHeader,
    CodeDiff,
  },
  data() {
    return {
      busy: false,
      finish: false,
      JSONVisible: false,
      affectsVisible: false,
      list: [],
      detail: '',
      affects: [],
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    showAffects(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.affects = data.affects;
      this.affectsVisible = true;
    },

    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function() {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/payments`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    init: async function() {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.note {
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

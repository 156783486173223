<template>
  <container>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">attach_money</i>
        <h3 slot="title">活动管理</h3>
        <p slot="desc">管理运营活动的地方</p>
      </page-header>
    </div>
    <div class="container" slot="container">
      <el-form class="form container-body" v-model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="活动名称（仅后台显示用）">
              <el-input :disabled="isEditor" v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="发放数量">
              <el-input-number :disabled="isEditor" :step="100" v-model="form.count"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="Plus 会员天数">
              <el-input-number :disabled="isEditor" :step="10" v-model="days"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="声音卡会员天数">
              <el-input-number :disabled="isEditor" :step="10" v-model="scene_pass_days"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="生效时间：">
              <el-date-picker type="datetime" v-model="validFrom"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="失效时间：">
              <el-date-picker type="datetime" v-model="validTo"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-form-item label="仅限新会员使用">
            <el-radio-group v-model="only_non_vip">
              <el-radio label="0">否</el-radio>
              <el-radio label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="每个用户可使用次数">
              <el-input-number :step="1" v-model="maximum_usage_count"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="注册时间小于（天）">
              <el-input-number :step="1" v-model="registered_within_days"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="派送特殊资格">
              <el-input :disabled="isEditor" v-model="form.content.send_magic_key"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="7">
            <el-form-item label="特殊资格天数">
              <el-input-number :disabled="isEditor" :step="10" v-model="magic_key_days"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="container">
      <div class="container-footer">
        <template v-if="isEditor">
          <el-button :disabled="!$store.getters.hasEditRole" size="small" class="act-btn" @click="toggleCode">{{ form.aborted ? '恢复活动' : '强制中止' }}</el-button>
        </template>
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="act-btn" @click="save">保存</el-button>
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="act-btn" @click="sync">{{ $env === 'production' ? '复制到开发环境' : '复制到正式环境' }}</el-button>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
export default {
  name: 'RedeemCodesForm',
  components: {
    Container,
    PageHeader,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      env: 'production',
      isEditor: false,
    };
  },
  computed: {
    only_non_vip: {
      get() {
        return this.form.only_non_vip;
      },
      set(val) {
        this.form.only_non_vip = val;
      },
    },
    maximum_usage_count: {
      get() {
        return this.form.maximum_usage_count;
      },
      set(val) {
        this.form.maximum_usage_count = val;
      },
    },
    registered_within_days: {
      get() {
        return this.form.registered_within_days;
      },
      set(val) {
        this.form.registered_within_days = val;
      },
    },
    days: {
      get() {
        return this.form.content.tide_plus_period / 86400;
      },
      set(val) {
        this.form.content.tide_plus_period = val * 86400;
      },
    },
    scene_pass_days: {
      get() {
        return this.form.content.scene_pass_period / 86400;
      },
      set(val) {
        this.form.content.scene_pass_period = val * 86400;
      },
    },
    magic_key_days: {
      get() {
        return this.form.content.magic_key_period / 86400;
      },
      set(val) {
        this.form.content.magic_key_period = val * 86400;
      },
    },
    validFrom: {
      get() {
        return this.form.valid_from * 1000;
      },
      set(val) {
        this.form.valid_from = parseInt(val / 1000);
      },
    },
    validTo: {
      get() {
        return this.form.valid_to * 1000;
      },
      set(val) {
        this.form.valid_to = parseInt(val / 1000);
      },
    },
  },
  watch: {},
  methods: {
    sync() {
      this.form.only_non_vip = this.form.only_non_vip === '1';
      this.$emit('sync', this.form);
    },
    save() {
      this.form.only_non_vip = this.form.only_non_vip === '1';
      this.$emit('save', this.form);
    },
    toggleCode() {
      this.form.only_non_vip = this.form.only_non_vip === '1';
      this.$emit('toggleCode', this.form);
    },
  },
  created: function() {
    this.env = this.$env;
    this.isEditor = this.$route.name === 'RedeemCodeEditor';
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .act-btn + .act-btn {
    margin-left: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}

.layers {
  display: flex;
  flex-direction: row;
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}
.form-label {
  position: relative;
  z-index: 2;
}
.el-form-item__label > .form-label i {
  opacity: 0;
}
.el-form-item:hover > .el-form-item__label > .form-label i {
  opacity: 1;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.32);
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
</style>

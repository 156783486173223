import { render, staticRenderFns } from "./reviewers.vue?vue&type=template&id=37e1ecdc&scoped=true"
import script from "./reviewers.vue?vue&type=script&lang=js"
export * from "./reviewers.vue?vue&type=script&lang=js"
import style0 from "./reviewers.vue?vue&type=style&index=0&id=37e1ecdc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37e1ecdc",
  null
  
)

export default component.exports
<template>
  <div :style="customStyle" class="manager-page-container">
    <div class="page-header">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ name: route.name, path: route.path }" v-for="route in $route.matched" :key="route.path">{{ route.meta.label }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="page-header-extra" v-if="showHeaderExtra">
        <slot name="page-header-extra"></slot>
      </div>
    </div>
    <div class="rows" v-if="showSubContainer">
      <slot name="container">
        <div class="container">
          <div class="container-head">
            <div class="container-head-title">
              <slot name="container-head-title"></slot>
            </div>
            <div class="container-head-extra">
              <slot name="container-head-extra"></slot>
            </div>
          </div>
          <div class="container-body">
            <slot name="container-body"></slot>
          </div>
        </div>
      </slot>
      <slot name="sub-container">
        <div class="sub-container">
          <div class="container-head">
            <div class="container-head-title">
              <slot name="sub-container-head-title"></slot>
            </div>
            <div class="container-head-extra">
              <slot name="sub-container-head-extra"></slot>
            </div>
          </div>
          <div class="container-body">
            <slot name="sub-container-body"></slot>
          </div>
        </div>
      </slot>
    </div>
    <div v-else class="column">
      <slot name="container">
        <div class="container">
          <div class="container-head">
            <div class="container-head-title">
              <slot name="container-head-title"></slot>
            </div>
            <div class="container-head-extra">
              <slot name="container-head-extra"></slot>
            </div>
          </div>
          <div class="container-body">
            <slot name="container-body"></slot>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    customStyle: {
      required: false,
      default: '',
    },
  },
  computed: {
    showHeaderExtra() {
      return this.$slots['page-header-extra'];
    },
    showSubContainer() {
      return this.$slots['sub-container'] || this.$slots['sub-container-head-title'] || this.$slots['sub-container-head-extra'] || this.$slots['sub-container-body'];
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.manager-page-container {
  .page-header {
    padding: 16px 32px 16px;
    border-bottom: 1px solid #e8e8e8;
    background: #fff;
  }
  .page-header > *:last-child {
    margin-bottom: 0;
  }
  .breadcrumb {
    box-sizing: border-box;
    margin-bottom: 16px;
    background: #fff;
    line-height: 1.5;
  }
  .rows {
    display: flex;
    flex-direction: rows;
    margin: 0 -12px;
    padding: 0 24px;
  }
  .column {
    margin: 0 -12px;
    padding: 0 24px;
  }
  .container,
  .sub-container {
    margin: 24px -12px 0;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
  }
  .container-head {
    margin: 0 12px;
    padding: 0 32px;
    line-height: 32px;
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    background-color: #fff;
  }
  .container-head-title {
    flex: 1;
    padding: 16px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    white-space: nowrap;
  }

  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .container-body {
    margin: 0 12px;
    padding: 12px 32px 24px;
    flex-grow: 1;
    background-color: #fff;
  }

  .container-body-right {
    margin: 0 12px;
    flex-shrink: 0;
    background-color: #fff;
  }

  .container-body-header {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    padding: 0 32px;
    background-color: #fff;
  }
  .container-body-header-title {
    flex: 1;
    padding: 16px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
  .container-body-header-extra {
    display: flex;
    align-items: center;
    padding: 12px 0;
  }

  .container-footer {
    box-sizing: border;
    padding: 0 12px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 56px;
    bottom: 0;
    right: 0;
    left: 240px;
    z-index: 3;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
    border-top: 1px solid #e8e8e8;
    background-color: #fff;
  }

  .container-block {
    border-bottom: 1px solid #e8e8e8;
  }

  .container-list {
    padding-bottom: 8px;
  }

  .container-list-item {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .container-list .container-list-item + .container-list-item {
    border-top: 1px solid #e8e8e8;
  }
}
</style>

<template>
  <router-view v-if="$route.name !== 'ExplorePageManager'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body">
          <el-menu class="nav" mode="horizontal" router :default-active="defaultActive">
            <template v-for="category in categories">
              <el-menu-item :key="category.id" :index="`/explorer_pages/${category.type}`">{{ category.name }}</el-menu-item>
            </template>
            <el-button v-show="!$route.params.category_type.includes(',')" :disabled="!$store.getters.hasEditRole" @click="showListSortPanel" type="text">重新排序</el-button>
            <el-button v-show="!$route.params.category_type.includes(',')" :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">添加</el-button>
          </el-menu>
        </div>
        <div class="container-body">
          <el-table :data="explorePageData" stripe style="width: 100%">
            <el-table-column label="名称" min-width="120px">
              <template slot-scope="scope">
                <p>{{ getTR(scope.row.name) }}<br />{{ scope.row.id }}</p>
              </template>
            </el-table-column>

            <el-table-column label="类型">
              <template slot-scope="scope">{{ scope.row.type }}</template>
            </el-table-column>

            <el-table-column label="状态">
              <template slot-scope="scope">{{ getStatus(scope.row.status) }} </template>
            </el-table-column>

            <el-table-column label="地区">
              <template slot-scope="scope">
                <span class="region" v-for="region in scope.row.regions" v-bind:key="region">
                  {{ region }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="语言">
              <template slot-scope="scope">
                <span class="region" v-for="lang in scope.row.languages" v-bind:key="lang">
                  {{ lang }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="操作" align="right">
              <template slot-scope="scope">
                <router-link
                  class="el-button el-button--text"
                  :to="{
                    name: 'ExplorePageEditor',
                    params: { package_id: scope.row.id, category_type: $route.params.category_type },
                  }"
                  >编辑</router-link
                >
                <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">复制JSON</el-button>
                <el-button type="text" :disabled="!$store.getters.hasAdminRole" @click="removeItem(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-dialog title="重新排序" width="40%" :visible.sync="dialogDisplay.listSortPanel">
          <draggable class="sort-list-container" v-model="sortList">
            <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
              <div class="sort-item-key">{{ index + 1 }}</div>
              <cover :width="48" :height="48" :src="item.image"></cover>
              <div class="sort-item-name">{{ getTR(item.name) }}</div>
            </div>
          </draggable>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveSort">保 存</el-button>
            <el-button @click="dialogDisplay.listSortPanel = false">返 回</el-button>
          </span>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
// import PageHeader from '@/components/page-header';
import Cover from '@/components/cover';
import Draggable from 'vuedraggable';

export default {
  name: 'ExplorePageManager',
  components: {
    Container,
    // PageHeader,
    Cover,
    Draggable,
  },
  data() {
    return {
      lang: 'zh-Hans',
      langs: [
        {
          value: 'en',
          label: 'en',
        },
        {
          value: 'zh-Hans',
          label: 'zh-Hans',
        },
        {
          value: 'zh-Hant',
          label: 'zh-Hant',
        },
      ],
      sortChanged: false,
      ready: false,
      busy: false,
      finish: false,
      categories: [
        {
          type: 'home',
          name: '潮汐探索',
        },
        {
          type: 'sleep_topic',
          name: '睡眠助手',
        },
        {
          type: 'nap_topic',
          name: '小憩助手',
        },
        {
          type: 'focus_topic',
          name: '专注助手',
        },
        {
          type: 'theme',
          name: '主题活动',
        },
        {
          type: 'tv_',
          name: 'TV 相关',
        },
        {
          type: 'car_',
          name: 'Car 相关',
        },
        {
          type: 'sdk_',
          name: 'SDK 相关',
        },
      ],
      explorePageData: [],
      sortList: [],
      dialogDisplay: {
        JSONVisible: false,
        listSortPanel: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
    defaultActive() {
      return `/explorer_pages/${this.$route.params.category_type}`;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'ExplorePageManager') {
        this.init();
      }
    },
    '$route.params.category_type'() {
      this.init();
    },
  },
  methods: {
    showListSortPanel() {
      this.sortList = this.explorePageData;
      this.dialogDisplay.listSortPanel = true;
    },

    async saveSort() {
      const data = this.sortList.map((item, idx) => {
        return { id: item.id, sort_key: idx };
      });
      try {
        await this.$request({
          url: '/v2/admin/explorer/pages/sort_keys',
          method: 'POST',
          data,
        });
        this.explorePageData = this.sortList;
        this.dialogDisplay.listSortPanel = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        this.$message.error(e.message);
      }
    },

    showJSON(item) {
      this.detail = JSON.stringify(item, undefined, 2);
      this.dialogDisplay.JSONVisible = true;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      switch (status) {
        case 'published':
          return '已上架';
        case 'silent':
          return '静默上架';
        case 'reviewing':
          return '审阅中';
        case 'draft':
          return '草稿';
      }
      return '';
    },
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
    addItem() {
      this.$router.push({
        name: 'ExplorePageAdder',
        params: {
          category_type: this.$route.params.category_type,
        },
      });
    },
    async removeItem(id) {
      try {
        const { value } = await this.$prompt('此操作将永久删除该页面，请输入 “确定删除” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        if (value !== '确定删除') {
          this.$message.error('输入错误');
        } else {
          await this.$request({
            url: `/v2/admin/explorer/pages/${id}`,
            method: 'DELETE',
          });
          this.explorePageData = this.explorePageData.filter((item) => item.id !== id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      await this.fetchList();
    },
    fetchList: async function () {
      const { category_type: categoryType } = this.$route.params;
      const res = await this.$request({
        url: `/v2/admin/explorer/pages`,
        params: {
          types: categoryType,
        },
      });
      this.explorePageData = res.data;
    },
  },
  async created() {
    await this.init();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  margin-left: auto;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  .select-btn {
    display: inline-block;
  }
  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;
  .select-btn {
    display: none;
  }
  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.reviewing {
  background-color: rgba(255, 126, 126, 0.12);
}

.sort-list-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

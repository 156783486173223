<template>
  <div class="attacheds-previewer" :class="{ show: show }" @click="$emit('update:show', false)">
    <div class="preview-container">
      <div class="attached-images-container">
        <div class="el-icon-close" @click="$emit('update:show', false)"></div>
        <div class="swiper-button-prev" @click.stop="next"></div>
        <div class="swiper-button-next" @click.stop="prev"></div>
        <swiper v-if="swiperReady" class="attached-image-top" ref="attachedImageTop" :options="swiperTopOptions" @slideChange="slideChange">
          <swiper-slide v-for="(image, index) in images" :key="`${image}-${index}`" class="full-attached-image-container">
            <div v-if="unknowIndexs.includes(index)" class="full-attached-image-link" @click.stop>未知文件</div>
            <div v-else-if="maybeVideoIndexs.includes(index)" class="full-attached-image-link" @click.stop>
              <video controls class="full-attached-image" :src="image" @error="videoOnloadFail(index)"></video>
            </div>
            <a v-else class="full-attached-image-link" @click.stop :href="image" target="_blank">
              <img class="full-attached-image" :src="image" @error="imageOnloadFail(index)" />
            </a>
          </swiper-slide>
        </swiper>
        <swiper class="attached-image-thumbs" ref="attachedImageThumbs" :options="swiperThumbsOptions">
          <swiper-slide class="thumb-image-container" v-for="(image, index) in images" :key="`thumb-${image}-${index}`">
            <video class="thumb-image" v-if="maybeVideoIndexs.includes(index)" :src="image" @click.stop></video>
            <img class="thumb-image" v-else :src="image" @click.stop />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css';

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
export default {
  name: 'AttachedsPreviewer',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    show: Boolean,
    images: Array,
    index: { type: Number, default: 0 },
  },
  data() {
    return {
      swiperTopOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperThumbsOptions: {
        spaceBetween: 10,
        slidesPerView: 4,
        watchSlidesVisibility: true,
      },
      swiperReady: false,
      maybeVideoIndexs: [],
      unknowIndexs: [],
      activeIndex: this.index,
    };
  },
  computed: {
    swiperTop() {
      return this.$refs.attachedImageTop.$swiper;
    },
    attachedImageThumbs() {
      if (this.$refs.attachedImageThumbs) {
        return this.$refs.attachedImageThumbs.$swiper;
      }
      return null;
    },
  },
  watch: {
    index(val) {
      this.swiperTop.slideTo(val);
    },
  },
  methods: {
    imageOnloadFail(index) {
      this.maybeVideoIndexs.push(index);
    },
    videoOnloadFail(index) {
      this.unknowIndexs.push(index);
    },
    slideChange() {
      this.$emit('update:index', this.swiperTop.activeIndex);
    },
    prev() {
      this.swiperTop.slideTo(this.swiperTop.activeIndex + 1);
    },
    next() {
      this.swiperTop.slideTo(this.swiperTop.activeIndex - 1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      const swiperThumbs = this.$refs.attachedImageThumbs.swiper;
      this.swiperTopOptions = {
        spaceBetween: 10,
        thumbs: {
          swiper: swiperThumbs,
        },
      };
      this.swiperReady = true;
    });
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.attacheds-previewer {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.attacheds-previewer.show {
  display: block;
}

.attached-image {
  cursor: pointer;
  width: 140px;
  height: 140px;
  object-fit: contain;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.04);
}
.attached-image + .attached-image {
  margin-left: 16px;
}
.full-attached-image-container {
  text-align: center;
}
.full-attached-image-link {
  display: inline-block;
  height: 74vh;
}
.full-attached-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.preview-container {
  display: flex;
  flex-direction: column;
}

.attached-image-top {
  margin: 3vh 0 3vh;
  height: 74vh;
}

.attached-image-thumbs {
  height: 16vh;
}

.thumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.attached-image-thumbs .swiper-slide {
  opacity: 0.6;
}

.attached-image-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.el-icon-close {
  display: block;
  cursor: pointer;
  position: absolute;
  z-index: 11;
  right: 24px;
  top: 24px;
  width: 36px;
  height: 36px;
  font-size: 36px;
  color: #fff;
}
</style>

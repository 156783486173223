<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:05
 * @LastEditors  : Ruilong Li
 * @LastEditTime : 2020-02-06 14:59:39
 * @Description: 标签选择器
 * @FilePath: /davinci/src/components/tags-selecter.vue
 -->

<script>
export default {
  name: 'TagsSelecter',
  props: {
    value: Boolean,
    selected: Array,
  },
  data() {
    return {
      tags: [],
    };
  },
  // -----------------------------------------------------------
  // MARK: - Properties Computer
  // -----------------------------------------------------------
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    selectedTags: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
  // -----------------------------------------------------------
  // MARK: - Life Cycle
  // -----------------------------------------------------------
  created() {
    this.init();
  },
  methods: {
    // -----------------------------------------------------------
    // MARK: - Init
    // -----------------------------------------------------------
    async init() {
      this.tags = await this.getTags();
    },

    // -----------------------------------------------------------
    // MARK: - Request Data
    // -----------------------------------------------------------
    async getTags() {
      const tagsRes = await this.$request({
        url: '/v1/admin/scene_tags',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      return tagsRes.data.sort((a, b) => {
        return a.sort_key - b.sort_key;
      });
    },

    // -----------------------------------------------------------
    // MARK: - Events Handler
    // -----------------------------------------------------------

    select(val) {
      this.$emit('change', val);
    },

    // -----------------------------------------------------------
    // MARK: - Helper
    // -----------------------------------------------------------
    translate(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
};
</script>

<template>
  <div>
    <el-dialog title="选择标签" width="40%" :visible.sync="show">
      <template v-if="show">
        <el-checkbox v-for="tag in tags" :key="tag.key" :checked="Boolean(selected.find((selectedTag) => selectedTag.key === tag.key))" @change="select(tag)">{{ translate(tag.name) }}</el-checkbox>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button key="backBtn" @click="show = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.sort-item {
  margin: 8px;
}
</style>

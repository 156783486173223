<template>
  <equip-form :form="form" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'EditOAuth2Client',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: Object,
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    async save(data) {
      const { client_id: clientId } = this.$route.params;
      await this.$request({
        url: `/v1/admin/oauth2/clients/${clientId}`,
        method: 'PUT',
        data,
      });
      await this.init();
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
    init: async function() {
      const { client_id: clientId } = this.$route.params;
      try {
        const res = await this.$request({
          url: `/v1/admin/oauth2/clients/${clientId}`,
        });
        this.form = Object.assign({}, this.form, res.data);
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
      console.log(this.form);
    },
  },
  created() {
    this.init();
  },
};
</script>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">场景配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.name[lang] && xForm.description[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="7">
                <el-form-item label="场景名">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="15" :offset="1">
                <el-form-item label="副标题">
                  <el-input v-model="xForm.sub_title[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" :rows="3" v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp()">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="快速填充">
          <el-input type="textarea" :rows="2" v-model="shortcut"></el-input>
        </el-form-item>

        <el-form-item label="播放模式">
          <el-col>
            <el-radio-group v-model="xForm.play_mode">
              <el-radio label="random">随机播放</el-radio>
              <el-radio label="weather">天气匹配</el-radio>
              <el-radio label="weekday">按周播放</el-radio>
              <el-radio label="ordered">顺序播放</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>

        <el-form-item label="上架状态">
          <el-col>
            <el-radio-group v-model="xForm.status">
              <el-radio label="published">上架</el-radio>
              <el-radio label="reviewing">审阅</el-radio>
              <el-radio label="draft">草稿</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>

        <el-form-item label="加入标签[手动]">
          <el-col>
            <el-select v-model="xForm.manual_simple_tag_ids" value-key="id" multiple default-first-option placeholder="选择标签">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="加入标签[自动]">
          <el-col>
            <el-select v-model="xForm.simple_tags" value-key="id" multiple disabled default-first-option placeholder="选择标签">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="新品角标持续时间">
          <el-col>
            <el-radio-group v-model="freshUntilLock">
              <el-radio :label="true">不显示</el-radio>
              <el-radio :label="false">
                显示到
                <el-date-picker :disabled="freshUntilLock" size="medium" type="datetime" v-model="freshUntil" placeholder="日期" default-time="23:59:59"></el-date-picker>
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>

        <el-form-item label="主色调">
          <color-picker v-model="xForm.primary_color"></color-picker>
        </el-form-item>

        <el-col :span="3">
          <el-col :span="24">
            <el-form-item>
              <div slot="label" class="form-label">
                <span>封面</span>
                <i class="el-icon-edit" @click="dialogDisplay.coverEdit = true"></i>
              </div>
              <el-col>
                <div class="preview" v-if="xForm['image']" @click="dialogDisplay.coverPreview = true">
                  <img class="preview-img" :src="xForm['image']" />
                  <div class="preview-img-cover">
                    <i class="material-icons">remove_red_eye</i>
                    <span>预览</span>
                  </div>
                </div>
                <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadCoverProgress" :on-error="uploadCoverError" :on-success="uploadCoverSuccess" :before-upload="beforeUploadCover" :data="{ token: picToken }" :show-file-list="false">
                  <div class="uploadPic">
                    <i class="el-icon-upload2"></i>
                    <span>上传场景封面</span>
                  </div>
                </el-upload>
              </el-col>
            </el-form-item>
          </el-col>
        </el-col>
      </el-form>

      <div class="container-head">
        <div class="container-head-title">场景列表</div>
        <div class="container-head-extra">
          <el-button
            :disabled="!$store.getters.hasEditRole"
            type="text"
            size="mini"
            @click="
              sortList = scenes;
              dialogDisplay.sortContents = true;
            "
            >排序</el-button
          >
          <el-button :disabled="!$store.getters.hasEditRole" type="text" size="mini" @click="dialogDisplay.editContents = true">编辑场景列表</el-button>
        </div>
      </div>

      <div class="form container-body">
        <el-table class="data-table" :data="scenes">
          <el-table-column label="排序" width="55px" align="left">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>

          <el-table-column label="图片">
            <template slot-scope="scope">
              <cover :width="48" :height="48" :src="scope.row.image"></cover>
            </template>
          </el-table-column>

          <el-table-column label="场景名">
            <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
          </el-table-column>

          <el-table-column label="定价">
            <template slot-scope="scope">
              <div v-if="scope.row.access.includes('free')">免费</div>
              <template v-else-if="scope.row.access.includes('purchase')">
                <div>¥{{ scope.row.purchase_info.price }}</div>
              </template>
              <div v-else>无</div>
            </template>
          </el-table-column>

          <el-table-column label="可订阅">
            <template slot-scope="scope">
              <div v-if="scope.row.access.includes('subscribe')" style="color: #67c23a">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
              <template v-else>
                <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ getStatus(scope.row.status) }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="选择场景" :visible.sync="dialogDisplay.editContents">
        <el-table ref="listTable" :data="filtedList" style="width: 100%" @select="onSelect" @select-all="onSelectAll">
          <el-table-column type="selection" width="55"></el-table-column>

          <el-table-column label="图片">
            <template slot-scope="scope">
              <cover :width="48" :height="48" :src="scope.row.image"></cover>
            </template>
          </el-table-column>

          <el-table-column label="场景名">
            <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
          </el-table-column>

          <el-table-column label="定价">
            <template slot-scope="scope">
              <div v-if="scope.row.access.includes('free')">免费</div>
              <template v-else-if="scope.row.access.includes('purchase')">
                <div>¥{{ scope.row.purchase_info.price }}</div>
              </template>
              <div v-else>无</div>
            </template>
          </el-table-column>

          <el-table-column label="可订阅">
            <template slot-scope="scope">
              <div v-if="scope.row.access.includes('subscribe')" style="color: #67c23a">是</div>
              <div v-else>否</div>
            </template>
          </el-table-column>

          <el-table-column label="展示地区">
            <template slot-scope="scope">
              <div v-if="!scope.row.regions || !scope.row.regions.length">所有</div>
              <template v-else>
                <div v-for="region in scope.row.regions" :key="region.value">{{ region }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot="header">
              <el-input placeholder="搜索" suffix-icon="el-icon-search" size="mini" v-model="filterKeyword"></el-input>
            </template>
            <template slot-scope="scope">
              {{ getStatus(scope.row.status) }}
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>

      <el-dialog title="场景排序" width="40%" :visible.sync="dialogDisplay.sortContents">
        <draggable class="sort-list-container" v-model="sortList">
          <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="sort-item-name">{{ getTR(item.name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSort">保 存</el-button>
          <el-button @click="dialogDisplay.sortContents = false">返 回</el-button>
        </span>
      </el-dialog>

      <el-dialog title="封面预览" :visible.sync="dialogDisplay.coverPreview">
        <img class="preview-dialog-img" :src="xForm['image']" />
      </el-dialog>

      <el-dialog title="封面地址" :visible.sync="dialogDisplay.coverEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="xForm['image']"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import ColorPicker from '@/components/color-picker';
import AudioUploader from '@/components/audio-uploader';
import OpenCC from '@/plugins/opencc';
import ElInputTag from '@/components/el-input-tag';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
import allStatus from '@/status.json';

export default {
  name: 'LessonForm',
  components: {
    Container,
    ColorPicker,
    AudioUploader,
    ElInputTag,
    Draggable,
    Cover,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = localStorage.getItem('env');
    return {
      loading: false,
      env,

      langs: require('@/langs.json'),

      simpleTagOptions: [],
      list: [],
      sortList: [],

      picToken: '',
      picCDN: '',
      shortcut: '',
      filterKeyword: '',

      coverUploading: false,

      dialogDisplay: {
        coverEdit: false,
        coverPreview: false,
        sortContents: false,
        editContents: false,
      },
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    freshUntilLock: {
      get() {
        return this.xForm.fresh_until == 0;
      },
      set(val) {
        if (!val) {
          const today = this.$moment();
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.freshUntil = +today;
        } else {
          this.freshUntil = 0;
        }
      },
    },
    scenes: {
      get() {
        if (!this.list.length) {
          return [];
        }
        return this.xForm.scenes_ids.map((v) => this.list.find((scn) => scn.id === v));
      },
      set(val) {
        this.xForm.scenes_ids = val.map((v) => v.id);
      },
    },
    freshUntil: {
      get() {
        return this.xForm.fresh_until * 1000;
      },
      set(val) {
        this.xForm.fresh_until = parseInt(new Date(val) / 1000);
      },
    },
    filtedList() {
      let filtedList = this.list;
      const filterKeyword = this.filterKeyword;

      if (filterKeyword.length) {
        filtedList = filtedList.filter((item) => {
          return (item.name['en'] || '').includes(filterKeyword) || (item.name['zh-Hans'] || '').includes(filterKeyword) || (item.name['zh-Hant'] || '').includes(filterKeyword);
        });
      }

      return filtedList;
    },
  },
  watch: {
    shortcut(val) {
      const i18nInfos = val.split('\n');
      const fields = ['name', 'subtitle', 'description'];
      if (!i18nInfos.length) {
        this.$message.error('解析失败，无法填充');
      } else {
        i18nInfos.forEach((item, index) => {
          const langInfos = item.split('	');
          if (langInfos.length === 6) {
            this.xForm[fields[index]]['zh-Hans'] = langInfos[0] || langInfos[1];
            this.xForm[fields[index]]['en'] = langInfos[1];
            this.xForm[fields[index]]['ja'] = langInfos[2] || langInfos[1];
            this.xForm[fields[index]]['es'] = langInfos[3] || langInfos[1];
            this.xForm[fields[index]]['ru'] = langInfos[4] || langInfos[1];
            this.xForm[fields[index]]['ko'] = langInfos[5] || langInfos[1];
          }
        });
        this.$nextTick(() => {
          this.s2tw();
        });
      }
    },
    filtedList() {
      this.freshSelected();
    },
    'dialogDisplay.editContents'() {
      this.freshSelected();
    },
  },
  methods: {
    saveSort() {
      this.scenes = this.sortList;
    },
    freshSelected() {
      this.$nextTick(() => {
        const $listTables = this.$refs.listTable;
        if ($listTables) {
          const $ref = $listTables;
          $ref.clearSelection();
          this.filtedList.forEach((row) => {
            if (this.xForm.scenes_ids.some((item) => item === row.id)) {
              $ref.toggleRowSelection(row, true);
            }
          });
        }
      });
    },
    onSelect(selection, row) {
      if (this.xForm.scenes_ids.some((item) => item === row.id)) {
        this.xForm.scenes_ids = this.xForm.scenes_ids.filter((item) => item !== row.id);
      } else {
        this.xForm.scenes_ids.unshift(row.id);
      }
    },
    onSelectAll(selection) {
      if (!selection.length) {
        const list = this.xForm.scenes_ids.filter((albumId) => !this.filtedList.some((item) => item.id === albumId));
        this.xForm.scenes_ids = list;
      } else {
        let list = [];
        selection.forEach((album) => {
          if (!this.xForm.scenes_ids.some((item) => item === album.id)) {
            list.push(album.id);
          }
        });
        this.xForm.scenes_ids = [...this.xForm.scenes_ids, ...list];
      }
    },
    async save() {
      this.$emit('save', this.xForm);
    },
    async fetchSimpleTags() {
      this.simpleTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/simple_tags',
        params: { offset: 0, limit: 512 },
      });
      this.simpleTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async s2tw() {
      try {
        this.form.name['zh-Hant'] = this.opencc_tw(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_tw(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_tw(this.form.description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    async s2twp() {
      try {
        this.form.name['zh-Hant'] = this.opencc_twp(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_twp(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_twp(this.form.description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    beforeUploadCover: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadCoverSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.image = picUrl;
      this.coverUploading = false;
    },
    uploadCoverProgress(response) {
      this.coverUploading = true;
    },
    uploadCoverError(response) {
      this.coverUploading = false;
    },
    async getSceneList() {
      let { category_type: categoryType } = this.$route.params;
      const res = await this.$request({
        url: '/v1/admin/scenes',
        params: { offset: 0, limit: 500, category: categoryType },
      });
      return res.data;
    },
    async refreshList() {
      this.list = await this.getSceneList();
      this.form.scenes_ids = this.form.scenes_ids.filter((item) => this.list.some((v) => v.id === item));
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  async created() {
    await this.fetchSimpleTags();
    await this.getSceneList();
    await this.refreshList();
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
    this.opencc_tw = await OpenCC.Converter('cn', 'tw');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.soundIcon img {
  background-color: rgba(0, 0, 0, 0.16);
}

.uploadIcon {
  display: inline-block;
}

.price-card {
  margin-top: 24px;
  padding: 0 24px 24px;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.diff {
  color: rgba(230, 162, 60, 1) !important;
  background: rgba(230, 162, 60, 0.12);
}

.scene-tag {
  margin-right: 16px;
  cursor: default;
}
.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button type="plain" size="small" @click="full">全屏</el-button>
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">
          <router-link replace :to="{ name: 'EditMedArticle' }">返回</router-link>
        </div>
        <div class="container-head-extra">
          <el-button type="text" @click="syncDataToOtherLang">将英文及简中填充至其他语言</el-button>
        </div>
      </div>

      <el-form class="form container-body" v-model="form">
        <el-tabs type="card" v-model="activeLang">
          <el-tab-pane v-for="lang in langs" :key="lang" :label="lang" :name="lang"></el-tab-pane>
        </el-tabs>
        <el-col :span="6">
          <el-form-item label="活动名称">
            <el-input v-model="form.title[activeLang]"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" :offset="1">
          <el-form-item label="副标题">
            <el-input v-model="form.sub_title[activeLang]"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="md-container" ref="mdContainer">
            <div class="editor-holder">
              <div class="toolbar">
                <div class="tool-group">
                  <i class="material-icons" title="加粗" @click.stop.prevent="boldContent">format_bold</i>
                  <i class="material-icons" title="删除线" @click.stop.prevent="delContent">format_strikethrough</i>
                  <i class="material-icons" title="斜体" @click.stop.prevent="italicContent">format_italic</i>
                  <i class="material-icons" title="引用" @click.stop.prevent="prependQuote">format_quote</i>
                  <i class="material-icons" title="支持选中" @click.stop.prevent="supportSelect">select_all</i>
                </div>
                <div class="tool-group">
                  <i title="大标题" @click.stop.prevent="prependH1">H1</i>
                  <i title="标题" @click.stop.prevent="prependH2">H2</i>
                  <i title="小标题" @click.stop.prevent="prependH3">H3</i>
                </div>
                <div class="tool-group">
                  <i class="material-icons" title="无序列表" @click.stop.prevent="makeBulletedList">format_list_bulleted</i>
                  <i class="material-icons" title="有序列表" @click.stop.prevent="makeNumberedList">format_list_numbered</i>
                  <i title="分割线" @click.stop.prevent="insertDivider">—</i>
                </div>
                <div class="tool-group">
                  <i class="material-icons" title="插入超链接" @click.stop.prevent="showInsertLinkDialog">insert_link</i>
                  <i class="material-icons" title="“插入图片" @click.stop.prevent="showInsertImageDialog">insert_photo</i>
                  <i class="material-icons" title="插入音频" @click.stop.prevent="showInsertAudioDialog">audiotrack</i>
                </div>
              </div>
              <div class="textarea-container">
                <textarea id="textarea" ref="textarea" class="textarea" v-model="form.markdown_content[activeLang]" @blur="setSelection" @keypress="setSelection" @keyup="setSelection" @click="setSelection" @scroll="onTextareaScroll"></textarea>
                <pre class="highlightjs" ref="highlightjs" v-highlightjs="fixed(form.markdown_content[activeLang])">
                <code ref="code" class="markdown"></code>
              </pre>
              </div>
            </div>
            <iframe id="result" ref="result"></iframe>
            <el-dialog title="插入超链接" width="40%" :visible.sync="dialog.insertLink.show">
              <el-form label-position="top" label-width="120px">
                <el-col>
                  <el-form-item label="标题">
                    <el-input v-model="dialog.insertLink.title"></el-input>
                  </el-form-item>
                </el-col>

                <el-col>
                  <el-form-item label="超链接">
                    <scheme-input v-model="dialog.insertLink.link" placeholder="tide://"></scheme-input>
                  </el-form-item>
                </el-col>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialog.insertLink.show = false">取 消</el-button>
                <el-button type="primary" @click="insertLink">确 定</el-button>
              </span>
            </el-dialog>

            <el-dialog title="插入图片" width="40%" :visible.sync="dialog.insertImage.show">
              <el-form label-position="top" label-width="120px">
                <el-form-item label="图片地址">
                  <el-input v-model="dialog.insertImage.url" :disabled="dialog.insertImage.uploading" placeholder="必须是 https://">
                    <i v-if="dialog.insertImage.uploading" class="el-icon-loading el-input__icon" slot="suffix"></i>
                    <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadImageProgress" :on-error="uploadImageError" :on-success="uploadImageSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                      <i class="el-icon-upload2 el-input__icon"></i>
                    </el-upload>
                  </el-input>
                </el-form-item>

                <el-form-item label="超链接">
                  <scheme-input v-model="dialog.insertImage.link" placeholder="tide://"></scheme-input>
                </el-form-item>

                <el-form-item label="描述">
                  <el-input v-model="dialog.insertImage.desc"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialog.insertImage.show = false">取 消</el-button>
                <el-button type="primary" :disabled="!dialog.insertImage.url.length" @click="insertImage">确 定</el-button>
              </span>
            </el-dialog>

            <el-dialog title="插入音频" width="40%" :visible.sync="dialog.insertAudio.show">
              <el-form label-position="top" label-width="120px" v-model="dialog.insertAudio">
                <el-form-item label="标题">
                  <el-input v-model="dialog.insertAudio.title"></el-input>
                </el-form-item>

                <el-form-item label="音频地址">
                  <el-input v-model="dialog.insertAudio.url" :disabled="dialog.insertAudio.uploading" placeholder="必须是 https://">
                    <i v-if="dialog.insertAudio.uploading" class="el-icon-loading el-input__icon" slot="suffix"></i>
                    <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadAudioProgress" :on-error="uploadAudioError" :on-success="uploadAudioSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                      <i class="el-icon-upload2 el-input__icon"></i>
                    </el-upload>
                  </el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialog.insertAudio.show = false">取 消</el-button>
                <el-button type="primary" :disabled="!dialog.insertAudio.url.length" @click="insertAudio">确 定</el-button>
              </span>
            </el-dialog>
          </div>
        </el-col>
      </el-form>
    </div>
  </container>
</template>

<script>
import draft from '@/assets/draft.txt';
import $ from 'jquery';
import Container from '@/components/container';
import SchemeInput from '@/components/scheme-input';
import OpenCC from '@/plugins/opencc';

// import _ from 'lodash'
import markdownit from 'markdown-it';
import markdownItFootnote from 'markdown-it-footnote';
import markdownItSup from 'markdown-it-sup';
import markdownItMark from 'markdown-it-mark';
import markdownItAttrs from 'markdown-it-attrs';
import markdownItInlineComments from 'markdown-it-inline-comments';
import markdownItImplicitFigures from 'markdown-it-implicit-figures';

const md = markdownit({
  html: true,
  linkify: false,
  typographer: false,
});

md.renderer.rules.table_open = function () {
  return '<table class="table table-striped">\n';
};

md.renderer.rules.paragraph_open = function (tokens, idx) {
  var line;
  var token = tokens[idx];
  if (token.map && token.level === 0) {
    line = token.map[0];
    return '<p class="line" data-line="' + line + '">';
  }
  return '<p>';
};

md.renderer.rules.heading_open = function (tokens, idx) {
  var line;
  var token = tokens[idx];
  if (token.map && token.level === 0) {
    line = token.map[0];
    return '<' + token.tag + ' class="line" data-line="' + line + '">';
  }
  return '<' + token.tag + '>';
};

md.use(markdownItFootnote);
md.use(markdownItSup);
md.use(markdownItMark);
md.use(markdownItAttrs);
md.use(markdownItInlineComments);
md.use(markdownItImplicitFigures, {
  dataType: true, // <figure data-type="image">, default: false
  figcaption: true, // <figcaption>alternative text</figcaption>, default: false
  tabindex: true, // <figure tabindex="1+n">..., default: false
  link: false, // <a href="img.png"><img src="img.png"></a>, default: false
});

export default {
  name: 'EditMedArticleContent',
  components: {
    Container,
    SchemeInput,
  },
  data() {
    return {
      picToken: '',
      picCDN: '',
      scrollMap: null,
      lastEditRange: {
        startPos: 0,
        endPos: 0,
      },
      dialog: {
        insertLink: {
          show: false,
          link: '',
          title: '',
        },
        insertImage: {
          show: false,
          uploading: false,
          url: '',
          link: '',
          desc: '',
        },
        insertAudio: {
          show: false,
          uploading: false,
          url: '',
          title: '',
        },
      },
      activeLang: 'zh-Hans',
      langs: require('@/langs.json'),
      form: {
        title: {
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          en: '',
          es: '',
          ru: '',
          ko: '',
        },
        sub_title: {
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          en: '',
          es: '',
          ru: '',
          ko: '',
        },
        markdown_content: {
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          en: '',
          es: '',
          ru: '',
          ko: '',
        },
      },
    };
  },
  computed: {
    idoc() {
      var iwindow = this.$refs.result.contentWindow;
      var idoc = iwindow.document;
      return idoc;
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.render();
      },
    },
  },
  methods: {
    init: async function () {
      const { article_id: articleId } = this.$route.params;
      try {
        const res = await this.$request({
          url: `/v1/admin/meditation/articles/${articleId}`,
        });
        this.form = Object.assign({}, this.form, res.data);
        this.inited = true;
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
      const self = this;
      this.$nextTick(() => {
        const iframe = this.$refs.result;
        if (iframe.attachEvent) {
          iframe.attachEvent('onload', function () {
            self.render();
          });
        } else {
          iframe.onload = function () {
            self.render();
          };
        }
      });
    },
    async save() {
      const { article_id: articleId } = this.$route.params;
      const data = this.form;
      const domain = this.$env === 'dev' ? 'd.tide.fm' : 'tide.fm';
      data.url = {
        'zh-Hans': `https://${domain}/zh_CN/inapp/articles/${articleId}`,
        'zh-Hant': `https://${domain}/zh_TW/inapp/articles/${articleId}`,
        en: `https://${domain}/en_US/inapp/articles/${articleId}`,
        ja: `https://${domain}/ja_JP/inapp/articles/${articleId}`,
        ko: `https://${domain}/ko_KR/inapp/articles/${articleId}`,
        es: `https://${domain}/en_US/inapp/articles/${articleId}`,
        ru: `https://${domain}/en_US/inapp/articles/${articleId}`,
      };

      try {
        await this.$request({
          url: `/v1/admin/meditation/articles/${articleId}`,
          method: 'PUT',
          data,
        });
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
        await this.init();
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    async syncDataToOtherLang() {
      const title = this.form.title['en'];
      const subTitle = this.form.sub_title['en'];
      const markdownContent = this.form.markdown_content['en'];
      this.form.title['ja'] = title;
      this.form.sub_title['ja'] = subTitle;
      this.form.markdown_content['ja'] = markdownContent;

      this.form.title['ko'] = title;
      this.form.sub_title['ko'] = subTitle;
      this.form.markdown_content['ko'] = markdownContent;

      this.form.title['es'] = title;
      this.form.sub_title['es'] = subTitle;
      this.form.markdown_content['es'] = markdownContent;

      this.form.title['ru'] = title;
      this.form.sub_title['ru'] = subTitle;
      this.form.markdown_content['ru'] = markdownContent;

      await this.s2twp();

      this.$message({
        message: '覆盖成功，请手动保存',
        type: 'success',
        duration: 2000,
      });
    },
    async s2twp() {
      try {
        this.form.title['zh-Hant'] = this.opencc_twp(this.form.title['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_twp(this.form.sub_title['zh-Hans']);
        this.form.markdown_content['zh-Hant'] = this.opencc_twp(this.form.markdown_content['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
    fixed(content) {
      if (content[content.length - 1] === '\n') {
        return content + '\n';
      }
      return content;
    },
    full() {
      const ele = this.$refs.mdContainer;
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
      } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
      }
    },
    render() {
      const lang = this.activeLang;
      const { title, sub_title, markdown_content } = this.form;
      const context = md.render(markdown_content[lang]);
      this.$refs.result.contentWindow.postMessage(
        {
          title: title[lang],
          subtitle: sub_title[lang],
          content: context,
        },
        '*',
      );
    },
    setSelection() {
      const textarea = this.$refs.textarea;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      this.lastEditRange = {
        startPos,
        endPos,
      };
      this.scrollMap = null;
    },

    onTextareaScroll() {
      this.syncScroll();
    },

    syncScroll() {
      this.$refs.highlightjs.scrollTop = this.$refs.textarea.scrollTop;
      var textarea = $('#textarea');
      let lineHeight = parseFloat(textarea.css('line-height'));
      let lineNo;
      let posTo;

      lineNo = Math.floor(textarea.scrollTop() / lineHeight);
      if (!this.scrollMap) {
        this.scrollMap = this.buildScrollMap();
      }
      posTo = this.scrollMap[lineNo];
      $(this.idoc.scrollingElement)
        .stop(true)
        .animate({ scrollTop: posTo + 74 + 64 }, 100, 'linear');
    },

    // 构建滚动条映射表
    buildScrollMap() {
      let i;
      let offset;
      let nonEmptyList;
      let pos;
      let a;
      let b;
      let lineHeightMap;
      let linesCount;
      let acc;
      let sourceLikeDiv;
      let textarea = $('#textarea');
      let _scrollMap;

      sourceLikeDiv = $('<div />')
        .css({
          position: 'absolute',
          visibility: 'hidden',
          height: 'auto',
          width: textarea[0].clientWidth,
          'font-size': textarea.css('font-size'),
          'font-family': textarea.css('font-family'),
          'line-height': textarea.css('line-height'),
          'white-space': textarea.css('white-space'),
        })
        .appendTo('body');

      const $result = $(this.idoc).find('#draft');

      offset = $result.scrollTop() - $result.offset().top;
      _scrollMap = [];
      nonEmptyList = [];
      lineHeightMap = [];

      acc = 0;
      textarea
        .val()
        .split('\n')
        .forEach(function (str) {
          let h;
          let lh;

          lineHeightMap.push(acc);

          if (str.length === 0) {
            acc++;
            return;
          }

          sourceLikeDiv.text(str);
          h = parseFloat(sourceLikeDiv.css('height'));
          lh = parseFloat(sourceLikeDiv.css('line-height'));
          acc += Math.round(h / lh);
        });
      sourceLikeDiv.remove();
      lineHeightMap.push(acc);
      linesCount = acc;

      for (i = 0; i < linesCount; i++) {
        _scrollMap.push(-1);
      }

      nonEmptyList.push(0);
      _scrollMap[0] = 0;

      $(this.idoc)
        .find('.line')
        .each(function (n, el) {
          let $el = $(el);
          let t = $el.data('line');
          if (t === '') {
            return;
          }
          t = lineHeightMap[t];
          if (t !== 0) {
            nonEmptyList.push(t);
          }
          _scrollMap[t] = Math.round($el.offset().top + offset);
        });

      nonEmptyList.push(linesCount);
      _scrollMap[linesCount] = $(this.idoc).find('#draft')[0].scrollHeight;

      pos = 0;
      for (i = 1; i < linesCount; i++) {
        if (_scrollMap[i] !== -1) {
          pos++;
          continue;
        }

        a = nonEmptyList[pos];
        b = nonEmptyList[pos + 1];
        _scrollMap[i] = Math.round((_scrollMap[b] * (i - a) + _scrollMap[a] * (b - i)) / (b - a));
      }

      return _scrollMap;
    },

    showInsertLinkDialog() {
      const { startPos, endPos } = this.lastEditRange;
      let text = this.form.markdown_content[this.activeLang].slice(startPos, endPos);
      text = text.replace(/\n/g, '');

      this.dialog.insertLink.title = text;
      this.dialog.insertLink.link = '';
      this.dialog.insertLink.show = true;
    },

    showInsertImageDialog() {
      const { startPos, endPos } = this.lastEditRange;
      let text = this.form.markdown_content[this.activeLang].slice(startPos, endPos);
      text = text.replace(/\n/g, '');

      this.dialog.insertImage.desc = text;
      this.dialog.insertImage.url = '';
      this.dialog.insertImage.link = '';
      this.dialog.insertImage.show = true;
    },

    showInsertAudioDialog() {
      const { startPos, endPos } = this.lastEditRange;
      let text = this.form.markdown_content[this.activeLang].slice(startPos, endPos);
      text = text.replace(/\n/g, '');

      this.dialog.insertAudio.desc = text;
      this.dialog.insertAudio.url = '';
      this.dialog.insertAudio.show = true;
    },

    // -----------------------------------------------------------
    // MARK: - 上传
    // -----------------------------------------------------------

    beforeUpload: async function () {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },

    uploadImageSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.dialog.insertImage.url = picUrl;
      this.dialog.insertImage.uploading = false;
    },
    uploadImageProgress() {
      this.dialog.insertImage.uploading = true;
    },
    uploadImageError() {
      this.dialog.insertImage.uploading = false;
    },

    uploadAudioSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.dialog.insertAudio.url = picUrl;
      this.dialog.insertAudio.uploading = false;
    },
    uploadAudioProgress() {
      this.dialog.insertAudio.uploading = true;
    },
    uploadAudioError() {
      this.dialog.insertAudio.uploading = false;
    },

    // -----------------------------------------------------------
    // MARK: - 基础操作
    // -----------------------------------------------------------
    insertContent(content) {
      this.$refs.textarea.focus();
      document.execCommand('insertText', false, content);
    },
    prependRows(content) {
      const { startPos, endPos } = this.lastEditRange;
      const str = this.form.markdown_content[this.activeLang].slice(0, startPos);
      const idx = str.lastIndexOf('\n') + 1;
      let text = this.form.markdown_content[this.activeLang].slice(idx, endPos);
      text = text.replace(/\n/g, `\n${content}`);
      text = `${content}${text}`;
      this.$refs.textarea.focus();
      this.$refs.textarea.setSelectionRange(idx, endPos);
      document.execCommand('insertText', false, text);
      let cursorIdx = idx + text.length;
      this.$refs.textarea.setSelectionRange(cursorIdx, cursorIdx);
    },
    prependContents(content) {
      const { startPos, endPos } = this.lastEditRange;
      const str = this.form.markdown_content[this.activeLang].slice(0, startPos);
      const idx = str.lastIndexOf('\n') + 1;
      let text = this.form.markdown_content[this.activeLang].slice(idx, endPos);
      let rows = text.split('\n');
      rows = rows.filter((row) => row.length);
      rows = rows.map((row) => {
        return `${content}${row}`;
      });
      text = rows.join('\n');
      this.$refs.textarea.focus();
      this.$refs.textarea.setSelectionRange(idx, endPos);
      document.execCommand('insertText', false, text);
      let cursorIdx = idx + text.length;
      this.$refs.textarea.setSelectionRange(cursorIdx, cursorIdx);
    },
    wrapContent(prefix, suffix) {
      const { startPos, endPos } = this.lastEditRange;
      let cursorIdx = startPos;
      this.$refs.textarea.focus();
      if (startPos === endPos) {
        document.execCommand('insertText', false, `${prefix}${suffix}`);
        cursorIdx = startPos + prefix.length;
      } else {
        const text = this.form.markdown_content[this.activeLang].slice(startPos, endPos);
        const insertText = `${prefix}${text}${suffix}`;
        document.execCommand('insertText', false, `${prefix}${text}${suffix}`);
        cursorIdx = startPos + insertText.length;
      }
      this.$refs.textarea.setSelectionRange(cursorIdx, cursorIdx);
    },
    // -----------------------------------------------------------
    // MARK: - 业务
    // -----------------------------------------------------------
    boldContent() {
      this.wrapContent('**', '**');
    },
    delContent() {
      this.wrapContent('~~', '~~');
    },
    italicContent() {
      this.wrapContent('*', '*');
    },

    prependQuote() {
      this.prependRows('> ');
    },

    supportSelect() {
      this.wrapContent('', '{.support-select}');
    },

    prependH1() {
      const { startPos, endPos } = this.lastEditRange;
      if (startPos === endPos) {
        this.prependRows('# ');
      } else {
        this.prependContents('# ');
      }
    },

    prependH2() {
      const { startPos, endPos } = this.lastEditRange;
      if (startPos === endPos) {
        this.prependRows('## ');
      } else {
        this.prependContents('## ');
      }
    },

    prependH3() {
      const { startPos, endPos } = this.lastEditRange;
      if (startPos === endPos) {
        this.prependRows('### ');
      } else {
        this.prependContents('### ');
      }
    },

    makeBulletedList() {
      const { startPos, endPos } = this.lastEditRange;
      if (startPos === endPos) {
        this.prependRows('- ');
      } else {
        this.prependContents('- ');
      }
    },

    makeNumberedList() {
      const { startPos, endPos } = this.lastEditRange;
      if (startPos === endPos) {
        this.prependRows('1. ');
        return;
      }

      const str = this.form.markdown_content[this.activeLang].slice(0, startPos);
      const idx = str.lastIndexOf('\n') + 1;
      let text = this.form.markdown_content[this.activeLang].slice(idx, endPos);
      let rows = text.split('\n');
      let rowIdx = 0;
      rows = rows.filter((row) => row.length);
      rows = rows.map((row) => {
        return `${++rowIdx}. ${row}`;
      });
      text = rows.join('\n');
      this.$refs.textarea.focus();
      this.$refs.textarea.setSelectionRange(idx, endPos);
      document.execCommand('insertText', false, text);
      let cursorIdx = startPos + text.length;
      this.$refs.textarea.setSelectionRange(cursorIdx, cursorIdx);
    },

    insertDivider() {
      this.insertContent('\n------------\n\n');
    },

    insertLink() {
      const { title, link } = this.dialog.insertLink;
      this.insertContent(`[${title}](${link} "${title}")`);
      this.dialog.insertLink.show = false;
    },
    insertImage() {
      const { desc, url, link } = this.dialog.insertImage;
      let content = '';

      if (desc.length && link.length) {
        // 全都有
        content = `\n[![${desc}](${url} "${desc}")](${link} "${desc}")\n`;
      } else if (desc.length) {
        // 图片 + 描述
        content = `\n![${desc}](${url} "${desc}")\n`;
      } else if (link.length) {
        // 图片 + 链接
        content = `[![](${url})](${link})\n`;
      } else {
        // 只有图片
        content = `![](${url})\n`;
      }

      if (this.lastEditRange.startPos !== 0 && this.form.markdown_content[this.activeLang][this.lastEditRange.startPos - 1] !== '\n') {
        content = `\n${content}`;
      }

      this.insertContent(content);
      this.dialog.insertImage.show = false;
    },
    insertAudio() {
      const { title, url } = this.dialog.insertAudio;
      let content = `<audio title="${title}" src="${url}" preload="auto" ></audio>\n`;

      if (this.lastEditRange.startPos !== 0 && this.form.markdown_content[this.activeLang][this.lastEditRange.startPos - 1] !== '\n') {
        content = `\n${content}`;
      }

      this.insertContent(content);
      this.dialog.insertAudio.show = false;
    },
  },
  created: async function () {
    this.init();
    this.$nextTick(() => {
      this.idoc.write(draft);
    });
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<style scoped src="highlight.js/styles/monokai-sublime.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;

    iframe {
      width: 375px;
      flex-shrink: 0;
      margin-left: 16px;
      border: none;
    }
  }

  .md-container {
    display: flex;
  }

  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}

.album-table .el-form-item {
  width: 180px;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.uploadIcon {
  display: inline-block;
}

.editor-holder {
  flex-grow: 1;
  height: 100%;
  position: relative;
  background: #1f1f1f !important;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.4);

  .toolbar {
    z-index: 3;
    position: absolute;
    height: 48px;
    padding: 0 8px;
    width: 100%;
    background: #fafafa;
    font-size: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tool-group + .tool-group {
    &:before {
      display: inline-block;
      content: ' ';
      margin: 0 8px;
      height: 16px;
      width: 1px;
      background: #dcdfe6;
    }
  }

  .tool-group {
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .toolbar {
    i {
      width: 32px;
      height: 32px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-out;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      font-style: normal;
      &:hover {
        color: #1c71fe;
        border: 1px solid #ddd;
        background: #eee;
      }
    }
    i + i {
      margin-left: 8px;
    }
  }

  .textarea-container {
    position: relative;
    min-height: 500px;
    height: 100%;
    padding-top: 48px;
  }

  .highlightjs {
    margin: 0;
    font-size: 0;
  }

  .textarea,
  .highlightjs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .textarea,
  .highlightjs code {
    white-space: pre-wrap;
    border: 0;

    margin: 0;
    padding: 72px 24px 24px !important;

    font-size: 14px;
    font-family: Consolas, Liberation Mono, Courier, monospace;
    line-height: 21px;

    transition: all 0.5s ease-in-out;

    background: transparent;
  }

  .textarea {
    background: transparent !important;
    z-index: 2;
    resize: vertical;
    color: #fff;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;

    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 1);
    }

    &:focus {
      outline: 0;
      border: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .highlightjs code {
    z-index: 1;
  }
}
</style>

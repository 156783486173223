<template>
  <router-view v-if="$route.name !== 'TagsManage'"></router-view>
  <container v-else>
    <div slot="page-header-extra"></div>
    <div class="container" slot="container">
      <div class="container-body-header">
        <div class="container-body-header-title">标签管理</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">添加</el-button>
        </div>
      </div>

      <div class="container-body">
        <el-table :data="tags" stripe style="width: 100%">
          <el-table-column label="ID">
            <el-button slot-scope="scope" type="text" @click="copyToBoard(scope.row.id)">{{ scope.row.id }}<br /> </el-button>
          </el-table-column>
          <el-table-column label="分类">
            <template slot-scope="scope">{{ scope.row.type }}</template>
          </el-table-column>
          <el-table-column label="权重(0最小)">
            <template slot-scope="scope">{{ scope.row.weight }}</template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">{{ scope.row.remark }}</template>
          </el-table-column>
          <el-table-column label="简体中文">
            <template slot-scope="scope">{{ scope.row.name['zh-Hans'] }}</template>
          </el-table-column>
          <el-table-column label="英文">
            <template slot-scope="scope">{{ scope.row.name['en'] }}</template>
          </el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <router-link class="el-button el-button--text" :to="{ name: 'EditTag', params: { tag_id: scope.row.id } }">编辑</router-link>
              <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';

export default {
  name: 'InstituteManager',
  components: {
    Container,
  },
  data() {
    return {
      tags: [],
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'TagsManage') {
        this.init();
      }
    },
  },
  methods: {
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
    addItem() {
      this.$router.push({
        name: 'AddTag',
      });
    },
    async removeItem(item) {
      try {
        const { value } = await this.$prompt('此操作将永久删除该标签，请输入 “确定删除” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        if (value !== '确定删除') {
          this.$message.error('输入错误');
        } else {
          await this.$request({
            url: `/v2/admin/simple_tags/${item.id}`,
            method: 'DELETE',
          });
          this.tags = this.tags.filter((tag) => tag.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      this.fetchList();
    },
    fetchList: async function () {
      const tagsRes = await this.$request({
        url: '/v2/admin/simple_tags',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      this.tags = tagsRes.data;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  async created() {
    await this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}
</style>

<template>
  <router-view v-if="$route.name !== 'MedAlbumManager'"></router-view>
  <container v-else>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">spa</i>
        <h3 slot="title">隐市小居</h3>
        <p slot="desc">结庐在人境，而无车马喧，问君何能尔？心远地自偏</p>
      </page-header>
    </div>
    <div class="container" slot="container">
      <div class="container-head">
        <div class="container-head-title">专辑管理</div>
        <div class="container-head-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="showListSortPanel" type="text">重新排序 </el-button>
          <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">新建专辑</el-button>
        </div>
      </div>
      <div class="container-body">
        <div class="container-flex">
          <div class="container-body-left">
            <el-form :inline="true">
              <el-form-item>
                <el-input v-model="keyword" suffix-icon="el-icon-search" placeholder="搜索专辑名称" @keyup.enter.native="refetch"></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="filterRegions" multiple filterable default-first-option placeholder="上架地区">
                  <el-option v-for="item in regionOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="container-body-right"></div>
        </div>
        <el-table :data="list" v-infinite-scroll="fetchList" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50" stripe style="width: 100%" @row-dblclick="rowDblClick">
          <el-table-column label="专辑图片">
            <template slot-scope="scope">
              <cover :width="48" :height="48" :src="scope.row.image" :mask-colors="scope.row.mask_colors"></cover>
            </template>
          </el-table-column>
          <el-table-column label="专辑名称" min-width="120px">
            <template slot-scope="scope"
              ><p>{{ scope.row.name['zh-Hans'] || scope.row.name.en }}</p>
              <el-button type="text" @click="copyToBoard(scope.row.id)">{{ scope.row.id }}</el-button>
            </template>
          </el-table-column>
          <el-table-column label="上架地区">
            <template slot-scope="scope">
              <span class="region" v-for="region in scope.row.regions" v-bind:key="region">{{ allRegions[region] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="章节数">
            <template slot-scope="scope">
              <span v-if="scope.row.sections.length < 1">无</span>
              <span v-else-if="scope.row.sections.length == 1">时刻</span>
              <span v-else>系列 [ {{ scope.row.sections.length }} ]</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">{{ getStatus(scope.row.status) }} </template>
          </el-table-column>

          <!--
          <el-table-column label="所在分类">
            <template slot-scope="scope">
              <span class="group" v-for="tag in pertainTags[scope.row.id]" :key="`${scope.row.id}-${tag.tag_id}`">
                <router-link :to="{ name: 'MedTagsEditor', params: { tag_id: tag.tag_id } }">{{ tag.key }}</router-link>
              </span>
            </template>
          </el-table-column>
          -->

          <el-table-column label="限时体验">
            <template slot-scope="scope">
              <div v-if="scope.row.trial_ended_local_time && scope.row.trial_started_local_time" style="font-size: 8px">
                <div>开始：{{ scope.row.trial_started_local_time }}</div>
                <div>结束：{{ scope.row.trial_ended_local_time }}</div>
              </div>
              <span v-else>无</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="editItem(scope.row)">编辑 </el-button>
              <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除 </el-button>
              <el-button type="text" @click="copyToBoard(JSON.stringify(scope.row))">复制JSON</el-button>
              <el-button type="text" v-if="$env === 'dev'" @click="copyToProduction(scope.row)">复制到正式环境</el-button>
              <el-button type="text" v-else @click="copyToDevelopment(scope.row)">复制到测试环境</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="重新排序" width="40%" :visible.sync="dialogDisplay.listSortPanel">
        <draggable class="sort-list-container" v-model="sortList">
          <div class="sort-item" v-for="(item, index) in sortList" :key="item.id">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <cover :width="48" :height="48" :src="item.image"></cover>
            <div class="sort-item-name">{{ getTR(item.name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveSort">保 存</el-button>
          <el-button @click="dialogDisplay.listSortPanel = false">返 回</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
// import _ from 'lodash'
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
import Cover from '@/components/cover';
import Draggable from 'vuedraggable';
import allRegions from '@/regions.json';
import allStatus from '@/status.json';

const regionOptions = Object.keys(allRegions).map((value) => {
  const label = allRegions[value];
  return { value, label };
});

export default {
  name: 'MedAlbumManager',
  components: {
    Container,
    PageHeader,
    Draggable,
    Cover,
  },
  data() {
    return {
      lang: 'zh-Hans',
      allRegions,
      regionOptions: regionOptions,
      langs: [
        {
          value: 'en',
          label: 'en',
        },
        {
          value: 'zh-Hans',
          label: 'zh-Hans',
        },
        {
          value: 'zh-Hant',
          label: 'zh-Hant',
        },
      ],
      keyword: '',
      filterRegions: [],
      filterStatus: '',
      sortList: [],
      list: [],
      pertain: {},
      pertainTags: {},
      ready: false,
      busy: false,
      finish: false,

      dialogDisplay: {
        listSortPanel: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'MedAlbumManager') {
        this.init();
      }
    },
    filterRegions() {
      this.refetch();
    },
    filterStatus() {
      this.refetch();
    },
  },
  methods: {
    async copyToProduction(album) {
      const data = { ...{}, ...album };
      delete data.id;
      delete data.created_at;
      delete data.updated_at;
      data.status = 'draft';

      this.$prompt('此操作将使测试环境的数据复制到正式环境，请输入 “没有问题” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        if (value === '没有问题') {
          try {
            await this.$request({
              url: 'https://davinci.moreless.space/api/v1/admin/meditation/albums',
              method: 'POST',
              data,
            });
            this.$message({
              message: '复制成功',
              type: 'success',
            });
          } catch (e) {
            this.$message.error('复制失败');
          }
        } else {
          this.$message.error('输入错误');
        }
      });
    },

    async copyToDevelopment(album) {
      const data = { ...{}, ...album };
      delete data.id;
      delete data.created_at;
      delete data.updated_at;
      data.status = 'draft';
      /* const albumId = data.id; */

      this.$prompt('此操作将使正式环境的数据复制到测试环境，请输入 “没有问题” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        if (value === '没有问题') {
          try {
            await this.$request({
              url: 'https://leonardo.moreless.space/api/v1/admin/meditation/albums',
              method: 'POST',
              data,
            });
            this.$message({
              message: '复制成功',
              type: 'success',
            });
          } catch (e) {
            this.$message.error('复制失败');
          }
        } else {
          this.$message.error('输入错误');
        }
      });
    },

    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
    addItem() {
      this.$router.push({
        name: 'AddMedAlbum',
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'EditMedAlbum',
        params: {
          album_id: item.id,
        },
      });
    },
    async removeItem(item) {
      const { value } = await this.$prompt('此操作将永久删除该专辑，请输入 “确定删除” 来确认该操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if (value !== '确定删除') {
        this.$message.error('输入错误');
      } else {
        try {
          await this.$request({
            url: `/v1/admin/meditation/albums/${item.id}`,
            method: 'DELETE',
          });
          this.list = this.list.filter((album) => album.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        } catch (e) {
          this.$message.error('操作失败');
        }
      }
    },
    async init() {
      this.refetch();
      this.fetchObjectTags();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetchList();
    },
    async fetchList() {
      this.busy = true;
      const limit = 1000;
      const params = { offset: this.list.length, limit };
      if (this.keyword) {
        params.name = this.keyword;
      }
      if (this.filterStatus) {
        params.status = this.filterStatus;
      }
      if (this.filterRegions.length) {
        params.regions = this.filterRegions;
      }
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params,
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    async fetchObjectTags() {
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: {
          type: 'meditation',
        },
      });
      const pertain = {};
      for (let tag of res.data) {
        if (tag.meditation_ids) {
          for (let albumId of tag.meditation_ids) {
            let album = pertain[albumId];
            if (!album) {
              pertain[albumId] = [{ tag_id: tag.id, key: tag.key }];
            } else {
              album.push({ tag_id: tag.id, key: tag.key });
            }
          }
        }
      }
      console.log(pertain);
      this.pertainTags = pertain;
    },
    showListSortPanel() {
      this.sortList = this.list;
      this.dialogDisplay.listSortPanel = true;
    },
    async saveSort() {
      const data = this.sortList.map((item, idx) => {
        return { id: item.id, sort_key: idx };
      });

      try {
        await this.$request({
          url: '/v1/admin/meditation/albums/sort_keys',
          method: 'PUT',
          data,
        });

        this.list = this.sortList;
        this.dialogDisplay.listSortPanel = false;

        this.$message({
          message: '操作成功',
          type: 'success',
        });
      } catch (e) {
        if (e !== 'cancel') {
          this.$message.error(e.message);
        }
      }
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },

    getStatus(status) {
      return allStatus[status];
    },

    rowDblClick(row) {
      this.editItem(row);
    },
  },
  async created() {
    await this.init();
    this.ready = true;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.list-item {
  padding: 16px 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}

.list-main-item {
  flex-grow: 1;
  margin-left: 16px;
  width: 320px;
}

.el-form-item {
  margin-bottom: 0;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 24px;

  a {
    color: #1890ff;
    cursor: pointer;

    &:hover {
      color: #40a9ff;
    }
  }

  a + a {
    margin-left: 8px;
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: #40a9ff;
  }
}

.list-item-name {
  font-size: 15px;
  font-weight: 500;
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}

.comment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;

  .select-btn {
    display: inline-block;
  }

  .remove-btn {
    display: none;
  }
}

.comment-container:hover {
  background-color: #ecf5ff;
}

.comment-container.selected {
  background-color: #f0f9eb;

  .select-btn {
    display: none;
  }

  .remove-btn {
    display: inline-block;
  }
}

.comment + .comment {
  margin-top: 16px;
}

.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nickname {
  font-size: 13px;
  font-weight: bold;
  margin-left: 8px;
}

.content {
  font-size: 15px;
  margin-top: 8px;
}

.reviewing {
  background-color: rgba(255, 126, 126, 0.12);
}

.region + .region::before {
  content: ', ';
}

.group + .group::before {
  content: ', ';
}

.sort-list-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}

.sort-item:hover {
  background-color: #f5f7fa;
}

.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 55px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.sort-item-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-wrapper",class:_vm.device,style:(`zoom:${_vm.scale}`),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"preview",style:({
      zoom: `${1 / _vm.scale}`,
      transform: `scale(${_vm.scale})`,
      fontFamily: `${_vm.quote.fontName}`,
    })},[_c('div',{staticClass:"device"}),_c('div',{staticClass:"canva"},[_c('div',{ref:"previewImage",staticClass:"dailypic preview-image",style:({
          backgroundImage: `url(${_vm.previewUrl})`,
          backgroundSize: `${_vm.cropXScale} ${_vm.cropYScale}`,
          backgroundPositionX: `${_vm.cropdx}`,
          backgroundPositionY: `${_vm.cropdy}`,
        })}),_c('div',{staticClass:"text-board"},[_c('p',{staticClass:"date",style:(`color: rgba(${_vm.quote.color}); border-color: rgba(${_vm.quote.color})`)},[_vm._v(_vm._s(_vm.month)+" "+_vm._s(_vm.date))]),_c('p',{staticClass:"quote",style:({
            color: `rgba(${_vm.quote.color})`,
            fontFamily: `'${_vm.quote['font_name']}'`,
            fontSize: `${_vm.quote['font_size']}px`,
            lineHeight: `${_vm.quote['font_size'] + _vm.quote['line_spacing']}px`,
          })},[_vm._v(" "+_vm._s(_vm.quote.text)+" ")]),_c('span',{staticClass:"dash",style:(`background-color: rgba(${_vm.quote.color})`)}),_c('p',{staticClass:"author",style:({
            color: `rgba(${_vm.author.color})`,
            fontFamily: `'${_vm.author['font_name']}'`,
            fontSize: `${_vm.author['font_size']}px`,
            lineHeight: `${_vm.author['font_size'] + _vm.author['line_spacing']}px`,
          })},[_vm._v(" "+_vm._s(_vm.author.text)+" ")]),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"brand"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.png")}}),_c('div',{staticClass:"brand-info"},[_c('p',[_vm._v("潮汐")]),_c('p',[_vm._v("@阿喵")])])]),_c('div',{staticClass:"download"},[_c('span',{staticClass:"bootup-text"},[_vm._v("扫码下载")]),_c('img',{staticClass:"qrcode",attrs:{"src":require("@/assets/qrcode.jpeg")}})])])
}]

export { render, staticRenderFns }
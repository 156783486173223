import Vue from 'vue';
import Vuex from 'vuex';
import Canva from './canva';
import Dailypics from './dailypics';
import User from './user';
import Role from './role';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    canva: Canva,
    dailypics: Dailypics,
    user: User,
    role: Role,
  },
});
export default store;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"previews"},[_c('div',{staticClass:"table"},[(_vm.imageInfo)?_c('div',{staticClass:"devices"},[_vm._l((_vm.devices),function(device){return _c('div',{key:device.name,staticClass:"device"},[_c('div',[_c('device-preview',{key:device.name,attrs:{"origin":"","device":device.name,"image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":device.scale,"data":{
              date: _vm.form.date,
              content: _vm.form.content,
              pic_url: _vm.oUrl,
            }},on:{"click":function($event){device.showDetail = true}}})],1)])}),_c('div',{staticClass:"device"},[_c('div',[_c('tide-v2-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.15,"data":{
              date: _vm.form.date,
              content: _vm.form.content,
              pic_url: _vm.oUrl,
            }},on:{"click":function($event){_vm.dialogShow.tidev2 = true}}})],1)]),_c('div',{staticClass:"device"},[_c('div',[_c('dailypic-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.1,"data":{
              date: _vm.form.date,
              content: _vm.form.content,
              pic_url: _vm.oUrl,
            }},on:{"click":function($event){_vm.dialogShow.dailypic = true}}})],1)]),_c('div',{staticClass:"device"},[_c('div',[_c('focus-share-pic-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.1,"data":{
              date: _vm.form.date,
              content: _vm.form.content,
              pic_url: _vm.oUrl,
            }},on:{"click":function($event){_vm.dialogShow.focusSharePic = true}}})],1)]),_c('div',{staticClass:"device"},[_c('div',[_c('sleep-share-pic-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.1,"data":{
              date: _vm.form.date,
              content: _vm.form.content,
              pic_url: _vm.oUrl,
            }},on:{"click":function($event){_vm.dialogShow.sleepSharePic = true}}})],1)]),_c('div',{staticClass:"device"},[_c('div',[_c('wx-calendar-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.1,"data":{
              date: _vm.form.date,
              content: _vm.form.content,
              pic_url: _vm.oUrl,
            }},on:{"click":function($event){_vm.dialogShow.wxCalendar = true}}})],1)])],2):_vm._e()]),_vm._l((_vm.devices),function(device){return _c('el-dialog',{key:device.name,attrs:{"title":device.name,"visible":device.showDetail},on:{"update:visible":function($event){return _vm.$set(device, "showDetail", $event)}}},[(_vm.imageInfo)?_c('device-preview',{key:device.name,attrs:{"origin":"","device":device.name,"image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":device.scale * 2.5,"data":{
        date: _vm.form.date,
        content: _vm.form.content,
        pic_url: _vm.oUrl,
      }}}):_vm._e()],1)}),_c('el-dialog',{attrs:{"title":"潮汐2.0首页","visible":_vm.dialogShow.tidev2},on:{"update:visible":function($event){return _vm.$set(_vm.dialogShow, "tidev2", $event)}}},[_c('tide-v2-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.5,"data":{
        date: _vm.form.date,
        content: _vm.form.content,
        pic_url: _vm.oUrl,
      }}})],1),_c('el-dialog',{attrs:{"title":"日帖分享卡片","visible":_vm.dialogShow.dailypic},on:{"update:visible":function($event){return _vm.$set(_vm.dialogShow, "dailypic", $event)}}},[_c('dailypic-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.5,"data":{
        date: _vm.form.date,
        content: _vm.form.content,
        pic_url: _vm.oUrl,
      }}})],1),_c('el-dialog',{attrs:{"title":"专注分享卡片","visible":_vm.dialogShow.focusSharePic},on:{"update:visible":function($event){return _vm.$set(_vm.dialogShow, "focusSharePic", $event)}}},[_c('focus-share-pic-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.5,"data":{
        date: _vm.form.date,
        content: _vm.form.content,
        pic_url: _vm.oUrl,
      }}})],1),_c('el-dialog',{attrs:{"title":"睡眠分享卡片","visible":_vm.dialogShow.sleepSharePic},on:{"update:visible":function($event){return _vm.$set(_vm.dialogShow, "sleepSharePic", $event)}}},[_c('sleep-share-pic-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.5,"data":{
        date: _vm.form.date,
        content: _vm.form.content,
        pic_url: _vm.oUrl,
      }}})],1),_c('el-dialog',{attrs:{"title":"小程序分享卡片","visible":_vm.dialogShow.wxCalendar},on:{"update:visible":function($event){return _vm.$set(_vm.dialogShow, "wxCalendar", $event)}}},[_c('wx-calendar-preview',{attrs:{"origin":"","image-info":_vm.imageInfo,"crop":_vm.crop,"lang":_vm.activeLang,"scale":0.5,"data":{
        date: _vm.form.date,
        content: _vm.form.content,
        pic_url: _vm.oUrl,
      }}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
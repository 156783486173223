<!--
 * @Author: Ruilong Li
 * @Date: 2019-07-13 11:56:01
 * @LastEditors: Ruilong Li
 * @LastEditTime: 2019-12-13 21:02:41
 * @Description:  冥想分类管理新增页面
 * @FilePath: /davinci/src/pages/meditation/group/add.vue
 -->
<template>
  <ad-banner-form :form="form" @change="change" @save="save"></ad-banner-form>
</template>

<script>
import AdBannerForm from '@/components/ad-banners/form';
export default {
  name: 'ExploreAdBannersEditor',
  components: {
    AdBannerForm,
  },
  props: {
    type: String,
  },
  data() {
    return {
      form: {
        id: '',
        content: {
          en: { layers: [], scheme: '' },
          es: { layers: [], scheme: '' },
          ja: { layers: [], scheme: '' },
          ko: { layers: [], scheme: '' },
          ru: { layers: [], scheme: '' },
          'zh-Hans': { layers: [], scheme: '' },
          'zh-Hant': { layers: [], scheme: '' },
        },
        activity_name: '',
        regions: ['ALL'],
        row_position: 0,
        status: 'draft',
        style: 'full',
        type: 'sleep_topic',
      },
    };
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      try {
        await this.$request({
          url: `/v2/admin/explorer/embedded_banners/${this.$route.params.ad_banner_id}`,
          method: 'PUT',
          data,
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    async init() {
      const res = await this.$request({
        url: `/v2/admin/explorer/embedded_banners/${this.$route.params.ad_banner_id}`,
      });
      this.form = res.data;
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

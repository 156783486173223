<template>
  <equip-form :form="form" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddOAuth2Scope',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: Object,
    };
  },
  methods: {
    async save(data) {
      const scopeRes = await this.$request({
        url: `/v1/admin/oauth2/scopes`,
        method: 'POST',
        data,
      });
      const scope = scopeRes.data;
      this.$router.replace({
        name: 'EditOAuth2Scope',
        params: {
          scope_id: scope.id,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
  },
};
</script>

<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'EditMedReportPageRecommend',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        type: 'meditation',
        location: '',
        condition: {},
        description: {
          en: '',
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        meditation_album_id: '',
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      const { recommend_id: recommendId } = this.$route.params;
      console.log(data);
      await this.$request({
        url: `/v1/admin/report_page_recommendations/${recommendId}`,
        method: 'PUT',
        data,
      });
      await this.init();
      this.$message({
        message: '操作成功',
        type: 'success',
        duration: 2000,
      });
    },
    init: async function () {
      const { recommend_id: recommendId } = this.$route.params;
      try {
        const res = await this.$request({
          url: `/v1/admin/report_page_recommendations/${recommendId}`,
        });
        this.form = Object.assign({}, this.form, res.data);
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

<template>
  <div class="preview-wrapper" :style="`zoom:${scale}`" @click="$emit('click')">
    <div
      class="preview"
      :style="{
        zoom: `${1 / scale}`,
        transform: `scale(${scale})`,
        fontFamily: `${quote.fontName}`,
      }"
    >
      <div class="canva">
        <div
          class="dailypic-preview preview-image"
          ref="previewImage"
          :style="{
            backgroundImage: `url(${previewUrl})`,
            backgroundSize: `${cropXScale} ${cropYScale}`,
            backgroundPositionX: `${cropdx}`,
            backgroundPositionY: `${cropdy}`,
          }"
        ></div>
        <div class="brand">
          <img class="logo" src="~@/assets/logo.png" />
          <div class="brand-info">
            <p>潮汐</p>
            <p>@阿喵</p>
          </div>
        </div>
        <div class="text-board">
          <span
            class="festival"
            v-if="festival.text"
            :style="{
              backgroundColor: `rgba(${festival.background_color})`,
              color: `rgba(${festival.color})`,
            }"
          >
            {{ festival.text }}
          </span>
          <p class="date">{{ date }}</p>
          <p class="month">{{ month }} {{ year }}</p>
          <p class="quote">{{ quote.text }}</p>
          <span class="dash"></span>
          <p class="author">{{ author.text }}</p>
          <div class="download">
            <span class="bootup-text">扫码下载</span>
            <img class="qrcode" src="~@/assets/qrcode.jpeg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DailypicPreview',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    data: {
      type: Object,
    },
    crop: {
      type: Object,
    },
    origin: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: 'zh-Hans',
    },
    imageInfo: {
      type: Object,
    },
  },
  data() {
    return {
      year: '',
      month: '',
      day: '',
      cropScale: {
        x: 'atuo',
        y: 'atuo',
      },
      imgScale: 1,
      screenWidth: 0,
      screenHeight: 0,
    };
  },
  methods: {
    // 正方图
    squareDx() {
      let offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    squareDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    squareXScale() {
      let scale = 'auto';
      let diff = 0.96;
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width;
        width = width * diff;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    squareYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        height = height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },

    // 竖图
    verticalDx() {
      let offset = 0.02;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    verticalDy() {
      let offset = 0.04;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      // const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale
      dy = dy / (this.crop.height / this.screenHeight);
      return `${-dy}px`;
    },
    verticalXScale() {
      let scale = 'auto';
      if (this.screenWidth > this.screenHeight) {
        let width = this.crop.width * 0.96;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    verticalYScale() {
      let scale = 'auto';
      if (this.screenWidth < this.screenHeight) {
        let height = this.crop.height;
        const imgHeight = this.crop.imgHeight;
        height = height * 0.96;
        scale = `${100 / (height / imgHeight)}%`;
      }
      return scale;
    },

    // 横图
    landscapeDx() {
      const offset = 0.03;
      let dx = this.crop.dx;
      dx = dx + this.crop.width * offset;
      dx = dx / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dx = (dx * parseFloat(cropScale)) / 100;
      return `${-dx}px`;
    },
    landscapeDy() {
      let offset = 0.02;
      let dy = this.crop.dy;
      dy = dy + this.crop.height * offset;
      dy = dy / this.imgScale;
      const cropScale = this.cropXScale === 'auto' ? this.cropYScale : this.cropXScale;
      dy = (dy * parseFloat(cropScale)) / 100;
      return `${-dy}px`;
    },
    landscapeXScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (imageInfo.width <= imageInfo.height) {
        let width = this.crop.width;
        scale = `${100 / (width / 210)}%`;
      }
      return scale;
    },
    landscapeYScale() {
      let scale = 'auto';
      const imageInfo = this.imageInfo;
      if (!imageInfo.width < imageInfo.height) {
        let height = this.crop.height * 0.96;
        scale = `${100 / (height / 210)}%`;
      }
      return scale;
    },
  },
  computed: {
    cropdx() {
      let dx;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dx = this.verticalDx();
      } else if (width > height) {
        dx = this.landscapeDx();
      } else {
        dx = this.squareDx();
      }
      return dx;
    },
    cropdy() {
      let dy;
      const { width, height } = this.imageInfo;
      if (width < height) {
        dy = this.verticalDy();
      } else if (width > height) {
        dy = this.landscapeDy();
      } else {
        dy = this.squareDy();
      }
      return dy;
    },
    cropXScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalXScale();
      } else if (width > height) {
        scale = this.landscapeXScale();
      } else {
        scale = this.squareXScale();
      }
      return scale;
    },
    cropYScale() {
      let scale;
      const { width, height } = this.imageInfo;
      if (width < height) {
        scale = this.verticalYScale();
      } else if (width > height) {
        scale = this.landscapeYScale();
      } else {
        scale = this.squareYScale();
      }
      return scale;
    },
    previewUrl() {
      let picUrl = this.data.pic_url;
      if (!this.origin) {
        picUrl = `${picUrl}?imageMogr2/thumbnail/!1024x1024r`;
        // picUrl = `${picUrl}/crop/${this.cropPath}`
      }
      return picUrl;
    },
    cropPath() {
      const scale = this.imgScale;
      const cropsize = `${this.crop.width / scale}x${this.crop.height / scale}`;
      const dx = this.crop.dx / scale;
      const dy = this.crop.dy / scale;
      return `!${cropsize}a${dx}a${dy}`;
    },
    quote() {
      return this.data.content[this.lang].quote;
    },
    author() {
      return this.data.content[this.lang].author;
    },
    festival() {
      return this.data.content[this.lang].festival;
    },
  },
  created() {
    console.log(new Date(this.data.date).toDateString());
    const dateString = new Date(this.data.date).toDateString();
    [, this.month, this.date, this.year] = dateString.split(' ');
    this.$nextTick(() => {
      const imageInfo = this.imageInfo;
      const $previewImage = this.$refs.previewImage;
      if (!$previewImage) {
        return 1;
      }
      const width = this.$refs.previewImage.clientWidth;
      const height = this.$refs.previewImage.clientHeight;
      // const crop = this.crop
      let scale;
      if (imageInfo.width < imageInfo.height) {
        scale = 210 / width;
      } else {
        scale = 210 / height;
      }
      this.imgScale = scale;
      this.screenWidth = width;
      this.screenHeight = height;
    });
  },
};
</script>
<style lang="less">
.cropper-face {
  background-size: cover !important;
  background: url('~@/assets/mask.png') !important;
}
</style>
<style lang="less" scoped>
.preview-wrapper {
  margin: 0 auto;
  width: 1080px;
  height: 1920px;
}
.preview {
  transform-origin: 0 0;
  width: 1080px;
  height: 1920px;
}
.preview-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.dailypic-preview {
  width: 1080px;
  height: 1920px;
}
.canva {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.brand {
  position: absolute;
  left: 72px;
  top: 80px;
  display: flex;
}
.text-board {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 72px 120px;
}
.logo {
  width: 88px;
  height: 88px;
  border-radius: 19.2px;
}
.brand-info {
  margin-left: 41px;
  font-size: 32px;
  font-weight: 300;
  line-height: 45px;
  color: rgba(255, 255, 255, 0.5);
  p {
    margin: 0;
  }
}
.festival {
  position: absolute;
  right: 72px;
  top: 171px;
  font-size: 33px;
  padding: 0 24px;
  height: 72px;
  line-height: 72px;
  border-radius: 12px;
}
.date {
  margin: 0;
  font-size: 192px;
  line-height: 192px;
  // letter-spacing: 12px;
  color: #fff;
  font-weight: 100;
  font-family: SFCompactDisplay;
}
.month {
  margin: 6px 8px 0;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 5.5px;
  color: #fff;
  font-family: SFCompactText;
  font-weight: 500;
}
.quote {
  margin: 72px 0 0;
  font-size: 64px;
  font-weight: 400;
  line-height: 96px;
  letter-spacing: 8px;
  color: #fff;
}
.dash {
  margin: 72px 0 0;
  display: block;
  width: 64px;
  height: 6px;
  background-color: #fff;
}
.author {
  margin: 72px 0 0;
  font-size: 40px;
  line-height: 56px;
  color: rgba(255, 255, 255, 0.7);
}
.download {
  position: absolute;
  right: 72px;
  bottom: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.bootup-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 22px;
  writing-mode: vertical-rl;
  vertical-align: middle;
  margin-right: 24px;
}
.qrcode {
  width: 96px;
  height: 96px;
  vertical-align: middle;
}
</style>

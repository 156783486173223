<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">功能记录</div>
        <div class="container-body-header-extra"></div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.started_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column label="结束时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.ended_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column label="类型">
            <template slot-scope="scope">
              <span>{{ getTypeTr(scope.row.type) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.status }}</span>
            </template>
          </el-table-column>

          <el-table-column label="详情">
            <template slot-scope="scope">
              <div class="setting-blocks">
                <div v-if="scope.row.type === 'sleep_nap'">
                  <div>
                    <label>类型:</label>
                    <span>{{ scope.row.metadata.sleep_mode }}</span>
                  </div>
                  <div>
                    <label>睡眠时长:</label>
                    <span>{{ scope.row.metadata.sleep_duration }}</span>
                  </div>
                  <div>
                    <label>起床时间:</label>
                    <span>{{ $moment(scope.row.metadata.getup_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
                  </div>
                </div>
                <div v-else-if="scope.row.type === 'focus'">
                  <div>
                    <label>模式（旧/新）:</label>
                    <span>{{ `${scope.row.metadata.focus_mode} / ${scope.row.metadata.mode}` }}</span>
                  </div>
                  <div>
                    <label>时长:</label>
                    <span>{{ scope.row.metadata.duration }}</span>
                  </div>
                  <div>
                    <label>进度:</label>
                    <span>{{ scope.row.metadata.progress }}</span>
                  </div>
                  <div>
                    <label>完成状态:</label>
                    <span>{{ scope.row.metadata.finished_type }}</span>
                  </div>
                </div>
                <div v-else-if="scope.row.type === 'meditation'">
                  <div>
                    <label>专辑 - 章节:</label>
                    <span>{{ getAlbumName(scope.row.metadata.album_id) }} - {{ getChapterName(scope.row.metadata.album_id, scope.row.metadata.section_id) }}</span>
                  </div>
                </div>
                <div v-else-if="scope.row.type === 'sleep_story'">
                  <div>
                    <label>故事名:</label>
                    <span>{{ getSleepStoryName(scope.row.metadata.sleep_story_id) }}</span>
                    <!-- <span>{{ JSON.stringify(scope.row.metadata) }}</span> -->
                  </div>
                </div>
                <div v-else-if="scope.row.type === 'breath'">
                  <div>
                    <label>时长:</label>
                    <span>{{ scope.row.metadata.breath_duration }}</span>
                  </div>
                  <div>
                    <label>次数:</label>
                    <span>{{ scope.row.metadata.breath_loop }}</span>
                  </div>
                  <div>
                    <label>模式:</label>
                    <span>{{ scope.row.metadata.mode }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-dialog title="JSON" :visible.sync="dialogVisible.JSON">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';

const defaultLogData = {
  id: '',
  device_id: 'davinci_device',
  version: 1,
  type: 'sleep',
  record_at: 0,
  started_at: 0,
  lie_down_at: 0,
  getup_at: 0,
  sleep_at: 0,
  wakeup_at: 0,
  alarm_at: 0,
  ended_at: 0,
  bed_rest_duration: 0,
  sleep_duration: 0,
  awake_duration: 0,
  sleep_sound_duration: 0,
  fall_asleep_duration: 0,
  getup_duration: 0,
  rem_duration: 0,
  shallow_sleep_duration: 0,
  deep_sleep_duration: 0,
  base_score: 80,
  sleep_duration_score: 0,
  sleep_time_score: 0,
  snooze_count_score: 0,
  motion_count_score: 0,
  total_score: 80,
  time_logs: [],
  client_platform: 'davinci',
  client_version: '1.0.0',
  status: 'finished',
  analysis: {
    algorithm_version: '',
    stages: null,
  },
};

export default {
  name: 'Records',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      busy: false,
      finish: false,
      dialogVisible: {
        JSON: false,
      },
      logData: defaultLogData,
      activeTab: 'logs',
      detailTitle: '',
      detail: '',
      listDetail: '',
      list: [],
      sleep_stories_map: {},
      albumDict: {},

      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    getTypeTr(type) {
      let str = '';
      switch (type) {
        case 'sleep_nap':
          str = '睡眠/小憩';
          break;
        case 'focus':
          str = '专注';
          break;
        case 'breath':
          str = '呼吸';
          break;
        case 'meditation':
          str = '冥想';
          break;
        case 'sleep_story':
          str = '睡眠故事';
          break;
        default:
          str = '未知';
          break;
      }
      return str;
    },
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.dialogVisible.JSON = true;
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/records`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data.map((v) => Object.assign(v, { metadata: this.getMetadata(v) }));
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    getMetadata: function (record) {
      let metadata = null;
      switch (record.type) {
        case 'sleep_nap':
          metadata = record.rec_metadata.sleep_nap_metadata;
          break;
        case 'focus':
          metadata = record.rec_metadata.focus_metadata;
          break;
        case 'breath':
          metadata = record.rec_metadata.breath_metadata;
          break;
        case 'meditation':
          metadata = record.rec_metadata.meditation_metadata;
          break;
        case 'sleep_story':
          metadata = record.rec_metadata.sleep_story_metadata;
          break;
        default:
          metadata = {};
          break;
      }
      return metadata;
    },
    init: async function () {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
      await this.loadSleepStories();
      await this.fetchAlbums();
    },
    async loadSleepStories() {
      const res = await this.$request({
        url: '/v1/admin/sleep_stories',
        params: {
          offset: 0,
          limit: 999,
        },
      });
      this.sleep_stories_map = {};
      for (let v of res.data) {
        this.sleep_stories_map[v.id] = v.name;
      }
    },
    getSleepStoryName(sleepStoryID) {
      return this.getTR(this.sleep_stories_map[sleepStoryID]);
    },
    async fetchAlbums() {
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      const dict = {};
      res.data.forEach((album) => {
        dict[album.id] = album;
      });
      this.albumDict = dict;
    },

    getAlbumName(albumId) {
      const album = this.albumDict[albumId];
      if (!album) {
        return '专辑已失效';
      } else {
        return this.getTR(album.name);
      }
    },
    getChapterName(albumId, sectionId) {
      const album = this.albumDict[albumId];
      if (!album) {
        return '无效专辑';
      }
      const section = album.sections.find((section) => section.id === sectionId);
      if (!section) {
        return '无效章节';
      }
      return this.getTR(section.name);
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.status-container {
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.status-container + .status-container {
  padding-left: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.04);
}

.status-infos-item {
  font-size: 13px;
  line-height: 16px;
}

.status-infos-item + .status-infos-item {
  margin-left: 16px;
}

.status-infos-item span {
  color: rgba(0, 0, 0, 0.48);
}

.sleep-setting {
  display: flex;
  flex-direction: row;
}

.system-state {
  display: flex;
  flex-direction: row;
}
.sleep-setting + .system-state {
  margin-top: 16px;
}
.status-infos-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.status-infos-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.status-view {
  width: 400px;
  height: 200px;
}

.chart-container {
  height: 240px;
  margin-top: 64px;
}

.setting-blocks {
  display: flex;
  flex-direction: row;
}

.setting-blocks {
  label {
    min-width: 64px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>

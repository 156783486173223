<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">标签配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label">{{ lang }}</span>
              <el-col :span="23">
                <el-form-item label="标题">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="副标题">
                  <el-input v-model="xForm.sub_title[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp()">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item label="类型">
          <el-select v-model="xForm.type" placeholder="请选择">
            <el-option label="内容展示标签" value="content_display"></el-option>
            <el-option label="内容搜索标签" value="content_search"></el-option>
            <el-option label="冥想指南" value="meditation_guide"></el-option>
            <el-option label="首页快捷入口" value="home_quick_entry"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="权重(0最小)(默认就2)：">
          <el-select v-model="xForm.weight" placeholder="请选择">
            <el-option :label="0" :value="0"> </el-option>
            <el-option :label="1" :value="1"> </el-option>
            <el-option :label="2" :value="2"> </el-option>
            <el-option :label="3" :value="3"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <div slot="label" class="form-label">
            <span>图标</span>
            <i class="el-icon-edit" @click="dialogDisplay.iconEdit = true"></i>
          </div>
          <el-col>
            <div class="preview" v-if="xForm.icon_url">
              <img class="preview-img" :src="xForm.icon_url" />
            </div>
            <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadIconProgress" :on-error="uploadIconError" :on-success="uploadIconSuccess" :before-upload="beforeUploadIcon" :data="{ token: picToken }" :show-file-list="false">
              <div class="uploadPic">
                <i class="el-icon-upload2"></i>
                <span>上传图标</span>
              </div>
            </el-upload>
          </el-col>
        </el-form-item>

        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages"></languages-selecter>
        </el-form-item>

        <el-form-item label="iOS上架版本：">
          <el-input-tag v-model="xForm.ios_versions"></el-input-tag>
        </el-form-item>
        <el-form-item label="Android上架版本：">
          <el-input-tag v-model="xForm.android_versions"></el-input-tag>
        </el-form-item>

        <el-form-item label="地址">
          <el-input v-model="xForm.link"></el-input>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="xForm.remark"></el-input>
        </el-form-item>

        <el-dialog title="Icon地址" :visible.sync="dialogDisplay.iconEdit">
          <el-form label-position="top" label-width="120px">
            <el-form-item>
              <el-input v-model="xForm.icon_url"></el-input>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-form>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import OpenCC from '@/plugins/opencc';
import LanguagesSelecter from '@/components/languages-selecter';
import ElInputTag from '@/components/el-input-tag';

export default {
  name: 'Form',
  components: {
    Container,
    LanguagesSelecter,
    ElInputTag,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      picToken: '',
      langs: require('@/langs.json'),

      dialogDisplay: {
        iconEdit: false,
      },
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
  },
  methods: {
    beforeUploadIcon: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadIconSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.form.icon_url = picUrl;
      this.iconUploading = false;
    },
    uploadIconProgress(response) {
      this.iconUploading = true;
    },
    uploadIconError(response) {
      this.iconUploading = false;
    },

    async save() {
      this.$emit('save', this.form);
    },
    async s2twp() {
      try {
        this.form.name['zh-Hant'] = this.opencc_twp(this.form.name['zh-Hans']);
        this.form.sub_title['zh-Hant'] = this.opencc_twp(this.form.sub_title['zh-Hans']);
        this.form.description['zh-Hant'] = this.opencc_twp(this.form.description['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
  },
  created: async function () {
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.diff {
  color: rgba(230, 162, 60, 1) !important;
  background: rgba(230, 162, 60, 0.12);
}

.scene-tag {
  margin-right: 16px;
  cursor: default;
}

.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}

.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}

.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
</style>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">关键字配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item label="名字">
          <el-input v-model="xForm.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label">{{ lang }}</span>
              <el-col :span="7">
                <el-form-item label="关键字列表（按回车输入）">
                  <el-input-tag v-model="xForm[lang]"></el-input-tag>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item v-if="lang === 'zh-Hant'">
                  <el-button type="text" @click="s2twp()">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
      </el-form>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Container from '@/components/container';
import ElInputTag from '@/components/el-input-tag';
import OpenCC from '@/plugins/opencc';

export default {
  name: 'Form',
  components: {
    Container,
    ElInputTag,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      langs: require('@/langs.json'),
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
  },
  methods: {
    async save() {
      this.$emit('save', this.form);
    },
    async s2twp() {
      try {
        this.form['zh-Hant'] = JSON.parse(this.opencc_twp(JSON.stringify(this.form['zh-Hans'])));
      } catch (e) {
        this.$message.error('转换失败');
      }
    },
  },
  created: async function () {
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.diff {
  color: rgba(230, 162, 60, 1) !important;
  background: rgba(230, 162, 60, 0.12);
}

.scene-tag {
  margin-right: 16px;
  cursor: default;
}
</style>

<template>
  <edit-form :form="form" @change="change" @save="save"></edit-form>
</template>

<script>
import Form from '../form';
export default {
  name: 'EditScene',
  components: {
    EditForm: Form,
  },
  data() {
    return {
      form: {
        id: '',
        title: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        sub_title: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        description: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        tag: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        url: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        markdown_content: {
          'zh-Hans': '',
          'zh-Hant': '',
          ja: '',
          en: '',
          es: '',
          ru: '',
          ko: '',
        },
        mask_colors: [],
        primary_color: '',
        image: '',
        status: 'draft',
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      const { article_id: articleId } = this.$route.params;

      try {
        await this.$request({
          url: `/v1/admin/meditation/articles/${articleId}`,
          method: 'PUT',
          data,
        });
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
        await this.init();
      } catch (e) {
        this.$message.error('操作失败');
      }
    },

    init: async function () {
      const { article_id: articleId } = this.$route.params;
      try {
        const res = await this.$request({
          url: `/v1/admin/meditation/articles/${articleId}`,
        });
        this.form = Object.assign({}, this.form, res.data);
      } catch (e) {
        this.form = Object.assign({}, this.form);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

import Layout from '@/components/layout';

import Users from '@/pages/users/index';
import UsersManager from '@/pages/users/manager/index';
import UserEditor from '@/pages/users/manager/edit';
import UserSettings from '@/pages/users/manager/settings';
import UserFavorites from '@/pages/users/manager/favorites';
import UserUsagesTask from '@/pages/users/manager/usages_task';
import UserRecords from '@/pages/users/manager/records';
import UserPurchased from '@/pages/users/manager/purchased';
import UserMixedScenes from '@/pages/users/manager/mixed_scenes';
import UserVipInfo from '@/pages/users/manager/vip-info';
import UserPayments from '@/pages/users/manager/payments';
import UserReceipts from '@/pages/users/manager/receipts';
import UserIAPReceipts from '@/pages/users/manager/iap_receipts';
import UserTransactionsNew from '@/pages/users/manager/transactions_new';
import UserSession from '@/pages/users/manager/session';
import UserDebug from '@/pages/users/manager/debug';
export default [
  {
    path: '/users',
    name: 'UsersIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '用户档案室',
    },
    redirect: {
      name: 'Users',
    },
    children: [
      {
        path: 'index',
        name: 'Users',
        components: {
          default: Users,
        },
        meta: {
          requireAuth: true,
          label: 'Users',
          newDesign: true,
        },
      },

      {
        path: 'manager',
        name: 'UsersManager',
        components: {
          default: UsersManager,
        },
        meta: {
          requireAuth: true,
          label: '资源管理',
          newDesign: true,
        },
        children: [
          {
            path: ':user_id/edit',
            name: 'UserEditor',
            components: {
              default: UserEditor,
            },
            meta: {
              requireAuth: true,
              label: '用户信息',
              newDesign: true,
            },
          },
          {
            path: ':user_id/settings',
            name: 'UserSettings',
            components: {
              default: UserSettings,
            },
            meta: {
              requireAuth: true,
              label: '用户设置',
              newDesign: true,
            },
          },
          {
            path: ':user_id/records',
            name: 'UserRecords',
            components: {
              default: UserRecords,
            },
            meta: {
              requireAuth: true,
              label: '功能记录',
              newDesign: true,
            },
          },
          {
            path: ':user_id/usages_task',
            name: 'UserUsagesTask',
            components: {
              default: UserUsagesTask,
            },
            meta: {
              requireAuth: true,
              label: '使用记录',
              newDesign: true,
            },
          },
          {
            path: ':user_id/favorites',
            name: 'UserFavorites',
            components: {
              default: UserFavorites,
            },
            meta: {
              requireAuth: true,
              label: '用户收藏',
              newDesign: true,
            },
          },
          {
            path: ':user_id/vip_info',
            name: 'UserVipInfo',
            components: {
              default: UserVipInfo,
            },
            meta: {
              requireAuth: true,
              label: '会员信息',
              newDesign: true,
            },
          },
          {
            path: ':user_id/purchased',
            name: 'UserPurchased',
            components: {
              default: UserPurchased,
            },
            meta: {
              requireAuth: true,
              label: '已购场景',
              newDesign: true,
            },
          },
          {
            path: ':user_id/mixed_scenes',
            name: 'UserMixedScenes',
            components: {
              default: UserMixedScenes,
            },
            meta: {
              requireAuth: true,
              label: '混音场景',
              newDesign: true,
            },
          },
          {
            path: ':user_id/transactions_new',
            name: 'UserTransactionsNew',
            components: {
              default: UserTransactionsNew,
            },
            meta: {
              requireAuth: true,
              label: 'TransactionsNew',
              newDesign: true,
            },
          },
          {
            path: ':user_id/payments',
            name: 'UserPayments',
            components: {
              default: UserPayments,
            },
            meta: {
              requireAuth: true,
              label: 'Payments',
              newDesign: true,
            },
          },
          {
            path: ':user_id/receipts',
            name: 'UserReceipts',
            components: {
              default: UserReceipts,
            },
            meta: {
              requireAuth: true,
              label: 'Receipts',
              newDesign: true,
            },
          },
          {
            path: ':user_id/iap_receipts',
            name: 'UserIAPReceipts',
            components: {
              default: UserIAPReceipts,
            },
            meta: {
              requireAuth: true,
              label: 'IAPReceipts',
              newDesign: true,
            },
          },
          {
            path: ':user_id/session',
            name: 'UserSession',
            components: {
              default: UserSession,
            },
            meta: {
              requireAuth: true,
              label: 'Session',
              newDesign: true,
            },
          },

          {
            path: ':user_id/debug',
            name: 'UserDebug',
            components: {
              default: UserDebug,
            },
            meta: {
              requireAuth: true,
              label: 'Session',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },
];

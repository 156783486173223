<template>
  <equip-form :form="form" @change="change" @save="save"></equip-form>
</template>

<script>
import Form from './form';
export default {
  name: 'AddScene',
  components: {
    EquipForm: Form,
  },
  data() {
    const today = this.$moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return {
      form: {
        remark: '',
        name: {
          'zh-Hans': '',
          'zh-Hant': '',
          en: '',
          ja: '',
          es: '',
          ru: '',
          ko: '',
        },
        album_id: '',
        album_ids: [],
        valid_from: parseInt(today.add(1, 'days') / 1000),
        valid_to: parseInt(today.add(2, 'days') / 1000) - 1,
        sort_key: 2,
        regions: [],
        status: 'draft',
      },
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    change(data) {
      this.form = Object({}, this.form, data);
    },
    async save(data) {
      try {
        const res = await this.$request({
          url: '/v1/admin/meditation/recommends',
          method: 'POST',
          data,
        });
        const recommend = res.data;
        this.$router.replace({
          name: 'EditMedRecommend',
          params: {
            recommend_id: recommend.id,
          },
        });

        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
  },
  created() {},
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

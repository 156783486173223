<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">故事配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <el-tabs type="border-card">
            <el-tab-pane v-for="lang in langs" :key="lang">
              <span slot="label" v-if="xForm.name[lang] && xForm.description[lang]">{{ lang }}</span>
              <span slot="label" v-else>{{ lang }} (未填写完整)</span>
              <el-col :span="23">
                <el-form-item label="故事名">
                  <el-input v-model="xForm.name[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="描述">
                  <el-input type="textarea" :rows="4" v-model="xForm.description[lang]"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="23">
                <el-form-item label="特别合作">
                  <el-input v-model="xForm.cooperation[lang]"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="23" v-if="lang === 'zh-Hant'">
                <el-form-item>
                  <el-button type="text" @click="s2twp">文案简中转繁中</el-button>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-row>
          <el-form-item label="快速填充">
            <el-input type="textarea" :rows="2" v-model="shortcut" placeholder="中文故事名, 中文描述, 中文特别合作, 英文故事名, 英文描述, 英文合作方, 主题色"></el-input>
          </el-form-item>
        </el-row>

        <el-form-item label="主色调：">
          <color-picker v-model="xForm.primary_color" style="width: 20%"></color-picker>
        </el-form-item>

        <el-form-item label="封面图片">
          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">如果存在视频封面，那么该自动截取首帧生成</div>
              <span>封面</span>
            </el-tooltip>
            <image-uploader v-model="xForm.image" :resourceFolder="this.resourceFolder"></image-uploader>
          </el-col>

          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">上传后会自动裁切出首帧和 Demo 视频</div>
              <span>视频</span>
            </el-tooltip>
            <encrypt-video-uploader :hash-key="xForm.video_cover_key" :resourceFolder="this.resourceFolder" :persistentOps="storyCoverVideoUploadPersistentOps()" @change="storyCoverVideoHashChanged"></encrypt-video-uploader>
          </el-col>

          <el-col :span="4">
            <el-tooltip placement="top">
              <div slot="content">等同于正式视频</div>
              <span>DEMO 视频(自动生成)</span>
            </el-tooltip>
            <video-uploader v-model="xForm.video_cover_demo_url" :resourceFolder="this.resourceFolder"></video-uploader>
          </el-col>
        </el-form-item>

        <el-form-item label="加入标签[手动]">
          <el-col>
            <el-select v-model="xForm.manual_simple_tag_ids" value-key="id" multiple filterable default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="加入标签[自动]">
          <el-col>
            <el-select v-model="xForm.simple_tag_ids" value-key="id" disabled multiple filterable default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="相关文章列表">
          <el-col>
            <el-select v-model="xForm.related_article_ids" multiple filterable placeholder="选择文章" style="width: 100%">
              <el-option v-for="item in articleOptions" :key="item.value.id" :label="getTR(item.value.title) + ' | ' + getStatus(item.value.status)" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="搜索关键字">
          <el-col>
            <el-select v-model="xForm.search_annotations" value-key="id" multiple filterable default-first-option placeholder="选择关键字" style="width: 100%">
              <el-option v-for="item in annotationOptions" :key="item.value.id" :label="item.value.name" :value="item.value"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="展示样式：">
          <el-radio-group v-model="xForm.style.size">
            <el-radio label="small">小图</el-radio>
            <el-radio label="big">大图</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="上架状态：">
          <status-radio v-model="xForm.status" style="width: 80%"></status-radio>
        </el-form-item>

        <el-form-item label="上架区域：">
          <regions-selecter v-model="xForm.regions" style="width: 40%"></regions-selecter>
        </el-form-item>

        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages" style="width: 40%"></languages-selecter>
        </el-form-item>

        <el-form-item label="上架渠道：">
          <channels-selector v-model="xForm.channels" style="width: 40%"></channels-selector>
        </el-form-item>

        <el-form-item label="禁止上架渠道：">
          <channels-selector v-model="xForm.blocked_channels" style="width: 40%"></channels-selector>
        </el-form-item>

        <el-form-item label="新品角标：">
          <el-radio-group v-model="freshMark">
            <el-radio :label="false">不显示</el-radio>
            <el-radio :label="true">显示到</el-radio>
          </el-radio-group>
          <el-date-picker type="datetime" style="margin-left: 8px" size="mini" :disabled="!freshMark" v-model="freshUntil"></el-date-picker>
        </el-form-item>

        <el-form-item label="限时体验选项">
          <el-radio-group v-model="triallLock">
            <el-radio :label="true">不限时</el-radio>
            <el-radio :label="false">限时</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="限时体验时间">
          <el-date-picker :disabled="triallLock" size="medium" type="datetime" v-model="trialStartedAt" placeholder="开始时间" default-time="23:59:59"></el-date-picker>
          <span> 到 </span>
          <el-date-picker :disabled="triallLock" size="medium" type="datetime" v-model="trialEndedAt" placeholder="结束时间" default-time="23:59:59"></el-date-picker>
        </el-form-item>

        <el-form-item label="限时体验登录状态">
          <login-status-selecter v-model="xForm.trial_login_status" style="width: 40%"></login-status-selecter>
        </el-form-item>

        <el-form-item label="故事定价">
          <el-radio-group v-model="xForm.access">
            <el-radio label="free">免费</el-radio>
            <el-radio label="subscribe">会员</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div class="container-head">
        <div class="container-head-title">资源列表</div>
        <div class="container-body-header-extra">
          <el-button type="text" @click="addResource">添加新资源</el-button>
        </div>
      </div>

      <div class="container-body">
        <el-table class="resources-content" :data="xForm.resources_v2" stripe>
          <el-table-column label="资源名" width="240">
            <template slot-scope="scope">
              {{ scope.row.name }}<br />
              {{ scope.row.guid }}
            </template>
          </el-table-column>

          <el-table-column label="时长">
            <template slot-scope="scope">{{ scope.row.duration }}分钟</template>
          </el-table-column>

          <el-table-column label="白噪音">
            <template slot-scope="scope">{{ scope.row.has_white_noise ? '是' : '否' }}</template>
          </el-table-column>

          <el-table-column label="人声">
            <template slot-scope="scope">{{ scope.row.has_human_voice ? '是' : '否' }}</template>
          </el-table-column>

          <el-table-column label="朗读者">
            <template slot-scope="scope">{{ getTR(getParticiInfo(scope.row.speaker_id).name_i18n) }}</template>
          </el-table-column>

          <el-table-column label="参与者">
            <template slot-scope="scope">{{ (scope.row.participant_ids || []).length }}</template>
          </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
          </el-table-column>

          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="editResource(scope.row.guid)">编辑</el-button>
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="deleteResource(scope.row.guid)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="资源编辑" :visible.sync="dialogDisplay.editResource" width="75%">
        <el-form v-if="activeResource && xForm.resources_v2.length">
          <el-input v-model="activeResource.guid" disabled></el-input>
          <el-form-item label="资源名">
            <el-input v-model="activeResource.name"></el-input>
          </el-form-item>
          <el-form-item label="时长">
            <el-input-number v-model="activeResource.duration"></el-input-number>
          </el-form-item>
          <el-form-item label="语言">
            <el-radio-group v-model="getParticiInfo(activeResource.speaker_id).language" size="mini" disabled>
              <el-radio-button label="zh">zh</el-radio-button>
              <el-radio-button label="en">en</el-radio-button>
              <el-radio-button label="zh_yue">zh_yue</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="音频">
            <el-row>
              <el-col :span="3">
                <span>立体声</span>
                <encrypt-audio-uploader :hash-key="activeResource.hash_key" :resourceFolder="this.resourceFolder" @change="storyHashChanged"></encrypt-audio-uploader>
              </el-col>

              <el-col :span="3">
                <span>多声道</span>
                <encrypt-audio-uploader :hash-key="activeResource.multichannel_hash_key" :resourceFolder="this.resourceFolder" @change="storyMultichannelHashChanged"></encrypt-audio-uploader>
              </el-col>

              <el-col :span="3">
                <span>杜比</span>
                <encrypt-audio-uploader :hash-key="activeResource.dolby_hash_key" :resourceFolder="this.resourceFolder" @change="storyDolbyHashChanged"></encrypt-audio-uploader>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item label="试听音频">
            <el-row>
              <el-col :span="3">
                <span>立体声</span>
                <audio-uploader v-model="activeResource.demo_sound_url" :resourceFolder="this.resourceFolder"></audio-uploader>
              </el-col>

              <el-col :span="3">
                <span>多声道</span>
                <audio-uploader v-model="activeResource.demo_multichannel_url" :resourceFolder="this.resourceFolder"></audio-uploader>
              </el-col>

              <el-col :span="3">
                <span>杜比</span>
                <audio-uploader v-model="activeResource.demo_dolby_url" :resourceFolder="this.resourceFolder"></audio-uploader>
              </el-col>
            </el-row>
          </el-form-item>

          <el-row>
            <el-form-item label="试听开关">
              <el-radio-group v-model="activeResource.demo_switch">
                <el-radio :label="false">关</el-radio>
                <el-radio :label="true">开</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="白噪音">
              <el-radio-group v-model="activeResource.has_white_noise">
                <el-radio :label="false" disabled>否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="人声">
              <el-radio-group v-model="activeResource.has_human_voice">
                <el-radio :label="false" disabled>否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="续播声音场景">
              <el-select v-model="activeResource.tail_scene_id" filterable placeholder="选择声音场景" style="width: 30%">
                <el-option v-for="item in sceneOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + getStatus(item.value.status)" :value="item.value.id"></el-option>
              </el-select>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="续播音量百分比(0-100)">
              <el-input-number v-model="activeResource.tail_scene_vol_pct"></el-input-number>
            </el-form-item>
          </el-row>

          <el-form-item label="状态：">
            <status-radio v-model="activeResource.status" style="width: 40%"></status-radio>
          </el-form-item>

          <div class="resources-header">
            <div class="resources-title">朗读者</div>
            <div class="resources-actions">
              <el-button type="text" @click="dialogDisplay.chooseSpeaker = true">编辑</el-button>
            </div>
          </div>
          <el-table class="resources-content" v-if="activeResource" :data="!!activeResource.speaker_id ? [activeResource.speaker_id] : []" stripe>
            <el-table-column label="名字">
              <template slot-scope="scope">
                <div class="participant-info">
                  <cover v-if="getParticiInfo(scope.row).avatar.length" :width="48" :height="48" :src="getParticiInfo(scope.row).avatar"></cover>
                  {{ getTR(getParticiInfo(scope.row).name_i18n) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="语言">
              <template slot-scope="scope">{{ getParticiInfo(scope.row).language }}</template>
            </el-table-column>
            <el-table-column label="身份">
              <template slot-scope="scope">{{ getParticiInfo(scope.row).role === 'author' ? '作者' : '朗读者' }}</template>
            </el-table-column>
            <el-table-column label="嘉宾">
              <template slot-scope="scope">{{ getParticiInfo(scope.row).special ? '是' : '否' }}</template>
            </el-table-column>
          </el-table>

          <div class="resources-header">
            <div class="resources-title">参与者列表</div>
            <div class="resources-actions">
              <el-button type="text" @click="dialogDisplay.sortPanel = true">排序</el-button>
              <el-button type="text" @click="chooseParticipants">编辑</el-button>
            </div>
          </div>

          <el-table class="resources-content" v-if="activeResource" :data="activeResource.participant_ids" stripe>
            <el-table-column label="名字">
              <template slot-scope="scope">
                <div class="participant-info">
                  <cover v-if="getParticiInfo(scope.row).avatar.length" :width="48" :height="48" :src="scope.row.avatar"></cover>
                  {{ getTR(getParticiInfo(scope.row).name_i18n) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="语言">
              <template slot-scope="scope">{{ getParticiInfo(scope.row).language }}</template>
            </el-table-column>
            <el-table-column label="身份">
              <template slot-scope="scope">{{ getParticiInfo(scope.row).role === 'author' ? '作者' : '朗读者' }}</template>
            </el-table-column>
            <el-table-column label="嘉宾">
              <template slot-scope="scope">{{ getParticiInfo(scope.row).special ? '是' : '否' }}</template>
            </el-table-column>
            <el-table-column width="120" label="操作" align="right">
              <template slot-scope="scope">
                <el-button type="text" @click="removeParticipant(scope.row)">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.editResource = false">返 回</el-button>
        </div>
      </el-dialog>

      <el-dialog v-if="activeResource" title="参与者排序" :visible.sync="dialogDisplay.sortPanel">
        <draggable class="sort-list-container" v-model="activeResource.participant_ids">
          <div class="sort-item" v-for="(participant, index) in activeResource.participant_ids" :key="`sort_${getParticiInfo(participant).id}`">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="banner-thumbnail">
              <cover :width="48" :height="48" :src="participant.avatar"></cover>
            </div>
            <div class="sort-item-name">{{ getTR(getParticiInfo(participant).name_i18n) }}</div>
            <div class="sort-item-role">{{ getParticiInfo(participant).role === 'author' ? '作者' : '朗读者' }}</div>
            <div class="sort-item-note">{{ getParticiInfo(participant).special ? '嘉宾' : '' }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.sortPanel = false">返 回</el-button>
        </span>
      </el-dialog>

      <el-dialog title="选择朗读者" :visible.sync="dialogDisplay.chooseSpeaker">
        <div class="speakers">
          <el-table ref="speakerTable" :data="allSpeakers" style="width: 100%" @current-change="onSelectSpeaker" highlight-current-row>
            <el-table-column label="名字">
              <template slot-scope="scope">
                <div class="participant-info">
                  <cover v-if="scope.row.avatar.length" :width="48" :height="48" :src="scope.row.avatar"></cover>
                  {{ getTR(scope.row.name_i18n) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="语言">
              <template slot-scope="scope">{{ scope.row.language }}</template>
            </el-table-column>
            <el-table-column label="嘉宾">
              <template slot-scope="scope">{{ scope.row.special ? '是' : '否' }}</template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.chooseSpeaker = false">返 回</el-button>
        </div>
      </el-dialog>

      <el-dialog title="选择参与者" :visible.sync="dialogDisplay.chooseParticipants">
        <div class="speakers">
          <el-table ref="participantsTable" :data="allParticipants" style="width: 100%" @select="onSelect" @select-all="onSelectAll">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column label="名字">
              <template slot-scope="scope">
                <div class="participant-info">
                  <cover v-if="scope.row.avatar.length" :width="48" :height="48" :src="scope.row.avatar"></cover>
                  {{ getTR(scope.row.name_i18n) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="语言">
              <template slot-scope="scope">{{ scope.row.language }}</template>
            </el-table-column>
            <el-table-column label="身份">
              <template slot-scope="scope">{{ scope.row.role === 'author' ? '作者' : '朗读者' }}</template>
            </el-table-column>
            <el-table-column label="嘉宾">
              <template slot-scope="scope">{{ scope.row.special ? '是' : '否' }}</template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.chooseParticipants = false">返 回</el-button>
        </div>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import Constant from '@/constant.json';
import Container from '@/components/container';
import ColorPicker from '@/components/color-picker';
import ImageUploader from '@/components/image-uploader';
import AudioUploader from '@/components/audio-uploader';
import VideoUploader from '@/components/video-uploader';
import EncryptVideoUploader from '@/components/encrypt-video-uploader';
import EncryptAudioUploader from '@/components/encrypt-audio-uploader';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
import RegionsSelecter from '@/components/regions-selecter';
import LanguagesSelecter from '@/components/languages-selecter';
import OpenCC from '@/plugins/opencc';
import ChannelsSelector from '@/components/channels-selector';
import LoginStatusSelecter from '@/components/login-status-selecter';
import allStatus from '@/status.json';
import StatusRadio from '@/components/status-radio';
import { qiniuUrlsafeBase64Encode } from '@/utils.js';

import { v4 as uuidv4 } from 'uuid';

import allRegions from '@/regions.json';

const allRegionsData = Object.keys(allRegions).map((value) => {
  const label = allRegions[value];
  return { value, label, disabled: false };
});

function hex2Rgb(hex, alpha) {
  var RGBA = parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + parseInt(alpha) / 100;
  return RGBA;
}

export default {
  name: 'StoryForm',
  components: {
    Container,
    Draggable,
    Cover,
    ColorPicker,
    ImageUploader,
    VideoUploader,
    AudioUploader,
    EncryptAudioUploader,
    EncryptVideoUploader,
    RegionsSelecter,
    LanguagesSelecter,
    ChannelsSelector,
    LoginStatusSelecter,
    StatusRadio,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = this.$env;
    return {
      loading: false,
      env,
      langTr: { english: '英语', mandarin: '普通话' },
      langs: require('@/langs.json'),

      objectTagOptions: [],
      simpleTagOptions: [],
      annotationOptions: [],
      articleOptions: [],
      sceneOptions: [],
      allRegions: allRegionsData,
      regions: ['CN', 'US', 'JP'],
      resourceToken: '',
      picToken: '',
      picCDN: '',

      imageUploading: false,
      avatarUploading: false,
      avatarUnselectedUploading: false,
      soundPlaying: false,

      resourceUploading: false,

      newResourceLang: '',
      resourceLangInput: false,
      xFormIndex: 0,
      xFormActiveLang: 'en',
      activeResourceGUID: '',
      shortcut: '',

      allParticipants: [],
      allSpeakers: [],
      groupSorter: [],

      speaker: {
        id: '',
        name: '',
        avatar: '',
        avatar_unselected: '',
      },

      dialogDisplay: {
        editResource: false,
        sortPanel: false,
        chooseSpeaker: false,
        chooseParticipants: false,
      },

      resourceFolder: 'sleep_story',
    };
  },
  watch: {
    shortcut(val) {
      const quoteInfos = val.split('	');
      console.log(quoteInfos.length);
      if (quoteInfos.length !== 7) {
        this.$message.error('解析失败，无法填充');
      } else {
        const [zh_hans_name, zh_hans_description, zh_hans_cooperation, en_name, en_description, en_cooperation, primary_color] = quoteInfos;
        this.$nextTick(() => {
          this.form.name['zh-Hans'] = zh_hans_name;
          this.form.name.en = en_name;
          this.form.name.es = en_name;
          this.form.name.ru = en_name;
          this.form.name.ko = en_name;
          this.form.name.ja = en_name;

          this.form.description['zh-Hans'] = zh_hans_description;
          this.form.description.en = en_description;
          this.form.description.es = en_description;
          this.form.description.ru = en_description;
          this.form.description.ko = en_description;
          this.form.description.ja = en_description;

          this.form.cooperation['zh-Hans'] = zh_hans_cooperation;
          this.form.cooperation.en = en_cooperation;
          this.form.cooperation.es = en_cooperation;
          this.form.cooperation.ru = en_cooperation;
          this.form.cooperation.ko = en_cooperation;
          this.form.cooperation.ja = en_cooperation;

          this.s2twp();
          this.form.primary_color = hex2Rgb(primary_color, 100);
        });
      }
    },
    'xForm.simple_tags': {
      deep: true,
      handler(val) {
        this.xForm.simple_tag_ids = val.map((tag) => tag.id);
      },
    },
    'xForm.primary_color': {
      deep: true,
      handler(val) {
        this.xForm.video_cover_primary_color = val;
      },
    },
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    freshMark: {
      get() {
        return Boolean(this.freshUntil);
      },
      set(boolean) {
        if (boolean) {
          this.freshUntil = +new Date();
          const today = this.$moment();
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.freshUntil = +today;
        } else {
          this.freshUntil = 0;
        }
      },
    },
    freshUntil: {
      get() {
        return this.xForm.fresh_until * 1000;
      },
      set(val) {
        this.xForm.fresh_until = parseInt(val / 1000);
      },
    },

    triallLock: {
      get() {
        return !(this.xForm.trial_started_local_time.length || this.xForm.trial_ended_local_time.length);
      },
      set(val) {
        if (!val) {
          const today = this.$moment();
          today.set('hour', 0);
          today.set('minute', 0);
          today.set('second', 0);
          this.xForm.trial_started_local_time = today.format('YYYY-MM-DD[T]HH:mm:ss');
          today.set('hour', 23);
          today.set('minute', 59);
          today.set('second', 59);
          this.xForm.trial_ended_local_time = today.format('YYYY-MM-DD[T]HH:mm:ss');
        } else {
          this.xForm.trial_started_local_time = '';
          this.xForm.trial_ended_local_time = '';
        }
      },
    },
    trialStartedAt: {
      get() {
        return +this.$moment(this.xForm.trial_started_local_time);
      },
      set(val) {
        this.xForm.trial_started_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },
    trialEndedAt: {
      get() {
        return +this.$moment(this.xForm.trial_ended_local_time);
      },
      set(val) {
        this.xForm.trial_ended_local_time = this.$moment(val).format('YYYY-MM-DD[T]HH:mm:ss');
      },
    },

    activeResource: {
      get() {
        console.log('change: activeResource');
        if (this.xForm && this.xForm.resources_v2.length) {
          const resource = this.xForm.resources_v2.find((resource) => resource.guid === this.activeResourceGUID);
          return resource;
        }
        return null;
      },
      set(val) {
        if (this.xForm && this.xForm.resources_v2.length) {
          const resourceIndex = this.xForm.resources_v2.findIndex((resource) => resource.guid === this.activeResourceGUID);
          if (~resourceIndex) {
            this.xForm.resources_v2[resourceIndex] = Object.assign(this.xForm.resources_v2[resourceIndex], val);
          } else {
            this.xForm.resources_v2 = [val];
          }
        }
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.form);
    },
    async fetchTags() {
      this.objectTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: 'sleep_story' },
      });
      this.objectTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchSimpleTags() {
      this.simpleTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/simple_tags',
        params: { offset: 0, limit: 512 },
      });
      this.simpleTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchAnnotations() {
      this.annotationOptions = [];
      const res = await this.$request({
        url: '/v2/admin/search_annotations',
      });
      this.annotationOptions = res.data.map((ann) => {
        return {
          value: ann,
          label: ann.name,
        };
      });
    },
    async s2twp() {
      try {
        this.xForm.name['zh-Hant'] = this.opencc_twp(this.xForm.name['zh-Hans']);
        this.xForm.description['zh-Hant'] = this.opencc_twp(this.xForm.description['zh-Hans']);
        this.xForm.cooperation['zh-Hant'] = this.opencc_twp(this.xForm.cooperation['zh-Hans']);
      } catch (e) {
        console.log(e);
        this.$message.error('转换失败');
      }
    },

    changeRegions(data) {
      console.log(this.xForm.regions);
    },
    removeRegion(value) {
      this.xForm.regions = this.xForm.regions.filter((region) => region !== value);
    },

    editResource(guid) {
      this.activeResourceGUID = guid;
      this.dialogDisplay.editResource = true;
    },

    deleteResource(guid) {
      const resources = this.xForm.resources_v2;
      this.xForm.resources_v2 = resources.filter((resource) => resource.guid !== guid);
    },

    addResource() {
      this.xForm.resources_v2.splice(0, 0, {
        name: '',
        duration: 0,
        language: 'zh',
        guid: uuidv4(),
        hash: '',
        hash_key: '',
        multichannel_hash: '',
        multichannel_hash_key: '',
        dolby_hash: '',
        dolby_hash_key: '',
        demo_sound_url: '',
        demo_multichannel_url: '',
        demo_dolby_url: '',
        demo_switch: true,
        participant_ids: [],
        speaker_id: '',
        status: 'draft',
        has_white_noise: true,
        has_human_voice: true,
      });
    },

    storyHashChanged(data) {
      const { hash, key } = data;
      this.activeResource.hash = hash;
      this.activeResource.hash_key = key;
    },
    storyMultichannelHashChanged(data) {
      const { hash, key } = data;
      this.activeResource.multichannel_hash = hash;
      this.activeResource.multichannel_hash_key = key;
    },
    storyDolbyHashChanged(data) {
      const { hash, key } = data;
      this.activeResource.dolby_hash = hash;
      this.activeResource.dolby_hash_key = key;
    },
    storyCoverVideoHashChanged(data) {
      const { hash, key } = data;
      if (hash === '' || key === '') {
        this.xForm.video_cover_key = '';
        this.xForm.video_cover_demo_url = '';
        this.xForm.image = '';
        return;
      }
      // 加密视频
      this.xForm.video_cover_key = key;
      // demo 视频和视频封面
      this.xForm.video_cover_demo_url = `${Constant.QINIU_OPEN_CDN_DOMAIN}/${this.resourceFolder}/${hash}/demo`;
      this.xForm.image = `${Constant.QINIU_OPEN_CDN_DOMAIN}/${this.resourceFolder}/${hash}/shots`;
    },
    storyCoverVideoUploadPersistentOps() {
      let ops_list = [];
      // 相当于复制
      let fops = 'avthumb/mp4';
      let saveas_key = qiniuUrlsafeBase64Encode(`${Constant.QINIU_OPEN_BUCKET}:${this.resourceFolder}/$(etag)/demo`);
      fops = fops + '|saveas/' + saveas_key;
      ops_list.push(fops);

      // 获取视频首帧
      fops = 'vframe/png/offset/0';
      saveas_key = qiniuUrlsafeBase64Encode(`${Constant.QINIU_OPEN_BUCKET}:${this.resourceFolder}/$(etag)/shots`);
      fops = fops + '|saveas/' + saveas_key;
      ops_list.push(fops);

      let result = ops_list.join(';');
      console.log(result);
      return result;
    },

    // -----------------------------------------------------------
    // MARK: - 上传相关
    // -----------------------------------------------------------

    // 获取token
    beforeUpload: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },

    // 上传封面
    uploadImageSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.image = picUrl;
      this.imageUploading = false;
    },
    uploadImageProgress(response) {
      this.imageUploading = true;
    },
    uploadImageError(response) {
      this.imageUploading = false;
    },

    // -----------------------------------------------------------
    // MARK: - 选择参与者
    // -----------------------------------------------------------

    async fetchParticipants() {
      const res = await this.$request({
        url: '/v1/admin/participants',
      });
      this.allParticipants = res.data;
      this.allSpeakers = res.data.filter((item) => item.role === 'speaker');
    },

    async fetchArticles() {
      this.articleOptions = [];
      const res = await this.$request({
        url: '/v1/admin/meditation/articles',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      this.articleOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },

    async fetchScenes() {
      this.sceneOptions = [];
      const res = await this.$request({
        url: '/v1/admin/scenes',
        params: { category: 'normal', offset: 0, limit: 999 },
      });
      this.sceneOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },

    getParticiInfo(id) {
      for (let v of this.allParticipants) {
        if (v.id === id) {
          return v;
        }
      }
      return { name_i18n: {} };
    },

    chooseParticipants() {
      this.dialogDisplay.chooseParticipants = true;
      setTimeout(() => {
        for (let v of this.activeResource.participant_ids) {
          this.$refs.participantsTable.toggleRowSelection(this.getParticiInfo(v), true);
        }
        console.log('finished');
      }, 200);
    },
    onSelectSpeaker(row) {
      this.activeResource.speaker_id = row.id;
    },
    onSelect(selection, row) {
      this.activeResource.participant_ids = selection.map((v) => v.id);
    },
    onSelectAll(selection) {
      this.activeResource.participant_ids = selection.map((v) => v.id);
    },
    removeParticipant(item) {
      this.activeResource.participant_ids = this.activeResource.participant_ids.filter((participant) => {
        return item !== participant;
      });
    },

    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  created: async function () {
    this.fetchTags();
    this.fetchSimpleTags();
    this.fetchAnnotations();
    this.fetchParticipants();
    this.fetchArticles();
    this.fetchScenes();
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.uploadIcon {
  display: inline-block;
}

hr {
  margin: 32px 0;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.04);
}

.album-duration .el-input {
  width: 160px;
}

.checkbox-group-regions {
  display: inline-block;
  margin-right: 16px;
}

.input-new-tag,
.button-new-tag {
  width: 90px;
}

.form-item-label {
  position: relative;
  z-index: 2;
}

.icon-help {
  font-size: 16px;
  vertical-align: sub;
}

.resources-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
}

.resources-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 16px 0;
}

.resources-content {
  margin: 0 16px;
  width: auto;
}

.resources .el-tag {
  cursor: pointer;
}

.lang-span + .lang-span,
.participant + .participant {
  &::before {
    content: ', ';
  }
}

.symmetry-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  width: 240px;
  padding-left: 16px;
}
.sort-item-role,
.sort-item-note {
  width: 80px;
  padding-left: 16px;
}

.resources-actions {
  display: flex;
  flex-direction: row;
}

.speaker {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.speaker.selected {
  border: 1px solid rgba(103, 194, 58, 0.2);
  background-color: rgba(103, 194, 58, 0.1);
}

.speaker + .speaker {
  margin-left: 12px;
}

.edit-speaker-btn {
  position: absolute;
  right: 20px;
  top: 0;
  display: none;
  font-size: 16px;
}

.speaker:hover {
  .edit-speaker-btn,
  .del-speaker-btn {
    display: block;
  }
}

.del-speaker-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  font-size: 16px;
}

.speaker-avatar {
  width: 32px;
  height: 36px;
}

.speaker-name {
  margin-top: 8px;
  font-size: 13px;
  width: 72px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.mini-speaker {
  margin-right: 4px;
}

.speaker-groups {
  margin-top: 16px;
}

.speaker-group {
  padding: 8px 16px;
}

.speaker-group + .speaker-group {
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}

.speaker-group-header {
  margin-bottom: 12px;
}

.speaker-groups {
  background-color: rgba(0, 0, 0, 0.04);
}

.speaker-group-title {
  padding: 8px 8px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
}

.speakers-container {
  padding: 16px 24px;
}

.participant-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.participant-info /deep/ .cover {
  margin-right: 8px;
}
</style>

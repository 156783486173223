<template>
  <router-view v-if="$route.name !== 'AnnotationsManage'"></router-view>
  <container v-else>
    <div slot="page-header-extra"></div>
    <div class="container" slot="container">
      <div class="container-body-header">
        <div class="container-body-header-title">搜索管理</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">添加</el-button>
        </div>
      </div>

      <div class="container-body">
        <el-table :data="annotations" stripe style="width: 100%">
          <el-table-column label="名称">
            <template slot-scope="scope">{{ scope.row.name }}<br />{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column label="英文关键字">
            <template slot-scope="scope">{{ scope.row.en }}</template>
          </el-table-column>
          <el-table-column label="简中关键字">
            <template slot-scope="scope">{{ scope.row['zh-Hans'] }}</template>
          </el-table-column>
          <el-table-column label="繁中关键字">
            <template slot-scope="scope">{{ scope.row['zh-Hant'] }}</template>
          </el-table-column>
          <el-table-column label="日语关键字">
            <template slot-scope="scope">{{ scope.row.ja }}</template>
          </el-table-column>
          <el-table-column label="西语关键字">
            <template slot-scope="scope">{{ scope.row.es }}</template>
          </el-table-column>
          <el-table-column label="俄语关键字">
            <template slot-scope="scope">{{ scope.row.ru }}</template>
          </el-table-column>
          <el-table-column label="韩语关键字">
            <template slot-scope="scope">{{ scope.row.ko }}</template>
          </el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="showContentEditor(scope.row)">编辑内容</el-button>
              <router-link class="el-button el-button--text" :to="{ name: 'EditAnnotation', params: { annotation_id: scope.row.id } }">编辑</router-link>
              <el-button type="text" :disabled="!($env === 'dev' || $store.getters.hasAdminRole)" @click="removeItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="添加内容" :visible.sync="dialogDisplay.addContents">
        <el-tabs type="border-card">
          <el-tab-pane v-for="contentType in contentTypes" :key="contentType.type">
            <span slot="label">{{ contentType.name }}</span>
            <el-button @click="attachContents(contentType.type)">保 存</el-button>
            <el-table :data="contentData[contentType.type]" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectedContent">
              <el-table-column type="selection"></el-table-column>
              <el-table-column label="名称">
                <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.addContents = false">返 回</el-button>
        </span>
      </el-dialog>
      <el-dialog title="编辑内容" :visible.sync="dialogDisplay.editContents">
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="showAttachContentDialog" type="text">添加内容</el-button>
        </div>
        <el-table :data="currentAnnotationContents" stripe style="width: 100%">
          <el-table-column label="名称">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">{{ scope.row.type }}</template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">{{ scope.row.status }}</template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="detachContent(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.editContents = false">返 回</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';

export default {
  name: 'InstituteManager',
  components: {
    Container,
  },
  data() {
    return {
      contentTypes: [
        {
          type: 'meditation',
          name: '冥想专辑',
        },
        {
          type: 'scene',
          name: '声音场景',
        },
        {
          type: 'sleep_story',
          name: '睡眠故事',
        },
      ],
      annotations: [],
      currentAnnotation: {},
      currentAnnotationContents: [],
      contentData: {
        sleep_story: [],
        scene: [],
        meditation: [],
      },
      selectedContent: [],

      dialogDisplay: {
        editContents: false,
        addContents: false,
      },
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'AnnotationsManage') {
        this.init();
      }
    },
    'dialogDisplay.addContents': async function (val) {
      console.log(val);
      if (val === false) {
        await this.loadCurrentAnnotationContents(this.currentAnnotation);
      }
    },
  },
  methods: {
    handleSelectedContent(selected) {
      this.selectedContent = selected;
      console.log(this.selectedContent);
    },
    async showContentEditor(annotation) {
      this.dialogDisplay.editContents = true;
      this.currentAnnotation = annotation;
      await this.loadCurrentAnnotationContents(annotation);
    },
    async loadCurrentAnnotationContents(annotation) {
      const resp = await this.$request({
        url: `/v2/admin/search_annotations:attach`,
        params: {
          id: annotation.id,
        },
      });
      console.log(resp.data);
      this.currentAnnotationContents = [];
      this.currentAnnotationContents.push(
        ...resp.data.meditation_albums.map((v) => {
          return { id: v.id, type: '冥想专辑', name: this.getTR(v.name), status: this.getStatus(v.status) };
        }),
      );
      this.currentAnnotationContents.push(
        ...resp.data.scenes.map((v) => {
          return { id: v.id, type: '声音场景', name: this.getTR(v.name), status: this.getStatus(v.status) };
        }),
      );
      this.currentAnnotationContents.push(
        ...resp.data.sleep_stories.map((v) => {
          return { id: v.id, type: '睡眠故事', name: this.getTR(v.name), status: this.getStatus(v.status) };
        }),
      );
    },
    async detachContent(content) {
      await this.$request({
        url: `/v2/admin/search_annotations:detach`,
        method: 'POST',
        data: {
          ids: [content.id],
          type: this.getType(content.type),
          annotation: this.currentAnnotation,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
      });
      this.currentAnnotationContents = this.currentAnnotationContents.filter((v) => v.id !== content.id);
    },
    async attachContents(type) {
      await this.$request({
        url: `/v2/admin/search_annotations:attach`,
        method: 'POST',
        data: {
          ids: this.selectedContent.map((v) => v.id),
          type: type,
          annotation: this.currentAnnotation,
        },
      });
      this.$message({
        message: '操作成功',
        type: 'success',
      });
    },
    async showAttachContentDialog() {
      this.dialogDisplay.addContents = true;
      const res1 = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      this.contentData.meditation = res1.data;
      const res2 = await this.$request({
        url: '/v1/admin/scenes',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      this.contentData.scene = res2.data;
      const res3 = await this.$request({
        url: '/v1/admin/sleep_stories',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      this.contentData.sleep_story = res3.data;
    },
    addItem() {
      this.$router.push({
        name: 'AddAnnotation',
      });
    },
    async removeItem(item) {
      try {
        const { value } = await this.$prompt('此操作将永久删除该关键字，请输入 “确定删除” 来确认该操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
        if (value !== '确定删除') {
          this.$message.error('输入错误');
        } else {
          await this.$request({
            url: `/v2/admin/search_annotations/${item.id}`,
            method: 'DELETE',
          });
          this.annotations = this.annotations.filter((annotation) => annotation.id !== item.id);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      this.fetchList();
    },
    fetchList: async function () {
      const annotationsRes = await this.$request({
        url: '/v2/admin/search_annotations',
        params: {
          offset: 0,
          limit: 512,
        },
      });
      this.annotations = annotationsRes.data;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return status === 'published' ? '已上架' : status === 'draft' ? '草稿' : status === 'reviewing' ? '审阅中' : '';
    },
    getType(zhType) {
      return zhType === '冥想专辑' ? 'meditation' : zhType === '声音场景' ? 'scene' : zhType === '睡眠故事' ? 'sleep_story' : '';
    },
  },
  async created() {
    await this.init();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.add-btn {
  width: 100%;
  border-style: dashed;
}

.lang-selecter {
  margin-left: 16px;
}

.filters .el-form-item {
  margin-bottom: 0;
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}

.btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 16px;
}

.mark {
  font-size: 13px;
  color: #bcbec2;
}

.remove-btn {
  color: #f56c6c;
}
</style>

<template>
  <router-view v-if="$route.name !== 'Configs'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">动态配置管理</div>
        </div>

        <div class="container-body">
          <div class="container-body-left">
            <el-row>
              <el-button @click="configDialogShow('new', null)">新增配置项</el-button>
              <el-button @click="logSalvageShow()">日志回捞</el-button>
            </el-row>
          </div>

          <el-table :data="list">
            <el-table-column prop="key" label="key"></el-table-column>
            <el-table-column width="120" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="configDialogShow('edit', scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </container>

    <!-- 配置相关 -->
    <el-dialog :title="configDialog.dialogTitle" :visible.sync="configDialog.dialogShow">
      <el-form>
        <el-form-item label="配置项">
          <el-input placeholder="KEY" v-model="configDialog.currentKey"></el-input>
        </el-form-item>
        <el-form-item label="配置内容">
          <el-input v-model="configDialog.currentJSONString" type="textarea" :rows="12"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="configDialog.dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="saveConfig">保 存</el-button>
      </div>
    </el-dialog>

    <!-- 日志回捞 -->
    <el-dialog title="日志回捞" :visible.sync="logSalvage.dialogShow">
      <el-form>
        <el-form-item label="类型">
          <el-radio-group v-model="logSalvage.type">
            <el-radio label="user">用户 ID</el-radio>
            <el-radio label="device">设备 ID</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="数据">
          <el-input placeholder="用户 uuid 或者是 deviceID" v-model="logSalvage.dataID"></el-input>
        </el-form-item>

        <el-form-item label="备注">
          <el-input placeholder="这次回捞是为了处理啥事情，防止回捞成功的时候忘记了。不能为空" v-model="logSalvage.note" type="textarea" :rows="5"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="logSalvage.dialogShow = false">取 消</el-button>
        <el-button type="primary" @click="logSalvageSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Container from '@/components/container';

export default {
  name: 'ClientLog',
  components: {
    Container,
  },
  data() {
    return {
      configDialog: {
        dialogTitle: '',
        dialogShow: false,
        currentKey: '',
        currentJSONString: '',
      },
      logSalvage: {
        dialogShow: false,
        type: 'user',
        dataID: '',
        note: '',
      },
      list: [],
    };
  },
  watch: {
    '$route.name'(name) {
      if (name === 'Configs') {
        this.refetch();
      }
    },
  },
  methods: {
    async refetch() {
      const list = [];
      const res = await this.$request({
        url: '/v1/admin/configs',
      });
      const configs = res.data;
      Object.keys(configs).forEach(key => {
        list.push({
          key,
          value: configs[key],
        });
      });
      this.list = list;
    },
    configDialogShow(type, e) {
      if (type === 'edit') {
        this.configDialog.dialogTitle = '更新配置';
        this.configDialog.dialogShow = true;
        this.configDialog.currentKey = e.key;
        this.configDialog.currentJSONString = JSON.stringify(e.value, undefined, 2);
      } else {
        this.configDialog.dialogTitle = '添加配置';
        this.configDialog.dialogShow = true;
        this.configDialog.currentKey = '';
        this.configDialog.currentJSONString = '';
      }
    },
    async saveConfig() {
      const currentKey = this.configDialog.currentKey;
      const currentJSONString = this.configDialog.currentJSONString;
      let value = null;
      try {
        value = JSON.parse(currentJSONString);
      } catch (e) {
        this.$message.error('解析JSON失败');
        return;
      }
      await this.$request({
        method: 'PUT',
        url: `/v1/admin/configs/${currentKey}`,
        data: value,
      });
      const config = this.list.find(item => {
        return item.key === currentKey;
      });
      if (config) {
        config.value = value;
      } else {
        this.list.push({
          key: currentKey,
          value,
        });
      }
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.refetch();
      this.configDialog.dialogShow = false;
    },
    logSalvageShow() {
      this.logSalvage.dialogShow = true;
      this.logSalvage.type = 'user';
      this.logSalvage.dataID = '';
      this.logSalvage.note = '';
    },
    async logSalvageSave() {
      const dataID = this.logSalvage.dataID;
      const note = this.logSalvage.note;
      if (dataID == '' || note == '') {
        this.$message.error('数据不能为空');
        return;
      }
      const data = {
        type: this.logSalvage.type,
        dataID: dataID,
        note: note,
      };
      try {
        await this.$request({
          method: 'PUT',
          url: '/v1/admin/configs/logsalvage',
          data: data,
        });
      } catch (e) {
        console.log(e);
        this.$message.error(e.response.data.msg);
        return;
      }
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.refetch();
      this.logSalvage.dialogShow = false;
    },
  },
  created() {
    this.refetch();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.mini-avatar {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
.select-option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  color: #fff;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.32);
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.form-label {
  position: relative;
}
</style>

<template>
  <div>
    <div class="preview voice" v-if="uploading" v-loading="uploading">
      <i class="material-icons">queue_music</i>
    </div>
    <div class="preview voice" v-else-if="imageUrl">
      <cover :width="96" :height="96" :src="imageUrl"></cover>
      <div class="preview-cover">
        <el-button type="text" @click="dialogDisplay = true" icon="el-icon-video-play">查看</el-button>
        <el-button type="text" @click="clearImage" icon="el-icon-delete" v-if="!onlyShow">清除</el-button>
      </div>
    </div>
    <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadProgress" :on-error="uploadError" :on-success="uploadSuccess" :before-upload="beforeUpload" :data="{ token: uploadToken }" :show-file-list="false">
      <div class="preview">
        <i class="el-icon-upload"></i>
        <span style="color: red">未上传</span>
      </div>
    </el-upload>
    <el-input v-model="imageUrl" size="mini" disabled />

    <el-dialog title="查看图片" :visible.sync="dialogDisplay">
      <el-input v-model="imageUrl" disabled />
      <div>
        <span v-if="imageInfo" class="image-info">
          <p>图片大小：{{ (imageInfo.size / (1024 * 1024)).toFixed(2) }} MB</p>
          <p>图片格式：{{ imageInfo.format }}</p>
          <p>图片尺寸：{{ imageInfo.width }} x {{ imageInfo.height }}</p>
          <p>色彩模式：{{ imageInfo.colorModel }}</p>
        </span>
        <span v-else>
          <el-button type="text" @click="getImageInfo">重新获取图片信息</el-button>
        </span>
      </div>
      <img class="preview-dialog-img" :src="imageUrl" width="40%" />
    </el-dialog>
  </div>
</template>

<script>
import Cover from '@/components/cover';

export default {
  name: 'ImageUploader',
  components: { Cover },
  model: {
    prop: 'imageUrl',
    event: 'change',
  },
  props: {
    imageUrl: String,
    resourceFolder: String,
    onlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogDisplay: false,
      uploading: false,
      uploadToken: '',
      uploadCDN: '',
      imageInfo: null,
    };
  },
  watch: {
    imageUrl(newValue) {
      if (newValue) {
        this.getImageInfo();
      } else {
        this.imageInfo = null;
      }
    },
  },
  methods: {
    clearImage() {
      this.imageUrl = '';
      this.$emit('change', '');
    },
    beforeUpload: async function () {
      // 判断resourceFolder不为 nil 并且不为空
      if (!this.resourceFolder) {
        this.$message.error('请设置资源文件夹');
        return false;
      }
      // 判断是否只能查看
      if (this.onlyShow) {
        this.$message.error('只能查看，不能上传');
        return false;
      }

      const getTokenResp = await this.$request({
        url: '/v1/admin/get_open_resource_upload_token',
        method: 'GET',
        params: { folder: this.resourceFolder },
      });
      this.uploadToken = getTokenResp.data.token;
      this.uploadCDN = getTokenResp.data.cdn;
    },
    uploadSuccess(response) {
      this.uploading = false;
      this.$emit('change', `${this.uploadCDN}/${response.key}`);
    },
    uploadProgress() {
      this.uploading = true;
    },
    uploadError() {
      this.uploading = false;
    },
    getImageInfo() {
      console.log('getImageInfo', this.imageUrl);
      if (this.imageUrl) {
        fetch(this.imageUrl + '?imageInfo')
          .then((response) => response.json())
          .then((data) => {
            this.imageInfo = data;
          })
          .catch((error) => {
            console.error('Error fetching image info:', error);
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.preview {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-cover {
  opacity: 1;
}
.preview-cover {
  /* opacity: 0; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  /deep/ i {
    color: #fff;
  }
  /deep/ span {
    color: #fff;
  }
}
.preview-dialog-img {
  max-width: 100%;
  max-height: 1024px;
  margin-top: 16px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.image-info {
  font-size: 12px;
  line-height: 20px;
}

.preview {
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">分类配置</div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item label="推荐专辑：">
          <el-select v-model="xForm.album_ids" multiple filterable placeholder="选择专辑" style="width: 50%">
            <el-option v-for="item in albumOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + getStatus(item.value.status)" :value="item.value.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上架区域：">
          <regions-selecter v-model="xForm.regions"></regions-selecter>
        </el-form-item>

        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages"></languages-selecter>
        </el-form-item>

        <el-form-item label="会员状态：">
          <vip-status-selecter v-model="xForm.vip_status"></vip-status-selecter>
        </el-form-item>

        <el-form-item label="登录状态：">
          <login-status-selecter v-model="xForm.login_status_v2"></login-status-selecter>
        </el-form-item>

        <el-form-item label="上架状态：">
          <status-radio v-model="xForm.status" style="width: 40%"></status-radio>
        </el-form-item>

        <el-form-item label="生效时间：">
          <el-date-picker type="datetime" v-model="validFrom"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效时间：">
          <el-date-picker type="datetime" v-model="validTo"></el-date-picker>
        </el-form-item>

        <el-form-item label="优先级(0最大)(默认就2)：">
          <el-select v-model="xForm.sort_key" placeholder="请选择">
            <el-option :label="0" :value="0"> </el-option>
            <el-option :label="1" :value="1"> </el-option>
            <el-option :label="2" :value="2"> </el-option>
            <el-option :label="3" :value="3"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注信息：">
          <el-input v-model="xForm.remark" style="width: 30%"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import RegionsSelecter from '@/components/regions-selecter';
import LanguagesSelecter from '@/components/languages-selecter';
import VipStatusSelecter from '@/components/vip-status-selecter';
import LoginStatusSelecter from '@/components/login-status-selecter';
import StatusRadio from '@/components/status-radio';
import allStatus from '@/status.json';

export default {
  name: 'AlbumForm',
  components: {
    Container,
    RegionsSelecter,
    LanguagesSelecter,
    VipStatusSelecter,
    LoginStatusSelecter,
    StatusRadio,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = this.$env;
    return {
      loading: false,
      env,
      albumOptions: [],
      // dialogDisplay: {},
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
      },
    },
    validFrom: {
      get() {
        return this.xForm.valid_from * 1000;
      },
      set(val) {
        this.xForm.valid_from = parseInt(val / 1000);
      },
    },
    validTo: {
      get() {
        return this.xForm.valid_to * 1000;
      },
      set(val) {
        this.xForm.valid_to = parseInt(val / 1000);
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.form);
    },
    async fetchAlbums() {
      this.albumOptions = [];
      const res = await this.$request({
        url: '/v1/admin/meditation/albums',
        params: {
          offset: 0,
          limit: 1000,
        },
      });
      this.albumOptions = res.data
        .filter((v) => v.id !== this.form.id)
        .map((tag) => {
          return {
            value: tag,
            label: tag.name,
          };
        });
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    getStatus(status) {
      return allStatus[status];
    },
  },
  created() {
    this.fetchAlbums();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}

.album-table .el-form-item {
  width: 180px;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
}
</style>

<template>
  <container>
    <div slot="page-header-extra">
      <page-header>
        <i slot="logo" class="material-icons">attach_money</i>
        <h3 slot="title">活动管理</h3>
        <p slot="desc">管理运营活动的地方</p>
      </page-header>
    </div>
    <div class="container" slot="container">
      <div class="container-head">
        <div class="container-head-title">兑换活动管理</div>
        <div class="container-head-extra">
          <el-select
            v-model="abortedFilter"
            placeholder="请选择"
            :style="{
              marginLeft: '32px',
              marginRight: 'auto',
            }"
          >
            <el-option v-for="item in abortedFilterOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-form @submit.native.prevent="queryCode" :style="{ marginRight: '32px' }">
            <el-input type="text" placeholder="查询兑换码" v-model="queryKeyword">
              <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer" @click="queryCode"></i>
            </el-input>
          </el-form>
          <el-button :disabled="!$store.getters.hasEditRole" @click="addItem" type="text">新建兑换活动</el-button>
        </div>
      </div>
      <div class="container-body">
        <el-table :data="list" stripe style="width: 100%" v-infinite-scroll="fetchList" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column label="兑换活动">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.aborted">强制中止</span>
              <span v-else-if="scope.row.valid_from > now">未开始</span>
              <span v-else-if="scope.row.valid_to >= now">进行中</span>
              <span v-else>已结束</span>
            </template>
          </el-table-column>
          <el-table-column label="已使用/总数量">
            <template slot-scope="scope">{{ scope.row.used_count }} / {{ scope.row.count }}</template>
          </el-table-column>
          <el-table-column label="有效时间">
            <template slot-scope="scope">
              <div>{{ $moment(scope.row.valid_from * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div>{{ $moment(scope.row.valid_to * 1000).format('YYYY-MM-DD HH:mm:ss') }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button type="text" @click="toggleCode(scope.row)">{{ scope.row.aborted ? '恢复活动' : '强制中止' }}</el-button>
              <router-link class="el-button el-button--text" :to="{ name: 'RedeemCodeEditor', params: { redeem_code_group_id: scope.row.id } }">编辑</router-link>
              <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="exportItem(scope.row)">导出</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </container>
</template>

<script>
import _ from 'lodash';
import Container from '@/components/container';
import PageHeader from '@/components/page-header';
export default {
  name: 'SplashesManager',
  components: {
    Container,
    PageHeader,
  },
  data() {
    const now = parseInt(new Date() / 1000);
    return {
      abortedFilter: false,
      abortedFilterOptions: [
        { label: '正常', value: false },
        { label: '强制中止', value: true },
        { label: '全部', value: null },
      ],
      queryKeyword: '',
      now,
      popoverAdvancedVisible: false,
      expand: false,
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'RedeemCodesManager') {
        this.refetch();
      }
    },
    abortedFilter() {
      this.refetch();
    },
  },
  methods: {
    async queryCode() {
      try {
        const { data: code } = await this.$request({
          url: `/v1/admin/redeem_codes/${this.queryKeyword}`,
        });
        console.log(code);
        let message = '<div style="white-space:pre">';
        message += `兑换码: ${code.code}`;
        const expired = code.valid_to < this.now;
        const started = code.valid_from <= this.now;
        if (code.used_time) {
          const { data: user } = await this.$request({
            url: `/v1/admin/users/${code.used_by_user_id}/profile`,
          });
          message += `\n`;
          message += `\n兑换状态: 已兑换`;
          message += `\n兑换时间: ${this.$moment(code.used_time * 1000).format('YYYY-MM-DD HH:mm:ss')}`;
          message += `\n兑换者: <a target="_blank" href="/users/manager/${code.used_by_user_id}/edit">${user.nickname}</a>`;
        } else {
          message += `\n兑换状态: 未兑换`;
        }

        const { data: group } = await this.$request({
          url: `/v1/admin/redeem_code_groups/${code.group_id}`,
        });

        message += `\n`;
        message += `\n活动名: <a target="_blank" href="/operation/redeem-codes/${code.group_id}/edit">${group.name}</a>`;
        message += `\n活动时间: ${this.$moment(code.valid_from * 1000).format('YYYY-MM-DD HH:mm:ss')} 至 ${this.$moment(code.valid_to * 1000).format('YYYY-MM-DD HH:mm:ss')}`;
        if (code.aborted) {
          message += `\n活动状态: 强制中止`;
        } else if (!started) {
          message += `\n活动状态: 未开始`;
        } else if (expired) {
          message += `\n活动状态: 已结束`;
        } else {
          message += `\n活动状态: 进行中`;
        }
        console.log(message);
        message += `</div>`;
        this.$alert(message, '查询兑换码', {
          dangerouslyUseHTMLString: true,
        });
      } catch (e) {
        this.$message.error(e.response.data.msg);
      }
    },
    async toggleCode(item) {
      this.$confirm(`此操作将使该兑换活动${item.aborted ? '生效' : '失效'}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        try {
          await this.$request({
            method: 'PATCH',
            url: `/v1/admin/redeem_code_groups/${item.id}`,
            data: {
              aborted: !item.aborted,
            },
          });
          item.aborted = !item.aborted;
        } catch (e) {
          this.$message.error(e.response.data.msg);
        }
      });
    },
    addItem() {
      this.$router.push({
        name: 'RedeemCodeAdd',
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'RedeemCodeEditor',
        params: {
          redeem_code_group_id: item.id,
        },
      });
    },
    async exportItem(item) {
      const itemId = item.id;
      const res = await this.$request({
        method: 'POST',
        url: `/v1/admin/redeem_code_groups/${itemId}/export`,
        responseType: 'blob',
      });

      let blob = res.data;
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        let a = document.createElement('a');
        a.download = `${item.name}.csv`;
        a.href = e.target.result;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
    },
    submit() {
      console.log(_.cloneDeep(this.form));
      this.searchQuery = Object.assign({}, _.cloneDeep(this.form));
      this.popoverAdvancedVisible = false;
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetchList();
    },
    fetchList: async function () {
      this.busy = true;
      const limit = 50;
      const params = {
        limit,
        offset: this.list.length,
        aborted: this.abortedFilter,
      };
      const res = await this.$request({
        url: '/v1/admin/redeem_code_groups',
        params,
      });
      const users = res.data;
      if (users.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...users];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container-head-extra {
  width: 100%;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>

<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">分类配置</div>
      </div>
      <el-form class="form container-body" v-model="form">
        <el-form-item label="分类KEY">
          <el-input v-model="form.key"></el-input>
        </el-form-item>

        <el-form-item>
          <el-tabs type="border-card" v-model="activeLang">
            <el-tab-pane v-for="lang in langs" :key="lang" :label="lang" :name="lang">
              <el-col :span="23">
                <el-form-item label="分类名称">
                  <el-input v-model="form.name[lang]"></el-input>
                </el-form-item>
              </el-col>
            </el-tab-pane>
          </el-tabs>
        </el-form-item>

        <el-form-item>
          <div slot="label" class="form-label">
            <span>图标</span>
            <i class="el-icon-edit" @click="dialogDisplay.iconEdit = true"></i>
          </div>
          <el-col>
            <div class="preview" v-if="form.icon_url">
              <img class="preview-img" :src="form.icon_url" />
            </div>
            <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadIconProgress" :on-error="uploadIconError" :on-success="uploadIconSuccess" :before-upload="beforeUploadIcon" :data="{ token: picToken }" :show-file-list="false">
              <div class="uploadPic">
                <i class="el-icon-upload2"></i>
                <span>上传图标</span>
              </div>
            </el-upload>
          </el-col>
        </el-form-item>

        <el-form-item>
          <div slot="label" class="form-label">
            <span>图标（选中状态）</span>
            <i class="el-icon-edit" @click="dialogDisplay.iconEdit = true"></i>
          </div>
          <el-col>
            <div class="preview" v-if="form.selected_icon_url">
              <img class="preview-img" :src="form.selected_icon_url" />
            </div>
            <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadSelectedIconProgress" :on-error="uploadSelectedIconError" :on-success="uploadSelectedIconSuccess" :before-upload="beforeUploadIcon" :data="{ token: picToken }" :show-file-list="false">
              <div class="uploadPic">
                <i class="el-icon-upload2"></i>
                <span>上传图标</span>
              </div>
            </el-upload>
          </el-col>
        </el-form-item>

        <el-form-item label="展示我的混音入口" v-if="form.type === 'scene'">
          <el-radio-group v-model="form.style.show_mix_space_entrance">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="状态：">
          <el-radio-group v-model="form.status">
            <el-radio label="published">发布</el-radio>
            <el-radio label="reviewing">审阅</el-radio>
            <el-radio label="draft">草稿</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="iOS上架版本：">
          <el-input-tag v-model="form.ios_versions"></el-input-tag>
        </el-form-item>
        <el-form-item label="Android上架版本：">
          <el-input-tag v-model="form.android_versions"></el-input-tag>
        </el-form-item>
      </el-form>

      <div class="container-head">
        <div class="container-head-title">内容列表</div>
        <div class="container-body-header-extra">
          <el-button :disabled="!$store.getters.hasEditRole" @click="dialogDisplay.sortPanel = true" type="text">重新排序</el-button>
        </div>
      </div>

      <div class="container-body">
        <el-table ref="table" :data="allContents" style="width: 100%" @selection-change="handleSelectedContent" row-key="id">
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="名称" prop="name">
            <template slot-scope="scope">{{ getTR(scope.row.name) }}</template>
          </el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">{{ getStatus(scope.row.status) }}</template>
          </el-table-column>
          <el-table-column v-if="form.type == 'scene'" label="声音类型">
            <template slot-scope="scope">{{ getSceneType(scope.row.type) }}</template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog title="内容排序" width="40%" :visible.sync="dialogDisplay.sortPanel">
        <draggable class="sort-list-container" v-model="selectedContent">
          <div class="sort-item" v-for="(id, index) in selectedContent" :key="index">
            <div class="sort-item-key">{{ index + 1 }}</div>
            <div class="sort-item-name">{{ getTR(selectedContent[index].name) }}</div>
          </div>
        </draggable>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.sortPanel = false">返 回</el-button>
        </span>
      </el-dialog>
      <el-dialog title="Icon地址" :visible.sync="dialogDisplay.iconEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['icon_url']"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog title="Icon（选中）地址" :visible.sync="dialogDisplay.iconEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="form['selected_icon_url']"></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import Draggable from 'vuedraggable';
import ElInputTag from '@/components/el-input-tag';

export default {
  name: 'TagForm',
  components: {
    Container,
    Draggable,
    ElInputTag,
  },
  model: {
    prop: 'form',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = this.$env;
    return {
      loading: false,
      env,
      langs: require('@/langs.json'),
      activeLang: 'zh-Hans',
      allContents: [],
      selectedContent: [],
      picToken: '',

      busy: false,
      finish: false,
      dialogDisplay: {
        sortPanel: false,
        iconEdit: false,
      },
    };
  },
  watch: {
    selectedContent(data) {
      this.updateIDsField(data);
    },
  },
  computed: {},
  methods: {
    save() {
      this.$emit('save', this.form);
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
    handleSelectedContent(selected) {
      this.selectedContent = selected;
    },
    updateIDsField(selected) {
      const selectedIDs = selected.map((v) => v.id);
      console.log(selectedIDs);
      switch (this.form.type) {
        case 'meditation':
          this.form.meditation_ids = selectedIDs;
          break;
        case 'scene':
          this.form.scene_ids = selectedIDs;
          break;
        case 'article':
          this.form.article_ids = selectedIDs;
          break;
        case 'sleep_story':
          this.form.sleep_story_ids = selectedIDs;
          break;
      }
      console.log(this.form);
    },
    getSelectedIDs() {
      let selectedIDs;
      switch (this.form.type) {
        case 'meditation':
          selectedIDs = this.form.meditation_ids;
          break;
        case 'scene':
          selectedIDs = this.form.scene_ids;
          break;
        case 'article':
          selectedIDs = this.form.article_ids;
          break;
        case 'sleep_story':
          selectedIDs = this.form.sleep_story_ids;
          break;
      }
      return selectedIDs;
    },
    toggleInitialSelectedContent() {
      const selectedIDs = this.getSelectedIDs();
      for (let id of selectedIDs) {
        let content = this.allContents.filter((v) => v.id === id)[0];
        if (content) {
          this.$refs.table.toggleRowSelection(content, true);
        }
      }
    },
    getStatus(status) {
      return status === 'published' ? '已上架' : status === 'draft' ? '草稿' : status === 'reviewing' ? '审阅中' : '';
    },
    getSceneType(type) {
      return type === 'mixed' ? '混音' : '普通';
    },
    beforeUploadIcon: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadIconSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.form.icon_url = picUrl;
      this.iconUploading = false;
    },
    uploadIconProgress(response) {
      this.iconUploading = true;
    },
    uploadIconError(response) {
      this.iconUploading = false;
    },

    uploadSelectedIconSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.form.selected_icon_url = picUrl;
      this.iconUploading = false;
    },
    uploadSelectedIconProgress(response) {
      this.iconUploading = true;
    },
    uploadSelectedIconError(response) {
      this.iconUploading = false;
    },

    loadAllContents: async function () {
      let resp;
      let allContents;
      switch (this.form.type) {
        case 'meditation':
          resp = await this.$request({
            url: '/v1/admin/meditation/albums',
            params: {
              offset: 0,
              limit: 512,
            },
          });
          allContents = resp.data.map((v) => {
            return {
              id: v.id,
              name: v.name,
              status: v.status,
              order: this.form.meditation_ids.includes(v.id) ? -1 : v.sort_key + 1000,
            };
          });
          break;
        case 'scene':
          resp = await this.$request({
            url: '/v1/admin/scenes',
            params: {
              offset: 0,
              limit: 512,
            },
          });
          allContents = resp.data.map((v) => {
            return {
              id: v.id,
              name: v.name,
              status: v.status,
              order: this.form.scene_ids.includes(v.id) ? -1 : v.sort_key + 1000,
              type: v.type,
            };
          });
          break;
        case 'sleep_story':
          resp = await this.$request({
            url: '/v1/admin/sleep_stories',
            params: {
              offset: 0,
              limit: 512,
            },
          });
          allContents = resp.data.map((v) => {
            return {
              id: v.id,
              name: v.name,
              status: v.status,
              order: this.form.sleep_story_ids.includes(v.id) ? -1 : v.sort_key,
            };
          });
          break;
        case 'article':
          resp = await this.$request({
            url: '/v1/admin/meditation/articles',
            params: {
              offset: 0,
              limit: 512,
            },
          });
          allContents = resp.data.map((v) => {
            return {
              id: v.id,
              name: v.name,
              status: v.status,
              order: this.form.article_ids.includes(v.id) ? -1 : v.sort_key + 1000,
            };
          });
          break;
      }
      this.allContents = allContents;
    },
  },
  created: async function () {
    await this.loadAllContents();
    this.toggleInitialSelectedContent();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.layers {
  display: inline-flex;
}

.uploader-container {
  display: inline-flex;
  vertical-align: top;
}

.layer-container {
  position: relative;
  width: 480px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.layer {
  position: absolute;
  max-width: 480px;
  max-height: 160px;
  object-fit: cover;
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.layer-preview {
  margin-right: 16px;
}

.material-icons {
  font-size: 18px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview-img-alignment {
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  line-height: 28px;
}
.preview:hover .layer-actions {
  opacity: 1;
}
.layer-actions {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);

  i {
    font-size: 16px;
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.48);
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 0.72);
    }
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
}

.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}

.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}
.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}
</style>

<template>
  <a :href="`mailto:${to}?subject=${subject}&cc=talk@moreless.io&body=${body}`">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'Mailto',
  components: {},
  props: {
    to: String,
    quote: String,
    nickname: String,
    userId: String,
    platform: String,
    appVersion: String,
    system: String,
    device: String,
    createdAt: Number,
  },
  data() {
    return {};
  },
  computed: {
    body() {
      return (
        `` +
        `%0d%0a` +
        `%0d%0a` +
        `%0d%0a` +
        `%0d%0a` +
        `%0d%0a====================` +
        `%0d%0a ${this.quote}` +
        `%0d%0a` +
        `%0d%0a` +
        `%0d%0a` +
        `%0d%0a` +
        `%0d%0a Version: ${this.appVersion}` +
        `%0d%0a System: ${this.platform === 'android' ? 'Android' : this.platform} ${this.system}` +
        `%0d%0a Device: ${this.device}` +
        `%0d%0a User: ${this.userId}` +
        `%0d%0a Date: ${this.$moment(this.createdAt * 1000).format('ddd, MMM D YYYY, h:mm A')}`
      );
    },
    useChinese() {
      return /.*[\u4e00-\u9fa5]+.*/.test(this.body) || /.*[\u4e00-\u9fa5]+.*/.test(this.nickname);
    },
    subject() {
      if (this.useChinese) {
        return 'Re：潮汐 - 用户反馈 ';
      } else {
        return 'Re: Tide - Feedback';
      }
    },
  },
};
</script>

<style lang="less" scoped></style>

const $env = location.hostname === 'davinci.moreless.space' ? 'production' : 'dev';
export default {
  install(Vue) {
    Vue.prototype.$env = $env;
    Vue.env = $env;
  },
  $request: $env,
};

export { $env };

<template>
  <router-view v-if="$route.name !== 'PromotionRecordsManager'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">优惠使用记录</div>
        </div>
        <div class="container-body">
          <div class="container-body-left">
            <el-form :inline="true">
              <el-row>
                <el-form-item>
                  <el-date-picker v-model="dateRange" type="daterange" value-format="yyyy-MM-dd" clearable :disabled="busy" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="promotion_id" placeholder="选择优惠" :disabled="busy" clearable>
                    <el-option v-for="item in promotions" :key="item.key" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="user_id" :disabled="busy" placeholder="用户ID"></el-input>
                </el-form-item>
                <el-button @click="onClick" :disabled="busy">搜索</el-button>
              </el-row>
            </el-form>
          </div>
          <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50" style="margin-top: 30px">
            <el-table-column label="UserID">
              <template slot-scope="scope">
                <router-link :to="{ name: 'UserEditor', params: { user_id: scope.row.user_id } }">
                  {{ scope.row.user_id }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column prop="promotion_id" label="优惠ID"></el-table-column>
            <el-table-column label="优惠动作执行结果">
              <template slot-scope="scope">
                <span>{{ JSON.stringify(scope.row.promotion_action_result) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.created_at * 1000) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="更新时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.updated_at * 1000) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title="JSON" :visible.sync="JSONVisible">
            <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
          </el-dialog>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/container';
export default {
  name: 'ClientMetrics',
  components: {
    Container,
  },
  data() {
    return {
      promotions: [
        { key: '6035ed2c91cfe2023628b384', value: '价格歧视' },
        { key: '6036131e91cfe2023628b385', value: '微信关注' },
        { key: '60f55e1191cfccdd8f1e5f1a', value: 'Apple Wellness 2021' },
        { key: '63081508744025b380912743', value: 'Apple Wellness 2022' },
        { key: '610212280794842297204bff', value: '小鹏汽车' },
        { key: '61add421234ed78020cc3917', value: '邀请注册 - 登录 - 邀请方' },
        { key: '61add421234ed78020cc3918', value: '邀请注册 - 登录 - 受邀方' },
        { key: '63b62c52b867f0d4d8401b4e', value: '新手礼包' },
        { key: '61ca91ba0527ff6fcc83de24', value: 'Hylla x Tide' },
        { key: '6226f8a0182628aa319680aa', value: 'Wanli x Tide' },
        { key: '622ee45585e1031f414ed326', value: '2022 睡眠周 KOL' },
        { key: '625e16870e639289a0d4b8f6', value: 'BottleDream x Tide' },
        { key: '6268e27fe42236d75035e4ae', value: 'Flomo x Tide' },
        { key: '6268ff3be42236d75035e4af', value: '少数派' },
        { key: '627a23c5c5310836aa9a0df5', value: '看理想' },
        { key: '628af0dde42236d75035e4b0', value: '三顿半' },
        { key: '62d516089b66f7a31fe4a79f', value: 'Westin' },
        { key: '63286d3a05a24b911f743576', value: '喜林苑' },
        { key: '6372f53c244f839d59701bf7', value: '石头 TV' },
        { key: '6391ac4c115d0e26bfbf3a73', value: '亚朵' },
      ],

      JSONVisible: false,
      detail: '',

      dateRange: [this.defaultStartTime(), this.defaultEndTime()],
      user_id: '',
      promotion_id: '',
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'PromotionRecordsManager') {
        this.refetch();
      }
    },
  },
  methods: {
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    onClick() {
      this.refetch();
    },
    defaultStartTime() {
      return this.$moment()
        .startOf('day')
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
    },
    defaultEndTime() {
      return this.$moment()
        .startOf('day')
        .format('YYYY-MM-DD');
    },
    addOneDay(date) {
      return this.$moment(date)
        .add(1, 'days')
        .format('YYYY-MM-DD');
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function() {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: '/v1/admin/promotion_records',
        params: {
          limit,
          offset: this.list.length,
          start_from: (this.dateRange && this.dateRange[0]) || '',
          start_to: (this.dateRange && this.addOneDay(this.dateRange[1])) || '',
          user_id: this.user_id,
          promotion_id: this.promotion_id,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
</style>

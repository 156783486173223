<script>
import allStatus from '@/status.json';

const allStatusOptions = Object.keys(allStatus).map(value => {
  const showName = allStatus[value];
  return { value, showName };
});

export default {
  name: 'StatusRadio',
  props: {
    value: String,
  },
  data() {
    return {
      allStatus: allStatusOptions,
    };
  },
  methods: {
    getStatusName(key) {
      return allStatus[key];
    },
  },
  watch: {
    value(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<template>
  <el-radio-group v-model="value">
    <el-radio v-for="item in allStatus" :key="item.value" :label="item.value">{{ item.showName }}</el-radio>
  </el-radio-group>
</template>

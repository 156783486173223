import Layout from '@/components/layout';

import RolesIndex from '@/pages/roles/index';
import AddMember from '@/pages/roles/add';
import EditMemberRole from '@/pages/roles/edit';

export default [
  {
    path: '/roles',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '网管房',
    },
    children: [
      {
        path: '/',
        name: 'RolesManager',
        components: {
          default: RolesIndex,
        },
        meta: {
          requireAuth: true,
          label: '人员管理',
          newDesign: true,
        },
        children: [
          {
            path: 'add',
            name: 'AddMember',
            components: {
              default: AddMember,
            },
            meta: {
              requireAuth: true,
              label: '新增',
              newDesign: true,
            },
          },
          {
            path: ':user_id/edit',
            name: 'EditMemberRole',
            components: {
              default: EditMemberRole,
            },
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },
];

import Layout from '@/components/layout';
import Dailypics from '@/pages/dailypics/index';
import DailypicsManager from '@/pages/dailypics/manager/index';
import DailypicsSearch from '@/pages/dailypics/manager/search';
import DailypicsUpload from '@/pages/dailypics/manager/upload';

import DailypicEditor from '@/pages/dailypics/manager/edit';

export default [
  {
    path: '/dailypics',
    name: 'DailypicsIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '潮汐日帖',
    },
    redirect: {
      name: 'Dailypics',
    },
    children: [
      {
        path: 'index',
        name: 'Dailypics',
        components: {
          default: Dailypics,
        },
        meta: {
          requireAuth: true,
          label: 'Dailypics',
          newDesign: true,
        },
      },

      {
        path: 'manager/search',
        name: 'DailypicsSearch',
        components: {
          default: DailypicsSearch,
        },
        meta: {
          requireAuth: true,
          label: '资源搜索',
          newDesign: true,
        },
      },

      {
        path: 'manager/upload',
        name: 'DailypicsUpload',
        components: {
          default: DailypicsUpload,
        },
        meta: {
          requireAuth: true,
          label: '批量上传图片',
          newDesign: true,
        },
      },

      {
        path: 'manager/:year-:month',
        name: 'DailypicsManager',
        components: {
          default: DailypicsManager,
        },
        meta: {
          requireAuth: true,
          label: '资源管理',
          newDesign: true,
        },
        children: [
          {
            path: ':day/edit',
            name: 'DailypicEditor',
            components: {
              default: DailypicEditor,
            },
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
    ],
  },
];

<template>
  <router-view v-if="$route.name !== 'DeprecatedReceipts'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">废弃收据</div>
          <div class="container-body-header-extra">
            <el-form :model="form" @submit.native.prevent="submit">
              <el-input v-model="form.user_id" name="user_id" size="small" placeholder="请输入关键字" suffix-icon="el-icon-search"></el-input>
            </el-form>
          </div>
        </div>
        <div class="container-body">
          <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="进入队列时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.first_enqueue_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="更新时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="内容">
              <template slot-scope="scope" v-if="scope.row.receipt_info">
                <span>{{ scope.row.receipt_info.receipt.in_app.length }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="receipt_hash" label="receipt_hash"></el-table-column>
            <el-table-column prop="retry_times" label="retry_times"></el-table-column>
            <el-table-column prop="last_error" label="last_error"></el-table-column>
            <el-table-column prop="user_id" label="user_id"></el-table-column>
            <el-table-column width="120" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
                <el-button type="text" :disabled="!$store.getters.hasEditRole" @click="restore(scope.row)">重试</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
      </div>
    </container>
  </div>
</template>

<script>
import _ from 'lodash';
import Container from '@/components/container';
export default {
  name: 'DeprecatedReceipts',
  components: {
    Container,
  },
  data() {
    return {
      searchQuery: {
        user_id: '',
      },
      form: {
        user_id: '',
      },
      busy: false,
      finish: false,
      JSONVisible: false,
      detail: '',
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'DeprecatedReceipts') {
        this.refetch();
      }
    },
  },
  methods: {
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    restore: async function(data) {
      try {
        await this.$request({
          method: 'POST',
          url: `/v1/admin/app_store_deprecated_receipts/${data.id}/restore`,
        });
        this.list = this.list.filter(item => {
          return item.id !== data.id;
        });
        this.$message({
          message: '操作成功',
          type: 'success',
          duration: 2000,
        });
      } catch (e) {
        this.$message.error('操作失败');
      }
    },
    submit() {
      this.searchQuery = Object.assign({}, _.cloneDeep(this.form));
      this.popoverAdvancedVisible = false;
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function() {
      this.busy = true;
      const limit = 20;
      const res = await this.$request({
        url: '/v1/admin/app_store_deprecated_receipts',
        params: {
          limit,
          offset: this.list.length,
          ...this.searchQuery,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
</style>

<template>
  <router-view v-if="$route.name !== 'UsersManager'"></router-view>
  <div v-else>
    <container>
      <div class="container" slot="container">
        <div class="container-body-header">
          <div class="container-body-header-title">用户列表</div>
        </div>
        <div class="container-body">
          <div class="container-body-left">
            <el-form :model="form" @submit.native.prevent="submit" :inline="true">
              <el-form-item>
                <el-input name="user_id" v-model="form.user_id" placeholder="userID"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input name="sid" v-model="form.sid" placeholder="SID"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input name="nickname" v-model="form.nickname" placeholder="昵称"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input name="phone_number" v-model="form.phone_number" placeholder="手机号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input name="email" v-model="form.email" placeholder="email"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input name="nation_code" v-model="form.nation_code" placeholder="国家码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-col>
                  <el-button style="width: 100%" native-type="submit">搜索</el-button>
                </el-col>
              </el-form-item>
            </el-form>
          </div>

          <el-table :data="list" v-loading="busy" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50" @row-dblclick="rowDblClick">
            <el-table-column label="头像">
              <template slot-scope="scope">
                <img class="avatar" :src="scope.row.avatar || 'https://pics.tide.moreless.io/avatar/Fvut7zZlzpasbn9Uw-FZGzDpcM53'" />
              </template>
            </el-table-column>
            <el-table-column prop="sid" label="SID"></el-table-column>
            <el-table-column label="UUID">
              <template slot-scope="scope">
                <el-button type="text" @click="copyToBoard(scope.row.id)">{{ scope.row.id }} </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称"></el-table-column>
            <el-table-column prop="email" label="email"></el-table-column>
            <el-table-column label="手机">
              <template slot-scope="scope">
                <span>{{ scope.row.nation_code }} - {{ scope.row.phone_number }}</span>
              </template>
            </el-table-column>
            <el-table-column label="性别">
              <template slot-scope="scope">
                <span>{{ scope.row.sex === 0 ? '未知' : scope.row.sex === 1 ? '男' : '女' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="注册时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
            <el-table-column width="120" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="editItem(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import _ from 'lodash';
import Container from '@/components/container';
export default {
  name: 'UsersManager',
  components: {
    Container,
  },
  data() {
    return {
      searchQuery: {
        sid: '',
        nickname: '',
        email: '',
        phone_number: '',
        nation_code: '',
      },
      form: {
        user_id: '',
        sid: '',
        nickname: '',
        email: '',
        phone_number: '',
        nation_code: '',
      },
      popoverAdvancedVisible: false,
      expand: false,
      busy: false,
      finish: false,
      list: [],
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.name'(name) {
      if (name === 'UsersManager') {
        this.refetch();
      }
    },
  },
  methods: {
    editItem(item) {
      this.$router.push({
        name: 'UserEditor',
        params: {
          user_id: item.id,
        },
      });
    },
    submit() {
      console.log(_.cloneDeep(this.form));
      this.searchQuery = Object.assign({}, _.cloneDeep(this.form));
      this.popoverAdvancedVisible = false;
      this.refetch();
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: '/v1/admin/users',
        params: {
          fuzzy: true,
          limit,
          offset: this.list.length,
          orderby: 'DESC',
          ...this.searchQuery,
        },
      });
      const users = res.data;
      if (users.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...users];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    rowDblClick(row) {
      this.editItem(row);
    },
    copyToBoard(str) {
      this.$copyText(str).then(() => {
        this.$message('复制到剪切板');
      });
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}

.advanced {
  margin-left: 8px;
  font-size: 13px;
  flex-shrink: 0;
  color: #1890ff;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: #40a9ff;
  }
}
</style>

<template>
  <div class="cover" :style="{ width: `${width}px`, height: `${height}px` }">
    <img class="image" :src="`${src}?imageMogr2/thumbnail/!120x120r`" />
    <div class="mask" :style="{ background: `linear-gradient(rgba(${maskColor_0}), rgba(${maskColor_1}))` }"></div>
  </div>
</template>

<script>
export default {
  name: 'Cover',
  props: {
    height: {
      type: Number,
    },
    width: {
      type: Number,
    },
    maskColors: {
      type: Array,
    },
    src: {
      type: String,
    },
  },
  computed: {
    maskColor_0() {
      if (this.maskColors && this.maskColors.length) {
        return this.maskColors[0];
      } else {
        return '255,255,255,0';
      }
    },
    maskColor_1() {
      if (this.maskColors && this.maskColors.length) {
        return this.maskColors[1];
      } else {
        return '255,255,255,0';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.cover {
  position: relative;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<template>
  <equip-form :form="form" :o-data="oData" @save="save" @update="update"></equip-form>
</template>

<script>
import _ from 'lodash';
import Form from './form';
const DefaultData = (options) => {
  const data = {
    content: {
      'zh-Hans': {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        festival_icon: '',
        festival_link: '',
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
      'zh-Hant': {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
      en: {
        festival: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        quote: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
        author_title: {
          color: '255,255,255,1',
          font_name: 'PingFangSC-Semibold',
          font_size: 22,
          kern: 1,
          line_spacing: 11,
          text: '',
        },
      },
    },
    watermark: {
      url: '',
      height: 640,
      width: 165,
    },
    date: '',
    layout_template: '',
    pic_url: '',
    raw_pic: '',
    unsplash_id: '',
  };
  return Object.assign({}, data, options);
};
export default {
  name: 'EditEquip',
  components: {
    EquipForm: Form,
  },
  data() {
    return {
      oData: {},
      form: DefaultData(),
    };
  },
  watch: {
    '$route.params': {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    update(data) {
      this.form = data;
      this.oData = _.cloneDeep(this.form);
    },
    save: async function (data) {
      if (!data.raw_pic && !data.pic_url) {
        data.raw_pic = 'https://pics.tide.moreless.io/dailypics/default.jpg';
      }
      try {
        const res = await this.$request({
          url: 'v1/admin/dailypics',
          method: 'POST',
          data: data,
        });
        this.$message({
          message: '保存成功',
          type: 'success',
        });
        this.form = res.data;
        this.oData = _.cloneDeep(this.form);
      } catch (e) {
        this.$message.error(`保存失败, ${e.response.data.msg}`);
      }
    },
    init: async function () {
      const { year, month, day } = this.$route.params;
      const date = `${year}-${month}-${day}`;
      try {
        const res = await this.$request({
          url: `/v1/dailypics/${date}`,
        });
        const { data } = res;
        if (data.pic_url === 'https://pics.tide.moreless.io/dailypics/default.jpg') {
          data.pic_url = '';
        }
        this.form = Object.assign({}, this.form, data);
      } catch (e) {
        this.form = DefaultData({ date });
      }
      this.oData = _.cloneDeep(this.form);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped></style>

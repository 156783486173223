import Layout from '@/components/layout';

import Reviewers from '@/pages/development/reviewers';
import Configs from '@/pages/development/configs';
import ClientMetrics from '@/pages/development/client-metrics';
import ClientMetricsEvent from '@/pages/development/client-metrics-event';
import OAuth2Clients from '@/pages/development/oauth2/clients/index';
import AddOAuth2Client from '@/pages/development/oauth2/clients/add';
import EditOAuth2Client from '@/pages/development/oauth2/clients/edit';
import OAuth2Scopes from '@/pages/development/oauth2/scopes/index';
import AddOAuth2Scope from '@/pages/development/oauth2/scopes/add';
import EditOAuth2Scope from '@/pages/development/oauth2/scopes/edit';
import BusinessMetrics from '@/pages/development/business-metrics';
import DeprecatedReceipts from '@/pages/development/deprecated-receipts';

export default [
  {
    path: '/development',
    name: 'DevelopmentIndex',
    component: Layout,
    meta: {
      requireAuth: true,
      label: '码农小黑屋',
    },
    children: [
      {
        path: 'configs',
        name: 'Configs',
        components: {
          default: Configs,
        },
        meta: {
          requireAuth: true,
          label: '动态配置管理',
          newDesign: true,
        },
      },
      {
        path: 'oauth2_clients',
        name: 'OAuth2Clients',
        components: {
          default: OAuth2Clients,
        },
        meta: {
          requireAuth: true,
          label: 'OAuth2.0 Clients',
          newDesign: true,
        },
        children: [
          {
            path: 'add',
            name: 'AddOAuth2Client',
            components: {
              default: AddOAuth2Client,
            },
            meta: {
              requireAuth: true,
              label: '新增',
              newDesign: true,
            },
          },
          {
            path: ':client_id/edit',
            name: 'EditOAuth2Client',
            components: {
              default: EditOAuth2Client,
            },
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
      {
        path: 'oauth2_scopes',
        name: 'OAuth2Scopes',
        components: {
          default: OAuth2Scopes,
        },
        meta: {
          requireAuth: true,
          label: 'OAuth2.0 Scopes',
          newDesign: true,
        },
        children: [
          {
            path: 'add',
            name: 'AddOAuth2Scope',
            components: {
              default: AddOAuth2Scope,
            },
            meta: {
              requireAuth: true,
              label: '新增',
              newDesign: true,
            },
          },
          {
            path: ':scope_id/edit',
            name: 'EditOAuth2Scope',
            components: {
              default: EditOAuth2Scope,
            },
            meta: {
              requireAuth: true,
              label: '编辑',
              newDesign: true,
            },
          },
        ],
      },
      {
        path: 'reviewers',
        name: 'Reviewers',
        components: {
          default: Reviewers,
        },
        meta: {
          requireAuth: true,
          label: '审阅者管理',
          newDesign: true,
        },
      },
      {
        path: 'client_metrics',
        name: 'ClientMetrics',
        components: {
          default: ClientMetrics,
        },
        meta: {
          requireAuth: true,
          label: '客户端上报',
          newDesign: true,
        },
      },
      {
        path: 'client_metrics_event',
        name: 'ClientMetricsEvent',
        components: {
          default: ClientMetricsEvent,
        },
        meta: {
          requireAuth: true,
          label: '客户端上报(Event)',
          newDesign: true,
        },
      },
      {
        path: 'business_metrics',
        name: 'BusinessMetrics',
        components: {
          default: BusinessMetrics,
        },
        meta: {
          requireAuth: true,
          label: '用户行为记录',
          newDesign: true,
        },
      },
      {
        path: 'deprecated_receipts',
        name: 'DeprecatedReceipts',
        components: {
          default: DeprecatedReceipts,
        },
        meta: {
          requireAuth: true,
          label: '废弃收据',
          newDesign: true,
        },
      },
    ],
  },
];
